import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() class?: string;

  @Input() label?: string;

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Input() color: 'undefined' | 'primary' | 'accent' | 'warn' = 'undefined';

  @Input() checked = false;

  @Input() disabled = false;

  @Output() valueChange = new EventEmitter();

  public onChange: (value?: boolean) => void = () => {
    return;
  };

  public onTouched: () => void = () => {
    return;
  };

  public setValue(event: MatCheckboxChange): void {
    if (!this.disabled) {
      this.onChange(event.checked);
      this.onTouched();
      this.valueChange.emit();
    }
  }

  public writeValue(state: boolean): void {
    this.checked = state;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
