import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf } from '@angular/core';

import { Subscription } from 'rxjs';

import { PeanutTreeService } from '../../services/peanut-tree.service';
import { UIStrategyType } from '../../ui-strategy-elements/strategy-types';

@Component({
  selector: 'app-edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['./edit-panel.component.scss'],
})
export class EditPanelComponent implements OnInit, OnDestroy {
  readonly UIStrategyType = UIStrategyType;

  @Input() domains?: string[];

  @Input() tags?: string[];

  @Input() productProperties?: string[];

  @Input() varsMap: Map<string, string> = new Map();

  @Input() shopsByDomain?: Map<string, string[]>;

  @Input() isPanelEnabled = true;

  @Output() toggleCallback = new EventEmitter<boolean>();

  subscription = new Subscription();

  type = '';

  constructor(@SkipSelf() public treeStore: PeanutTreeService) {}

  get vars(): string[] {
    return [...this.varsMap.values()];
  }

  ngOnInit() {
    this.toggleCallback.emit(true);
    this.subscription = this.treeStore.selectedChildData$.subscribe((data) => {
      this.type = data?.strategyType || '';
    });
  }

  ngOnDestroy() {
    this.toggleCallback.emit(false);
    this.subscription.unsubscribe();
  }
}
