import { PeanutTreeUI } from '../PeanutTree';

/**
 * Searches for last node's ruleName and returns it(string) or null
 */

export const getBranchRuleName = (tree: PeanutTreeUI): string | null =>
  tree.children?.length > 0 ? getBranchRuleName(tree.children[0]) : tree.data.ruleName || null;

// Checks if source parent of dragged children had only child then we should assign the ruleName of child to parent
export const shouldPropagateChildRuleNameToSourceParent = (
  draggedParent: PeanutTreeUI | undefined
): boolean => !!draggedParent && draggedParent.children.length === 1;

/**
 * Recursively set leaf nodes ruleName to null (i.e: cleanup moved/copied before append to target)
 */
export const resetLeavesRuleNameToNull = (tree: PeanutTreeUI): void => {
  if (tree.children.length === 0) {
    tree.data.ruleName = null;
  } else {
    tree.children.forEach((child) => resetLeavesRuleNameToNull(child));
  }
};

/**
 * Recursively set 1st leaf node ruleName to target ruleName
 * - target ruleName (i.e target is leaf and has a name)
 */
export const setFirstLeafRuleNameToTargetRuleName = (
  tree: PeanutTreeUI,
  target: PeanutTreeUI
): void => {
  if (tree.children.length === 0) {
    tree.data.ruleName = target.data.ruleName;
  } else {
    setFirstLeafRuleNameToTargetRuleName(tree.children[0], target);
  }
};
