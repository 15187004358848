import { createSelector } from '@ngrx/store';

import { UserRoles } from '@app/models/enum/user-roles.enum';

import { AppState } from '@store/app.state';

import { UserDetailsState } from './details.reducers';

const selectUserDetailsKey = ({ user }: AppState) => user.details;

export const selectUserDetails = createSelector(
  selectUserDetailsKey,
  (userDetails: UserDetailsState) => userDetails
);

export const selectUserId = createSelector(selectUserDetails, ({ id }) => id);

export const selectIsAdmin = createSelector(selectUserDetails, ({ roles }) =>
  roles?.includes(UserRoles.ADMIN)
);

export const selectIsCompanyAdmin = createSelector(
  selectUserDetails,
  ({ roles }) => roles?.includes(UserRoles.COMPANY_ADMIN) || roles?.includes(UserRoles.ADMIN)
);
