import { DIRECT_SCRAPING, DIRECT_SCRAPING_CONNECTOR } from '@app/models/const/pricing-strategy';
import { ApiStrategyFactory } from '@app/utils/StrategyConverter/api-strategy-factory';

import {
  API_QUERY_TYPES,
  ApiComparator,
  ApiCompetitorsOnPosition,
  ApiConditionsQuery,
  ApiFilterMatcherType,
  ApiFormulaComparison,
  ApiFormulaPriceFilter,
  ApiQueryTypes,
  ApiStrategyContextModifiers,
  ApiStrategyName,
  ApiTagBasedFilter,
  IApiStrategyCondition,
  IApiStrategyFilters,
} from '@models/interfaces/api-strategies';

import { NumericComparator, PriceGapUnit, UIStrategyType } from '@components/index';
import {
  CompetitivePricingStrategy,
  Shop,
} from '@components/strategy-component/ui-strategy-elements/competitive-pricing/competitive-pricing';
import {
  IfCompetitionStrategy,
  IfFormulaAppliesStrategy,
  IfRecommendedPriceStrategy,
  TimestampRangeCondition,
} from '@components/strategy-component/ui-strategy-elements/conditions/conditions';
import { StockClearanceStrategy } from '@components/strategy-component/ui-strategy-elements/goal-based-pricing/stock-clearance';
import { PriceFormulaStrategy } from '@components/strategy-component/ui-strategy-elements/price-formula/price-formula';
import { SafetyRuleStrategy } from '@components/strategy-component/ui-strategy-elements/saftey-rule/safety-rule';
import { StartStrategy } from '@components/strategy-component/ui-strategy-elements/start/start';
import { UIStrategyElement } from '@components/strategy-component/ui-strategy-elements/ui-strategy-element';

/**
 * START Peanut tree strategy filters types
 * TODO: CON-277 to be consumed from Quantum peanuttree component in quantum
 */
export interface IfStrategyFilters extends UIStrategyElement {
  query: Condition[];
}

// Each condition holds its data and holds the logical operator that links to next condition in query
export interface Condition {
  data: StringComparisonCondition | TimestampRangeCondition; // or any other condition type
  operator?: ApiQueryTypes;
}

interface StringComparisonCondition {
  tagName?: string;
  matcher: ApiFilterMatcherType;
  tagValue?: string;
  productPropertyName?: string;
  productPropertyValue?: string;
}

/**
 * END Peanut tree strategy filters types
 */
export class PeanutTreeFactory {
  // Used to uppercase the API formula functions signatures before render UI
  static functionSignatureToUppercase(formula?: string): string | undefined {
    const formulaFunctionsSignatures = {
      max2: 'MAX2',
      max3: 'MAX3',
      max4: 'MAX4',
      max5: 'MAX5',
      min2: 'MIN2',
      min3: 'MIN3',
      min4: 'MIN4',
      min5: 'MIN5',
    };
    const functionsSignaturesRegex = /(max[2-5]|min[2-5])/g;
    return formula?.replace(
      functionsSignaturesRegex,
      (term) => formulaFunctionsSignatures[term as keyof typeof formulaFunctionsSignatures]
    );
  }

  static createStartStrategy(apiStrategy: ApiStrategyContextModifiers): StartStrategy {
    const selectors = apiStrategy.StrategyContextModifiers.selectors;
    const strategy: StartStrategy = {
      strategyType: UIStrategyType.START,
      isElementValid: true,
      priceGap: PeanutTreeFactory.functionSignatureToUppercase(selectors.priceGap?.formula),
      priceGapUnit: selectors.priceGapUnit?.value as unknown as PriceGapUnit,
      targetPosition: PeanutTreeFactory.functionSignatureToUppercase(
        selectors.targetPosition?.formula
      ),
      priceRequirement: selectors.calculatePriceWhenNoOffersExist?.value,
      adjustToNextPricier: selectors.adjustToNextPricier?.value,
    };
    if (apiStrategy.StrategyContextModifiers.id) {
      strategy.id = apiStrategy.StrategyContextModifiers.id;
    }
    return strategy;
  }

  static createPriceFormulaStrategy(
    apiStrategy: ApiStrategyContextModifiers
  ): PriceFormulaStrategy {
    const selectors = apiStrategy.StrategyContextModifiers.selectors;
    const strategy: PriceFormulaStrategy = {
      strategyType: UIStrategyType.PRICE_FORMULA,
      isElementValid: true,
      priceRequirement: selectors.calculatePriceWhenNoOffersExist?.value,
      priceFormula: PeanutTreeFactory.functionSignatureToUppercase(selectors.priceFormula?.value),
    };
    if (apiStrategy.StrategyContextModifiers.id) {
      strategy.id = apiStrategy.StrategyContextModifiers.id;
    }
    return strategy;
  }

  static createSafetyRuleStrategy(apiStrategy: ApiStrategyContextModifiers): SafetyRuleStrategy {
    const strategy: SafetyRuleStrategy = {
      strategyType: UIStrategyType.SAFETY_RULE,
      isElementValid: true,
      minPrice: PeanutTreeFactory.functionSignatureToUppercase(
        apiStrategy.StrategyContextModifiers.selectors.minPrice?.formula
      ),
      maxPrice: PeanutTreeFactory.functionSignatureToUppercase(
        apiStrategy.StrategyContextModifiers.selectors.maxPrice?.formula
      ),
    };
    if (apiStrategy.StrategyContextModifiers.id) {
      strategy.id = apiStrategy.StrategyContextModifiers.id;
    }
    return strategy;
  }

  /**
   * Hint: UI representation of scraping shops, so we combine domain to the shop name ĺike this: shopName/domain to distinguish similar direct scraping shops having the same name.
   */
  static createCompetitivePricingStrategy(
    apiStrategy: ApiStrategyContextModifiers,
    directScrapingShops: Shop[]
  ): CompetitivePricingStrategy {
    const selectors = apiStrategy.StrategyContextModifiers.selectors;
    const strategy: CompetitivePricingStrategy = {
      strategyType: UIStrategyType.COMPETITIVE_PRICING,
      isElementValid: true,
      priceGap: PeanutTreeFactory.functionSignatureToUppercase(selectors.priceGap?.formula),
      priceGapUnit: selectors.priceGapUnit?.value as unknown as PriceGapUnit,
      vendorOfferFilter: selectors.vendorOfferFilter?.vendors.flatMap((vendorsByDomain) => {
        const isDirectScrapingShop = directScrapingShops.some((shop) => {
          return shop.domain === vendorsByDomain.domain;
        });
        return vendorsByDomain.vendorNames.map((vendorName) => ({
          domain: isDirectScrapingShop ? DIRECT_SCRAPING : vendorsByDomain.domain,
          shop: isDirectScrapingShop
            ? vendorName + DIRECT_SCRAPING_CONNECTOR + vendorsByDomain.domain
            : vendorName,
        }));
      }),
      targetPosition: PeanutTreeFactory.functionSignatureToUppercase(
        selectors.targetPosition?.formula
      ),
      includeDeliveryCosts: selectors.includeDeliveryCosts?.value,
    };
    if (apiStrategy.StrategyContextModifiers.id) {
      strategy.id = apiStrategy.StrategyContextModifiers.id;
    }
    return strategy;
  }

  static createStockClearanceStrategy(
    apiStrategy: ApiStrategyContextModifiers
  ): StockClearanceStrategy {
    const selectors = apiStrategy.StrategyContextModifiers.selectors;
    const strategy: StockClearanceStrategy = {
      strategyType: UIStrategyType.STOCK_CLEARANCE,
      isElementValid: true,
      startDate: selectors.startDate?.value
        ? { value: selectors.startDate.value }
        : { tagKey: selectors.startDate?.tagKey as string },
      endDate: selectors.endDate?.value
        ? { value: selectors.endDate.value }
        : { tagKey: selectors.endDate?.tagKey as string },
      targetStockLevel: PeanutTreeFactory.functionSignatureToUppercase(
        selectors.targetStockLevel?.formula
      ),
      continuePricing: selectors.calculatePriceAfterEndDate?.value, // tagValue is not supported here
    };
    if (apiStrategy.StrategyContextModifiers.id) {
      strategy.id = apiStrategy.StrategyContextModifiers.id;
    }
    return strategy;
  }

  /**
   *
   * @param apiStrategy
   * @returns
   */
  static createStrategyFilters(apiStrategy: IApiStrategyFilters): IfStrategyFilters {
    let query: Condition[] = [];
    const apiQuery = (apiStrategy as IApiStrategyFilters)[ApiStrategyName.FILTERS]?.query;
    // Query of nested AND/OR will be parsed recursively
    if (apiQuery?.or || apiQuery?.and) {
      this.convertNestedApiStrategyConditions(apiQuery as ApiConditionsQuery, query);
      const length = query.length;
      query.forEach((con, i) => {
        query[length - i - 1].operator = query[length - i - 2]?.operator;
        return con;
      });
      // UI requires root condition has no operator (starting from condition at pos1 we assign operators)
      delete query[0].operator;
    }

    if (apiQuery?.conditions) {
      // Query of single string comparision condition
      query = this.convertSingleApiStrategyConditions(apiQuery as IApiStrategyCondition);
    }

    const strategyType: UIStrategyType = this.extractStrategyFilterType(query);

    return this.createStrategyFilterElement(strategyType, query, apiStrategy);
  }

  static extractStrategyFilterType(query: Condition[]): UIStrategyType {
    if (query.length && query[0].data.hasOwnProperty('tagName')) {
      return UIStrategyType.IF_TAG;
    } else {
      return UIStrategyType.IF_TIMESTAMP_RANGE;
    }
  }

  static createStrategyFilterElement(
    strategyType: UIStrategyType,
    query: Condition[],
    apiStrategy: IApiStrategyFilters
  ): IfStrategyFilters {
    const ifTagStrategy: IfStrategyFilters = {
      strategyType,
      isElementValid: true,
      query,
    };
    const id = this.retrieveId(apiStrategy);
    if (id) {
      ifTagStrategy.id = id;
    }
    return ifTagStrategy;
  }

  /**
   * This function is deprecated, refrain from using it
   * ApiTagBasedFilter is deprecated and instead we are using IApiStrategyFilters
   *
   * This method handles the already saved/deprecated strategies with ApiTagBasedFilter
   *
   * @param apiStrategy
   * @returns
   */
  static createTagBasedStrategy(apiStrategy: ApiTagBasedFilter): IfStrategyFilters {
    let ifTagStrategy: IfStrategyFilters = {
      strategyType: UIStrategyType.IF_TAG,
      isElementValid: true,
      query: [
        {
          data: {
            tagName: (apiStrategy as ApiTagBasedFilter).TagBasedFilter.key,
            matcher: 'exact',
            tagValue: (apiStrategy as ApiTagBasedFilter).TagBasedFilter.value,
          },
        },
      ],
    };

    const id = this.retrieveId(apiStrategy);
    if (id) {
      ifTagStrategy.id = id;
    }

    return ifTagStrategy;
  }

  static convertNestedApiStrategyConditions(
    apiQuery: ApiConditionsQuery,
    uiQuery: Condition[] = [],
    operator?: ApiQueryTypes
  ): void {
    Object.keys(apiQuery).forEach((key) => {
      if (key === API_QUERY_TYPES.AND || key === API_QUERY_TYPES.OR) {
        // @ts-ignore
        apiQuery[key as ApiQueryTypes].forEach((c) => {
          this.convertNestedApiStrategyConditions(c, uiQuery, key);
        });
      }
      if (key === API_QUERY_TYPES.CONDITIONS) {
        // else means it is a single condition
        uiQuery.push(this.buildCondition(apiQuery as IApiStrategyCondition, operator));
        operator = undefined;
      }
    });
  }

  static convertSingleApiStrategyConditions(query: IApiStrategyCondition): Condition[] {
    return [this.buildCondition(query, undefined)];
  }

  static buildCondition(query: IApiStrategyCondition, operator?: ApiQueryTypes): Condition {
    const apiQueryCondition = query?.conditions;
    const condition = apiQueryCondition?.stringComparison
      ? this.buildStringComparisonCondition(query)
      : this.buildTimestampRangeCondition(query);
    return { ...condition, ...(operator ? { operator } : {}) };
  }

  static buildStringComparisonCondition(query: IApiStrategyCondition): Condition {
    const apiQueryCondition = query?.conditions;
    let data: Partial<StringComparisonCondition> = {
      matcher: apiQueryCondition?.stringComparison?.matcher.type as ApiFilterMatcherType,
    };

    if (apiQueryCondition?.stringComparison?.leftString.productPropertyKey) {
      data.productPropertyName = apiQueryCondition?.stringComparison?.leftString
        .productPropertyKey as string;
      data.productPropertyValue = apiQueryCondition?.stringComparison?.rightString.value as string;
      data.tagName = '';
      data.tagValue = '';
    } else {
      data.tagName = apiQueryCondition?.stringComparison?.leftString.tagKey as string;
      data.tagValue = apiQueryCondition?.stringComparison?.rightString.value as string;
    }
    return { data } as Condition;
  }

  static buildTimestampRangeCondition(query: IApiStrategyCondition): Condition {
    const apiQueryCondition = query?.conditions;
    const timestampCondition: Condition = {
      data: {
        start: {
          value: apiQueryCondition?.timestampRange?.start?.value as string,
          tagKey: apiQueryCondition?.timestampRange?.start?.tagKey as string,
          productPropertyKey: apiQueryCondition?.timestampRange?.start
            ?.productPropertyKey as string,
        },
        end: {
          value: apiQueryCondition?.timestampRange?.end?.value as string,
          tagKey: apiQueryCondition?.timestampRange?.end?.tagKey as string,
          productPropertyKey: apiQueryCondition?.timestampRange?.end?.productPropertyKey as string,
        },
      },
    };
    return ApiStrategyFactory.removeUndefinedProperties(timestampCondition);
  }

  static createIfCompetitionStrategy(apiStrategy: ApiCompetitorsOnPosition): IfCompetitionStrategy {
    const competitorsMatcher: ApiComparator = Object.keys(
      apiStrategy.CompetitorsOnPosition.matcher
    )[0] as ApiComparator;
    const positionAttr = apiStrategy.CompetitorsOnPosition.position;
    const positionMatcher: ApiComparator | undefined = positionAttr
      ? (Object.keys(positionAttr)[0] as ApiComparator)
      : undefined;
    const ifCompetitionStrategy: IfCompetitionStrategy = {
      strategyType: UIStrategyType.IF_COMPETITOR,
      isElementValid: true,
      competitorsMatcher: {
        operator: competitorsMatcher as unknown as NumericComparator,
        matchValue: apiStrategy.CompetitorsOnPosition.matcher[competitorsMatcher].matchValue,
      },
    };
    if (positionAttr && positionMatcher) {
      ifCompetitionStrategy.positionMatcher = {
        operator: positionMatcher as unknown as NumericComparator,
        matchValue: positionAttr[positionMatcher].matchValue,
      };
    }
    if (apiStrategy.CompetitorsOnPosition.id) {
      ifCompetitionStrategy.id = apiStrategy.CompetitorsOnPosition.id;
    }
    return ifCompetitionStrategy;
  }

  static createIfRecommendedPriceStrategy(
    apiStrategy: ApiFormulaPriceFilter
  ): IfRecommendedPriceStrategy {
    const strategy: IfRecommendedPriceStrategy = {
      strategyType: UIStrategyType.IF_RECOMMENDED_PRICE,
      isElementValid: true,
      formula: PeanutTreeFactory.functionSignatureToUppercase(
        apiStrategy.FormulaPriceFilter.formula
      )!,
      operator: apiStrategy.FormulaPriceFilter.matcher.type as NumericComparator,
    };
    if (apiStrategy.FormulaPriceFilter.id) {
      strategy.id = apiStrategy.FormulaPriceFilter.id;
    }
    return strategy;
  }

  static createIfFormulaAppliesStrategy(
    apiStrategy: ApiFormulaComparison
  ): IfFormulaAppliesStrategy {
    const strategy: IfFormulaAppliesStrategy = {
      strategyType: UIStrategyType.IF_FORMULA,
      isElementValid: true,
      leftFormula: PeanutTreeFactory.functionSignatureToUppercase(
        apiStrategy.FormulaComparison.leftFormula
      )!,
      rightFormula: PeanutTreeFactory.functionSignatureToUppercase(
        apiStrategy.FormulaComparison.rightFormula
      )!,
      operator: apiStrategy.FormulaComparison.matcher.type as NumericComparator,
    };
    if (apiStrategy.FormulaComparison.id) {
      strategy.id = apiStrategy.FormulaComparison.id;
    }
    return strategy;
  }

  /*
   * This method is used for strategies that can be either of type IApiStrategyFilters | ApiTagBasedFilter.
   * With the strategy type not being known the retrieving of the id isn't straight forward and deserves a sparate method.
   */
  private static retrieveId(
    apiStrategy: IApiStrategyFilters | ApiTagBasedFilter
  ): number | undefined {
    if ((apiStrategy as ApiTagBasedFilter)[ApiStrategyName.TAG_BASED]) {
      return (apiStrategy as ApiTagBasedFilter)[ApiStrategyName.TAG_BASED]?.id;
    }
    if ((apiStrategy as IApiStrategyFilters)[ApiStrategyName.FILTERS]) {
      return (apiStrategy as IApiStrategyFilters)[ApiStrategyName.FILTERS]?.id;
    }
    return undefined;
  }
}
