<div class="flex flex-row place-content-end pt-2 pb-2">
  <div class="flex flex-col text-right">
    <span class="text-sx font-light">Fixed price</span>
    <mat-spinner *ngIf="fixedPriceLoading" [diameter]="16"></mat-spinner>
    <span class="font-bold" *ngIf="!fixedPriceLoading">{{
      fixedPrice ? fixedPrice + ' €' : 'None'
    }}</span>
  </div>
  <div class="mx-4 w-0.5 self-stretch bg-gray-300"></div>
  <app-raised-button
    class="pr-6"
    color="primary"
    text="Set fixed price"
    (clickEvent)="openFixedPriceSelection()"
    [disabled]="fixedPriceLoading"
  ></app-raised-button>
</div>
