<button
  *ngIf="!isIconButton; else icon"
  mat-button
  [disabled]="disabled"
  [matMenuTriggerFor]="menu"
  aria-label="button with a menu"
>
  {{ placeholder }}
</button>
<ng-template #icon>
  <button
    mat-icon-button
    class="floating-icon-button"
    [matMenuTriggerFor]="menu"
    [disabled]="disabled"
    aria-label="icon-button with a menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <button
    [attr.data-external-id]="item?.externalId"
    *ngFor="let item of items"
    (click)="selected.emit(item)"
    mat-menu-item
  >
    {{ item.label }}
  </button>
</mat-menu>
