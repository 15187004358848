import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-menu-select',
  templateUrl: './menu-select.component.html',
  styleUrls: ['./menu-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MenuSelectComponent,
    },
  ],
})
export class MenuSelectComponent implements ControlValueAccessor {
  @Input() disabled = false;

  @Input() required = false;

  @Input() multipleSelection = false;

  @Input() key?: string;

  @Input() label?: string;

  @Input() placeholder?: string;

  @Input() hint?: string;

  @Input() items?: unknown[];

  public selectedValue = '';

  get selectValue(): string {
    return this.selectedValue;
  }

  set selectValue(value: string) {
    if (value !== this.selectedValue) {
      // @ts-ignore
      this.selectedValue = value[this.key];
      this.onChange(this.selectValue);
    }

    this.onTouched();
  }

  public onChange: (value: string) => void = () => {
    return;
  };

  public onTouched: () => void = () => {
    return;
  };

  public writeValue(selectedValue: string): void {
    this.selectedValue = selectedValue;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
