<div class="p-2">
  <label class="block pb-4 text-center text-xl font-bold" id="delete-node-label">
    How do you want to proceed?
  </label>
  <mat-radio-group aria-labelledby="delete-node-label" [(ngModel)]="isNotDeletingChildren">
    <div>
      <mat-radio-button class="py-2" [disabled]="data.isSingleDisabled" [value]="true"
        >Single element</mat-radio-button
      >
    </div>
    <div>
      <mat-radio-button class="py-2" [disabled]="data.isWithChildrenDisabled" [value]="false"
        >Incl. elements to the right</mat-radio-button
      >
    </div>
  </mat-radio-group>
  <div mat-dialog-actions class="justify-center">
    <app-basic-button
      text="Cancel"
      [hasIconAfterText]="false"
      [color]="'primary'"
      (clickEvent)="dialogRef.close()"
    >
    </app-basic-button>
    <app-basic-button
      text="Confirm"
      [hasIconAfterText]="false"
      [color]="'primary'"
      [isRaisedButton]="true"
      [mat-dialog-close]="isNotDeletingChildren"
    >
    </app-basic-button>
  </div>
</div>
