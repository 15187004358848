import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { PricemonitorApiProductV3 } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { BehaviorSubject, combineLatest, filter, takeUntil } from 'rxjs';

import { cloneDeep } from 'lodash';

import { DIRECT_SCRAPING, DIRECT_SCRAPING_CONNECTOR } from '@app/models/const/pricing-strategy';
import { APP_ROUTES } from '@app/routes/routes.constants';
import {
  loadingSpinnerHide,
  loadingSpinnerShow,
} from '@app/store/loader-spinner/loader-spinner.actions';

import { StorageService } from '@services/local-storage/pricing-strategy-storage.service';

import { AppState } from '@store/app.state';
import {
  fetchDomains,
  fetchProductProperties,
  fetchShopsByDomain,
  fetchStrategy,
  fetchTags,
  fetchTaskData,
  fetchTaskStatus,
  fetchVendorSettings,
  pricingStrategyDestroy,
  pricingStrategyInit,
  selectProductData,
  selectProductProperties,
  selectShopsByDomain,
  selectStrategyApiData,
  selectStrategyDomains,
  selectStrategyTags,
  selectStrategyVendorSettings,
  selectTaskData,
  selectVarsMap,
} from '@store/pricing-strategy';
import { SnackbarWarningAction } from '@store/snackbar';
import {
  contractSettingsAction,
  selectActiveContract,
  selectContractSettings,
  selectUserDetails,
} from '@store/user';

import { IBadgeType, IJobStatus, IJobStatusFailures } from '@models/common';
import { ContractFeatures } from '@models/enum/contract-features.enum';
import { UserRoles } from '@models/enum/user-roles.enum';
import { ApiStrategyTreeResponse, StrategyMetadata } from '@models/interfaces/api-strategies';
import { ITaskData } from '@models/interfaces/task-data.interface';

import { PeanutTreeUI } from '@components/strategy-component/PeanutTree';
import { Shop } from '@components/strategy-component/ui-strategy-elements/competitive-pricing/competitive-pricing';

import { filterNullAndUndefined } from '@utils/helpers/is-defined';
import { StatusTypeMapper } from '@utils/helpers/status-type.mapper';
import { StrategyConverter } from '@utils/StrategyConverter/strategy-converter';
import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Component({
  selector: 'app-base-pricing-strategy',
  templateUrl: './base-pricing-strategy.component.html',
  styleUrls: ['./base-pricing-strategy.component.scss'],
})
export class BasePricingStrategyComponent
  extends SubscriptionManagementBaseClass
  implements OnInit, OnDestroy
{
  tree?: PeanutTreeUI;

  _tree = new BehaviorSubject<PeanutTreeUI | undefined>(undefined);

  domains?: string[];

  tags?: string[];

  productProperties?: string[];

  taskStatusData?: IJobStatus;

  activeContractSid = '';

  // used to suggest shop names in the competitive pricing element for differnet data sources domains and direct scraping ones
  shopsByDomain?: Map<string, string[]>;

  // Holds direct scraping pairs of domain-shop, used to convert api to competitive pricing nodes with direct scraping
  directScrapingDomainShopPairs: Shop[] = [];

  // Local states

  strategyMetadata?: StrategyMetadata;

  varsMap = new Map<string, string>();

  shops?: Map<string, string[]>;

  showFeatureMissingWarning = false;

  protected strategyConverter = new StrategyConverter();

  protected apiStrategyTree?: ApiStrategyTreeResponse;

  protected activeContractId?: number;

  // Private members

  private taskData?: ITaskData;

  private readonly defaultVarsMap = new Map<string, string>([
    ['<ownPrice>', 'CURRENT_PRICE'],
    ['<ownPosition>', 'CURRENT_POSITION'],
    ['<deliveryCosts>', 'DELIVERY_COSTS'],
    ['<averageUnitPrice>', 'AVERAGE_UNIT_PRICE'],
    ['<averageTotalPrice>', 'AVERAGE_TOTAL_PRICE'],
    ['<lowestUnitPrice>', 'LOWEST_UNIT_PRICE'],
    ['<lowestTotalPrice>', 'LOWEST_TOTAL_PRICE'],
    ['<highestUnitPrice>', 'HIGHEST_UNIT_PRICE'],
    ['<highestTotalPrice>', 'HIGHEST_TOTAL_PRICE'],
    ['<mostOccurringUnitPrice>', 'MOST_OCCURRING_UNIT_PRICE'],
    ['<mostOccurringTotalPrice>', 'MOST_OCCURRING_TOTAL_PRICE'],
    ['<medianUnitPrice>', 'MEDIAN_UNIT_PRICE'],
    ['<medianTotalPrice>', 'MEDIAN_TOTAL_PRICE'],
    ['<competitor>', 'COMPETITOR'],
    // ToDo: revert once concept properly reflected in genesis PH-394
    // ['<maxPrice>', 'MAX_PRICE'],
    // ['<minPrice>', 'MIN_PRICE'],
    // ['<referencePrice>', 'REFERENCE_PRICE'],
  ]);

  constructor(
    protected readonly store: Store<AppState>,
    protected readonly actions$: ActionsSubject,
    protected readonly storageService: StorageService
  ) {
    super();
    this.store.dispatch(pricingStrategyInit());
  }

  ngOnInit() {
    this.store.dispatch(loadingSpinnerShow());
    this.setComponentData();
    this.getProductData();
    this.updateFeatureMissingWarning();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(pricingStrategyDestroy());
  }

  /**
   * This method maps direct scraping shops to the direct.scraping domain
   * Assumption: Any domain in the list of shopsByDomain that has no match in domains is a direct scraping domain
   * TODO: This should be refactored once the endpoint of List all known domains is used the check should rely on the return of this endpoint
   */
  groupDirectScrapingDomains(
    shopsByDomain: Map<string, string[]>,
    domains: string[]
  ): Map<string, string[]> {
    const directScrapingShops: string[] = [];
    const shopsByDomainCopy: Map<string, string[]> = new Map(shopsByDomain);
    shopsByDomain.forEach((value, key) => {
      // if a domain not listed in the main domain list then it is direct scraping domain (assumption see above)
      if (!domains.includes(key)) {
        value.forEach((shop) => {
          directScrapingShops.push(shop + DIRECT_SCRAPING_CONNECTOR + key);
          this.directScrapingDomainShopPairs.push({ domain: key, shop });
        });
        shopsByDomainCopy.delete(key);
      }
    });
    shopsByDomainCopy.set(DIRECT_SCRAPING, directScrapingShops);
    return shopsByDomainCopy;
  }

  private setComponentData(): void {
    combineLatest([
      this.store.select(selectActiveContract).pipe(filter(filterNullAndUndefined)),
      this.store.select(selectUserDetails),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([{ sid, id }, userDetails]) => {
        /**
         * Why this check?
         * On page refresh the tokens and user account data are fetched again so in the beginning acount data are set to undefined in store;
         * So init of all strategy tree related api requests must only trigger once the store has the account data i.e selectUserDetails
         * For contract switch or navigate to page through sidebar Userdetails already there so the check is fullfilled.
         */
        if (userDetails.id) {
          this.store.dispatch(fetchShopsByDomain.init({ sid }));
          this.store.dispatch(fetchDomains.init({ sid }));
          this.store.dispatch(fetchStrategy.init({ sid }));
          this.store.dispatch(fetchTags.init({ sid }));
          this.store.dispatch(fetchVendorSettings.init({ sid }));
          this.store.dispatch(contractSettingsAction.init());
          this.store.dispatch(fetchTaskData.init({ sid }));
          this.store.dispatch(fetchTaskStatus.init());
          this.store.dispatch(fetchProductProperties.init({ sid }));
        }
        this.activeContractSid = sid;
        this.activeContractId = id;
      });

    this.store
      .pipe(select(selectStrategyDomains), takeUntil(this.componentDestroyed))
      .pipe(filter(filterNullAndUndefined))
      .subscribe((domains) => {
        this.domains = domains;
      });

    // selectShopsByDomain will be filtered from undefined values i.e
    // - the tree will not render till the api call is completed with map of shops.
    // - the tree will render without mapped shops api call is completed with empty map of shops. (handeled in reducer)
    combineLatest([
      this.store.select(selectStrategyApiData),
      this.store.select(selectShopsByDomain).pipe(filter(filterNullAndUndefined)),
      this.store.select(selectTaskData),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe({
        next: ([apiData, shops, taskData]) => {
          if (this.domains && shops)
            this.shopsByDomain = this.groupDirectScrapingDomains(shops, this.domains);
          this.tree = apiData
            ? this.strategyConverter.convertApiStrategyTreeToPeanutTree(
                apiData,
                this.directScrapingDomainShopPairs
              )
            : undefined;
          this._tree.next(this.tree);

          this.strategyMetadata = apiData
            ? this.strategyConverter.extractStrategyMetadata(apiData)
            : undefined;

          this.taskData = cloneDeep(taskData);
          const domainUrl =
            window.location.origin +
            `/${APP_ROUTES.ROOT}/${taskData?.contractId}/${APP_ROUTES.PRODUCTS}`;
          this.taskData?.failures?.forEach(
            ({ attributes }) => (attributes.productUrl = `${domainUrl}/${attributes.productId}`)
          );
          this.setTaskStatusData(this.taskData);

          if (apiData === null || (apiData && shops && taskData)) {
            this.store.dispatch(loadingSpinnerHide());
          }
        },
      });

    this.store
      .pipe(select(selectStrategyTags), takeUntil(this.componentDestroyed))
      .subscribe((tags) => {
        this.tags = tags;
      });

    this.store
      .select(selectProductProperties)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((properties) => (this.productProperties = properties));

    this.store
      .pipe(select(selectVarsMap), takeUntil(this.componentDestroyed))
      .subscribe((additionalVars) => {
        this.varsMap = new Map([...this.defaultVarsMap, ...additionalVars]);
      });

    this.store
      .pipe(select(selectStrategyVendorSettings), takeUntil(this.componentDestroyed))
      .subscribe((vendorSettings) => {
        if (!vendorSettings?.shops) return;
        const shops = new Map<string, string[]>();
        for (const shop of vendorSettings?.shops) {
          shops.set(shop.domain, [...(shops.get(shop.domain) || []), shop.shop]);
        }
        this.shops = shops;
      });
  }

  private getProductData(): void {
    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((productData) => {
        const matchedData = this.taskData?.failures?.map((failedTask: IJobStatusFailures) => {
          const matchingProduct = productData?.data.find(
            (product: PricemonitorApiProductV3) => product?.id === failedTask.attributes.productId
          );
          return {
            ...failedTask,
            attributes: {
              ...failedTask.attributes,
              ean: matchingProduct?.gtin,
              articleId: matchingProduct?.customerProductId,
            },
          };
        });
        this.taskData = {
          ...this.taskData,
          failures: matchedData,
        };
        this.setTaskStatusData(this.taskData);
      });
  }

  protected getDates(apiData: ApiStrategyTreeResponse, timestamp: any) {
    const strategyUpdateTimestamp = this.retrieveStrategyUpdateDate(apiData);
    const priceCalculationDate = new Date(timestamp).getTime();
    const strategyUpdateDate = new Date(strategyUpdateTimestamp).getTime();
    return [priceCalculationDate, strategyUpdateDate];
  }

  /**
   * In case strategy tree doesn't have 'updateDate',
   * Then we fall back to the date when the property was introduced
   *
   * This way we ensure that evaluated path is highlited for trees that haven't been updated since then
   *
   * This is a temporary fix that can be removed later once we know that all trees have 'updateDate'
   */
  private retrieveStrategyUpdateDate(apiTree?: ApiStrategyTreeResponse): string {
    const fallbackDate = '2023-04-22T00:00:00.000Z';
    return apiTree?.updateDate || fallbackDate;
  }

  /**
   * A warnning banner is shown for admins in case the contract does not have 'Total market pricing' feature enabled
   * This function enable/disables the banner
   */
  private updateFeatureMissingWarning(): void {
    combineLatest([this.store.select(selectContractSettings), this.store.select(selectUserDetails)])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([settings, userDetails]) => {
        const roles = userDetails.roles;
        const enabledFeatures = settings?.features.enabled;

        this.showFeatureMissingWarning =
          roles &&
          enabledFeatures &&
          roles.includes(UserRoles.ADMIN) &&
          !enabledFeatures.includes(ContractFeatures.OMNIA_TOTAL_MARKET_PRICING)
            ? true
            : false;
      });
  }

  private setTaskStatusData(data?: ITaskData): void {
    const state = StatusTypeMapper(data?.state);
    this.taskStatusData = {
      badgeType: (state === 'success' && data?.failures && data.failures?.length
        ? 'warning'
        : state) as IBadgeType,
      timestamp: data?.finishDate,
      failures: {
        data: data?.failures as unknown as IJobStatusFailures[],
        count: data?.result?.operations.failed,
      },
    };
  }

  protected showToastMessage(message: string) {
    this.store.dispatch(SnackbarWarningAction({ message }));
  }
}
