import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { ChannelScope } from '@models/legacy/enums/legacy-channel-scope.enum';
import { CreateChannelResponseInterface } from '@models/legacy/interfaces/legacy-channel.interface';
import { IChannelMapSetting } from '@models/legacy/interfaces/legacy-channel-map-setting.interface';
import { INewChannelDto } from '@models/legacy/interfaces/legacy-new-channel-dto.interface';

import { IChannelService } from './legacy-channel.interface.service';

@Injectable({ providedIn: 'root' })
export class ChannelService implements IChannelService {
  public scope: ChannelScope = ChannelScope.Marketing;

  public headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  public configuration = environment;

  constructor(private http: HttpClient) {}

  public isTagsSectionValidAsync(channelName: string, channelId: string): Promise<boolean> {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/gettags',
        this.provideBody({
          id: channelId,
        }),
        { headers: this.headers }
      )
    ).then(($response: any) => this.isTagsSectionValid(channelName, $response));
  }

  public createChannel(data: INewChannelDto): Promise<CreateChannelResponseInterface | undefined> {
    return firstValueFrom(
      this.http.post<CreateChannelResponseInterface>(
        environment.bffLegacyEndpoint + '/feedmanager/channel/add',
        this.provideBody({
          name: data.name,
          shopname: data.shopName,
          channelname: data.channelTypeName,
        }),
        { headers: this.headers }
      )
    );
  }

  public createChannel$(data: INewChannelDto): Observable<CreateChannelResponseInterface> {
    return this.http.post<CreateChannelResponseInterface>(
      environment.bffLegacyEndpoint + '/feedmanager/channel/add',
      this.provideBody({
        name: data.name,
        shopname: data.shopName,
        channelname: data.channelTypeName,
      }),
      { headers: this.headers }
    );
  }

  public clone(baseFeedId: string, newChannelName: string, newChannelShopname: string): any {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/clone',
        this.provideBody({
          baseFeedId,
          newChannelName,
          newChannelShopname,
        }),
        { headers: this.headers }
      )
    );
  }

  public loadStatusDetails(channel: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/status/get',
        this.provideBody({
          type: 'channel',
          id: channel.feed_id,
          shopname: channel.shopname,
          details: true,
        }),
        { headers: this.headers }
      )
    ).then(($response) => {
      channel.statusdetails = $response;
    });
  }

  public toggle(channel: any, isEnabled: boolean) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/switchenabled',
        this.provideBody({
          id: channel.feed_id,
          enabled: isEnabled,
        }),
        { headers: this.headers }
      )
    );
  }

  public cancelExecution(channel: any) {
    channel.status.status = 'cancelling';
    channel.status.inprogress = false;
    channel.status.inProgress = false;

    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/cancelexport',
        this.provideBody({
          id: channel.feed_id,
          shopname: channel.shopname,
        }),
        { headers: this.headers }
      )
    );
  }

  public startExecution(channel: any) {
    channel.status.status = 'enqueued';
    channel.status.inprogress = true;
    channel.status.inProgress = true;
    channel.status.enqueue_lastupdate = channel.status.lastupdate;
    channel.status.enqueue_lastupdate = channel.status.lastUpdate;

    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/runexport',
        this.provideBody({
          id: channel.feed_id,
          shopname: channel.shopname,
        }),
        { headers: this.headers }
      )
    ).then(() => {
      channel.updateRequired = true;
    });
  }

  public getMapping(channelId: string) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/get_channel_mapping',
        this.provideBody({ channelId }),
        { headers: this.headers }
      )
    );
  }

  public getExportFields(channelId: string): any {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/get_channel_export_fields',
        this.provideBody({ channelId }),
        { headers: this.headers }
      )
    );
  }

  provideBody(data: any): any {
    return Object.keys(data)
      .map((key) => {
        return [key, JSON.stringify(data[key])].map(encodeURIComponent).join('=');
      })
      .join('&');
  }

  public saveMappingIndex(channelId: string, mapping: IChannelMapSetting[]): any {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/save_channel_mapping_index',
        this.provideBody({ channelId, mapping }),
        { headers: this.headers }
      )
    );
  }

  public showGoogleSpecificFeatures(channelName: string): boolean {
    const googleSpecificChannels = [
      'googleshoppinginventoryupdate',
      'googleshopping',
      'googleadwords',
    ];
    return googleSpecificChannels.indexOf(channelName) >= 0;
  }

  // The signature of this method is caused by legacy (and omitted
  // refactoring efforts), it might've been nicer if it just took
  // a single channel as an argument.
  private isTagsSectionValid(channelName: string, tags: { [key: string]: string }[]): boolean {
    if (this.showGoogleSpecificFeatures(channelName)) {
      return true;
    }

    if (tags?.length) {
      return tags.every((t) => !t['required'] || !!t['expression']);
    }

    return !!tags;
    // When coping the service, previous return looked like the following,
    // return !!tags && !!tags['utm_source'] && !!tags['utm_medium'];
  }
}
