import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContractAccess, User } from '../users-list/users-list.component';

type UserContractAccess = {
  name: string;
  contractId: string;
  hasAccess: boolean;
};

@Component({
  selector: 'app-users-list-item',
  templateUrl: './users-list-item.component.html',
  styleUrl: './users-list-item.component.scss',
})
export class UsersListItemComponent implements OnInit {
  @Input() user: User = {
    name: '',
    email: '',
    lastActive: new Date(),
    hasAccessToAllContracts: false,
    accessibleContracts: [],
  };

  @Input() showDetails: boolean = false;

  @Input() allContracts: ContractAccess[] = [];

  contractsAccess: UserContractAccess[] = [];

  @Output() userChanged = new EventEmitter<User>();

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  contractAccessDescription: String = '';

  ngOnInit() {
    this.updateContractAccessDescription();
    this.contractsAccess = this.allContracts.map((contract) => {
      return {
        name: contract.name,
        contractId: contract.contractId,
        hasAccess:
          this.user.hasAccessToAllContracts ||
          this.user.accessibleContracts.some((contractId) => contractId === contract.contractId),
      };
    });
  }

  getAccessibleContractNames() {
    return this.allContracts
      .filter((contract) =>
        this.user.accessibleContracts.some((contractId) => contractId === contract.contractId)
      )
      .map((contract) => contract.name);
  }

  updateContractAccessDescription() {
    let text = this.user.hasAccessToAllContracts
      ? 'All contracts'
      : this.getAccessibleContractNames().join(', ');
    const maxCharacters = 30;
    if (text.length > maxCharacters) {
      // Use max 30 characters to avoid overflow and continue with "..."
      text = text.substring(0, maxCharacters - 3) + '...';
    }
    this.contractAccessDescription = text;
  }

  toggleContractAccess(contractId: string, checked: boolean) {
    const contract = this.contractsAccess.find((c) => c.contractId === contractId);
    if (contract) {
      contract.hasAccess = checked;
      if (checked) {
        this.user.accessibleContracts.push(contract.contractId);
      } else {
        this.user.accessibleContracts = this.user.accessibleContracts.filter(
          (c) => c !== contractId
        );
      }
    }
    // Check if all contracts are checked and update user accordingly
    this.user.hasAccessToAllContracts = this.contractsAccess.every((c) => c.hasAccess);
    this.updateContractAccessDescription();
    this.userChanged.emit(this.user);
  }

  toggleFullAccess() {
    this.user.accessibleContracts = [];
    this.user.hasAccessToAllContracts = !this.user.hasAccessToAllContracts;
    this.contractsAccess.forEach((contract) => {
      contract.hasAccess = this.user.hasAccessToAllContracts;
    });
    this.updateContractAccessDescription();
    this.userChanged.emit(this.user);
  }
}
