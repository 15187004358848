<h2 class="header">Library</h2>

<div class="strategy-types">
  <h3 class="title if-tag">Conditions</h3>
  <div qDraggable [child]="ifTag">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">dashboard_customize</mat-icon>
      <div class="mt-2 text-sm">Tag</div>
    </div>
  </div>
  <div qDraggable [child]="ifRecommended">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">price_check</mat-icon>
      <div class="mt-2 text-sm">Recommended Price</div>
    </div>
  </div>
  <div qDraggable [child]="ifFormula">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">functions</mat-icon>
      <div class="mt-2 text-sm">Formula</div>
    </div>
  </div>
  <div qDraggable [child]="ifCompetition">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">leaderboard</mat-icon>
      <div class="mt-2 text-sm">Competition</div>
    </div>
  </div>
  <div qDraggable [child]="ifTimestampRange">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">date_range</mat-icon>
      <div class="mt-2 text-sm">Date</div>
    </div>
  </div>

  <h3 class="title parameters">Competitive pricing</h3>
  <div qDraggable [child]="targetPosition">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">my_location</mat-icon>
      <div class="mt-2 text-sm">Target Position</div>
    </div>
  </div>
  <div qDraggable [child]="priceGap">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">expand</mat-icon>
      <div class="mt-2 text-sm">Price Gap</div>
    </div>
  </div>
  <div qDraggable [child]="followCompetitors">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">add_business</mat-icon>
      <div class="mt-2 text-sm">Follow Competitors</div>
    </div>
  </div>
  <div qDraggable [child]="relevantPrice">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">request_quote</mat-icon>
      <div class="mt-2 text-sm">Relevant Price</div>
    </div>
  </div>

  <h3 class="title price-formula">Price Formula</h3>
  <div qDraggable [child]="priceFormula">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">calculate</mat-icon>
      <div class="mt-2 text-sm">Price Formula</div>
    </div>
  </div>
  <div qDraggable [child]="priceCalculation">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">fact_check</mat-icon>
      <div class="mt-2 text-sm">Price Formula Requirements</div>
    </div>
  </div>

  <h3 class="title safety-rules">Safety Rules</h3>
  <div qDraggable [child]="minimumPrice">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">vertical_align_bottom</mat-icon>
      <div class="mt-2 text-sm">Minimum Price</div>
    </div>
  </div>
  <div qDraggable [child]="maximumPrice">
    <div class="node-draggable strategy-type">
      <mat-icon class="strategy-type-icon">vertical_align_top</mat-icon>
      <div class="mt-2 text-sm">Maximum Price</div>
    </div>
  </div>

  <h3 class="title goal-base-pricing" *ngIf="contractHasStockClearance">Goal-based Pricing</h3>
  <div qDraggable [child]="stockClearance" *ngIf="contractHasStockClearance">
    <div class="node-draggable strategy-type">
      <!-- TODO - CON-737 update icon to match mockup -->
      <mat-icon class="strategy-type-icon">remove_shopping_cart</mat-icon>
      <div class="mt-2 text-sm">Stock Clearance</div>
    </div>
  </div>
</div>
