<div class="row h-18 inline-flex items-end justify-between overflow-visible">
  <div class="start node" [ngClass]="{ closed: isStartNodeClosed }">
    <div class="header cursor-pointer" (click)="isStartNodeClosed = !isStartNodeClosed">
      <span>Start</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <div class="content">
      <div class="text-sm font-bold">Default Pricing Settings</div>
      <p>
        Default pricing settings will be applied to products if no other rules are defined in your
        pricing strategy
      </p>
      <div class="truncate">
        <span class="material-icons">gps_fixed</span>
        <span>{{ data?.targetPosition | formatFormulaVars : varsMap }}</span>
      </div>
      <div class="truncate">
        <span class="material-icons">expand</span>
        <span>{{ data?.priceGap | formatFormulaVars : varsMap }} ({{ data?.priceGapUnit }})</span>
      </div>
      <div class="truncate">
        <mat-icon class="material-icons rotate-90">enable</mat-icon>
        <span>{{
          data?.adjustToNextPricier
            ? 'Adjust to next more expensive offer'
            : 'Adjust to minimum price'
        }}</span>
      </div>
      <div class="truncate">
        <span class="material-icons">price_check</span>
        <span>{{
          data?.priceRequirement ? 'Always recommend price' : 'Only if at least one offer is found'
        }}</span>
      </div>
      <span
        class="mt-4 inline-block cursor-pointer text-sm font-bold uppercase text-accent"
        (click)="treeStore.selectedChild = node"
        >Configure</span
      >
    </div>
  </div>
  <img class="ml-28 w-44" src="/img/Legend.png" />
</div>
