import { Component, Input, OnInit, SkipSelf } from '@angular/core';

import { PeanutTreeService } from '@app/components';

import { BRANCH_NAME_MAX_LENGTH, BRANCH_NAME_ROWS } from '@components/strategy-component/constants';
import { PeanutTree } from '@components/strategy-component/PeanutTree';

@Component({
  selector: 'app-strategy-rule-name',
  templateUrl: './strategy-rule-name.component.html',
  styleUrls: ['./strategy-rule-name.component.scss'],
})
export class StrategyRuleNameComponent implements OnInit {
  @Input() child!: PeanutTree;

  public branchNameMaxLength = BRANCH_NAME_MAX_LENGTH;

  public branchNameCols = BRANCH_NAME_ROWS;

  public initialNodeName: string | null = null;

  constructor(@SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    this.initialNodeName = this.child.data?.ruleName ?? '';
  }

  public handleKeyUp(): void {
    const ruleName = this.child.data?.ruleName;
    this.treeStore.isDirty = ruleName?.length >= 0 && ruleName !== this.initialNodeName;
  }
}
