<app-condition-form-header></app-condition-form-header>
<div class="content-subheader">
  <mat-icon class="strategy-type-icon">dashboard_customize</mat-icon>
  <div class="legend">
    <h3>If Tag</h3>
    <p>
      Define tag name-value pair(s) to control whether the following strategy elements are applied.
      Combine multiple conditions with OR logic.
    </p>
  </div>
</div>
<div class="form" [formGroup]="ifTagForm">
  <div
    class="tag-query mt-3"
    formArrayName="query"
    *ngFor="let queryData of queryFormArray?.controls; let i = index"
  >
    <ng-container [formGroupName]="i">
      <div *ngIf="i > 0">
        <div class="tag-query-separator">
          <span>{{ queryData.value.operator }}</span>
          <app-icon-button
            class="tag-value-remove"
            color="primary"
            icon="delete_forever"
            iconSize="text-xl"
            (click)="removeFormSection(i)"
          ></app-icon-button>
        </div>
      </div>
      <app-toggle
        *ngIf="productProperties.length > 0"
        class="my-4"
        [color]="'accent'"
        [label]="'Pick properties for comparison'"
        [labelPosition]="'after'"
        (valueChange)="onToggleChange(i)"
        [toggled]="queryData.value.pickProductProperties"
      >
      </app-toggle>
      <ng-container formGroupName="data">
        <!-- template for the query source with context variables -->
        <ng-template
          #querySourceTemplate
          let-i="index"
          let-source="source"
          let-nameFormControl="nameFormControl"
          let-valueFormControl="valueFormControl"
        >
          <div class="tag-key-container">
            <label class="tag-key-label" for="{{ nameFormControl }}">{{ source }}: </label>
            <input
              matInput
              type="text"
              placeholder="Select {{ source }}"
              id="{{ nameFormControl }}"
              [ngClass]="source === 'Tag' ? 'tag-key-input' : 'product-property-key-input'"
              formControlName="{{ nameFormControl }}"
              [matAutocomplete]="tagAutocomplete"
            />

            <mat-autocomplete #tagAutocomplete="matAutocomplete" class="tag-key-autocomplete">
              <mat-option
                *ngIf="!ifTagForm.get('query[' + i + '].data.' + '{{nameFormControl}}')"
                disabled
                selected
                value="null"
                >Select {{ source }}</mat-option
              >
              <mat-option *ngFor="let source of filteredQuerySource[i] | async" [value]="source">
                {{ source }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="error" *ngIf="i === formControlErrorIndex">
            {{ source }} name must be specified
          </div>

          <div class="tag-value-container">
            <select class="tag-matcher" formControlName="matcher">
              <option value="{{ matcherOptionsEnum.IS }}" selected>is</option>
              <option value="{{ matcherOptionsEnum.IS_NOT }}">is not</option>
              <option value="{{ matcherOptionsEnum.CONTAINS }}">contains</option>
              <option value="{{ matcherOptionsEnum.CONTAINS_NOT }}">not contains</option>
            </select>
            <input
              class="tag-value"
              type="text"
              placeholder="Enter value"
              formControlName="{{ valueFormControl }}"
            />
          </div>
        </ng-template>
        <!-- template set with productproperty as query source -->
        <ng-container
          *ngIf="queryData.value.pickProductProperties; else tagsAsQuerySource"
          [ngTemplateOutlet]="querySourceTemplate"
          [ngTemplateOutletContext]="{
            index: i,
            source: 'Product Property',
            nameFormControl: 'productPropertyName',
            valueFormControl: 'productPropertyValue'
          }"
        >
        </ng-container>
        <ng-template #tagsAsQuerySource>
          <!-- template set with tags as query source -->
          <ng-container
            [ngTemplateOutlet]="querySourceTemplate"
            [ngTemplateOutletContext]="{
              index: i,
              source: 'Tag',
              nameFormControl: 'tagName',
              valueFormControl: 'tagValue'
            }"
          >
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
  <div class="flex justify-between">
    <app-basic-button
      class="self-start"
      icon="add"
      text="OR"
      color="primary"
      iconSize="text-xl"
      (click)="addFormSection(operatorEnum.OR)"
    ></app-basic-button>
    <app-basic-button
      class="self-end"
      icon="add"
      text="AND"
      color="primary"
      iconSize="text-xl"
      (click)="addFormSection(operatorEnum.AND)"
    ></app-basic-button>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="isSubmitDisabled"
        (click)="saveForm()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
