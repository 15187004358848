<h2 (click)="open = !open">
  My shops
  <mat-icon>arrow_drop_down</mat-icon>
</h2>
<div class="content">
  Please define the names of your own shops for each relevant data source. This will ensure that
  Omnia does not consider your own offers as competitor offers.
</div>
<div class="content shops-wrapper">
  <div class="add">
    <h3>Add new shop</h3>
    <div>Select data source</div>
    <div class="inputs">
      <select [(ngModel)]="newShopDomain">
        <option disabled selected *ngIf="!domains?.length">No available domains</option>
        <option [ngValue]="domain" *ngFor="let domain of domains">
          {{ domain }}
        </option>
      </select>
      <input class="name" type="text" [(ngModel)]="newShopName" placeholder="Enter Shop Name" />
      <app-basic-button
        class="leading-none"
        text="{{ isShopSaving ? '' : 'Add' }}"
        [hasIconAfterText]="false"
        [color]="'primary'"
        [isRaisedButton]="true"
        [disabled]="!newShopDomain || !newShopName || isShopSaving"
        [textSize]="'text-sm'"
        (clickEvent)="addShop()"
      >
        <mat-icon *ngIf="isShopSaving" class="loading-icon">cached</mat-icon>
      </app-basic-button>
    </div>
    <div class="legend">
      <span>Warning:</span> Make sure the spelling is correct, otherwise your own offers are
      identified as offers from a competitor!
    </div>
  </div>
  <!--
    Why overflow only when component is open?
    because it breaks the auto-scroll when adding a new node to the startegy tree. Cant really explain why.
    The issue will still occur if My shops was expanded while adding a new node.
  -->
  <div class="shops" #shopsElement [ngClass]="{ 'overflow-auto': open }">
    <h3>My shops</h3>
    <div class="empty" *ngIf="!shops?.size">No shops have been added yet</div>
    <div class="domain" *ngFor="let domain of shops | keyvalue">
      <span class="title">{{ domain.key }}</span>
      <span class="shop" *ngFor="let shop of domain.value">
        {{ shop }}
        <mat-icon *ngIf="isShopSaving" class="loading-icon">cached</mat-icon>
        <mat-icon *ngIf="!isShopSaving" (click)="removeShop(domain.key, shop)">close</mat-icon>
      </span>
    </div>
  </div>
</div>
