import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { AppState } from '@app/store/app.state';
import { SetHeaderSuccessAction } from '@app/store/legacy';

import { SaveDialogComponent } from './components/save-dialog/save-dialog.component';
import { PeanutTreeService } from './services/peanut-tree.service';
import { Shop } from './ui-strategy-elements/competitive-pricing/competitive-pricing';
import { BranchStats } from './models';
import { PeanutTreeUI, PeanutTreeUIWithVersionMessage } from './PeanutTree';
import { emptyTree } from './stub';

@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss'],
  providers: [PeanutTreeService],
})
export class StrategyComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {
  @Input() tree?: PeanutTreeUI;

  @Input() shops?: Map<string, string[]>;

  @Input() domains?: string[];

  @Input() tags?: string[] = [];

  @Input() productProperties?: string[] = [];

  @Input() shopsByDomain?: Map<string, string[]>;

  @Input() isSaving = false;

  @Input() isShopSaving = false;

  @Input() isCalculatingPrices = false;

  @Input() varsMap = new Map();

  @Input() hasStrategyInLocalStorage = false;

  /**
   *CON-373: To keep code related to same concern close by, the warning message about the price banner has also been moved to app-strategy.
   */

  @Input() showFeatureMissingWarning = false;

  /**
   * Used explicitly for pasting a strategy. This fields works as an intermediate storage before we commit the pasted strategy tree to this.tree
   * */
  @Input() pastedTree?: PeanutTreeUI;

  /**
   * Holds tree Branch Statistics, maps leaf node id to BranchStats
   */
  @Input() branchStats?: Map<number, BranchStats>;

  @Output() saveCallback = new EventEmitter<PeanutTreeUIWithVersionMessage>();

  @Output() toggleShopCallback = new EventEmitter<Shop>();

  @Output() calculatePricesCallback = new EventEmitter<void>();

  @Output() copyStrategyCallback = new EventEmitter<PeanutTreeUI>();

  @Output() pasteStrategyCallback = new EventEmitter<void>();

  @ViewChild('calculatePricesTooltip') calculatePricesTooltip?: MatTooltip;

  isEmpty?: boolean;

  isEditPanelOpen: boolean = false;

  subscription = new Subscription();

  saveIcon: string = 'block';

  calculatePricesIcon: string = '';

  constructor(
    @Host() public treeStore: PeanutTreeService,
    public dialog: MatDialog,
    private readonly store: Store<AppState>
  ) {
    this.store.dispatch(
      SetHeaderSuccessAction({
        payload: {
          title: `Pricing Strategy`,
        },
      })
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.treeStore.children$.subscribe((children) => {
        this.isEmpty = !children.length;
      })
    );

    // in case of any changes to the tree remove any highlighted nodes
    this.subscription.add(
      this.treeStore.isDirty$.subscribe((isDirty) => {
        if (isDirty) {
          this.saveIcon = this.setSaveIcon();
          this.branchStats = undefined;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tree']) this.refresh();

    this.saveIcon = this.setSaveIcon();
    this.calculatePricesIcon = this.setCalculatePricesIcon();
    if (changes['pastedTree']) this.commitPastedTree();
    // Upon changes to Tree i.e re-render, the local state of treeStore.selectedChild should be clean up, which is used to show/hide the editpanel.
    this.treeStore.selectedChild = undefined;
  }

  private commitPastedTree() {
    if (this.pastedTree) {
      this.treeStore.paste(this.pastedTree);
    }
  }

  ngAfterContentChecked() {
    if (this.isEmpty) this.calculatePricesTooltip?.show();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  copyToLocalStorage() {
    this.copyStrategyCallback.emit(this.treeStore.tree);
  }

  pasteFromLocalStorage() {
    this.pasteStrategyCallback.emit();
  }

  refresh() {
    this.treeStore.init(this.tree || emptyTree);
    this.saveIcon = this.setSaveIcon();
  }

  save() {
    const dialogRef = this.dialog.open(SaveDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      let savedTree: PeanutTreeUIWithVersionMessage = {
        tree: this.treeStore.tree,
        versionMessage: result,
      };
      this.saveCallback.emit(savedTree);
    });
  }

  calculatePrices() {
    this.calculatePricesCallback.emit();
  }

  toggleEditPanelCallback(isEditPanelOpen: boolean) {
    setTimeout(() => {
      this.isEditPanelOpen = isEditPanelOpen;
    }, 0);
  }

  private setSaveIcon(): string {
    if (!this.isEmpty && !this.isSaving && this.treeStore.isDirty) {
      return 'save';
    }
    if (!this.isEmpty && !this.isSaving && !this.treeStore.isDirty) {
      return 'done';
    }
    if (this.isSaving) {
      return 'sync';
    }

    return 'block';
  }

  private setCalculatePricesIcon(): string {
    if (this.isCalculatingPrices) {
      return 'sync';
    }

    if (this.isEmpty || this.treeStore.isDirty) {
      return 'block';
    }

    return '';
  }

  /**
   * To ensure that keyValue pipe preserves the order of Map entries
   * @returns 1 to keep item in its original slot
   * For more info, check this solution: https://github.com/angular/angular/issues/31420#issuecomment-509759583
   */
  asIsOrder(_a: any, _b: any) {
    return 1;
  }
}
