import {
  ApiCompetitorsOnPosition,
  ApiFormulaComparison,
  ApiFormulaPriceFilter,
  ApiPriceCalculation,
  ApiSerial,
  ApiStrategy,
  ApiStrategyContextModifiers,
  ApiStrategyName,
  ApiStrategyTreeRequest,
  ApiStrategyTreeResponse,
  ApiTagBasedFilter,
  IApiStrategyFilters,
  STRATEGY_SCHEMA_VERSION,
  StrategyMetadata,
} from '@models/interfaces/api-strategies';

import { PeanutTreeUI } from '@components/strategy-component/PeanutTree';
import { Shop } from '@components/strategy-component/ui-strategy-elements/competitive-pricing/competitive-pricing';

import { ApiStrategyFactory } from './api-strategy-factory';
import { ApiStrategyTypeValidator } from './api-strategy-type-validator';
import { PeanutTreeFactory } from './peanut-tree-factory';
export class StrategyConverter {
  directScrapingShops: Shop[] = [];

  /**
   * Extract the root strategy and remove meta data attributes
   * @param apiStrategyTree
   * @returns root strategy
   */
  extractRootStrategy(apiStrategyTree: ApiStrategyTreeResponse): ApiStrategy {
    const rootStrategykey = ApiStrategyTypeValidator.extractStrategyTreeType(apiStrategyTree);
    const rootStrategyValue = (apiStrategyTree as any)[rootStrategykey];
    var strategyTree = {
      [rootStrategykey]: rootStrategyValue,
    } as ApiStrategy;
    return strategyTree;
  }

  extractStrategyMetadata(apiStrategyTree: ApiStrategyTreeResponse): StrategyMetadata {
    const rootStrategykey = ApiStrategyTypeValidator.extractStrategyTreeType(apiStrategyTree);
    return (({ [rootStrategykey as keyof ApiStrategyTreeResponse]: _, ...metadata }) => metadata)(
      apiStrategyTree
    ) as StrategyMetadata;
  }

  convertApiStrategyTreeToPeanutTree(
    apiStrategyTree: ApiStrategyTreeResponse,
    directScrapingShops: Shop[]
  ): PeanutTreeUI {
    this.directScrapingShops = directScrapingShops;
    // Start PeanutTree (has no data just successors)
    const tree: PeanutTreeUI = { children: [] };
    if (ApiStrategyTypeValidator.isApiStrategyTreeValid(apiStrategyTree)) {
      // root is valid Recursevly traverse/convert the tree
      const rootStrategy = this.extractRootStrategy(apiStrategyTree);
      this.convertToPeanutTree(rootStrategy, tree);
    }
    return tree;
  }

  /**
   * Traverses api strategy json and convert it to Peanut tree
   * @remark
   * - Assumptions:
   *   + JSON structure is valid and contains only the strategies of supported strategyTypes
   *   + Each strategy is expected to have builder attribute, only Serial don't
   *   + All JSON API strategies must start with a context modifier that hold global config of tree i.e start and has no children
   *   + Unknown strategy settings get thrown away e.g. unknown selectors in context modifiers get thrown away when you parse it from json to apistrategy.
   *   + Price calculation is the leaf node, and has no UI representation
   *
   * - Special handeling
   *   If strategy name not supported i.e in ApiStrategyName, throw exception and return null tree (won't show component)
   *
   */
  convertToPeanutTree(apiStrategy: ApiStrategy, tree: PeanutTreeUI): void {
    let treeElement: PeanutTreeUI = { children: [] };
    const strategyType = Object.keys(apiStrategy)[0];
    if (strategyType === ApiStrategyName.SERIAL) {
      (apiStrategy as ApiSerial).Serial.builders.forEach((sibling: ApiStrategy) => {
        this.convertToPeanutTree(sibling, tree);
      });
    } else if (
      ApiStrategyTypeValidator.isStartApiStrategyContextModifier(strategyType, apiStrategy)
    ) {
      const strategy = apiStrategy as ApiStrategyContextModifiers;
      treeElement.data = PeanutTreeFactory.createStartStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.StrategyContextModifiers.builder, treeElement);
    } else if (
      ApiStrategyTypeValidator.isPriceFormulaApiStrategyContextModifier(strategyType, apiStrategy)
    ) {
      const strategy = apiStrategy as ApiStrategyContextModifiers;
      treeElement.data = PeanutTreeFactory.createPriceFormulaStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.StrategyContextModifiers.builder, treeElement);
    } else if (
      ApiStrategyTypeValidator.isSafetyRuleApiStrategyContextModifier(strategyType, apiStrategy)
    ) {
      const strategy = apiStrategy as ApiStrategyContextModifiers;
      treeElement.data = PeanutTreeFactory.createSafetyRuleStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.StrategyContextModifiers.builder, treeElement);
    } else if (
      ApiStrategyTypeValidator.isCompetitivePriceingApiStrategyContextModifier(
        strategyType,
        apiStrategy
      )
    ) {
      const strategy = apiStrategy as ApiStrategyContextModifiers;
      treeElement.data = PeanutTreeFactory.createCompetitivePricingStrategy(
        strategy,
        this.directScrapingShops
      );
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.StrategyContextModifiers.builder, treeElement);
    } else if (
      ApiStrategyTypeValidator.isStockClearanceStrategyApiStrategyContextModifier(
        strategyType,
        apiStrategy
      )
    ) {
      const strategy = apiStrategy as ApiStrategyContextModifiers;
      treeElement.data = PeanutTreeFactory.createStockClearanceStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.StrategyContextModifiers.builder, treeElement);
    } else if (strategyType === ApiStrategyName.TAG_BASED) {
      const strategy = apiStrategy as ApiTagBasedFilter;
      // Handel converting deprecated ApiTagBasedFilter to new IfTagStrategyV2
      treeElement.data = PeanutTreeFactory.createTagBasedStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.TagBasedFilter.builder, treeElement);
    } else if (strategyType === ApiStrategyName.COMPETITOR) {
      const strategy = apiStrategy as ApiCompetitorsOnPosition;
      treeElement.data = PeanutTreeFactory.createIfCompetitionStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.CompetitorsOnPosition.builder, treeElement);
    } else if (strategyType === ApiStrategyName.FORMULA_PRICE_FILTER) {
      const strategy = apiStrategy as ApiFormulaPriceFilter;
      treeElement.data = PeanutTreeFactory.createIfRecommendedPriceStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.FormulaPriceFilter.builder, treeElement);
    } else if (strategyType === ApiStrategyName.FORMULA_COMPARISON) {
      const strategy = apiStrategy as ApiFormulaComparison;
      treeElement.data = PeanutTreeFactory.createIfFormulaAppliesStrategy(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy.FormulaComparison.builder, treeElement);
    } else if (strategyType === ApiStrategyName.FILTERS) {
      const strategy = apiStrategy as IApiStrategyFilters;
      treeElement.data = PeanutTreeFactory.createStrategyFilters(strategy);
      tree.children.push(treeElement);
      this.convertToPeanutTree(strategy[ApiStrategyName.FILTERS].builder, treeElement);
    } else if (strategyType === ApiStrategyName.PRICE_CALCULATION) {
      // Leaf execution node that holds the rule name -> Assign the name to the parent (we do not implement the pricecalculation in UI)
      const strategy = apiStrategy as ApiPriceCalculation;
      if (strategy[ApiStrategyName.PRICE_CALCULATION]?.name) {
        tree.data.ruleName = strategy[ApiStrategyName.PRICE_CALCULATION]?.name;
      }
      return;
    } else {
      throw new Error('Unsupported strategy type');
    }
  }

  /**
   * ToDo: Recursivly iterate over Peanut tree and convert each FE strategy to coresponding API strategy
   * @remarks assumption
   * - UI won't allow submitting empty peanutTree (at least root peanutTree.children[0])
   * - All PeanutTree nodes has data attribute
   * @param peanutTree
   * @param versionMessage message of the strategy version
   * @returns ApiStrategyTree
   */
  convertPeanutTreeToApiStrategyTree(
    peanutTree: PeanutTreeUI,
    versionMessage?: string
  ): ApiStrategyTreeRequest {
    let tree: ApiStrategyTreeRequest = {
      ...this.convertToApiStrategy(peanutTree.children[0]),
      version: STRATEGY_SCHEMA_VERSION,
    };

    if (versionMessage) {
      tree = { ...tree, documentVersionMessage: versionMessage };
    }
    return tree;
  }

  convertToApiStrategy(peanutTree: PeanutTreeUI): ApiStrategy {
    const uiStrategyMapper = ApiStrategyFactory.uiApiStrategyMap.get(peanutTree.data.strategyType)!;
    const apiStrategy = { ...uiStrategyMapper(peanutTree.data) };
    const apiStrategyType = ApiStrategyFactory.uiApiTypesMap.get(peanutTree.data.strategyType)!;
    if (peanutTree.children.length === 0) {
      // if UI leaf node has ruleName defined then assign name attribute to ApiStrategyName.PRICE_CALCULATION] API leaf, other wise it stays at {}
      apiStrategy[apiStrategyType] = {
        ...apiStrategy[apiStrategyType],
        builder: {
          [ApiStrategyName.PRICE_CALCULATION]: peanutTree.data.ruleName
            ? { name: peanutTree.data.ruleName }
            : {},
        },
      };
    } else if (peanutTree.children.length === 1) {
      apiStrategy[apiStrategyType] = {
        ...apiStrategy[apiStrategyType],
        builder: this.convertToApiStrategy(peanutTree.children[0]),
      };
    } else {
      apiStrategy[apiStrategyType] = {
        ...apiStrategy[apiStrategyType],
        builder: {
          Serial: {
            builders: peanutTree.children.map((sibling: PeanutTreeUI) =>
              this.convertToApiStrategy(sibling)
            ),
          },
        },
      };
    }
    return apiStrategy;
  }
}
