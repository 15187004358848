import { Component, EventEmitter, Input, OnChanges, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

import {
  CompanyMapping,
  ContractMapping,
  ContractSwitcherCurrentItem,
} from '../../PageHeader.models';

enum FilterTypes {
  ANY = 'any',
  COMPANIES = 'companies',
  CONTRACTS = 'contracts',
}

@Component({
  selector: 'app-contract-switcher',
  templateUrl: './contract-switcher.component.html',
  styleUrls: ['./contract-switcher.component.scss'],
})
export class ContractSwitcherComponent implements OnChanges {
  readonly FilterTypes = FilterTypes;

  @Input() companies?: CompanyMapping[];

  @Input() current?: ContractSwitcherCurrentItem;

  @Input() action?: EventEmitter<ContractSwitcherCurrentItem>;

  @ViewChild('companiesMenuTrigger') companiesMenuTrigger: MatMenuTrigger | undefined;

  currentCompany?: CompanyMapping;

  currentContract?: ContractMapping;

  filteredCompanies?: number[];

  filteredContracts?: string[];

  isShowingAll = false;

  filterTerm = '';

  filterType = FilterTypes.ANY;

  ngOnChanges() {
    this.currentCompany = this.companies?.find((company) => company.id === this.current?.companyId);
    this.currentContract = this.currentCompany?.contracts.find(
      (contract) => contract.sid === this.current?.contractSid
    );
  }

  toggleAllCompanies() {
    this.isShowingAll = !this.isShowingAll;
    this.filterTerm = '';
    this.applyFilter();
  }

  applyFilter() {
    this.filteredCompanies = [];
    this.filteredContracts = [];
    if (!this.filterTerm) return;
    if (this.filterType !== FilterTypes.COMPANIES) {
      this.filteredContracts = this.companies?.flatMap((company) =>
        company.contracts
          .filter(
            (contract) => !contract.name.toLowerCase().includes(this.filterTerm.toLowerCase())
          )
          .map((contract) => contract.sid)
      );
      this.filteredCompanies =
        this.filterType === FilterTypes.ANY
          ? []
          : this.companies
              ?.filter(
                (company) =>
                  this.filteredContracts?.filter((contractSid) =>
                    company.contracts.find((contract) => contract.sid === contractSid)
                  ).length === company.contracts.length
              )
              .map((company) => company.id);
    }
    if (this.filterType !== FilterTypes.CONTRACTS) {
      this.filteredCompanies = this.companies
        ?.filter((company) =>
          this.filterType === FilterTypes.ANY
            ? !company.name.toLowerCase().includes(this.filterTerm.toLowerCase()) &&
              !company.contracts.filter(
                (contract) => !this.filteredContracts?.includes(contract.sid)
              ).length
            : !company.name.toLowerCase().includes(this.filterTerm.toLowerCase())
        )
        .map((company) => company.id);
    }
    if (this.filterType === FilterTypes.ANY) {
      this.companies
        ?.filter((company) => !this.filteredCompanies?.includes(company.id))
        .forEach((company) => {
          this.filteredContracts = this.filteredContracts?.filter(
            (contractSid) =>
              !company.contracts.map((contract) => contract.sid).includes(contractSid)
          );
        });
    }
  }

  onOptionClicked(companyId: number, contractSid: string) {
    this.companiesMenuTrigger?.closeMenu();
    this.action?.emit({ companyId, contractSid });
  }
}
