const bffAbsEndpoint = 'https://frontend-api-genesis.omniaretail.com';

export const environment = {
  production: true,
  bffAbsEndpoint: `${bffAbsEndpoint}`,
  bffLegacyEndpoint: `${bffAbsEndpoint}/legacy`,
  portalApiBaseUrl: 'https://feed.omniaretail.com',
  bffApiEndpoint: `${bffAbsEndpoint}/api`,
  pricemonitorApiBaseUrl: 'https://api.patagona.de',
  customerServiceEmail: 'customerservice@omniaretail.com',
  features: {
    singleSignOn: {
      properties: {
        domain: 'auth.omniaretail.com',
        clientId: 'q3KDjRO0wfzntqMaDFJa3Vb3z1SJo8c8',
        audience: 'frontend-api-genesis.omniaretail.com',
        redirectUri: `https://${window.location.host}`,
      },
    },
    pricemonitorSingleSignOn: {
      properties: {
        domain: 'auth.omniaretail.com',
        clientId: 'q3KDjRO0wfzntqMaDFJa3Vb3z1SJo8c8',
        audience: 'api.patagona.de',
        redirectUri: `https://${window.location.host}`,
      },
    },
    lookerDashboard: {
      lookerDomain: 'https://looker.omniaretail.com',
    },
    sentry: {
      envName: 'production',
      dsn: 'https://007a12498f0a4932b69e74e6ab1dfaae@o423345.ingest.sentry.io/4504910387806208',
    },
    applicationInsights: {
      envName: 'production',
      connectionString:
        'InstrumentationKey=48b2b5c2-b5b8-4456-a7aa-305f69f30e8c;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=3cd08f34-4245-4038-97e6-09e98a399511',
    },
  },
};
