import { cloneDeep } from 'lodash';

import { FilterBooleanOperator } from '@app/models/enum/api-query-filter.enum';
import {
  MultipleQueryFilters,
  QueryComparisonFilter,
  QueryFilterTypes,
  SingleQueryFilter,
} from '@app/models/interfaces/api-query-filter.interface';

export class ApiQueryFilter {
  public queryFilterTypes: QueryFilterTypes = {
    string: {
      type: 'ComparisonFilter',
      left: {
        type: 'StringValueProvider',
        attributeName: '',
      },
      right: {
        type: 'StringConstantValueProvider',
        value: '',
      },
      comparison: {
        type: 'StringEquality',
      },
    },
    number: {
      type: 'ComparisonFilter',
      left: {
        type: 'NumberValueProvider',
        attributeName: '',
      },
      right: {
        type: 'NumberConstantValueProvider',
        value: '',
      },
      comparison: {
        type: 'NumberEquality',
      },
    },
  };

  createQueryFilter(key: string, type: string, value: string | number): QueryComparisonFilter {
    if (!this.queryFilterTypes[type])
      throw new Error(`Query filter type not found for type: ${type}`);
    else return this.setQueryFilterValues(this.queryFilterTypes[type], key, value);
  }

  setQueryFilterValues(
    filter: QueryComparisonFilter,
    key: string,
    value: string | number
  ): QueryComparisonFilter {
    const queryFilter: QueryComparisonFilter = cloneDeep(filter);
    queryFilter.left.attributeName = key;
    queryFilter.right.value = value;
    return queryFilter;
  }

  andFilter(
    filters: (QueryComparisonFilter | SingleQueryFilter | MultipleQueryFilters)[]
  ): MultipleQueryFilters {
    return {
      filters: filters,
      type: FilterBooleanOperator.AND,
    };
  }

  notFilter(
    filter: QueryComparisonFilter | SingleQueryFilter | MultipleQueryFilters
  ): SingleQueryFilter {
    return {
      filter: filter,
      type: FilterBooleanOperator.NOT,
    };
  }
}
