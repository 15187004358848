import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-copy-or-move-dialog',
  templateUrl: './copy-or-move-dialog.component.html',
  styleUrls: ['./copy-or-move-dialog.component.scss'],
})
export class CopyOrMoveDialogComponent {
  public isCopy: boolean = true;

  constructor(public dialogRef: MatDialogRef<CopyOrMoveDialogComponent>) {}
}
