<mat-form-field class="block" appearance="standard">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [disabled]="disabled"
    [required]="required"
    [multiple]="multipleSelection"
    placeholder="placeholder"
    [(ngModel)]="selectValue"
  >
    <mat-option *ngFor="let item of items" [value]="item">
      {{ item }}
    </mat-option>
  </mat-select>
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>
