import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';

import { Subscription } from 'rxjs';

import { PeanutTreeUI } from '../../PeanutTree';
import { PeanutTreeService } from '../../services/peanut-tree.service';

@Component({
  selector: 'app-execution-node',
  templateUrl: './execution-node.component.html',
  styleUrls: ['./execution-node.component.scss'],
})
export class ExecutionNodeComponent implements OnInit, OnDestroy {
  @Input() child!: PeanutTreeUI;

  isSelected = false;

  subscription = new Subscription();

  constructor(@SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    this.subscription.add(
      this.treeStore.selectedChildPath$.subscribe((selectedChildPath) => {
        this.isSelected = !!selectedChildPath && selectedChildPath === this.child.path;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
