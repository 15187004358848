import { Pipe, PipeTransform } from '@angular/core';

import { branchStatsPlaceholder } from '../../constants';
import { BranchStats } from '../../models';

@Pipe({
  name: 'branchStatsMapValue',
})
export class BranchStatsMapValuePipe implements PipeTransform {
  transform(map: Map<number, BranchStats>, key: number): BranchStats {
    const branchStats = map.get(key);
    return branchStats ? branchStats : branchStatsPlaceholder;
  }
}
