import { createAction } from '@ngrx/store';
import {
  ApiOffer,
  PricemonitorApiPriceRecommendation,
  PricemonitorStrategyMetadataResponseV3,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { ApiStrategy, ApiStrategyTreeResponse } from '@app/models/interfaces/api-strategies';

import { ApiActions } from '@store/api-actions';

import { PricingStrategyVendorSettings } from './pricing-strategy.reducers';

enum PricingStrategyActionsEnum {
  MODULE_INIT = '[Pricing strategy] Module init',
  MODULE_DESTROY = '[Pricing strategy] Module destroy',
  RESET_PRICING_STRATEGY = '[Pricing strategy] Reset Data',
  FETCH_STRATEGY = '[Pricing strategy] Fetch Strategy ',
  FETCH_STRATEGY_BY_DOCUMENT_VERSION = '[Pricing strategy] Fetch Strategy By Document-version ',
  FETCH_TASK_DATA = '[Pricing strategy] Fetch Task Data ',
  FETCH_PRODUCT_DATA = '[Pricing strategy] Fetch Product Data ',
  FETCH_TASK_STATUS = '[Pricing strategy] Fetch Task Status ',
  FETCH_DOMAINS = '[Pricing strategy] Fetch Domains ',
  FETCH_SHOPS_BY_DOMAIN = '[Pricing strategy] Fetch Shops ',
  FETCH_VENDOR_SETTINGS = '[Pricing strategy] Fetch Vendor Settings ',
  FETCH_TAGS = '[Pricing strategy] Fetch Tags ',
  TOGGLE_SHOP = '[Pricing strategy] Toggle Shop ',
  SAVE_STRATEGY_TREE = '[Pricing strategy] Save Strategy ',
  PUBLISH_PREPROCESSING_TASK = '[Pricing strategy] Publish Preprocessing Task ',
  FETCH_STRATEGY_METADATA_HISTORY = '[Pricing strategy] Fetch Strategy Metadata History ',
  FETCH_PRODUCT_PRICE_RECOMMENDATION = '[Pricing strategy - Recommendation explaination] Fetch Product Price Recommendation ',
  FETCH_PRODUCT_OFFERS = '[Pricing strategy - Recommendation explaination] Fetch Product Offers ',
  FETCH_PRODUCT_PROPERTIES = '[Pricing strategy] Fetch Product Properties ',
}

export const pricingStrategyInit = createAction(PricingStrategyActionsEnum.MODULE_INIT);
export const pricingStrategyDestroy = createAction(PricingStrategyActionsEnum.MODULE_DESTROY);

export const fetchStrategy = new ApiActions<{ sid: string }, { apiData: ApiStrategyTreeResponse }>(
  PricingStrategyActionsEnum.FETCH_STRATEGY
);
export const fetchStrategyByDocumentVersion = new ApiActions<{ documentVersion: number }, {}>(
  PricingStrategyActionsEnum.FETCH_STRATEGY_BY_DOCUMENT_VERSION
);
export const fetchTaskData = new ApiActions(PricingStrategyActionsEnum.FETCH_TASK_DATA);
export const fetchProductData = new ApiActions<{ data: any }, {}>(
  PricingStrategyActionsEnum.FETCH_PRODUCT_DATA
);
export const fetchTaskStatus = new ApiActions(PricingStrategyActionsEnum.FETCH_TASK_STATUS);
export const fetchDomains = new ApiActions<{ sid: string }, { domains: string[] }>(
  PricingStrategyActionsEnum.FETCH_DOMAINS
);
export const fetchShopsByDomain = new ApiActions<
  { sid: string },
  { shopsByDomain: Map<string, string[]> }
>(PricingStrategyActionsEnum.FETCH_SHOPS_BY_DOMAIN);

export const fetchVendorSettings = new ApiActions<
  { sid: string },
  { vendorSettings: PricingStrategyVendorSettings }
>(PricingStrategyActionsEnum.FETCH_VENDOR_SETTINGS);
export const fetchTags = new ApiActions<{ sid: string }, { tags: string[] }>(
  PricingStrategyActionsEnum.FETCH_TAGS
);
export const toggleShop = new ApiActions(PricingStrategyActionsEnum.TOGGLE_SHOP);
export const saveStrategy = new ApiActions<{ apiTree: ApiStrategy }, {}>(
  PricingStrategyActionsEnum.SAVE_STRATEGY_TREE
);
export const publishPreprocessingTask = new ApiActions(
  PricingStrategyActionsEnum.PUBLISH_PREPROCESSING_TASK
);
export const fetchStrategyMetadataHistory = new ApiActions<
  { sid: string },
  { strategyMetadataHistory: Array<PricemonitorStrategyMetadataResponseV3> }
>(PricingStrategyActionsEnum.FETCH_STRATEGY_METADATA_HISTORY);
export const fetchProductPriceRecommendation = new ApiActions<
  { sid: string; productId: string; timestamp: string },
  { productPriceRecommendation: PricemonitorApiPriceRecommendation }
>(PricingStrategyActionsEnum.FETCH_PRODUCT_PRICE_RECOMMENDATION);
export const fetchProductOffers = new ApiActions<
  {
    sid: string;
    contractId: number;
    productId: string;
  },
  { productOffers: ApiOffer[] }
>(PricingStrategyActionsEnum.FETCH_PRODUCT_OFFERS);
export const fetchProductProperties = new ApiActions<
  { sid: string },
  { productProperties: string[] }
>(PricingStrategyActionsEnum.FETCH_PRODUCT_PROPERTIES);
