import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-details-page-header',
  templateUrl: './product-details-page-header.component.html',
  styleUrls: ['./product-details-page-header.component.scss'],
})
export class ProductDetailsPageHeaderComponent {
  @Input() fixedPrice?: Number;

  @Input() fixedPriceLoading!: boolean;

  @Output() setFixedPrice = new EventEmitter();

  openFixedPriceSelection() {
    this.setFixedPrice.emit();
  }
}
