import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { DIALOG_SIZE_XL } from '../../constants';
import { CompetitorVariableDialogComponent } from '../competitor-variable-dialog/competitor-variable-dialog.component';

@Component({
  selector: 'app-competitor-variable',
  templateUrl: './competitor-variable.component.html',
  styleUrls: ['./competitor-variable.component.scss'],
})
export class CompetitorVariableComponent {
  @Output() getCompetitorVariable = new EventEmitter<string>();

  constructor(public dialog: MatDialog) {}

  @HostListener('click') onClick() {
    const dialogRef = this.dialog.open(CompetitorVariableDialogComponent, DIALOG_SIZE_XL);
    dialogRef.afterClosed().subscribe((result: string) => this.getCompetitorVariable.emit(result));
  }
}
