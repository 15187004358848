/****Start - UI strategy types/enums ****/

// These types describe the distinguished UI strategy types to be rendered in veiw
export enum UIStrategyType {
  IF_TAG = 'IfTagStrategy',
  IF_COMPETITOR = 'IfCompetitionStrategy',
  IF_RECOMMENDED_PRICE = 'IfRecommendedPriceStrategy',
  IF_FORMULA = 'IfFormulaAppliesStrategy',
  IF_TIMESTAMP_RANGE = 'IfTimestampRange',
  PRICE_FORMULA = 'PriceFormulaStrategy',
  COMPETITIVE_PRICING = 'CompetitivePricingStrategy',
  STOCK_CLEARANCE = 'StockClearanceStrategy',
  SAFETY_RULE = 'SafetyRuleStrategy',
  START = 'StartStrategy',
}

export enum NumericComparator {
  LESS = 'lessThan',
  GREATER = 'greaterThan',
  EQUAL = 'equalTo',
}

export enum PriceGapUnit {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export enum IfTagMatcher {
  IS = 'exact',
  IS_NOT = 'isNot',
  CONTAINS = 'contains',
  CONTAINS_NOT = 'containsNot',
}

export enum IfTagOperator {
  OR = 'or',
  AND = 'and',
}

/*****************************************/
