import { Injectable } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  readonly storageEvent = 'storage';

  private readonly storageEvent$ = fromEvent<StorageEvent>(window, this.storageEvent);

  public getStorageChanges(): Observable<StorageEvent> {
    return this.storageEvent$;
  }
}
