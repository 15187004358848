import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DATE_FORMAT } from '@app/components/strategy-component/constants';
import { StockClearanceStrategy } from '@app/components/strategy-component/ui-strategy-elements/goal-based-pricing/stock-clearance';

@Component({
  selector: 'app-stock-clearance-node',
  templateUrl: './stock-clearance-node.component.html',
  styleUrls: ['./stock-clearance-node.component.scss'],
})
export class StockClearanceNodeComponent {
  @ViewChild('stockClearanceNode')
  elementRef!: ElementRef;

  @Input() data?: StockClearanceStrategy;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isSelected = false;

  @Input() isFocused = false;

  @Input() isDeletingEnabled = true;

  @Output() deleteCallback = new EventEmitter();

  public dateFormat = DATE_FORMAT;
}
