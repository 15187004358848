<div class="flex h-14 flex-row justify-end">
  <div class="flex items-center gap-4">
    <div class="m-0 flex">
      <div class="flex items-center self-center">
        <span class="leading-6">
          Updated at: {{ (strategyMetadata?.updateDate | date : dateFormat) || '-' }}
        </span>
      </div>
      <div class="flex items-center self-center">
        <span class="mx-4 opacity-25">|</span>
        <span class="leading-6">Updated by: {{ strategyMetadata?.updatedBy || '-' }}</span>
      </div>
      <div class="flex items-center self-center pl-2">
        <mat-icon
          matTooltip="{{
            strategyMetadata?.documentVersionMessage
              ? 'Strategy comment: ' + strategyMetadata?.documentVersionMessage
              : 'No strategy comment'
          }}"
          >info</mat-icon
        >
      </div>
      <div class="flex items-center self-center pl-3" *ngIf="isRevertStrategyEnabled">
        <app-raised-button
          class="ml-4"
          color="secondary"
          text="Revert Strategy"
          icon="restore"
          iconSize="text-xl"
          matTooltip="Show old strategies to start revert process"
          (clickEvent)="openDialog()"
        >
        </app-raised-button>
      </div>
    </div>
  </div>
</div>
