import { Component, Inject, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DATE_FORMAT } from '@constants/date-format.constant';
import { Store } from '@ngrx/store';

import { AppState } from '@store/app.state';
import { fetchStrategyByDocumentVersion } from '@store/pricing-strategy';

import { StrategyMetadata } from '@models/interfaces/api-strategies';

@Component({
  selector: 'app-revert-dialog',
  templateUrl: './revert-dialog.component.html',
  styleUrls: ['./revert-dialog.component.scss'],
})
export class RevertDialogComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  strategyMetadataHistory: MatTableDataSource<StrategyMetadata>;

  dateFormat = DATE_FORMAT;

  selectedDocumentVersion?: number;

  columnsToDisplay: string[] = ['documentVersion', 'updateDate', 'updatedBy', 'comment'];

  constructor(
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<RevertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.strategyMetadataHistory = new MatTableDataSource<StrategyMetadata>(
      this.data.strategyHistory
    );
  }

  revertStrategy(): void {
    if (this.selectedDocumentVersion) {
      this.store.dispatch(
        fetchStrategyByDocumentVersion.init({ documentVersion: this.selectedDocumentVersion })
      );
      this.dialogRef.close();
    }
  }

  selectStrategy(documentVersion: number): void {
    this.selectedDocumentVersion = documentVersion;
  }
}
