import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-unknown-node',
  templateUrl: './unknown-node.component.html',
  styleUrls: ['./unknown-node.component.scss'],
})
export class UnknownNodeComponent {
  @Input() data: any;

  @Input() isSelected: boolean = false;

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
