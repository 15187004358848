<mat-form-field class="block" appearance="standard">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [disabled]="disabled"
    [placeholder]="placeholder"
    [attr.maxlength]="maxLength"
    [value]="value"
    (keyup)="setValue($event)"
  />
  <mat-hint>{{ hint }}</mat-hint>
  <mat-hint *ngIf="maxLength" align="end">{{ value?.length || 0 }}/{{ maxLength }}</mat-hint>
</mat-form-field>
