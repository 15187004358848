import { Pipe, PipeTransform } from '@angular/core';

import { UIStrategyType } from '../ui-strategy-elements/strategy-types';

@Pipe({
  name: 'isConditionNode',
})
export class IsConditionNodePipe implements PipeTransform {
  transform = (strategyType: string | undefined) =>
    strategyType === UIStrategyType.IF_TAG ||
    strategyType === UIStrategyType.IF_RECOMMENDED_PRICE ||
    strategyType === UIStrategyType.IF_FORMULA ||
    strategyType === UIStrategyType.IF_COMPETITOR ||
    strategyType === UIStrategyType.IF_TIMESTAMP_RANGE;
}
