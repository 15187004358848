import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItemInterface, NavigationState } from './navigation.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() navigationList: NavigationState | undefined;

  @Input() isPinned = false;

  @Input() isExpanded = false;

  @Input() fullLogoSrc = '';

  @Input() logoSrc = '';

  @Input() hasLogin?: boolean = false;

  @Output() setSizeEvent = new EventEmitter<MouseEvent>();

  @Output() setPinEvent = new EventEmitter();

  @Output() logoutCallback = new EventEmitter();

  @Output() expandItem = new EventEmitter<MenuItemInterface | null>();

  constructor(private readonly router: Router) {}

  public isOpen(label: string): boolean {
    const { url } = this.router;
    const isInPath = this.hasParentInUrl(label, url);
    return isInPath;
  }

  public setExpandedItem(navItem: MenuItemInterface, value: boolean): void {
    const item = { ...navItem, isExpanded: value };
    this.expandItem.emit(item);
  }

  public resetExpandedItem(): void {
    this.expandItem.emit(null);
  }

  public setSize(event: MouseEvent): void {
    this.setSizeEvent.emit(event);
  }

  public setPin(): void {
    this.setPinEvent.emit();
  }

  public logout(): void {
    this.logoutCallback.emit();
  }

  private hasParentInUrl(label: string, url: string): boolean {
    return url.includes(label.toLowerCase());
  }
}
