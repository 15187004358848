<div
  #stockClearanceNode
  class="node stock-clearance"
  [ngClass]="{ selected: isSelected, focus: isFocused }"
>
  <!-- This should be a header component -->
  <div class="header">
    <span>Stock Clearance</span>
    <ng-container *ngIf="isDeletingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <div *ngIf="data" class="flex flex-col justify-evenly">
      <div class="mt-1 truncate text-sm">
        <mat-icon class="strategy-type-icon float-left mr-1 text-base"> my_location </mat-icon>
        <span>Target level: </span>
        <span>{{ data.targetStockLevel | formatFormulaVars : varsMap }}</span>
      </div>
      <div class="mt-1">
        <div class="flex">
          <mat-icon class="strategy-type-icon float-left mr-1 text-base"> date_range </mat-icon>
          <div class="flex flex-col">
            <div class="flex truncate text-sm">
              <span class="mr-1">Start: </span>

              <span *ngIf="data.startDate?.value">{{
                data.startDate?.value | date : dateFormat
              }}</span>
              <span *ngIf="data.startDate?.tagKey">{{ data.startDate?.tagKey }}</span>
            </div>
            <div class="mt-1 flex truncate text-sm">
              <div class="mr-1">End:</div>
              <span>{{ data.endDate?.value | date : dateFormat }}</span>
              <span>{{ data.endDate?.tagKey }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1 flex justify-start truncate align-baseline text-sm">
        <mat-icon class="material-symbols symbol strategy-type-icon"> resume</mat-icon>
        <span class="flex flex-col justify-center" *ngIf="data.continuePricing"
          >Continue pricing</span
        >
        <span class="flex flex-col justify-center" *ngIf="!data.continuePricing"
          >Don't Continue pricing</span
        >
      </div>
    </div>
  </div>
</div>
