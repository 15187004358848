<div class="flex h-full flex-wrap">
  <div *ngFor="let step of steps; let $last = last" class="block">
    <app-link
      [placeholder]="step.label"
      [selected]="step.isActive"
      [disabled]="!step.isEnabled"
      [hasIcon]="!$last"
      [route]="step.route"
      (clickEvent)="onClick()"
    ></app-link>
  </div>
</div>
