import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { combineLatest, map } from 'rxjs';

import { selectHeaderTitle } from '@app/store/legacy';
import { loadingSpinnerShow } from '@app/store/loader-spinner/loader-spinner.actions';

import { AppState } from '@store/app.state';
import { contractSwitchAction } from '@store/router';
import {
  selectActiveCompany,
  selectActiveContract,
  selectCompanies,
  selectPortals,
  selectUserDetails,
} from '@store/user';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent extends SubscriptionManagementBaseClass {
  public headerSteps$ = this.store.select(selectHeaderTitle);

  public readonly $contractData = combineLatest([
    this.store.select(selectCompanies),
    this.store.select(selectUserDetails),
    this.store.select(selectActiveContract),
    this.store.select(selectActiveCompany),
    this.store.select(selectPortals),
  ]).pipe(
    map(([companies, userDetails, activeContract, activeCompany, portals]) => {
      const contractsData = Object.values(companies);
      const mappedPortals = Object.values(portals);

      if (!contractsData || !userDetails || !activeContract || !activeCompany || !mappedPortals) {
        return;
      }

      return {
        email: userDetails.email,
        currentContractSid: activeContract.sid,
        contractSwitcherData: {
          contractsData,
          currentItem: {
            companyId: activeCompany.id,
            contractSid: activeContract.sid,
          },
        },
      };
    })
  );

  constructor(private readonly store: Store<AppState>, private readonly router: Router) {
    super();
  }

  public contractSwitcherAction(contractSid?: string, currentContractSid?: string): void {
    if (!currentContractSid || !contractSid) {
      return;
    }
    this.store.dispatch(loadingSpinnerShow());

    this.store.dispatch(
      contractSwitchAction.init({
        currentSid: currentContractSid,
        newSid: contractSid,
      })
    );
  }
}
