import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 0.5 })),
      ]),
    ]),
  ],
})
export class BannerComponent {
  @Input() size: 'small' | 'large' = 'large';

  @Input() type: 'info' | 'warning' = 'info';

  @Input() messages: string[] = [];

  @ContentChildren(TemplateRef, { descendants: true })
  contentChildren?: QueryList<TemplateRef<ElementRef>>;
}
