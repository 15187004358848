import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  IfStrategyFilters,
  StringComparisonCondition,
} from '../../../ui-strategy-elements/conditions/conditions';

@Component({
  selector: 'app-if-tag-node',
  templateUrl: './if-tag-node.component.html',
  styleUrls: ['./if-tag-node.component.scss'],
})
export class IfTagNodeComponent {
  public readonly dataRowLimit = 5;

  public readonly StringComparisonCondition!: StringComparisonCondition;

  @Input() data: IfStrategyFilters | undefined;

  @Input() isSelected: boolean = false;

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
