<div class="max-w-sm">
  <form [formGroup]="form">
    <h2>Set fixed price</h2>
    <div class="alert alert-info">
      <p class="text-justify">
        <b>Note</b>: To apply the fixed price as the price recommendation, please create a branch in
        your strategy tree and select the fixed price properties as variables in the price formula
        or when setting start/end dates.
      </p>
    </div>
    <app-radio-button
      [checked]="!fixedPriceEnabled"
      label="Disabled"
      (valueChange)="enableFixedPrice(false)"
      class="mr-4"
    ></app-radio-button>
    <app-radio-button
      [checked]="fixedPriceEnabled"
      label="Enabled"
      (valueChange)="enableFixedPrice(true)"
    ></app-radio-button>
    <div class="mb-2 flex flex-col">
      <mat-form-field class="mt-4 block" appearance="standard">
        <mat-label>Fixed price (excluding shipping costs)</mat-label>
        <input
          matInput
          type="number"
          formControlName="price"
          (input)="calculateMargin()"
          [errorStateMatcher]="showErrorInstantly"
        />
        <mat-error *ngIf="priceControl.errors?.['required']">Price is required</mat-error>
        <mat-error *ngIf="priceControl.errors?.['min']">Price must be greater than 0</mat-error>
      </mat-form-field>
      <span
        *ngIf="fixedPriceEnabled && !priceControl.errors"
        class="pointer-events-none -mt-4 inline-block origin-left scale-75 transform whitespace-nowrap text-black text-opacity-60"
        [innerHTML]="marginText"
      >
      </span>
    </div>
    <div class="mb-2 flex flex-col">
      <mat-form-field class="block" appearance="standard">
        <mat-label>Start date</mat-label>
        <input matInput type="datetime-local" formControlName="startDate" />
        <mat-error *ngIf="form.errors?.['endDateBeforeStartDate']"
          >End date must be after start date</mat-error
        >
      </mat-form-field>
    </div>
    <div class="mb-2 flex flex-col">
      <mat-form-field class="block" appearance="standard">
        <mat-label>End date</mat-label>
        <input matInput type="datetime-local" formControlName="endDate" />
        <mat-error *ngIf="form.errors?.['endDateBeforeStartDate']"
          >End date must be after start date</mat-error
        >
      </mat-form-field>
    </div>
    <mat-error *ngIf="form.errors?.['endDateBeforeStartDate']"
      >End date must be after start date</mat-error
    >
    <div class="mt-4 flex justify-end">
      <app-raised-button color="warn" (clickEvent)="onCancel()" text="Cancel"></app-raised-button>
      <app-raised-button
        color="primary"
        class="ml-4"
        (clickEvent)="onSave()"
        [disabled]="form.invalid"
        text="Save"
      ></app-raised-button>
    </div>
  </form>
</div>
