// Copied from https://github.com/Patagona/pricemonitor-ui/blob/master/pricemonitor-ui/src/javascripts/services/FormulaValidation.ts

import { AbstractControl } from '@angular/forms';
import { Expression, Parser } from 'expr-eval';

import { isEqual, merge } from 'lodash';

import { AttributeOptions } from '../../constants';

/**
 * Represents the different possiblities for an invalid formula
 */
export enum InvalidFormulaOptions {
  emptyFormula = 'EmptyFormula',
  invalidFormula = 'InvalidFormula',
  invalidVariable = 'InvalidVariable',
  invalidNumber = 'InvalidNumber',
  invalidFunction = 'InvalidFunctionSyntax',
}

/**
 * Contains the validation data of the formula field.
 */
export interface FormulaValidationData {
  isValidFormula: boolean;
  invalidType?: string;
  invalidItem?: string;
  errorMessage?: string;
}
/**
 * Contains the validation type with matching error message of the formula field.
 */
export interface ConstantValidation {
  type: ConstantValidationType;
  errorMessage: string;
}

export enum ConstantValidationType {
  LARGER_THAN_ZERO = 'largerThanZero',
  LARGER_THAN_ONE = 'largerThanOne',
  LARGER_OR_EQUAL_ZERO = 'largerOrEqualZero',
  LARGER_OR_EQUAL_ONE = 'largerOrEqualOne',
  IS_INTEGER = 'isInteger',
}

export class FormulaValidation {
  // Note: UI allows double values only up to 4 decimal places
  private readonly formulaNumberValidationRegex =
    /^[0-9]\d*(((,\d{3}){2})?(\.\d{0,4})?)$|^[1-9]\d*(((,\d{3}){1})?(\.\d{0,4})?)$/g;

  public readonly operatorsRegex = /[-+*\/<,]/;

  public readonly parentheseRegex = /[()]/g;

  public readonly competitorVarRegex = new RegExp(
    `COMPETITOR\\[[0-9]+\\]\\.(${Object.values(AttributeOptions).join('|')})`,
    'g'
  );

  private parser = new Parser({
    operators: {
      add: true,
      subtract: true,
      divide: true,
      multiply: true,
      concatenate: false,
      conditional: false,
      factorial: false,
      power: false,
      remainder: false,
      logical: false,
      comparison: false,
      in: false,
      assignment: false,
    },
  });

  // Holds the mapping between the constant validation types and their functions
  private constantValidationMap: { [key: string]: (formula: string) => boolean } = {};

  private readonly variablePlaceHolder = 'TAG_VARIABLE';

  private readonly tagPrefix = 'tag';

  private readonly functionsSignatures = [
    'MAX1',
    'MAX2',
    'MAX3',
    'MAX4',
    'MAX5',
    'MIN1',
    'MIN2',
    'MIN3',
    'MIN4',
    'MIN5',
  ];

  public readonly functionSignaturesRegex = /MAX[2-5]|MIN[2-5]/g;

  constructor() {
    this.setConstantValidationMap();
  }

  private setConstantValidationMap(): void {
    this.constantValidationMap[ConstantValidationType.LARGER_THAN_ZERO] = (formula) => {
      return this.validateLargerThan(0, formula);
    };
    this.constantValidationMap[ConstantValidationType.LARGER_THAN_ONE] = (formula) => {
      return this.validateLargerThan(1, formula);
    };
    this.constantValidationMap[ConstantValidationType.LARGER_OR_EQUAL_ZERO] = (formula) => {
      return this.validateLargerOrEqual(0, formula);
    };
    this.constantValidationMap[ConstantValidationType.LARGER_OR_EQUAL_ONE] = (formula) => {
      return this.validateLargerOrEqual(1, formula);
    };
  }

  /**
   * Validate the formula.
   * @remark
   * The formula will be validated for: empty, invalid variable names,
   * invalide numbers (decimal sperator is not '.'), math expression
   */
  validateFormula(formula: string, validVariables: string[]): FormulaValidationData {
    let validationObject: FormulaValidationData = {
      isValidFormula: false,
      invalidType: '',
      invalidItem: '',
    };

    if (this.isEmpty(formula)) {
      validationObject.invalidType = InvalidFormulaOptions.emptyFormula;
      return validationObject;
    }

    /*  Sort the variable list in descending order of variable name lengths.
     *   This is to ensure tag names that are substrings of other tags are replaced last in the formula.
     *   Example of variables in front-end formula = TAG.VAR_NAME, in api-formula = <tag.VAR_NAME>
     */
    validVariables.sort((a, b) => b.length - a.length);
    validVariables.forEach((variable) => {
      if (
        variable.startsWith(this.tagPrefix.toUpperCase()) ||
        variable.startsWith(`<${this.tagPrefix}`)
      )
        formula = formula.replace(variable, this.variablePlaceHolder);
    });

    // Clean Formula from functions signatures (prepare to test variables)
    const formulaWithoutFunctions = formula
      .replace(this.functionSignaturesRegex, '')
      .replace(this.parentheseRegex, '');
    // Split Formula operands by operators or comma
    const items = formulaWithoutFunctions.split(this.operatorsRegex);

    for (let item of items) {
      item = item.trim();
      validationObject.invalidItem = item;

      if (item.match(this.competitorVarRegex)?.[0] !== item) {
        item = item.replace(this.parentheseRegex, '');

        if (item.match(/[a-z]+/gi)) {
          /*  The formula at this point could contain three types of valid variables:
           *   1. Variables named as 'offers'
           *   2. Variable placeholders added instead of TAGS in the previous step. i.e TAG_VARIABLE
           *   3. Other valid variables such as COMPETITOR, OFFER_PRICE, MAX_PRICE etc
           */
          if (
            !item.includes('offers') &&
            item !== this.variablePlaceHolder &&
            !this.hasValidFormulaVariable(item, validVariables)
          ) {
            if (item.includes(this.variablePlaceHolder)) validationObject.invalidItem = '';
            validationObject.invalidType = InvalidFormulaOptions.invalidVariable;
            return validationObject;
          }
        } else if (item.match(/[0-9]/g)) {
          if (!this.hasValidFormulaNumber(item)) {
            validationObject.invalidType = InvalidFormulaOptions.invalidNumber;
            return validationObject;
          }
        }
      }
    }

    if (formula.match(this.competitorVarRegex)?.[0] !== formula) {
      // validate that the formula represents a correct math expression after removing < and >
      try {
        this.parser.parse(formula.replace(this.competitorVarRegex, 'a').replace(/<|>/g, ''));
      } catch (e) {
        validationObject.invalidType = InvalidFormulaOptions.invalidFormula;
        validationObject.invalidItem = '';
        return validationObject;
      }
    }
    return { isValidFormula: true };
  }

  /**
   * Validate Function (based on expr-eval parser)
   * 1. Function should have a signature which only one of those MAX2,MAX3,MAX4,MAX5,MIN2,MIN3,MIN4,MIN5
   * 2. Each funtion should have matching number of arguments, e.g: MIN2 should have 2 arguments MIN2(ARG1,ARG2) comma separated
   * 3. Valid mathematical expression
   * @param formula
   * @param validVariables
   * @returns
   */
  validateFunction(formula: string, validVariables: string[]): FormulaValidationData {
    let validationObject: FormulaValidationData = {
      isValidFormula: false,
      invalidType: '',
      invalidItem: '',
    };

    if (this.isEmpty(formula)) {
      validationObject.invalidType = InvalidFormulaOptions.emptyFormula;
      return validationObject;
    }

    /*  Sort the variable list in descending order of variable name lengths.
     *   This is to ensure tag names that are substrings of other tags are replaced last in the formula.
     *   Example of variables in front-end formula = TAG.VAR_NAME, in api-formula = <tag.VAR_NAME>
     */
    validVariables.sort((a, b) => b.length - a.length);
    validVariables.forEach((variable) => {
      if (
        variable.startsWith(this.tagPrefix.toUpperCase()) ||
        variable.startsWith(`<${this.tagPrefix}`)
      )
        formula = formula.replace(variable, this.variablePlaceHolder);
    });

    // Validate Function signature
    const functionArgsLimit: string[] = [];
    const numOfArguments: string[] = [];
    try {
      // Parse validates the syntax i.e: valid math expression
      const ParsedFormula: Expression = this.parser.parse(
        formula.replace(this.competitorVarRegex, 'a').replace(/<|>/g, '')
      );
      // @ts-ignore Expression Object has an attribut called tokens with parsed items of expression, and tokens is not defined in library Expression interface, hence the typescript is complaining about it
      ParsedFormula.tokens.forEach((token) => {
        const isFunctionSignature = this.functionsSignatures.includes(token.value);
        // Token holds a function signature and not a potential variable name
        if (token.type === 'IVAR' && isFunctionSignature) {
          const expectedNumOfArguments = token.value.split(/(MAX|MIN)/)[2];
          functionArgsLimit.push(expectedNumOfArguments);
        }
        // Token holds the mumber of arguments of function call
        else if (token.type === 'IFUNCALL') {
          numOfArguments.push('' + token.value);
        }
      });
      if (!isEqual(functionArgsLimit.sort(), numOfArguments.sort())) {
        validationObject.isValidFormula = false;
        validationObject.invalidType = InvalidFormulaOptions.invalidFunction;
        validationObject.invalidItem = formula;
        return validationObject;
      }
    } catch (e) {
      validationObject.invalidType = InvalidFormulaOptions.invalidFormula;
      validationObject.invalidItem = formula;
      return validationObject;
    }

    return { isValidFormula: true };
  }

  isEmpty(formula: string): boolean {
    return formula === '';
  }

  hasValidFormulaVariable(variable: string, validVariables: string[]): boolean {
    return Array.from(validVariables).includes(variable);
  }

  hasValidFormulaNumber(number: string): boolean {
    return !(number.search(this.formulaNumberValidationRegex) < 0);
  }

  /**
   * This includes additional validation on the formula
   * @remark this is only applied on constant formula
   * @param validationType one of the ConstantValidation types
   * @param errorMsg error message to show in case of invalid constant formula
   */
  constantValidation(
    formula: string,
    validationType: string,
    errorMsg: string
  ): FormulaValidationData {
    if (this.isConstant(formula)) {
      if (!this.constantValidationMap[validationType](formula))
        return { isValidFormula: false, errorMessage: errorMsg };
    }
    return { isValidFormula: true };
  }

  /**
   * Check if the formula consists of only a constant
   */
  isConstant(formula: string): boolean {
    const formulaDigits: RegExpMatchArray | null = formula.match(/[0-9.-]+/);
    if (formulaDigits) return formulaDigits[0] === formula;
    return false;
  }

  /**
   * Check if the constant formula is an integer
   */
  isInteger(constant: string): boolean {
    const containsFloatSeparator: RegExpMatchArray | null = constant.match(/[.]+/);
    return !containsFloatSeparator ? Number.isInteger(parseFloat(constant)) : false;
  }

  /**
   * Validate that constant formula is larger than a specific number
   * @param validationNumber the number to validate against
   */
  validateLargerThan(validationNumber: number, formula: string): boolean {
    return parseFloat(formula) > validationNumber;
  }

  /**
   * Validate that constant formula is larger than or equal to a specific number
   */
  validateLargerOrEqual(validationNumber: number, formula: string): boolean {
    return parseFloat(formula) >= validationNumber;
  }
}

export const getInvalidTypeErrorMessage = (error: string | null | undefined) => {
  switch (error) {
    case 'EmptyFormula':
      return 'Formula must not be empty';
    case 'InvalidFormula':
      return 'Formula is invalid';
    case 'InvalidVariable':
      return 'Formula has invalid variable name:';
    case 'InvalidNumber':
      return 'Invalid number:';
    case 'InvalidFunctionSyntax':
      return 'Invalid function syntax or number of arguments does not match in expression: ';
    default:
      return 'There are errors in this formula';
  }
};

export const formulaValidator = (validVariables: string[]) => (control: AbstractControl) => {
  const formulaValidation = new FormulaValidation().validateFormula(
    control.value || '',
    validVariables
  );
  const functionValidation = new FormulaValidation().validateFunction(
    control.value || '',
    validVariables
  );
  const isFormulaValid = functionValidation.isValidFormula && formulaValidation.isValidFormula;
  const validation = merge(functionValidation, formulaValidation);
  validation.isValidFormula = isFormulaValid;

  return validation.isValidFormula ? null : validation;
};
