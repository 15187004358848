import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyReplace',
})
export class LegacyReplacePipe implements PipeTransform {
  public transform(value: string, strToReplace: string, replacementStr: string): string {
    return value.replace(new RegExp(this.escapeStr(strToReplace), 'g'), replacementStr);
  }

  private escapeStr(str: string): string {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
