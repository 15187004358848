import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { delay, map, startWith } from 'rxjs/operators';

import { FilterOptions } from './model';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSelectComponent implements OnChanges {
  @ViewChild('filter') public readonly filterSelectElement?: ElementRef<HTMLInputElement>;

  @Input() value: unknown;

  @Input() disabled = false;

  @Input() valueAttribute = '';

  @Input() options: FilterOptions[] = [];

  @Input() label = '';

  @Input() selected?: string;

  public readonly filterSelect = new UntypedFormGroup({
    selectedOption: new UntypedFormControl(null, Validators.required),
    filter: new UntypedFormControl(''),
  });

  @Output() public readonly emitChange = new EventEmitter();

  public readonly filteredValues$ = this.filterSelect.valueChanges.pipe(
    startWith({ filter: '' }),
    delay(300),
    map(({ filter }) => {
      const match = new RegExp(filter as string, 'gi');

      return filter
        ? this.options.filter(({ options }) => options.label.match(match))
        : this.options;
    })
  );

  public ngOnChanges(): void {
    const selectedOption = this.options.find(({ options }) => options.id === this.selected);
    this.filterSelect.patchValue({
      selectedOption: selectedOption?.options?.reference || selectedOption?.options,
    });
    this.filterSelect.updateValueAndValidity();
    this.updateSelectedOption();
  }

  /**
   * Update disabled property of selectedOption control,
   * by using the reactive form.
   * @remark adding [disabled] on the element will cause a console warning
   */
  public updateSelectedOption(): void {
    if (this.disabled) {
      this.filterSelect.controls['selectedOption'].disable();
    }
  }

  public afterClose(event: boolean): void {
    if (event) {
      this.triggerFocus();
    }

    this.filterSelect.patchValue({
      filter: '',
    });
  }

  public change(value: unknown): void {
    if (this.valueAttribute) {
      // @ts-ignore
      this.value[this.valueAttribute] = value;
    } else {
      this.value = value;
    }
    this.emitChange.emit(value);
  }

  private triggerFocus(): void {
    setTimeout(() => {
      this.filterSelectElement?.nativeElement.focus();
    }, 500);
  }
}
