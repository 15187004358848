<div class="node unknown" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Unknown</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    {{ data.strategyType }}
  </div>
</div>
