<div class="node condition" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Condition</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <mat-icon class="strategy-type-icon float-left mr-1 text-xl">functions</mat-icon>
    <div class="mt-0 text-base font-bold">If formula applies</div>
    <div *ngIf="data">
      <div class="mt-1 truncate text-sm">
        {{ data.leftFormula | formatFormulaVars : varsMap }}
        {{ comparatorSymbol[data.operator] }}
        {{ data.rightFormula | formatFormulaVars : varsMap }}
      </div>
    </div>
  </div>
</div>
