<section
  class="grid grid-cols-8 items-center justify-items-center gap-3 border-0 border-b border-solid py-4"
>
  <h5 class="m-0">Basic</h5>
  <app-basic-button text="Demo button"></app-basic-button>
  <app-basic-button color="primary" text="Primary"></app-basic-button>
  <app-basic-button color="accent" text="Accent"></app-basic-button>
  <app-basic-button color="warn" text="Warn"></app-basic-button>
  <app-basic-button disabled text="Disabled"></app-basic-button>
  <app-basic-button icon="play_arrow" text="Icon left"></app-basic-button>
  <app-basic-button
    [hasIconAfterText]="true"
    icon="play_arrow"
    text="Icon right"
  ></app-basic-button>
</section>

<section
  class="grid grid-cols-8 items-center justify-items-center gap-3 border-0 border-b border-solid py-4"
>
  <h5 class="m-0">Raised</h5>
  <app-raised-button text="Demo button"></app-raised-button>
  <app-raised-button color="primary" text="Primary"></app-raised-button>
  <app-raised-button color="accent" text="Accent"></app-raised-button>
  <app-raised-button color="warn" text="Warn"></app-raised-button>
  <app-raised-button disabled text="Disabled"></app-raised-button>
  <app-raised-button icon="play_arrow" text="Icon left"></app-raised-button>
  <app-raised-button
    [hasIconAfterText]="true"
    icon="play_arrow"
    text="Icon right"
  ></app-raised-button>
</section>

<section
  class="grid grid-cols-8 items-center justify-items-center gap-3 border-0 border-b border-solid py-4"
>
  <h5 class="m-0">Icon</h5>
  <app-icon-button icon="delete_outline"></app-icon-button>
  <app-icon-button color="primary" icon="delete_outline"></app-icon-button>
  <app-icon-button color="accent" icon="delete_outline"></app-icon-button>
  <app-icon-button color="warn" icon="delete_outline"></app-icon-button>
  <app-icon-button disabled icon="delete_outline"></app-icon-button>
</section>
