import { IBadgeType } from '@app/models/common/badge.type';

export function StatusTypeMapper(status?: string): IBadgeType {
  switch (status) {
    case 'succeeded':
      return 'success';
    case 'failed':
      return 'error';
    case undefined:
      return 'error';
    default:
      return status as IBadgeType;
  }
}
