import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  createTimestamp,
  createTimestampInUTC,
  extractDate,
  extractTime,
} from '@app/components/strategy-component/utils/timestamp';

import {
  IfStrategyFilters,
  TimestampRangeCondition,
} from '../../../ui-strategy-elements/conditions/conditions';

@Component({
  selector: 'app-if-timestamp-range-form',
  templateUrl: './if-timestamp-range-form.component.html',
  styleUrls: ['./if-timestamp-range-form.component.scss'],
})
export class IfTimestampRangeFormComponent implements OnChanges {
  @Input() data?: IfStrategyFilters;

  @Input() isSavingEnabled? = true;

  @Input() productProperties: string[] = [];

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  ifTimestampForm!: UntypedFormGroup;

  selectDateFromProductProperties: boolean = false;

  startDatePropertyKeys = ['fixed_price_start_date'];

  endDatePropertyKeys = ['fixed_price_end_date'];

  hasDatePropertyKey =
    this.containsDateKey(this.productProperties, this.startDatePropertyKeys) &&
    this.containsDateKey(this.productProperties, this.endDatePropertyKeys);

  controlErrorMessage: Map<string, string> = new Map([
    ['incorrectDateOrder', 'Start date/time should not be larger than end date/time'],
    ['startValueMissing', 'Both start date and time should be defined'],
    ['endValueMissing', 'Both end date and time should be defined'],
    ['startOrEndRequired', 'At least start or end value should be defined'],
  ]);

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(): void {
    this.initDateAndTime();
    this.hasDatePropertyKey =
      this.containsDateKey(this.productProperties, this.startDatePropertyKeys) &&
      this.containsDateKey(this.productProperties, this.endDatePropertyKeys);
  }

  private containsDateKey(productPropertyKeys: string[] | undefined, dateKeys: string[]): boolean {
    return (
      productPropertyKeys?.some((productPropertyKey) => dateKeys.includes(productPropertyKey)) ??
      false
    );
  }

  private initDateAndTime(): void {
    const timestampRange = this.data?.query[0].data as TimestampRangeCondition;
    if (!timestampRange) {
      return;
    }
    const startDate = timestampRange.start?.value;
    const endDate = timestampRange.end?.value;
    const startDateKey = timestampRange.start?.productPropertyKey;
    const endDateKey = timestampRange.end?.productPropertyKey;

    this.selectDateFromProductProperties = startDateKey || endDateKey ? true : false;

    this.ifTimestampForm = this.formBuilder.group(
      {
        startDate: startDate ? extractDate(startDate) : '',
        endDate: endDate ? extractDate(endDate) : '',
        startTime: startDate ? extractTime(startDate) : '',
        endTime: endDate ? extractTime(endDate) : '',
        startDatePropertyKey: startDateKey ?? undefined,
        endDatePropertyKey: endDateKey ?? undefined,
      },
      {
        validators: this.timestampsValidator.bind(this),
      }
    );
  }

  /**
   * Perform the following validations:
   * 1. If date picker is selected:
   *    a. Check that at leaset one of the timestamps (start, end) should be defined
   *    b. Check that start date is before date in case both are defined
   * 2. If property keys are selected for dates:
   *    a. Check that at least one of the keys - for start or end are defined
   * @param control
   * @returns
   */
  private timestampsValidator(control: AbstractControl) {
    if (this.selectDateFromProductProperties) {
      return this.validateSelectFromPropertyKeysForm(control);
    } else {
      return this.validateDatePickerForm(control);
    }
  }

  private fetchControlsFromForm(control: AbstractControl): any {
    const startDatePropertyKey = control.get('startDatePropertyKey');
    const endDatePropertyKey = control.get('endDatePropertyKey');
    const startDate = control.get('startDate');
    const endDate = control.get('endDate');
    const startTime = control.get('startTime');
    const endTime = control.get('endTime');

    return { startDatePropertyKey, endDatePropertyKey, startDate, endDate, startTime, endTime };
  }

  private validateSelectFromPropertyKeysForm(control: AbstractControl): any {
    const { startDatePropertyKey, endDatePropertyKey } = this.fetchControlsFromForm(control);
    if (startDatePropertyKey?.value || endDatePropertyKey?.value) {
      return null;
    }
    return { startOrEndRequired: true };
  }

  private validateDatePickerForm(control: AbstractControl): any {
    const { startDate, endDate, startTime, endTime } = this.fetchControlsFromForm(control);

    const { dateDefined: startDateDefined, timeDefined: startTimeDefined } =
      this.checkDateTimePickersDefined(startDate, startTime);
    const startDefined = startDateDefined && startTimeDefined;

    const { dateDefined: endDateDefined, timeDefined: endTimeDefined } =
      this.checkDateTimePickersDefined(endDate, endTime);
    const endDefined = endDateDefined && endTimeDefined;

    if (startDateDefined !== startTimeDefined) {
      return { startValueMissing: true };
    }
    if (endDateDefined !== endTimeDefined) {
      return { endValueMissing: true };
    }

    return this.checkDateOrder(
      startDefined,
      endDefined,
      startDate.value,
      startTime.value,
      endDate.value,
      endTime.value
    );
  }

  private checkDateOrder(
    startDefined: boolean,
    endDefined: boolean,
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) {
    if (!startDefined && !endDefined) {
      return { startOrEndRequired: true };
    }
    if (startDefined && endDefined) {
      const start = createTimestamp(startDate, startTime);
      const end = createTimestamp(endDate, endTime);
      if (end <= start) {
        return { incorrectDateOrder: true };
      }
    }
    return null;
  }

  private checkDateTimePickersDefined(dateFromPicker: any, timeFromPicker: any) {
    const dateDefined =
      dateFromPicker !== null && dateFromPicker.value !== null && dateFromPicker.value !== '';
    const timeDefined =
      timeFromPicker !== null && timeFromPicker.value !== null && timeFromPicker.value !== '';
    return { dateDefined, timeDefined };
  }

  toggleDateSelection(): void {
    this.selectDateFromProductProperties = !this.selectDateFromProductProperties;
    this.ifTimestampForm.updateValueAndValidity();
  }

  save(): void {
    let timestampRange: TimestampRangeCondition = {};
    const { startDatePropertyKey, endDatePropertyKey, startDate, endDate, startTime, endTime } =
      this.fetchControlsFromForm(this.ifTimestampForm);

    if (this.selectDateFromProductProperties) {
      if (startDatePropertyKey.value) {
        timestampRange.start = { productPropertyKey: startDatePropertyKey.value };
      }
      if (endDatePropertyKey.value) {
        timestampRange.end = { productPropertyKey: endDatePropertyKey.value };
      }
    } else {
      if (startDate && startDate.value && startTime && startTime.value) {
        const start = createTimestampInUTC(startDate.value, startTime.value);
        timestampRange.start = { value: start };
      }

      if (endDate && endDate.value && endTime && endTime.value) {
        const end = createTimestampInUTC(endDate.value, endTime.value);
        timestampRange.end = { value: end };
      }
    }

    const filter = { ...this.data, query: [{ data: timestampRange }] };
    this.saveCallback.emit(filter);
  }

  clearFormControl(control: string) {
    this.ifTimestampForm.get(control)!.setValue(null);
  }
}
