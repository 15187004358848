import { Component, EventEmitter, Input, Output } from '@angular/core';

import { COMPARATOR_SYMBOL } from '../../../constants';
import { IfFormulaAppliesStrategy } from '../../../ui-strategy-elements/conditions/conditions';

@Component({
  selector: 'app-if-formula-node',
  templateUrl: './if-formula-node.component.html',
  styleUrls: ['./if-formula-node.component.scss'],
})
export class IfFormulaNodeComponent {
  readonly comparatorSymbol = COMPARATOR_SYMBOL;

  @Input() data: IfFormulaAppliesStrategy | undefined;

  @Input() isSelected: boolean = false;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
