import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { cloneDeep } from 'lodash';

export type ContractAccess = {
  name: string;
  contractId: string;
};

export type User = {
  name: string;
  email: string;
  lastActive: Date;
  hasAccessToAllContracts: boolean;
  accessibleContracts: string[];
};

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
})
export class UsersListComponent implements OnInit, OnChanges {
  @Input() users: User[] = [];

  @Input() allContracts: ContractAccess[] = [];

  usersWorkingCopy: User[] = [];

  isDirty: boolean = false;

  isValid: boolean = true;

  saveIcon: string = 'block';

  @Input() saveInProgress = false;

  @Output() save = new EventEmitter<User[]>();

  ngOnInit() {
    this.usersWorkingCopy = cloneDeep(this.users);
  }

  reset() {
    this.usersWorkingCopy = cloneDeep(this.users);
    this.isDirty = false;
    this.updateSaveIcon();
  }

  userChanged(_: User) {
    this.isDirty = JSON.stringify(this.users) !== JSON.stringify(this.usersWorkingCopy);
    this.isValid = this.usersAreValid(this.usersWorkingCopy);
    this.updateSaveIcon();
  }

  updateSaveIcon() {
    this.saveIcon = this.isDirty ? 'save' : 'block';
    if (!this.isValid) {
      this.saveIcon = 'block';
    }
    if (this.saveInProgress) {
      this.saveIcon = 'sync';
    }
  }

  /**
   * Make sure each user has either at least one accessible contract or has access to all contracts.
   * @param users
   */
  usersAreValid(users: User[]): boolean {
    return users.every((user) => {
      return (
        (user.hasAccessToAllContracts && user.accessibleContracts.length == 0) ||
        (!user.hasAccessToAllContracts && user.accessibleContracts.length > 0)
      );
    });
  }

  saveClicked() {
    this.save.emit(this.usersWorkingCopy);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['users']) {
      this.usersWorkingCopy = cloneDeep(this.users);
      this.isDirty = false;
      this.updateSaveIcon();
    }
    if (changes['saveInProgress']) {
      this.updateSaveIcon();
    }
  }
}
