<div class="content-header">
  <h2>Goal-based Pricing</h2>
  <p>Define a business goal and let Omnia’s algorithms optimize towards it</p>
</div>
<!-- TODO Icon not positioned and sized according to mockup -->
<div class="content-subheader">
  <!-- Add the necessary import statement for 'mat-icon' -->
  <mat-icon class="strategy-type-icon shopping-cart-icon">remove_shopping_cart</mat-icon>
  <div class="legend">
    <h3>Stock Clearance</h3>
    <p>
      Prevent low margins due to high end-of-season discounts for overstock. Allow Omnia to optimize
      discounts in small steps towards a stock-clearance end-date. All automated.
    </p>
  </div>
</div>

<div class="form" [formGroup]="stockClearanceForm">
  <div *ngIf="stockClearanceForm?.errors" class="error">
    <span *ngIf="stockClearanceForm.errors?.['startAndEndRequired']">
      {{ controlErrorMessage.get('startAndEndRequired') }}
    </span>
  </div>
  <div>
    <h3>Target stock level</h3>
    <div class="inputs">
      <input
        type="text"
        placeholder="Enter value or select variable"
        formControlName="targetStockLevel"
      />
      <div class="warning" *ngIf="!target?.errors && !isInteger(+target?.value)">
        Please note that in the context of target stock levels, formulas must result in 0 or
        positive integers for the strategy to work as expected.
      </div>
      <div class="error" *ngIf="target?.errors">
        <span *ngIf="target?.errors?.['invalidType']">
          {{getInvalidTypeErrorMessage(target?.errors?.['invalidType'])}}
        </span>
        <span *ngIf="target?.errors?.['invalidItem']">
          {{target?.errors?.['invalidItem']}}
        </span>
      </div>
      <div class="suggested-vars-link" (click)="isTargetVarsShown = !isTargetVarsShown">
        <span>Available variables</span>
        <mat-icon *ngIf="!isTargetVarsShown">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="isTargetVarsShown">arrow_drop_up</mat-icon>
      </div>
      <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isTargetVarsShown }">
        <div class="suggested-var-filter">
          <input
            type="text"
            formControlName="searchTargetVar"
            [(ngModel)]="searchTargetVar"
            placeholder="Search available variables"
          />
          <button
            class="search-button {{ searchTargetVar ? 'is-clickable' : '' }}"
            (click)="searchTargetVar = ''"
          >
            <mat-icon *ngIf="searchTargetVar === ''">search</mat-icon>
            <mat-icon *ngIf="searchTargetVar">clear</mat-icon>
          </button>
        </div>
        <ul class="suggested-vars">
          <li
            *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchTargetVar"
            [ngSwitch]="variable"
          >
            <div *ngSwitchCase="'COMPETITOR'">
              <app-competitor-variable
                class="suggested-var"
                (getCompetitorVariable)="setCompetitorVariable($event, target)"
              ></app-competitor-variable>
            </div>

            <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

            <div
              *ngSwitchDefault
              class="suggested-var"
              (click)="target?.setValue((target?.value || '') + variable); target?.markAsDirty()"
            >
              {{ variable }}
            </div>
          </li>
        </ul>
      </div>
      <div
        class="error"
        *ngIf="stockClearanceForm.get('targetStockLevel')?.errors?.['incorrectTargetLevel']"
      >
        {{ controlErrorMessage.get('incorrectTargetLevel') }}
      </div>
    </div>
  </div>
  <app-toggle
    class="mt-3"
    [color]="'accent'"
    [label]="'Pick dates from tags'"
    (valueChange)="toggleDateSelection()"
    [labelPosition]="'after'"
    [toggled]="selectDatesFromTags"
  >
  </app-toggle>

  <ng-container *ngIf="!selectDatesFromTags">
    <div>
      <h3>End of season - Start date</h3>
      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Date"
          formControlName="startDate"
          [matDatepicker]="startDatepicker"
          (click)="startDatepicker.open()"
        />
        <mat-icon
          *ngIf="stockClearanceForm?.get('startDate')?.value"
          class="form-button clear-icon"
          (click)="clearFormControl('startDate')"
        >
          clear
        </mat-icon>
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
        <div *ngIf="stockClearanceForm?.errors" class="error">
          <span *ngIf="stockClearanceForm.errors?.['incorrectDateOrder']">
            {{ controlErrorMessage.get('incorrectDateOrder') }}
          </span>
          <span *ngIf="stockClearanceForm.errors?.['startValueMissing']">
            {{ controlErrorMessage.get('startValueMissing') }}
          </span>
        </div>
      </div>

      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Time"
          formControlName="startTime"
          [format]="24"
          [ngxMatTimepicker]="startTimepicker"
        />
        <mat-icon
          *ngIf="stockClearanceForm?.get('startTime')?.value"
          class="clear-icon"
          (click)="clearFormControl('startTime')"
        >
          clear
        </mat-icon>
        <ngx-mat-timepicker-toggle matSuffix [for]="startTimepicker"></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker #startTimepicker></ngx-mat-timepicker>
        <div *ngIf="stockClearanceForm?.errors" class="error">
          <span *ngIf="stockClearanceForm.errors?.['startValueMissing']">
            {{ controlErrorMessage.get('startValueMissing') }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <h3>End of season - End date</h3>
      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Date"
          formControlName="endDate"
          [matDatepicker]="endDatepicker"
          (click)="endDatepicker.open()"
        />
        <mat-icon
          *ngIf="stockClearanceForm?.get('endDate')?.value"
          class="clear-icon"
          (click)="clearFormControl('endDate')"
        >
          clear
        </mat-icon>
        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
        <div *ngIf="stockClearanceForm?.errors" class="error">
          <span *ngIf="stockClearanceForm.errors?.['incorrectDateOrder']">
            {{ controlErrorMessage.get('incorrectDateOrder') }}
          </span>
          <span *ngIf="stockClearanceForm.errors?.['endValueMissing']">
            {{ controlErrorMessage.get('endValueMissing') }}
          </span>
          <span *ngIf="stockClearanceForm.errors?.['endDatePassed']">
            {{ controlErrorMessage.get('endDatePassed') }}
          </span>
        </div>
      </div>

      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Time"
          formControlName="endTime"
          [format]="24"
          [ngxMatTimepicker]="endTimepicker"
        />
        <mat-icon
          *ngIf="stockClearanceForm?.get('endTime')?.value"
          class="clear-icon"
          (click)="clearFormControl('endTime')"
        >
          clear
        </mat-icon>
        <ngx-mat-timepicker-toggle matSuffix [for]="endTimepicker"></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker #endTimepicker></ngx-mat-timepicker>
        <div *ngIf="stockClearanceForm?.errors" class="error">
          <span *ngIf="stockClearanceForm.errors?.['endValueMissing']">
            Both end date and time should be defined
          </span>
          <span *ngIf="stockClearanceForm.errors?.['endDatePassed']">
            {{ controlErrorMessage.get('endDatePassed') }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectDatesFromTags">
    <div>
      <h3>End of season - Start date</h3>
      <div class="tag-key-container">
        <label class="tag-key-label" for="tagName">Tag: </label>
        <input
          class="tag-key-input"
          matInput
          type="text"
          placeholder="Select Tag"
          formControlName="startDateFromTags"
          id="tagName"
          [matAutocomplete]="tagAutocomplete"
        />

        <mat-autocomplete #tagAutocomplete="matAutocomplete" class="tag-key-autocomplete">
          <mat-option disabled selected value="null">Select Tag</mat-option>
          <mat-option *ngFor="let tag of tags" [value]="tag">
            {{ tag }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
    <div>
      <h3>End of season - End date</h3>
      <div class="tag-key-container">
        <label class="tag-key-label" for="tagName">Tag: </label>
        <input
          class="tag-key-input"
          matInput
          type="text"
          placeholder="Select Tag"
          formControlName="endDateFromTags"
          id="tagName"
          [matAutocomplete]="tagAutocomplete"
        />
      </div>
    </div>
  </ng-container>

  <div class="checkbox-option">
    <h3>
      <input type="checkbox" formControlName="continuePricing" />
      <mat-icon class="strategy-type-icon resume-icon">resume</mat-icon>
      Resume after end date
    </h3>
    <p class="ml-4">
      By default, the price calculation will continue to optimize any remaining stock after the end
      date. Do you want the calculation to use the configured fallback rules instead?
    </p>
  </div>
  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="stockClearanceForm && stockClearanceForm.invalid"
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
