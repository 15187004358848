<div class="content-header">
  <label class="block pb-4 text-center text-xl"
    ><b>Do you want to revert to another strategy?</b></label
  >
</div>

<div class="p-2">
  <table mat-table [dataSource]="strategyMetadataHistory">
    <ng-container matColumnDef="documentVersion">
      <th mat-header-cell *matHeaderCellDef>Version</th>
      <td mat-cell *matCellDef="let strategy">{{ strategy.documentVersion }}</td>
    </ng-container>
    <ng-container matColumnDef="updateDate">
      <th mat-header-cell *matHeaderCellDef>Updated at</th>
      <td mat-cell *matCellDef="let strategy">{{ strategy.updateDate | date : dateFormat }}</td>
    </ng-container>
    <ng-container matColumnDef="updatedBy">
      <th mat-header-cell *matHeaderCellDef>Updated by</th>
      <td mat-cell *matCellDef="let strategy">{{ strategy.updatedBy }}</td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let strategy">{{ strategy.documentVersionMessage }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="this.columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let strategy; columns: this.columnsToDisplay"
      [ngClass]="{ 'is-selected': selectedDocumentVersion === strategy.documentVersion }"
      (click)="selectStrategy(strategy.documentVersion)"
    ></tr>
  </table>
  <div mat-dialog-actions class="justify-center">
    <app-raised-button
      class="ml-4"
      color="secondary"
      text="Cancel"
      (clickEvent)="dialogRef.close()"
    >
    </app-raised-button>
    <app-raised-button
      class="ml-4"
      color="primary"
      text="Revert Strategy"
      [disabled]="!this.selectedDocumentVersion"
      (clickEvent)="revertStrategy()"
    >
    </app-raised-button>
  </div>
</div>
