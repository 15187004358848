import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PriceFormulaStrategy } from '../../../ui-strategy-elements/price-formula/price-formula';

@Component({
  selector: 'app-price-formula-node',
  templateUrl: './price-formula-node.component.html',
  styleUrls: ['./price-formula-node.component.scss'],
})
export class PriceFormulaNodeComponent {
  @Input() data: PriceFormulaStrategy | undefined;

  @Input() isSelected: boolean = false;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
