<div class="content-header">
  <h2>Default Pricing Settings</h2>
  <p>
    Default pricing settings will be applied to your products if no other rules are defined in your
    pricing strategy
  </p>
</div>

<div class="form" [formGroup]="defaultSettingsForm">
  <div class="optional-group">
    <input checked disabled type="checkbox" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">my_location</mat-icon>
        Target Position
      </h3>
      <p>Omnia will try to place your offer on the target position defined here.</p>
      <div class="inputs">
        <input
          type="text"
          placeholder="Enter value or select variable"
          formControlName="targetPosition"
        />
        <div class="warning" *ngIf="!target?.errors && !isInteger(+target?.value)">
          In the context of target position strategies, decimal numbers are rounded down: E.g. 1.6
          becomes 1. This applies to the value of a variable as well as decimal numbers used in the
          formula.
        </div>
        <div class="error" *ngIf="target?.errors">
          <span *ngIf="target?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(target?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="target?.errors?.['invalidItem']">
            {{target?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isTargetVarsShown = !isTargetVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isTargetVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isTargetVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isTargetVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="searchTargetVar"
              [(ngModel)]="searchTargetVar"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ searchTargetVar ? 'is-clickable' : '' }}"
              (click)="searchTargetVar = ''"
            >
              <mat-icon *ngIf="searchTargetVar === ''">search</mat-icon>
              <mat-icon *ngIf="searchTargetVar">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchTargetVar"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, target)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="target?.setValue((target?.value || '') + variable); target?.markAsDirty()"
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input checked disabled type="checkbox" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">expand</mat-icon>
        Price Gap
      </h3>
      <p>
        Define the absolute or relative difference between the targeted position and your own offer
      </p>
      <div class="inputs">
        <select formControlName="priceGapUnit">
          <option [ngValue]="'absolute'">Absolute</option>
          <option [ngValue]="'relative'">Relative</option>
        </select>
        <input
          type="text"
          placeholder="e.g. 0.01 to be 1 cent cheaper than matched competitor"
          formControlName="priceGap"
        />
        <div class="error" *ngIf="gap?.errors">
          <span *ngIf="gap?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(gap?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="gap?.errors?.['invalidItem']">
            {{gap?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isGapVarsShown = !isGapVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isGapVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isGapVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isGapVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="searchGapVar"
              [(ngModel)]="searchGapVar"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ searchGapVar ? 'is-clickable' : '' }}"
              (click)="searchGapVar = ''"
            >
              <mat-icon *ngIf="searchGapVar === ''">search</mat-icon>
              <mat-icon *ngIf="searchGapVar">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchGapVar"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, gap)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="gap?.setValue((gap?.value || '') + variable); gap?.markAsDirty()"
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input checked disabled type="checkbox" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">calculate</mat-icon>
        Price Formula Requirements
      </h3>
      <p>
        Define if the formula will be applied to your products independently of the market situation
        or not.
      </p>
      <div class="inputs">
        <select formControlName="priceRequirement">
          <option [ngValue]="true">Always recommend price</option>
          <option [ngValue]="false">Only if at least one offer is found</option>
        </select>
        <div class="error" *ngIf="requirement?.errors">
          Price Formula Requirement must be specified
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input checked disabled type="checkbox" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">vertical_align_bottom</mat-icon>
        Price Boundary Behavior
      </h3>
      <p>
        Define if the formula will be applied to your products independently of the market situation
        or not.
      </p>
      <div class="inputs">
        <select formControlName="adjustToNextPricier">
          <option [ngValue]="false">Adjust to minimum price</option>
          <option [ngValue]="true">Adjust to next more expensive offer</option>
        </select>
        <div class="error" *ngIf="boundary?.errors">Price Boundary Behavior must be specified</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          target?.errors ||
          gap?.errors ||
          unit?.errors ||
          requirement?.errors ||
          boundary?.errors ||
          !defaultSettingsForm.dirty
        "
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
