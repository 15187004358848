import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DATE_FORMAT } from '@constants/date-format.constant';
import { Store } from '@ngrx/store';

import { combineLatest, filter, takeUntil } from 'rxjs';

import {
  fetchStrategyMetadataHistory,
  selectStrategyMetadataHistory,
} from '@app/store/pricing-strategy';
import { filterNullAndUndefined } from '@app/utils/helpers/is-defined';

import { AppState } from '@store/app.state';
import { selectActiveContract, selectUserDetails } from '@store/user';

import { StrategyMetadata } from '@models/interfaces/api-strategies';

import { RevertDialogComponent } from '@components/revert-dialog/revert-dialog.component';

import { SubscriptionManagementBaseClass } from '@utils/subscription-management/subscription-management-base-class';

@Component({
  selector: 'app-strategy-status',
  templateUrl: './strategy-status.component.html',
  styleUrls: ['./strategy-status.component.scss'],
})
export class StrategyStatusComponent extends SubscriptionManagementBaseClass implements OnInit {
  @Input() strategyMetadata?: StrategyMetadata;

  @Input() isRevertStrategyEnabled? = true;

  public dateFormat = DATE_FORMAT;

  strategyMetadataHistory?: StrategyMetadata[];

  constructor(private readonly store: Store<AppState>, public readonly dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.retrieveStrategyMetadataHistory();
  }

  private retrieveStrategyMetadataHistory(): void {
    combineLatest([
      this.store.select(selectUserDetails),
      this.store.select(selectActiveContract).pipe(filter(filterNullAndUndefined)),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([userDetails, { sid }]) => {
        /**
         * Why this check?
         * To ensure that user details are already retrieved before dispatching, otherwise we run into 401
         */
        if (userDetails?.id) {
          this.store.dispatch(fetchStrategyMetadataHistory.init({ sid }));
        }
      });

    this.store
      .select(selectStrategyMetadataHistory)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((history) => (this.strategyMetadataHistory = history));
  }

  openDialog(): void {
    const history: StrategyMetadata[] = this.strategyMetadataHistory ?? [];
    this.dialog.open(RevertDialogComponent, { data: { strategyHistory: history } });
  }
}
