import { createReducer, on } from '@ngrx/store';
import {
  ApiOffer,
  PricemonitorApiPriceRecommendation,
  QueryProductsVendorV3ApiResponse,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { ITaskData } from '@app/models/interfaces/task-data.interface';

import {
  fetchDomains,
  fetchProductData,
  fetchProductOffers,
  fetchProductPriceRecommendation,
  fetchProductProperties,
  fetchShopsByDomain,
  fetchStrategy,
  fetchStrategyByDocumentVersion,
  fetchStrategyMetadataHistory,
  fetchTags,
  fetchTaskData,
  fetchVendorSettings,
  toggleShop,
} from '@store/pricing-strategy';

import { ApiStrategyTreeResponse, StrategyMetadata } from '@models/interfaces/api-strategies';

import { Shop } from '@components/strategy-component/ui-strategy-elements/competitive-pricing/competitive-pricing';

export const pricingStrategyFeatureKey = 'pricingStrategy';

export type PricingStrategyVendorSettings = {
  priceGap: number;
  targetPosition: number;
  shops: Shop[];
  adjustToNextPricier: boolean;
};

export interface PricingStrategyState {
  apiData?: ApiStrategyTreeResponse | null;
  revertedApiStrategy?: ApiStrategyTreeResponse;
  domains?: string[];
  tags?: string[];
  productProperties?: string[];
  vendorSettings?: PricingStrategyVendorSettings;
  shopsByDomain?: Map<string, string[]>;
  strategyMetadataHistory?: StrategyMetadata[];
  taskData?: ITaskData;
  productData?: QueryProductsVendorV3ApiResponse;
  productPriceRecommendation?: PricemonitorApiPriceRecommendation;
  productOffers?: ApiOffer[];
}

export const initialPricingStrategyState: PricingStrategyState = {
  apiData: undefined,
  revertedApiStrategy: undefined,
  domains: undefined,
  tags: undefined,
  vendorSettings: undefined,
  shopsByDomain: undefined,
  taskData: undefined,
  productData: undefined,
  productPriceRecommendation: undefined,
  productOffers: undefined,
};

export const pricingStrategyReducer = createReducer(
  initialPricingStrategyState,
  on(fetchStrategy.success, (state, { apiData }: { apiData: ApiStrategyTreeResponse }) => ({
    ...state,
    apiData,
  })),
  on(
    fetchStrategyByDocumentVersion.success,
    (state, { apiData }: { apiData: ApiStrategyTreeResponse }) => ({
      ...state,
      revertedApiStrategy: apiData,
    })
  ),
  on(fetchDomains.success, (state, { domains }: { domains: string[] }) => ({
    ...state,
    domains,
  })),
  on(fetchTags.success, (state, { tags }: { tags: string[] }) => ({
    ...state,
    tags,
  })),
  on(
    fetchShopsByDomain.success,
    (state, { shopsByDomain }: { shopsByDomain: Map<string, string[]> }) => ({
      ...state,
      shopsByDomain,
    })
  ),
  on(
    fetchVendorSettings.success,
    toggleShop.success,
    (state, { vendorSettings }: { vendorSettings: PricingStrategyVendorSettings }) => ({
      ...state,
      vendorSettings,
    })
  ),
  on(
    fetchStrategyMetadataHistory.success,
    (state, { strategyMetadataHistory }: { strategyMetadataHistory: StrategyMetadata[] }) => ({
      ...state,
      strategyMetadataHistory,
    })
  ),
  on(fetchTaskData.success, (state, { taskData }: { taskData: ITaskData }) => ({
    ...state,
    taskData,
  })),
  on(
    fetchProductData.success,
    (state, { productData }: { productData: QueryProductsVendorV3ApiResponse }) => ({
      ...state,
      productData,
    })
  ),
  on(
    fetchProductPriceRecommendation.success,
    (
      state,
      {
        productPriceRecommendation,
      }: { productPriceRecommendation: PricemonitorApiPriceRecommendation }
    ) => ({
      ...state,
      productPriceRecommendation,
    })
  ),
  on(fetchProductOffers.success, (state, { productOffers }: { productOffers: ApiOffer[] }) => ({
    ...state,
    productOffers,
  })),
  on(
    fetchProductProperties.success,
    (state, { productProperties }: { productProperties: string[] }) => ({
      ...state,
      productProperties,
    })
  ),
  on(fetchStrategy.failed, () => ({
    ...initialPricingStrategyState,
    apiData: null,
  })),
  on(
    fetchDomains.failed,
    fetchTags.failed,
    fetchShopsByDomain.failed,
    fetchVendorSettings.failed,
    fetchStrategyMetadataHistory.failed,
    fetchStrategyByDocumentVersion.failed,
    fetchTaskData.failed,
    fetchProductData.failed,
    fetchProductPriceRecommendation.failed,
    fetchProductOffers.failed,
    fetchProductProperties.failed,
    () => ({
      ...initialPricingStrategyState,
      // When shopsByDomain end point fails, we need on component level to distinguish between initial/still in progress and failed shopsByDomain call
      shopsByDomain: new Map<string, string[]>(),
    })
  )
);
