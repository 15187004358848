<app-condition-form-header></app-condition-form-header>
<div class="content-subheader">
  <mat-icon class="strategy-type-icon">date_range</mat-icon>
  <div class="legend">
    <h3>If Date</h3>
    <p>
      Allows you to define a start-date and/or end-date to define a timeframe when this condition
      will be true. Before the start- and/or after the end-date, it will be false.
    </p>
  </div>
</div>
<div class="form" [formGroup]="ifTimestampForm">
  <div *ngIf="ifTimestampForm?.errors" class="error">
    <span *ngIf="ifTimestampForm.errors?.['startOrEndRequired']">
      {{ controlErrorMessage.get('startOrEndRequired') }}
    </span>
  </div>
  <ng-container *ngIf="hasDatePropertyKey">
    <app-toggle
      class="mb-3"
      [color]="'accent'"
      [label]="'Pick dates from product properties'"
      [labelPosition]="'after'"
      (valueChange)="toggleDateSelection()"
      [toggled]="selectDateFromProductProperties"
    >
    </app-toggle>
  </ng-container>
  <div>
    <div class="mb-3 font-bold">Start</div>

    <ng-container *ngIf="selectDateFromProductProperties; else startDatePickerBlock">
      <div class="input-group">
        <input
          matInput
          type="text"
          placeholder="Select start date property"
          id="startDate"
          [matAutocomplete]="startDateAutocomplete"
          formControlName="startDatePropertyKey"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('startDatePropertyKey')?.value"
          class="clear-icon"
          (click)="clearFormControl('startDatePropertyKey')"
        >
          clear
        </mat-icon>
      </div>

      <mat-autocomplete #startDateAutocomplete="matAutocomplete">
        <mat-option *ngFor="let dateKey of startDatePropertyKeys" [value]="dateKey">
          {{ 'PROPERTY.' + dateKey }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>

    <ng-template #startDatePickerBlock>
      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Date"
          formControlName="startDate"
          [matDatepicker]="startDatepicker"
          (click)="startDatepicker.open()"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('startDate')?.value"
          class="form-button clear-icon"
          (click)="clearFormControl('startDate')"
        >
          clear
        </mat-icon>
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
        <div *ngIf="ifTimestampForm?.errors" class="error">
          <span *ngIf="ifTimestampForm.errors?.['incorrectDateOrder']">
            {{ controlErrorMessage.get('incorrectDateOrder') }}
          </span>
          <span *ngIf="ifTimestampForm.errors?.['startValueMissing']">
            {{ controlErrorMessage.get('startValueMissing') }}
          </span>
        </div>
      </div>

      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Time"
          formControlName="startTime"
          [format]="24"
          [ngxMatTimepicker]="startTimepicker"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('startTime')?.value"
          class="clear-icon"
          (click)="clearFormControl('startTime')"
        >
          clear
        </mat-icon>
        <ngx-mat-timepicker-toggle matSuffix [for]="startTimepicker"></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker #startTimepicker></ngx-mat-timepicker>
        <div *ngIf="ifTimestampForm?.errors" class="error">
          <span *ngIf="ifTimestampForm.errors?.['startValueMissing']">
            {{ controlErrorMessage.get('startValueMissing') }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div>
    <div class="mb-3 font-bold">End</div>
    <ng-container *ngIf="selectDateFromProductProperties; else endDatePickerBlock">
      <div class="input-group">
        <input
          matInput
          type="text"
          placeholder="Select end date property"
          id="endDate"
          [matAutocomplete]="endDateAutocomplete"
          formControlName="endDatePropertyKey"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('endDatePropertyKey')?.value"
          class="clear-icon"
          (click)="clearFormControl('endDatePropertyKey')"
        >
          clear
        </mat-icon>
      </div>

      <mat-autocomplete #endDateAutocomplete="matAutocomplete">
        <mat-option *ngFor="let dateKey of endDatePropertyKeys" [value]="dateKey">
          {{ 'PROPERTY.' + dateKey }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
    <ng-template #endDatePickerBlock>
      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Date"
          formControlName="endDate"
          [matDatepicker]="endDatepicker"
          (click)="endDatepicker.open()"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('endDate')?.value"
          class="clear-icon"
          (click)="clearFormControl('endDate')"
        >
          clear
        </mat-icon>
        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
        <div *ngIf="ifTimestampForm?.errors" class="error">
          <span *ngIf="ifTimestampForm.errors?.['incorrectDateOrder']">
            {{ controlErrorMessage.get('incorrectDateOrder') }}
          </span>
          <span *ngIf="ifTimestampForm.errors?.['endValueMissing']">
            {{ controlErrorMessage.get('endValueMissing') }}
          </span>
        </div>
      </div>

      <div class="input-group">
        <input
          readonly
          type="text"
          placeholder="Time"
          formControlName="endTime"
          [format]="24"
          [ngxMatTimepicker]="endTimepicker"
        />
        <mat-icon
          *ngIf="ifTimestampForm?.get('endTime')?.value"
          class="clear-icon"
          (click)="clearFormControl('endTime')"
        >
          clear
        </mat-icon>
        <ngx-mat-timepicker-toggle matSuffix [for]="endTimepicker"></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker #endTimepicker></ngx-mat-timepicker>
        <div *ngIf="ifTimestampForm?.errors" class="error">
          <span *ngIf="ifTimestampForm.errors?.['endValueMissing']">
            Both end date and time should be defined
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="ifTimestampForm && ifTimestampForm.invalid"
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
