import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SafetyRuleStrategy } from '../../../ui-strategy-elements/saftey-rule/safety-rule';

@Component({
  selector: 'app-safety-rule-node',
  templateUrl: './safety-rule-node.component.html',
  styleUrls: ['./safety-rule-node.component.scss'],
})
export class SafetyRuleNodeComponent {
  @Input() data?: SafetyRuleStrategy;

  @Input() isSelected: boolean = false;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
