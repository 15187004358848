import { Component, EventEmitter, Input, Output } from '@angular/core';

import { StepInterface } from './steps.model';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
  @Input() steps: StepInterface[] = [];

  @Output() clickEvent = new EventEmitter();

  public onClick(): void {
    this.clickEvent.emit();
  }
}
