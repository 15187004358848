import { IfStrategyFilters } from './ui-strategy-elements/conditions/conditions';

export class PeanutTree {
  children!: PeanutTree[];

  // TODO: Add all possible node types and remove `any`
  data?: IfStrategyFilters | any;
}

/**
 * Represents the PeanutTree entity in the user interface
 * holding variables and methods used only there.
 */
export class PeanutTreeUI extends PeanutTree {
  override children!: PeanutTreeUI[];

  /**
   * Used to avoid expensive traversal operations or mutating
   * the original instance when performing changes to the tree
   * through drop/edit user actions.
   * It is calculated on the service's initialization and represents
   * the address of a value in the tree in the form
   * `children[1].children[2]`, used with Lodash's `get` and `set`
   * to manipulate a working copy of the tree that's finally emitted
   * as a new value of the Rxjs's `BehaviourSubject` that the
   * template renders asynchronously.
   */
  path?: string;
}

/**
 * Represents the PeanutTree with a version message that could be provided by the user
 */
export interface PeanutTreeUIWithVersionMessage {
  tree: PeanutTreeUI;

  versionMessage: string;
}
