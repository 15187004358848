export enum ComparatorSymbol {
  lessThan = '<',
  greateThan = '>',
  equalTo = '=',
}

export enum Comparator {
  LESS = 'lessThan',
  GREATER = 'greaterThan',
  EQUAL = 'equalTo',
}

export enum StringComparisonTextType {
  TAG = 'tag',
  CONSTANT = 'constant',
}

export enum StrategyType {
  TAG_BASED = 'StrategyFilters',
  PRICE_STEP = 'PriceStep',
  TIME_FRAME = 'TimeFrame',
  COMPETITOR = 'CompetitorsOnPosition',
  FORMULA_PRICE_GAP = 'FormulaPriceFilter',
  TAG_BASED_PRICE_GAP = 'TagBasedPriceGap',
  TAG_BASED_TARGET_POSITION = 'TagBasedTargetPosition',
  FORMULA_COMPARISON = 'FormulaComparison',
  STRING_COMPARISON = 'StringComparison',
  PRICE_FORMULA = 'Formula',
  SERIAL = 'Serial',
  TARGET_POSITION = 'TargetPosition',
  TARGET_POSITION_BUILDER = 'TargetPositionBuilder',
  STRATEGY_CONTEXT_MODIFIERS = 'StrategyContextModifiers',
  AGGREGATION = 'PriceAggregation',
  AMAZON_BUY_BOX = 'AmazonBuyBox',
  AMAZON_BUY_BOX_PREDICTION = 'AmazonBuyBoxPrediction',
}

export const ConditionGeneratorType = 'conditionGenerator';

/**
 * Holds the template definition of methods and variables of all strategy types
 */
export abstract class StrategyElement {
  strategyType: StrategyType;

  abstract templateUrl: string;

  abstract compactTemplateUrl: string;

  abstract detailedTemplateUrl: string;

  editForm: string = '';

  isElementValid: boolean = true;

  constructor(strategyType: StrategyType) {
    this.strategyType = strategyType;
  }

  public switchElementTemplate(showDetails: boolean): void {
    this.templateUrl = showDetails ? this.detailedTemplateUrl : this.compactTemplateUrl;
  }

  /**
   * Gets the mathematical operator corresponding to the text operator i.e lessThan to <
   * Used in all Strategies involve operators e.g: Formula comparison.
   */
  public getOperatorSymbol(operator: string): ComparatorSymbol {
    return ComparatorSymbol[operator as keyof typeof ComparatorSymbol];
  }
}
