<div class="execution-flow" [ngClass]="{ hidden: !isEnabled }" [ngStyle]="position">
  <div class="top-row">
    <div class="left-col"></div>
    <div class="center-col">
      <div class="legend" *ngIf="treeStore.selectedChild">
        <span *ngIf="treeStore.selectedChild?.data?.strategyType | isConditionNode">false</span>
        <span *ngIf="!(treeStore.selectedChild?.data?.strategyType | isConditionNode)">
          on failure
        </span>
      </div>
    </div>
    <div class="right-col"></div>
  </div>
  <div class="bottom-row">
    <div class="left-col">
      <mat-icon class="connector-false">arrow_drop_down</mat-icon>
    </div>
    <div class="center-col"></div>
    <div class="right-col"></div>
  </div>
</div>
