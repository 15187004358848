import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonComponent,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  // unknown type is used because we cannot predict which value will be used
  @Input() value: unknown;

  @Input() disabled = false;

  @Input() checked = false;

  @Input() label = '';

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Output() valueChange = new EventEmitter<any>();

  public onChange: (value: string) => void = () => {
    return;
  };

  public onTouched: () => void = () => {
    return;
  };

  public writeValue(selected: string): void {
    this.value = selected;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setValue(event: MatRadioChange): void {
    if (!this.disabled) {
      this.onChange(event.value);
      this.onTouched();
      this.valueChange.emit(event.value);
    }
  }
}
