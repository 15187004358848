import { Component, Input } from '@angular/core';
import { DATE_FORMAT } from '@constants/date-format.constant';

import { JsonToCsvConverterService } from '@services/json-to-csv-converter/json-to-csv-converter.service';

import { convertUTCToLocalTime } from '@utils/helpers/utc-to-local-time-converter';

import { IJobStatus } from '../../models/common/job-status.type';

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
})
export class JobStatusComponent {
  @Input() data?: IJobStatus;

  @Input() contractSid = '';

  public dateFormat = DATE_FORMAT;

  constructor(private readonly jsonToCsvConverter: JsonToCsvConverterService) {}

  public downloadFile(): void {
    if (!this.data?.failures) return;
    const timestamp = convertUTCToLocalTime(this.data.timestamp);
    this.jsonToCsvConverter.download(this.data.failures.data, this.contractSid, timestamp);
  }
}
