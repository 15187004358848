<div class="">
  <div class="flex flex-row-reverse">
    <app-outlined-button
      mat-button
      color="primary"
      text="Save"
      iconSize="text-xl"
      [icon]="saveIcon"
      [disabled]="!isDirty || saveInProgress || !isValid"
      (clickEvent)="saveClicked()"
    ></app-outlined-button>
    <app-raised-button
      mat-button
      text="Reset"
      icon="refresh"
      iconSize="text-xl"
      (clickEvent)="reset()"
      [disabled]="!isDirty || saveInProgress"
      class="p-0"
    ></app-raised-button>
  </div>
  <div class="">
    <app-users-list-item
      [user]="user"
      *ngFor="let user of usersWorkingCopy"
      [allContracts]="allContracts"
      (userChanged)="userChanged($event)"
    >
    </app-users-list-item>
  </div>
</div>
