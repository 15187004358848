import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { RouterModule } from '@angular/router';
import { NgxMatTimepickerLocaleService, NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { ExplainWhyStrategyComponent } from '@app/components/strategy-component/components/explain-why-strategy/explain-why-strategy.component';
import { BasePricingStrategyComponent } from '@app/features/base-pricing-strategy/base-pricing-strategy.component';

import { MaterialModule } from '@vendor-features/material-module/material.module';

import { PipeModule } from '@pipes/pipes.module';

import { HighlightTextDirective } from './page-header/directives/highlight-text.directive';
import { StockClearanceFormComponent } from './strategy-component/components/edit-panel/stock-clearance-form/stock-clearance-form.component';
import { ExplainWhyTablesComponent } from './strategy-component/components/explain-why-tables/explain-why-tables.component';
import { StrategyRuleNameComponent } from './strategy-component/components/strategy-rule-name/strategy-rule-name.component';
import { CompetitorVariableDirective } from './strategy-component/directives/competitor-variable.directive';
import {
  ArraySortPipe,
  BranchStatsMapValuePipe,
  CastPipe,
  FilterStringPipe,
  FormatFormulaVarsPipe,
  IsConditionNodePipe,
} from './strategy-component/pipes';
import {
  AccordionCardComponent,
  BadgeComponent,
  BannerComponent,
  BaseButtonComponent,
  BasicButtonComponent,
  BranchStatsComponent,
  ButtonsDemoComponent,
  CardComponent,
  CheckboxComponent,
  CompetitivePricingFormComponent,
  CompetitivePricingNodeComponent,
  CompetitorVariableComponent,
  CompetitorVariableDialogComponent,
  ConditionFormHeaderComponent,
  ContractSwitcherComponent,
  CopyOrMoveDialogComponent,
  DefaultSettingsFormComponent,
  DeleteDialogComponent,
  DelimitedItemsListComponent,
  DraggableDirective,
  DroppableDirective,
  EditPanelComponent,
  EmptyListComponent,
  ExecutionFlowComponent,
  ExecutionNodeComponent,
  FilterSelectComponent,
  FixedPriceSelectionComponent,
  FixedPriceSelectionDialogComponent,
  FloatingSelectComponent,
  HeaderComponent,
  IconButtonComponent,
  IfCompetitionFormComponent,
  IfCompetitionNodeComponent,
  IfFormulaFormComponent,
  IfFormulaNodeComponent,
  IfRecommendedFormComponent,
  IfRecommendedNodeComponent,
  IfTagFormComponent,
  IfTagNodeComponent,
  IfTimestampRangeFormComponent,
  IfTimestampRangeNodeComponent,
  InputComponent,
  JobStatusComponent,
  LegacyLoaderComponent,
  LinkComponent,
  MenuSelectComponent,
  MutationNotificationComponent,
  MyShopsComponent,
  NavigationComponent,
  NodeBaseComponent,
  OutlinedButtonComponent,
  PageHeaderComponent,
  PeanutTreeComponent,
  PriceFormulaFormComponent,
  PriceFormulaNodeComponent,
  ProductDetailsPageHeaderComponent,
  RadioButtonComponent,
  RaisedButtonComponent,
  RevertDialogComponent,
  SafetyRuleFormComponent,
  SafetyRuleNodeComponent,
  SaveDialogComponent,
  ShopSummaryComponent,
  SnackbarComponent,
  SnackBarDemoComponent,
  StartNodeComponent,
  StepsComponent,
  StockClearanceNodeComponent,
  StrategyComponent,
  StrategyLibraryComponent,
  StrategyStatusComponent,
  ToggleComponent,
  TopBarComponent,
  UnknownNodeComponent,
  UsersListComponent,
  UsersListItemComponent,
} from '.';

/**
 * This is a module that will include components that are shared across the application.
 */

const components = [
  HeaderComponent,
  BadgeComponent,
  JobStatusComponent,
  LegacyLoaderComponent,
  StrategyStatusComponent,
  RevertDialogComponent,
  CardComponent,
  BasicButtonComponent,
  OutlinedButtonComponent,
  RaisedButtonComponent,
  BaseButtonComponent,
  SnackbarComponent,
  FilterSelectComponent,
  CheckboxComponent,
  ToggleComponent,
  IconButtonComponent,
  InputComponent,
  RadioButtonComponent,
  ButtonsDemoComponent,
  MenuSelectComponent,
  FloatingSelectComponent,
  LinkComponent,
  MutationNotificationComponent,
  SnackBarDemoComponent,
  DelimitedItemsListComponent,
  TopBarComponent,
  ShopSummaryComponent,
  NavigationComponent,
  StepsComponent,
  PageHeaderComponent,
  StrategyComponent,
  ExecutionNodeComponent,
  CompetitivePricingNodeComponent,
  IfCompetitionNodeComponent,
  IfFormulaNodeComponent,
  IfRecommendedNodeComponent,
  PriceFormulaNodeComponent,
  SafetyRuleNodeComponent,
  IfTagNodeComponent,
  UnknownNodeComponent,
  PeanutTreeComponent,
  StrategyLibraryComponent,
  EditPanelComponent,
  CopyOrMoveDialogComponent,
  NodeBaseComponent,
  StartNodeComponent,
  DeleteDialogComponent,
  ExecutionFlowComponent,
  IfTagFormComponent,
  IfFormulaFormComponent,
  IfRecommendedFormComponent,
  ConditionFormHeaderComponent,
  IfCompetitionFormComponent,
  PriceFormulaFormComponent,
  SafetyRuleFormComponent,
  CompetitivePricingFormComponent,
  DefaultSettingsFormComponent,
  ContractSwitcherComponent,
  CompetitorVariableComponent,
  CompetitorVariableDialogComponent,
  MyShopsComponent,
  SaveDialogComponent,
  IfTimestampRangeNodeComponent,
  BranchStatsComponent,
  IfTimestampRangeFormComponent,
  StrategyRuleNameComponent,
  ExplainWhyStrategyComponent,
  BasePricingStrategyComponent,
  ExplainWhyTablesComponent,
  AccordionCardComponent,
  EmptyListComponent,
  BannerComponent,
  FixedPriceSelectionComponent,
  FixedPriceSelectionDialogComponent,
  ProductDetailsPageHeaderComponent,
  StockClearanceNodeComponent,
  StockClearanceFormComponent,
  UsersListComponent,
  UsersListItemComponent,
];

const directives = [
  DraggableDirective,
  DroppableDirective,
  HighlightTextDirective,
  CompetitorVariableDirective,
];

const pipes = [
  IsConditionNodePipe,
  FormatFormulaVarsPipe,
  BranchStatsMapValuePipe,
  ArraySortPipe,
  CastPipe,
  FilterStringPipe,
];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    CommonModule,
    PipeModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    NgxMatTimepickerModule,
  ],
  exports: [...components, ...pipes, MaterialModule, ReactiveFormsModule, FormsModule],
  providers: [...pipes, NgxMatTimepickerLocaleService],
})
export class ComponentsModule {}
