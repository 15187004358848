import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CompetitivePricingStrategy } from '../../../ui-strategy-elements/competitive-pricing/competitive-pricing';

@Component({
  selector: 'app-competitive-pricing-node',
  templateUrl: './competitive-pricing-node.component.html',
  styleUrls: ['./competitive-pricing-node.component.scss'],
})
export class CompetitivePricingNodeComponent {
  @Input() data: CompetitivePricingStrategy | undefined;

  @Input() isSelected: boolean = false;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();
}
