<div class="strategy-component">
  <div class="banner">
    <mat-expansion-panel
      #recommendationPanel
      hideToggle
      (opened)="bannerOpenState = true"
      (closed)="bannerOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="mr-2">info</mat-icon>
          <ng-container *ngIf="priceCalculationDetails?.size">
            <ng-container *ngFor="let item of priceCalculationDetails | keyvalue : asIsOrder">
              <span class="pr-5">
                <ng-container *ngIf="item.key.toLowerCase().includes('price'); else regularValue">
                  <b>{{ item.key }}: </b> {{ item.value | number : '1.2-2' }}€
                </ng-container>
                <ng-template #regularValue>
                  <b>{{ item.key }}: </b> {{ item.value }}
                </ng-template>
              </span>
            </ng-container>
          </ng-container>
          <div class="expand-toggle ml-auto flex cursor-pointer justify-center">
            <span> More info </span>
            <mat-icon *ngIf="!bannerOpenState" class="pl-1 text-base">expand_more</mat-icon>
            <mat-icon *ngIf="bannerOpenState" class="pl-1 text-base">expand_less</mat-icon>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-explain-why-tables
        [priceCalculationDetails]="priceCalculationDetails"
        [recommendationTags]="recommendationTags"
        [recommendationOffers]="recommendationOffers"
        [shops]="shops"
      ></app-explain-why-tables>
    </mat-expansion-panel>
  </div>

  <div class="tree-container">
    <div class="tree-wrapper">
      <app-peanut-tree
        [varsMap]="varsMap"
        [focusedNodeIds]="evaluatedPathNodeIds"
        [isDraggingEnabled]="false"
        [isDeleteingEnabled]="false"
      ></app-peanut-tree>
    </div>
    <app-edit-panel
      *ngIf="treeStore.selectedChild"
      [domains]="domains"
      [tags]="tags"
      [varsMap]="varsMap"
      [shopsByDomain]="shopsByDomain"
      [isPanelEnabled]="false"
      (toggleCallback)="toggleEditPanelCallback($event)"
    ></app-edit-panel>
  </div>
</div>

<div
  *ngIf="showFeatureMissingWarning"
  class="banner-margin-left sticky top-full z-10 bg-warn pl-4 text-white"
>
  <div class="pl-4 pb-3 leading-6">
    <mat-icon class="relative top-2">warning</mat-icon>
    Total market pricing is not enabled. Please enable it via Pricemonitor Controlpanel
  </div>
</div>
