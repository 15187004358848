import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

import { Shop } from '../../ui-strategy-elements/competitive-pricing/competitive-pricing';

@Component({
  selector: 'app-my-shops',
  templateUrl: './my-shops.component.html',
  styleUrls: ['./my-shops.component.scss'],
})
export class MyShopsComponent implements OnChanges, AfterViewChecked {
  @HostBinding('class.open') open = false;

  @Input() toggleShopCallback?: EventEmitter<Shop>;

  @Input() domains?: string[];

  @Input() shops?: Map<string, string[]>;

  @ViewChild('shopsElement') shopsElement!: ElementRef;

  @Input() isShopSaving = false;

  newShopDomain = '';

  newShopName?: string;

  ngAfterViewChecked() {
    this.shopsElement.nativeElement.scrollTop = this.shopsElement.nativeElement.scrollHeight;
  }

  ngOnChanges() {
    this.newShopDomain = this.domains?.[0] || '';
    if (!this.shops) this.shops = new Map<string, string[]>();
  }

  addShop() {
    const currentValue = this.shops?.get(this.newShopDomain) || [];
    if (this.newShopName && !currentValue.includes(this.newShopName)) {
      currentValue.push(this.newShopName);
      this.shops?.set(this.newShopDomain, currentValue);
      this.toggleShopCallback?.emit({ domain: this.newShopDomain, shop: this.newShopName });
    }
    this.newShopName = undefined;
  }

  removeShop(domain: string, shop: string) {
    let currentValue = this.shops?.get(domain) || [];
    currentValue = currentValue.filter((value) => value !== shop);
    if (currentValue.length < 1) this.shops?.delete(domain);
    else this.shops?.set(domain, currentValue);
    this.toggleShopCallback?.emit({ domain, shop });
  }
}
