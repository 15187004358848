<div class="p-2">
  <label class="block pb-4 text-center text-xl font-bold" id="copy-or-move-node-label">
    How do you want to proceed?
  </label>
  <mat-radio-group aria-labelledby="copy-or-move-node-label" [(ngModel)]="isCopy">
    <div>
      <mat-radio-button class="py-2" [value]="true">Copy</mat-radio-button>
    </div>
    <div>
      <mat-radio-button class="py-2" [value]="false">Move</mat-radio-button>
    </div>
  </mat-radio-group>
  <div mat-dialog-actions class="justify-center">
    <app-basic-button
      text="Cancel"
      [hasIconAfterText]="false"
      [color]="'primary'"
      (clickEvent)="dialogRef.close()"
    >
    </app-basic-button>
    <app-basic-button
      text="Confirm"
      [hasIconAfterText]="false"
      [color]="'primary'"
      [isRaisedButton]="true"
      [mat-dialog-close]="isCopy"
    >
    </app-basic-button>
  </div>
</div>
