<div class="node competitivePricing" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Competitive pricing</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <div *ngIf="data?.targetPosition !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">my_location</mat-icon>
      <div *ngIf="data?.targetPosition !== null" class="mt-1 truncate text-sm">
        {{ data?.targetPosition | formatFormulaVars : varsMap }}
      </div>
      <div *ngIf="data?.targetPosition === null" class="mt-1 truncate text-sm">Target position</div>
    </div>
    <div *ngIf="data?.priceGap !== undefined && data?.priceGapUnit !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">expand</mat-icon>
      <div *ngIf="data?.priceGap !== null" class="mt-1 truncate text-sm">
        {{ data?.priceGap | formatFormulaVars : varsMap }} ({{ data?.priceGapUnit }})
      </div>
      <div *ngIf="data?.priceGap === null" class="mt-1 truncate text-sm">Price gap</div>
    </div>
    <div *ngIf="data?.vendorOfferFilter">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">add_business</mat-icon>
      <div *ngIf="data?.vendorOfferFilter?.length || 0 > 0" class="mt-1 truncate text-sm">
        <span *ngFor="let vendor of data?.vendorOfferFilter; let isLast = last">
          {{ vendor.shop }}{{ isLast ? '' : ', ' }}
        </span>
      </div>
      <div *ngIf="data?.vendorOfferFilter?.length === 0" class="mt-1 truncate text-sm">
        Follow competitors
      </div>
    </div>
    <div *ngIf="data?.includeDeliveryCosts !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">request_quote</mat-icon>
      <div *ngIf="data?.includeDeliveryCosts !== null" class="mt-1 truncate text-sm">
        {{ data?.includeDeliveryCosts ? 'Total Price' : 'Unit Price' }}
      </div>
      <div *ngIf="data?.includeDeliveryCosts === null" class="mt-1 truncate text-sm">
        Relevant Price
      </div>
    </div>
  </div>
</div>
