<section
  *ngIf="navigationList"
  class="navigation relative flex h-full w-full flex-col overflow-hidden border-0 border-r-1 border-solid bg-white transition-all"
  [ngClass]="{
    loading: navigationList.isLoading,
    expanded: isExpanded,
    'drop-shadow-lg filter': isExpanded && !isPinned
  }"
  (mouseenter)="setSize($event)"
  (mouseleave)="setSize($event)"
>
  <!-- <mat-icon
    *ngIf="isExpanded"
    class="absolute top-1 right-1 rotate-45 transform cursor-pointer text-xl"
    [ngClass]="isPinned ? 'opacity-100' : 'opacity-50'"
    (click)="setPin()">
    push_pin
  </mat-icon> -->
  <picture class="box-border flex h-24">
    <img *ngIf="isExpanded; else sansExpanded" class="m-auto w-52" [src]="fullLogoSrc" />
    <ng-template #sansExpanded>
      <img class="block w-full" [src]="logoSrc" />
    </ng-template>
  </picture>

  <aside class="flex flex-auto flex-col justify-between overflow-y-auto overflow-x-hidden">
    <ul class="my-0 pl-0">
      <li *ngFor="let navItem of navigationList.menuItems">
        <mat-accordion *ngIf="navItem.isVisible && navItem.children">
          <mat-expansion-panel
            [hideToggle]="!isExpanded"
            [expanded]="navItem.isExpanded || isOpen(navItem.label)"
            (opened)="setExpandedItem(navItem, true)"
            (closed)="setExpandedItem(navItem, false)"
          >
            <mat-expansion-panel-header class="px-0">
              <mat-panel-title class="text-primary">
                <mat-icon class="relative left-5 pl-0.5 text-xl">
                  {{ navItem.icon }}
                </mat-icon>
                <span class="ml-7 font-bold" [ngClass]="{ 'text-transparent': !isExpanded }">
                  {{ navItem.label }}
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul *ngIf="isExpanded && navItem.children as children" class="my-0 pl-0">
              <li *ngFor="let childNavItem of children">
                <a
                  *ngIf="childNavItem.isVisible && !childNavItem.hidden"
                  class="relative flex items-center whitespace-nowrap pl-5 pt-3.5 pb-4 transition-all hover:bg-purple-50"
                  routerLinkActive="sel"
                  [routerLink]="[childNavItem.route]"
                >
                  <span [ngClass]="{ 'text-transparent': !isExpanded }" class="ml-9 font-bold">
                    {{ childNavItem.label }}
                  </span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
        <a
          *ngIf="navItem.isVisible && navItem.route"
          routerLinkActive="sel"
          class="relative flex items-center whitespace-nowrap pt-3.5 pb-4 transition-all hover:bg-purple-50"
          [routerLink]="[navItem.route]"
          (click)="resetExpandedItem()"
        >
          <mat-icon class="relative left-5 pl-0.5 text-xl">
            {{ navItem.icon }}
          </mat-icon>
          <span class="ml-7 font-bold" [ngClass]="{ 'text-transparent': !isExpanded }">
            {{ navItem.label }}
          </span>
        </a>
      </li>
      <li *ngIf="hasLogin">
        <a
          class="relative flex cursor-pointer items-center whitespace-nowrap pt-3.5 pb-4 transition-all hover:bg-purple-50"
          (click)="logout()"
        >
          <mat-icon class="relative left-5 pl-0.5 text-xl"> logout </mat-icon>
          <span [ngClass]="{ 'text-transparent': !isExpanded }" class="ml-7 font-bold">
            Logout
          </span>
        </a>
      </li>
    </ul>
    <ul class="my-0 p-0">
      <li class="relative">
        <a href="https://help.omniaretail.com" target="_blank" class="flex items-end py-4">
          <mat-icon class="relative left-5 pl-0.5 text-xl">help</mat-icon>
          <span class="ml-7 mb-1.5" [ngClass]="{ 'text-transparent': !isExpanded }"> Help </span>
        </a>
      </li>
    </ul>
  </aside>
</section>
