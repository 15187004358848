<app-card class="relative my-5 mt-0 py-0" *ngIf="user">
  <div class="grid grid-cols-4" (click)="toggleDetails()">
    <div class="mr-4 grid gap-1">
      <h4 class="row-start-1 m-0 text-xl">
        {{ user.name }}
      </h4>
      <h5 class="row-start-2 m-0 text-gray-400">
        {{ user.email }}
      </h5>
    </div>
    <div class="flex items-center space-x-1">
      <span>last active:</span>
      <span class="text-sm font-bold">{{ user.lastActive | date }}</span>
    </div>
    <div class="flex items-center space-x-1">
      <span>access to:</span>
      <span class="text-sm font-bold">{{ contractAccessDescription }}</span>
    </div>
    <div class="flex items-center justify-self-end">
      <app-basic-button
        id="showDetailsButton"
        text="More info"
        [hasIconAfterText]="true"
        [icon]="showDetails ? 'expand_less' : 'expand_more'"
        [color]="'primary'"
      ></app-basic-button>
    </div>
  </div>
  <div *ngIf="showDetails">
    <hr class="my-4 h-px border-0 bg-gray-200" />
    <h4>Access control</h4>
    <form>
      <app-radio-button
        [checked]="user.hasAccessToAllContracts"
        label="Full access"
        (valueChange)="toggleFullAccess()"
        class="mr-4"
      ></app-radio-button>
      <app-radio-button
        [checked]="!user.hasAccessToAllContracts"
        label="Restricted access"
        (valueChange)="toggleFullAccess()"
        class="mr-4"
      ></app-radio-button>
    </form>
    <h4>Accessible contracts</h4>
    <div class="grid grid-cols-5">
      <div class="flex items-center p-2" *ngFor="let c of contractsAccess">
        <mat-checkbox
          [disabled]="user.hasAccessToAllContracts"
          (change)="toggleContractAccess(c.contractId, $event.checked)"
          [checked]="c.hasAccess"
        ></mat-checkbox>
        <span class="break-all px-2">{{ c.name }}</span>
      </div>
    </div>
  </div>
</app-card>
