<div class="node safety-rule" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Safety Rule</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <div *ngIf="data?.minPrice !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">
        vertical_align_bottom
      </mat-icon>
      <div *ngIf="data?.minPrice !== null" class="mt-1 truncate text-sm">
        {{ data?.minPrice | formatFormulaVars : varsMap }}
      </div>
      <div *ngIf="data?.minPrice === null" class="mt-1 truncate text-sm">Minimum price</div>
    </div>
    <div *ngIf="data?.maxPrice !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">vertical_align_top</mat-icon>
      <div *ngIf="data?.maxPrice !== null" class="mt-1 truncate text-sm">
        {{ data?.maxPrice | formatFormulaVars : varsMap }}
      </div>
      <div *ngIf="data?.maxPrice === null" class="mt-1 truncate text-sm">Maximum price</div>
    </div>
  </div>
</div>
