<div class="content-header">
  <h2>Safety Rules</h2>
  <p>
    Define your minimum and/or maximum price. Omnia will never calculate prices under/over these
    boundaries.
  </p>
</div>

<div class="form" [formGroup]="safetyRuleForm">
  <div class="optional-group">
    <input type="checkbox" [checked]="isMinEnabled" (change)="toggleMin()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">vertical_align_bottom</mat-icon>
        Minimum Price
      </h3>
      <p>
        Define your minimum price boundary here. No prices below that threshold will be calculated.
      </p>
      <div class="inputs" *ngIf="isMinEnabled">
        <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="minPrice" />
        <div class="error" *ngIf="minPrice?.errors">
          <span *ngIf="minPrice?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(minPrice?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="minPrice?.errors?.['invalidItem']" class="break-all">
            {{minPrice?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isMinVarsShown = !isMinVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isMinVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isMinVarsShown">arrow_drop_up</mat-icon>
        </div>

        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isMinVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="minVarSearch"
              [(ngModel)]="minVarSearch"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ minVarSearch ? 'is-clickable' : '' }}"
              (click)="minVarSearch = ''"
            >
              <mat-icon *ngIf="minVarSearch === ''">search</mat-icon>
              <mat-icon *ngIf="minVarSearch">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : minVarSearch"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, minPrice)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="
                  minPrice?.setValue((minPrice?.value || '') + variable); minPrice?.markAsDirty()
                "
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input type="checkbox" [checked]="isMaxEnabled" (change)="toggleMax()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">vertical_align_top</mat-icon>
        Maximum Price
      </h3>
      <p>
        Define your maximum price boundary here. No prices above that threshold will be calculated.
      </p>
      <div class="inputs" *ngIf="isMaxEnabled">
        <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="maxPrice" />
        <div class="error" *ngIf="maxPrice?.errors">
          <span *ngIf="maxPrice?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(maxPrice?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="maxPrice?.errors?.['invalidItem']" class="break-all">
            {{maxPrice?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isMaxVarsShown = !isMaxVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isMaxVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isMaxVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isMaxVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="maxVarSearch"
              [(ngModel)]="maxVarSearch"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ maxVarSearch ? 'is-clickable' : '' }}"
              (click)="maxVarSearch = ''"
            >
              <mat-icon *ngIf="maxVarSearch === ''">search</mat-icon>
              <mat-icon *ngIf="maxVarSearch">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : maxVarSearch"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, maxPrice)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="
                  maxPrice?.setValue((maxPrice?.value || '') + variable); maxPrice?.markAsDirty()
                "
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          (!isMaxEnabled && !isMinEnabled) ||
          (isMaxEnabled && maxPrice?.errors) ||
          (isMinEnabled && minPrice?.errors) ||
          !safetyRuleForm.dirty
        "
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
