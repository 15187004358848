<div class="text-gray-500">
  <label class="block text-center text-2xl font-bold">Configure Competitor</label>
  <p class="mb-8">
    Define the value of the competitor variable by specifying the following parameters:
  </p>
  <div class="flex items-center justify-between">
    <span>Position</span>
    <input
      class="w-48 border-1 border-solid border-gray-300 p-2 text-right"
      type="number"
      [(ngModel)]="position"
      min="1"
      step="1"
    />
  </div>
  <div class="my-6 flex items-center justify-between">
    <span>Attribute</span>
    <select
      [(ngModel)]="attribute"
      class="box-content w-48 border-1 border-solid border-gray-300 p-2"
    >
      <option [ngValue]="AttributeOptions.unitPrice">
        {{ AttributeOptions.unitPrice }}
      </option>
      <option [ngValue]="AttributeOptions.deliveryCosts">
        {{ AttributeOptions.deliveryCosts }}
      </option>
    </select>
  </div>
  <div mat-dialog-actions class="justify-center">
    <app-basic-button
      text="Cancel"
      [hasIconAfterText]="false"
      [color]="'primary'"
      (clickEvent)="dialogRef.close()"
    >
    </app-basic-button>
    <app-basic-button
      text="Confirm"
      [hasIconAfterText]="false"
      [color]="'primary'"
      [isRaisedButton]="true"
      [mat-dialog-close]="formatCompetitorVariable()"
    >
    </app-basic-button>
  </div>
</div>
