import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyFilterCondition',
})
export class LegacyFilterConditionPipe implements PipeTransform {
  transform(items: { [key: string]: string }[], searchText: string): { [key: string]: string }[] {
    if (items.length > 0 && searchText) {
      return items.filter((x) => x['filtertype'].toLowerCase() === searchText.toLowerCase());
    }
    return items;
  }
}
