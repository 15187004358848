import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { map, Observable, startWith } from 'rxjs';

import {
  IfStrategyFilters,
  StringComparisonCondition,
} from '../../../ui-strategy-elements/conditions/conditions';
import { IfTagMatcher, IfTagOperator } from '../../../ui-strategy-elements/strategy-types';
@Component({
  selector: 'app-if-tag-form',
  templateUrl: './if-tag-form.component.html',
  styleUrls: ['./if-tag-form.component.scss'],
})
export class IfTagFormComponent implements OnChanges {
  queryFormArray!: UntypedFormArray;

  filteredQuerySource: Observable<string[]>[] = [];

  matcherOptionsEnum = IfTagMatcher;

  operatorEnum = IfTagOperator;

  // Buttons state
  isSubmitDisabled = false;

  formControlErrorIndex = -1;

  ifTagForm!: UntypedFormGroup;

  @Input() tags: string[] = [];

  @Input() productProperties: string[] = [];

  @Input() data?: IfStrategyFilters;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private destroyRef: DestroyRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    // runs when you switch from one open if-tag edit panel to another
    this.setupIfTagForm();
    // if tags or product properties change then adapt the form
    if (changes['tags'] || changes['productProperties']) {
      this.readjustFormForChangedTagsOrProperties();
    }
  }

  private readjustFormForChangedTagsOrProperties(): void {
    this.data?.query.forEach(({ data, operator }, i) => {
      let tagsChanged,
        productPropertiesChanged = false;
      const condition = data as StringComparisonCondition;
      if (condition.tagName && this.tags.indexOf(condition.tagName) < 0) {
        const tags = [...this.tags];
        tags.unshift(condition.tagName);
        this.tags = tags;
        tagsChanged = true;
      }
      if (
        condition.productPropertyName &&
        this.productProperties.indexOf(condition.productPropertyName) < 0
      ) {
        const properties = [...this.productProperties];
        properties.unshift(condition.productPropertyName);
        this.productProperties = properties;
        productPropertiesChanged = true;
      }
      if (tagsChanged || productPropertiesChanged) {
        if (i === 0) {
          this.initForm(condition);
        } else {
          this.addFormSection(operator!, condition);
        }
        this.setFormValidation();
        this.ifTagForm?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
          this.setFormValidation();
        });
      }
    });
  }

  /**
   * (Re-) initializes the ifTagForm and (re-)runs validation.
   * initializes when any ifTag-node is opened for the first time
   * Reinitializes the form and re-runs validation when you switch from one open if-tag node to another
   */
  private setupIfTagForm() {
    this.prepareForm();
    this.setFormValidation();
    this.ifTagForm?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.setFormValidation();
    });
  }

  private prepareForm(): void {
    this.data?.query.forEach(({ data, operator }, i) => {
      const querySourceCondition = data as StringComparisonCondition;
      if (i === 0) {
        this.initForm(querySourceCondition);
      } else {
        this.addFormSection(operator!, querySourceCondition);
      }
    });
  }

  private initForm(options: StringComparisonCondition): void {
    this.ifTagForm = this.formBuilder.group({
      query: this.formBuilder.array([
        new UntypedFormGroup(
          {
            pickProductProperties: new FormControl<boolean>(
              !!options?.productPropertyName && !options?.tagName
            ),
            data: new UntypedFormGroup({
              tagName: new UntypedFormControl(options?.tagName || ''),
              matcher: new UntypedFormControl(
                options?.matcher || this.matcherOptionsEnum.IS,
                Validators.required
              ),
              tagValue: new UntypedFormControl(options?.tagValue || ''),
              productPropertyName: new UntypedFormControl(options?.productPropertyName || ''),
              productPropertyValue: new UntypedFormControl(options?.productPropertyValue || ''),
            }),
          },
          { validators: this.ifTagFormValidator }
        ),
      ]),
    });

    this.queryFormArray = this.ifTagForm?.get('query') as UntypedFormArray;
    this.setAutocompleteOptions(0);
  }

  private setAutocompleteOptions(index: number) {
    const formGroup = this.queryFormArray?.at(index);
    const controlKey = formGroup.get('pickProductProperties')?.value
      ? 'data.productPropertyName'
      : 'data.tagName';
    const querySource = controlKey === 'data.tagName' ? this.tags : this.productProperties;
    this.filteredQuerySource[index] = formGroup?.get(controlKey)!.valueChanges.pipe(
      startWith<string>(''),
      map((name) => (name ? this.filterAutocompleteOptions(name, controlKey) : querySource.slice()))
    );
  }

  private filterAutocompleteOptions(value: string, querySourceKey: string): string[] {
    const filterValue = value.toLowerCase();
    const options = querySourceKey == 'data.tagName' ? this.tags : this.productProperties;
    return options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  private setFormValidation(): void {
    this.formControlErrorIndex = this.queryFormArray?.controls?.findIndex(
      (control) => control.invalid
    );
    this.isSubmitDisabled = this.ifTagForm?.invalid;
  }

  private ifTagFormValidator(group: AbstractControl): ValidationErrors | null {
    const pickProductProperties = group.get('pickProductProperties')?.value;
    const dataGroup = group.get('data') as UntypedFormGroup;
    const tagName = dataGroup.get('tagName')?.value;
    const productPropertyName = dataGroup.get('productPropertyName')?.value;

    if (pickProductProperties && productPropertyName) {
      return null;
    } else if (!pickProductProperties && tagName) {
      return null;
    }
    return { ambiguousQuerySource: true };
  }

  addFormSection(operator: IfTagOperator, options?: StringComparisonCondition): void {
    this.queryFormArray?.push(
      new UntypedFormGroup(
        {
          data: new UntypedFormGroup({
            tagName: new UntypedFormControl(options?.tagName || ''),
            matcher: new UntypedFormControl(
              options?.matcher || this.matcherOptionsEnum.IS,
              Validators.required
            ),
            tagValue: new UntypedFormControl(options?.tagValue || ''),
            productPropertyName: new UntypedFormControl(options?.productPropertyName || ''),
            productPropertyValue: new UntypedFormControl(options?.productPropertyValue || ''),
          }),
          operator: new UntypedFormControl(operator),
          pickProductProperties: new FormControl<boolean>(
            !!options?.productPropertyName && !options?.tagName
          ),
        },
        { validators: this.ifTagFormValidator }
      )
    );
    this.setAutocompleteOptions(this.queryFormArray?.controls.length - 1);
  }

  removeFormSection(index: number): void {
    this.queryFormArray?.removeAt(index);
    this.filteredQuerySource.splice(index, 1);
  }

  onToggleChange(index: number): void {
    const formArrayElement = this.queryFormArray.at(index);
    const pickProductPropertiesControl = formArrayElement.get('pickProductProperties');
    const toggled = pickProductPropertiesControl?.value;
    pickProductPropertiesControl?.setValue(!toggled);

    this.setAutocompleteOptions(index);
  }

  saveForm(): void {
    this.queryFormArray?.controls.forEach((control) => {
      const controlAsFormGroup = control as UntypedFormGroup;
      const pickProductProperties = control.get('pickProductProperties')?.value;
      const dataGroup = control.get('data') as UntypedFormGroup;
      if (pickProductProperties) {
        dataGroup.get('tagValue')?.setValue('');
        dataGroup.get('tagName')?.setValue('');
      } else {
        dataGroup.get('productPropertyName')?.setValue('');
        dataGroup.get('productPropertyValue')?.setValue('');
      }
      controlAsFormGroup.removeControl('pickProductProperties');
    });
    this.saveCallback.emit(this.ifTagForm.value);
  }
}
