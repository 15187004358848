<!-- cdkScrollable is added to ensure that mat-auto-complete is sticky, it exists in competitors-pricing -->
<div
  class="edit-panel"
  [ngClass]="{ open: !!(treeStore.selectedChild$ | async), 'edit-panel-top-0': !isPanelEnabled }"
  cdkScrollable
>
  <div class="header">
    Configure
    <mat-icon (click)="treeStore.selectedChild = undefined">close</mat-icon>
  </div>
  <div>
    <ng-container [ngSwitch]="type">
      <app-if-tag-form
        *ngSwitchCase="UIStrategyType.IF_TAG"
        [tags]="tags ?? []"
        [productProperties]="productProperties ?? []"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-if-tag-form>
      <app-if-timestamp-range-form
        *ngSwitchCase="UIStrategyType.IF_TIMESTAMP_RANGE"
        [data]="treeStore.selectedChildData$ | async"
        [productProperties]="productProperties ?? []"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-if-timestamp-range-form>
      <app-if-formula-form
        *ngSwitchCase="UIStrategyType.IF_FORMULA"
        [varsMap]="varsMap"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-if-formula-form>
      <app-if-recommended-form
        *ngSwitchCase="UIStrategyType.IF_RECOMMENDED_PRICE"
        [varsMap]="varsMap"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-if-recommended-form>
      <app-if-competition-form
        *ngSwitchCase="UIStrategyType.IF_COMPETITOR"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-if-competition-form>
      <app-price-formula-form
        *ngSwitchCase="UIStrategyType.PRICE_FORMULA"
        [varsMap]="varsMap"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-price-formula-form>
      <app-safety-rule-form
        *ngSwitchCase="UIStrategyType.SAFETY_RULE"
        [varsMap]="varsMap"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-safety-rule-form>
      <app-competitive-pricing-form
        *ngSwitchCase="UIStrategyType.COMPETITIVE_PRICING"
        [varsMap]="varsMap"
        [domains]="domains"
        [shopsByDomain]="shopsByDomain"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-competitive-pricing-form>
      <app-default-settings-form
        *ngSwitchCase="UIStrategyType.START"
        [varsMap]="varsMap"
        [data]="treeStore.selectedChildData$ | async"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-default-settings-form>
      <app-stock-clearance-form
        *ngSwitchCase="UIStrategyType.STOCK_CLEARANCE"
        [tags]="tags ?? []"
        [data]="treeStore.selectedChildData$ | async"
        [varsMap]="varsMap"
        [isSavingEnabled]="isPanelEnabled"
        (saveCallback)="treeStore.editSelectedNode($event)"
        (cancelCallback)="treeStore.selectedChild = undefined"
      ></app-stock-clearance-form>
      <div *ngSwitchDefault>{{ type }}</div>
    </ng-container>
  </div>
</div>
