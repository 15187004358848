import { getCETOffset } from '../CET-UTC-offset/CET-UTC-offset';

const CRON_HOURS_INDEX = 2;
const CRON_MINUTES_INDEX = 1;
const CRON_WEEKDAYS_INDEX = 5;

const matchWeeklyExpression: RegExp = /^0 (\d+) (\d+) (\?|\*) \* ([0-6]|(?:([0-6],){1,5}[0-6]))$/;

/**
 * @param days Array of days in cron expression
 * If the day is greater than 6 (Saturday), it will be changed to 0 (Sunday)
 * @returns Array of days in cron expression with the next day
 */
export function handleDayChangeToNextDay(days: string[]): string[] {
  return days.map((day) => {
    const newDay = Number.parseInt(day) + 1;
    return newDay > 6 ? '0' : newDay.toString();
  });
}

/**
 * @param days Array of days in cron expression
 * If the day is lower than 0 (Sunday), it will be changed to 6 (Saturday)
 * @returns Array of days in cron expression with the next day
 */
export function handleDayChangeToPreviousDay(days: string[]): string[] {
  return days.map((day) => {
    const newDay = Number.parseInt(day) - 1;
    return newDay < 0 ? '6' : newDay.toString();
  });
}

export function getHoursWithOffset(hours: number, offset: number, minutes = 0): number {
  const hoursWithOffset = hours - offset;
  const isFirstHalfHour = minutes < 30;
  return isFirstHalfHour ? Math.floor(hoursWithOffset) : Math.ceil(hoursWithOffset);
}

export function getMinutesWithOffset(minutes: number, offset: number): number {
  const minutesOffset = (offset * 60) % 60;
  const minutesWithOffset = minutes - minutesOffset;
  if (minutesWithOffset < 0) {
    return minutesWithOffset + 60;
  }
  if (minutesWithOffset > 60) {
    return minutesWithOffset - 60;
  }
  return minutesWithOffset;
}

export function getDaysMinutesFromCron(cronExpression: string): number[] {
  const splitCron = cronExpression.split(' ');
  const hours = Number.parseInt(splitCron[CRON_HOURS_INDEX]);
  const minutes = Number.parseInt(splitCron[CRON_MINUTES_INDEX]);
  return [hours, minutes];
}

export function getWeekDaysFromCron(cronExpression: string): string[] {
  const splitCron = cronExpression.split(' ');
  const days = splitCron[CRON_WEEKDAYS_INDEX].split(',');

  return days;
}

/**
 * @param cronExpression
 * @param offset
 * @returns Cron expression with the offset provided in hours, the days will be changed if the offset is greater than 24 or lower than 0
 * Examples:
 * convertCronToOffsetCron('0 10 10 * * ?', 1) => '0 10 11 * * ?'
 * convertCronToOffsetCron('0 10 10 * * ?', -1) => '0 10 09 * * ?'
 * convertCronToOffsetCron('0 10 10 * * ?', 1) => '0 10 09 * * ?'
 * convertCronToOffsetCron('0 10 0 ? * 1,3', -1) => '0 10 23 * * 0,2'
 * convertCronToOffsetCron('0 10 23 ? * 1,3', 2) => '0 10 1 * * 2,4'
 */
export function convertCronToOffsetCron(cronExpression: string, offset: number): string {
  const splitCron = cronExpression.split(' ');
  const [hours, minutes] = getDaysMinutesFromCron(cronExpression);
  let days = getWeekDaysFromCron(cronExpression);
  let hoursWithOffset = getHoursWithOffset(hours, offset, minutes);

  let minutesWithOffset = getMinutesWithOffset(minutes, offset);

  const isWeekly = cronExpression.match(matchWeeklyExpression) !== null;
  const isPreviousDay = hoursWithOffset < 0;
  const isNextDay = hoursWithOffset >= 24;
  const isSameDay = !isPreviousDay && !isNextDay;
  if (!isSameDay) {
    if (isPreviousDay) {
      hoursWithOffset += 24;
      if (isWeekly) {
        days = handleDayChangeToPreviousDay(days);
      }
    }
    if (isNextDay) {
      hoursWithOffset -= 24;
      if (isWeekly) {
        days = handleDayChangeToNextDay(days);
      }
    }
  }
  const utcCronWithoutDays = [
    ...splitCron.slice(0, CRON_MINUTES_INDEX),
    minutesWithOffset,
    hoursWithOffset,
    ...splitCron.slice(CRON_HOURS_INDEX + 1, CRON_WEEKDAYS_INDEX),
  ].join(' ');
  const utcCron = `${utcCronWithoutDays} ${days.join(',')}`;
  return utcCron;
}

export function convertCETCronToUTCCron(cronExpression: string): string {
  return convertCronToOffsetCron(cronExpression, getCETOffset());
}

export function convertUTCCronToCETCron(cronExpression: string): string {
  return convertCronToOffsetCron(cronExpression, -getCETOffset());
}

export function getOffset(timeZone: string): number {
  const dateParts = Intl.DateTimeFormat('ia', {
    timeZoneName: 'short',
    timeZone,
  }).formatToParts();
  const timeZoneName = dateParts.find((i) => i.type === 'timeZoneName')?.value ?? '';

  const offset = timeZoneName.slice(3);
  if (!offset) return 0;

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) return 0;

  const [, sign, hour, minute] = matchData;
  let result = parseInt(hour) * 60;
  if (sign === '+') result *= -1;
  if (minute) result += parseInt(minute);

  return result;
}
