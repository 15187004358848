<mat-slide-toggle
  [color]="color"
  [disabled]="disabled"
  [checked]="toggled"
  [class]="class"
  [labelPosition]="labelPosition"
  (change)="setValue($event)"
>
  {{ label }}</mat-slide-toggle
>
