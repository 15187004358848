import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false,
})
export class FilterStringPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or [null]
   */
  transform(items: string[], searchText: string): Array<string | null> {
    if (!items) {
      return [];
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    const filteredItems = items.filter((item) => item.toLocaleLowerCase().includes(searchText));

    return filteredItems.length ? filteredItems : [null];
  }
}
