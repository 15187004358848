import { Component, Input } from '@angular/core';

import { IBadgeType } from '../../models/common/badge.type';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() type?: IBadgeType = 'success';

  @Input() text?: string = '';
}
