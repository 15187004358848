import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter, takeUntil } from 'rxjs';

import { AppState } from '@app/store/app.state';
import { selectActiveContract } from '@app/store/user';
import { filterNullAndUndefined } from '@app/utils/helpers/is-defined';
import { SubscriptionManagementBaseClass } from '@app/utils/subscription-management/subscription-management-base-class';

import { EXTERNAL_DROPPABLE_ELEMENT_PATH } from '../../constants';
import { PeanutTreeUI } from '../../PeanutTree';
import { PriceGapUnit, UIStrategyType } from '../../ui-strategy-elements/strategy-types';

@Component({
  selector: 'app-strategy-library',
  templateUrl: './strategy-library.component.html',
  styleUrls: ['./strategy-library.component.scss'],
})
export class StrategyLibraryComponent extends SubscriptionManagementBaseClass implements OnDestroy {
  /**
   * Start block for limiting showing stock clearance feature
   * Todo: Delete block after rolling out the feature
   */
  contractHasStockClearance: boolean = true;

  // Constellation-test , Phoenix-test 2.0, Yaza-test, Demo-Consumer Electronics, Genesis pricing test
  contractsWithStockClearanceAllowed: string[] = ['m0cbcn', '615dd6', 'evk4ui', '1olig9', 's1id0j'];

  constructor(protected readonly store: Store<AppState>) {
    super();
    this.store
      .select(selectActiveContract)
      .pipe(filter(filterNullAndUndefined), takeUntil(this.componentDestroyed))
      .subscribe(({ sid }) => {
        this.contractHasStockClearance = this.contractsWithStockClearanceAllowed.includes(sid);
      });
  }
  /**
   * End block for limiting showing stock clearance feature
   */

  public ifTag: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.IF_TAG,
      isElementValid: true,
      query: [
        {
          data: {
            tagValue: '',
            matcher: '',
            tagName: '',
          },
        },
      ],
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public ifTimestampRange: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
      isElementValid: true,
      query: [
        {
          data: {},
        },
      ],
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public ifRecommended: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.IF_RECOMMENDED_PRICE,
      isElementValid: true,
      formula: '',
      operator: '',
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public ifFormula: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.IF_FORMULA,
      leftFormula: '',
      rightFormula: '',
      operator: '',
      isElementValid: false,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public ifCompetition: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.IF_COMPETITOR,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public targetPosition: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.COMPETITIVE_PRICING,
      isElementValid: true,
      targetPosition: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public priceGap: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.COMPETITIVE_PRICING,
      isElementValid: true,
      priceGap: null,
      priceGapUnit: PriceGapUnit.ABSOLUTE,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public followCompetitors: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.COMPETITIVE_PRICING,
      isElementValid: true,
      vendorOfferFilter: [],
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public relevantPrice: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.COMPETITIVE_PRICING,
      isElementValid: true,
      includeDeliveryCosts: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public priceFormula: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.PRICE_FORMULA,
      priceFormula: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public priceCalculation: PeanutTreeUI = {
    children: [],
    data: {
      priceRequirement: null,
      strategyType: UIStrategyType.PRICE_FORMULA,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public minimumPrice: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.SAFETY_RULE,
      minPrice: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public maximumPrice: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.SAFETY_RULE,
      maxPrice: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };

  public stockClearance: PeanutTreeUI = {
    children: [],
    data: {
      strategyType: UIStrategyType.STOCK_CLEARANCE,
      isElementValid: true,
      continuePricing: false,
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      targetStockLevel: null,
    },
    path: EXTERNAL_DROPPABLE_ELEMENT_PATH,
  };
}
