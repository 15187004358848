import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { InstantErrorStateMatcher } from './utils/instant-error-state-matcher';

@Component({
  selector: 'app-fixed-price-selection',
  templateUrl: './fixed-price-selection.component.html',
  styleUrls: ['./fixed-price-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedPriceSelectionComponent implements OnInit {
  @Input() price?: Number = undefined;

  @Input() startDate?: Date = undefined;

  @Input() endDate?: Date = undefined;

  @Input() fixedPriceEnabled = false;

  @Input() purchasePrice?: number;

  @Input() vat?: number;

  @Output() cancel = new EventEmitter();

  @Output() save = new EventEmitter<{
    price: Number | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    fixedPriceEnabled: boolean;
  }>();

  marginText?: string;

  showErrorInstantly = new InstantErrorStateMatcher();

  endDateAfterStartDate: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const startDate = group.get('startDate')?.value;
    const endDate = group.get('endDate')?.value;

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      return { endDateBeforeStartDate: true };
    }
    return null;
  };

  priceControl: FormControl = new FormControl(null, [Validators.required, Validators.min(0.01)]);

  form: FormGroup = new FormGroup(
    {
      price: this.priceControl,
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    },
    { validators: this.endDateAfterStartDate }
  );

  /**
   * Converts a Date object to a string representation in the format "YYYY-MM-ddTHH:mm:ss".
   * The date string will represent a date in local time zone.
   * @param date - The Date object to convert.
   * @returns The string representation of the date in the format "YYYY-MM-ddTHH:mm:ss", or undefined if the input date is undefined.
   */
  date2String(date: Date | undefined): string | undefined {
    if (!date) return undefined;
    // We can fixate the locale here, as the date format doesn't require localization
    return formatDate(date, 'YYYY-MM-ddTHH:mm:ss', 'en');
  }

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((values) => {
      this.price = values.price;
      this.startDate = values.startDate ? new Date(values.startDate) : undefined;
      this.endDate = values.endDate ? new Date(values.endDate) : undefined;
    });

    this.form.patchValue({
      price: this.price,
      startDate: this.date2String(this.startDate),
      endDate: this.date2String(this.endDate),
    });
    if (!this.fixedPriceEnabled) this.form.disable();

    this.calculateMargin();
  }

  enableFixedPrice(value: boolean): void {
    this.fixedPriceEnabled = value;
    if (!this.fixedPriceEnabled) {
      this.form.patchValue({
        price: undefined,
        startDate: undefined,
        endDate: undefined,
      });
      this.form.disable();
    } else {
      this.form.enable();
      this.calculateMargin();
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onSave(): void {
    this.save.emit({
      price: this.price,
      startDate: this.startDate,
      endDate: this.endDate,
      fixedPriceEnabled: this.fixedPriceEnabled,
    });
  }

  calculateMargin(): void {
    if (this.cantCalculateMargin()) {
      return;
    }
    const priceWithoutVat = this.price!.valueOf() / (1 + this.vat! / 100);
    const absMargin = parseFloat((priceWithoutVat - this.purchasePrice!).toFixed(2));
    const relMargin = parseFloat(((absMargin / priceWithoutVat) * 100).toFixed(2));
    this.marginText = `Expected Margin: <b>${relMargin} % (${absMargin})</b>`;
  }

  private cantCalculateMargin(): boolean {
    if (!this.price || this.priceControl.errors) {
      return true;
    }

    if (!this.purchasePrice && !this.vat) {
      this.marginText = 'Cannot calculate expected margin, purchase price and vat are missing';
      return true;
    }

    if (!this.purchasePrice) {
      this.marginText = 'Cannot calculate expected margin, purchase price is missing';
      return true;
    }

    if (!this.vat) {
      this.marginText = 'Cannot calculate expected margin, vat is missing';
      return true;
    }

    return false;
  }
}
