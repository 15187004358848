import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subscription } from 'rxjs';

import { compact } from 'lodash';

import {
  DIALOG_SIZE,
  EXTERNAL_DROPPABLE_ELEMENT_PATH,
  TREE_FIRST_LEVEL_PATH,
} from '../../constants';
import { PeanutTreeUI } from '../../PeanutTree';
import { IsConditionNodePipe } from '../../pipes/is-condition-node.pipe';
import { PeanutTreeService } from '../../services/peanut-tree.service';
import { UIStrategyType } from '../../ui-strategy-elements/strategy-types';
import { CopyOrMoveDialogComponent } from '../copy-or-move-dialog/copy-or-move-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

/**
 * This is the core component of the tree nodes.
 *
 * It renders the correct node template according to the node type (strategyType)
 */
@Component({
  selector: 'app-node-base',
  templateUrl: './node-base.component.html',
  styleUrls: ['./node-base.component.scss'],
})
export class NodeBaseComponent implements AfterViewChecked, OnInit, OnDestroy {
  readonly TREE_FIRST_LEVEL_PATH = TREE_FIRST_LEVEL_PATH;

  readonly uiStrategyType = UIStrategyType;

  @ViewChild('node') elementRef!: ElementRef;

  @Input() index!: number;

  @Input() child!: PeanutTreeUI;

  @Input() parent!: PeanutTreeUI;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() focusedNodeIds?: number[];

  @Input() isDeleteingEnabled = true;

  subscription = new Subscription();

  isSelected = false;

  isFocused = false;

  isAutoScrollEnabled = false;

  constructor(
    private isConditionNode: IsConditionNodePipe,
    public dialog: MatDialog,
    @SkipSelf() public treeStore: PeanutTreeService
  ) {}

  onClickOutside = ({ target }: MouseEvent) => {
    const selectedElement = this.elementRef.nativeElement.querySelector('.selected');
    const targetNode = target as Element;

    const topBar = document.querySelector('.strategy-component .top-bar');
    const treeWrapper = document.querySelector('.strategy-component .tree-wrapper');
    const strategyLibrary = document.querySelector('.strategy-component app-strategy-library');
    const editSidebar = document.querySelector('.edit-panel');

    /* If the user clicks outside the selected node, the edit sidebar should
     * hide. However, this behavior should be limited a bit, since the sidebar
     * contains elements, such as mat-autocomplete, that are not children of
     * the sidebar but still need to function as if they are.
     * To do this, we make sure that the edit sidebar is closed only when
     * clicking outside the sidebar, but inside it's siblings.
     */
    const shouldHideSidebar =
      // Outside the node itself
      !selectedElement?.contains(targetNode) &&
      // Outside the sidebar
      !editSidebar?.contains(targetNode) &&
      (topBar?.contains(targetNode) || // inside the strategy topbar
        treeWrapper?.contains(targetNode) || // inside the strategy tree
        strategyLibrary?.contains(targetNode)); // inside the strategy library

    if (
      !!selectedElement &&
      shouldHideSidebar &&
      this.treeStore.selectedChild &&
      this.treeStore.selectedChild.path === this.child.path
    ) {
      this.treeStore.selectedChild = undefined;
    }
  };

  ngOnInit() {
    this.subscription.add(
      this.treeStore.selectedChildPath$.subscribe((selectedChildPath) => {
        if (selectedChildPath && selectedChildPath === this.child.path) {
          this.isSelected = true;
          document.addEventListener('click', this.onClickOutside);
        } else if (this.isSelected) {
          this.isSelected = false;
          document.removeEventListener('click', this.onClickOutside);
        }
      })
    );

    this.subscription.add(
      this.treeStore.lastCreatedPath$.subscribe((lastCreatedPath) => {
        if (lastCreatedPath && lastCreatedPath === this.child.path) {
          this.isAutoScrollEnabled = true;
        }
      })
    );

    this.checkPriceRecommendationNode();
  }

  ngAfterViewChecked() {
    if (!this.isAutoScrollEnabled) return;
    this.elementRef.nativeElement.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
    this.treeStore.lastCreatedPath = undefined;
    // Without the timeout it scrolls to the wrong place :\
    setTimeout(() => (this.isAutoScrollEnabled = false), 1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  copyOrMoveToFirst = () => {
    if (this.treeStore.draggedChild?.path === EXTERNAL_DROPPABLE_ELEMENT_PATH) {
      this.treeStore.copyOrMoveNode(this.parent, false, this.index, true, true);
      return;
    }
    const dialogRef = this.dialog.open(CopyOrMoveDialogComponent, DIALOG_SIZE);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      this.treeStore.copyOrMoveNode(this.parent, !result, this.index, true, true);
    });
  };

  copyOrMoveNode = (direction: boolean) => {
    if (this.treeStore.draggedChild?.path === EXTERNAL_DROPPABLE_ELEMENT_PATH) {
      this.treeStore.copyOrMoveNode(
        direction ? this.child : this.parent,
        false,
        direction === true ? 0 : this.index + 1,
        direction
      );
      return;
    }
    const dialogRef = this.dialog.open(CopyOrMoveDialogComponent, DIALOG_SIZE);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      this.treeStore.copyOrMoveNode(
        direction ? this.child : this.parent,
        !result,
        direction === true ? 0 : this.index + 1,
        direction
      );
    });
  };

  deleteNode = (event: PointerEvent) => {
    event.stopPropagation();
    if (this.treeStore.selectedChild !== this.child) {
      this.treeStore.selectedChild = undefined;
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      ...DIALOG_SIZE,
      data: {
        isSingleDisabled:
          compact(this.child.children).length > 1 ||
          (this.parent.path !== TREE_FIRST_LEVEL_PATH &&
            this.index + 1 < compact(this.parent.children).length &&
            compact(this.child.children).length === 1 &&
            !this.isConditionNode.transform(this.child.children[0]?.data?.strategyType)),
        isWithChildrenDisabled: !compact(this.child.children).length,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      this.treeStore.deleteNode(result, this.child, this.parent, this.index);
      // After deleting node need to reset selected child ( sidebar and edges to disappear depends on selected child too )
      this.treeStore.selectedChild = undefined;
    });
  };

  private checkPriceRecommendationNode(): void {
    const nodeId = this.child.data.id;
    this.isFocused = nodeId && this.focusedNodeIds?.includes(nodeId);
  }
}
