<!-- TODO: uncomment when the edit form is created-->

<mat-accordion class="accordion">
  <mat-expansion-panel
    (opened)="isOpen = true"
    (closed)="isOpen = false"
    hideToggle
    [expanded]="isOpen"
    [disabled]="true"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'auto'"
      [expandedHeight]="'auto'"
      class="accordion_card_header pr-0"
    >
      <div class="header-content">
        <ng-content select="[header]"></ng-content>
      </div>
      <!-- <i class="material-icons toggle-icon">&#xE5CF;</i> -->
    </mat-expansion-panel-header>
    <div class="accordion_card_collapse accordion_card_collapse_content" #panel>
      <ng-content select="[content]"></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>
