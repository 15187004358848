<div class="flex h-14 flex-row justify-end">
  <div class="flex items-center gap-4">
    <div class="m-0 flex">
      <div class="flex items-center self-center">
        <span class="leading-6"
          >Last calculation: {{ (data?.timestamp | date : dateFormat) || '-' }}
        </span>
      </div>
      <div class="flex items-center self-center">
        <span class="mx-4 opacity-25">|</span>
        <span class="leading-6">Failed calculations: {{ data?.failures?.count || '-' }} </span>
      </div>
      <div
        class="flex items-center self-center"
        *ngIf="data?.badgeType && data?.timestamp && data?.failures"
      >
        <span class="mx-4 opacity-25">|</span>
        <app-badge [type]="data?.badgeType"></app-badge>
      </div>
      <div
        class="flex items-center self-center"
        *ngIf="data?.failures && (data?.failures)!.data && (data?.failures)!.data.length > 0"
      >
        <app-basic-button
          class="ml-4"
          color="primary"
          text="Download report"
          icon="download"
          iconSize="text-xl"
          (clickEvent)="downloadFile()"
        ></app-basic-button>
      </div>
    </div>
  </div>
</div>
