import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EMPTY, map, Observable, startWith } from 'rxjs';

import { chain, isEqual, isInteger } from 'lodash';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import {
  CompetitivePricingStrategy,
  Shop,
} from '../../../ui-strategy-elements/competitive-pricing/competitive-pricing';
import {
  FormulaValidation,
  formulaValidator,
  getInvalidTypeErrorMessage,
} from '../FormulaValidation';

const groupCompetitors = (vendorOfferFilter: Shop[]) =>
  chain(vendorOfferFilter)
    .groupBy('domain')
    .map((value, key) => ({
      domain: key,
      shops: value.map((val) => val.shop),
    }))
    .orderBy(['domain'], ['asc'])
    .value();

@Component({
  selector: 'app-competitive-pricing-form',
  templateUrl: './competitive-pricing-form.component.html',
  styleUrls: ['./competitive-pricing-form.component.scss'],
})
export class CompetitivePricingFormComponent
  extends CompetitorVariableDirective
  implements OnChanges
{
  readonly isInteger = isInteger;

  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() domains?: string[] = [];

  @Input() shopsByDomain?: Map<string, string[]>;

  @Input() data: CompetitivePricingStrategy | undefined;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  formulaValidation = new FormulaValidation();

  competitivePriceForm = new UntypedFormGroup({
    targetPosition: new UntypedFormControl(null),
    priceGap: new UntypedFormControl(null, Validators.required),
    priceGapUnit: new UntypedFormControl(null, Validators.required),
    vendorOfferFilter: new UntypedFormControl([], Validators.required),
    includeDeliveryCosts: new UntypedFormControl(null, Validators.required),
    searchTargetVar: new UntypedFormControl(null),
    searchPriceVar: new UntypedFormControl(null),
  });

  isTargetVarsShown = false;

  isPriceVarsShown = false;

  isFollowVarsShown = false;

  target = this.competitivePriceForm.get('targetPosition');

  price = this.competitivePriceForm.get('priceGap');

  unit = this.competitivePriceForm.get('priceGapUnit');

  follow = this.competitivePriceForm.get('vendorOfferFilter');

  relevantPrice = this.competitivePriceForm.get('includeDeliveryCosts');

  isTargetEnabled = false;

  isPriceEnabled = false;

  isFollowEnabled = false;

  isRelevantPriceEnabled = false;

  // used to show list of competitors in UI
  competitors: { domain: string; shops: string[] }[] = [];

  competitorOptions: Observable<string[]> = EMPTY;

  newShopDomain: string | undefined;

  newShopName = new UntypedFormControl();

  shopValid: boolean = false;

  defaultShopPlaceholder: string = 'Competitor Name';

  shopPlaceholder: string = this.defaultShopPlaceholder;

  searchTargetVar: string = '';

  searchPriceVar: string = '';

  filteredVars: string[] = [];

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  updateCompetitorOptions(): void {
    this.competitorOptions = this.newShopName.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCompetitors(value))
    );
  }

  /**
   * Used for the auto-complete filtering of competitors options
   * @param value
   * @returns
   */
  private filterCompetitors(value: string): string[] {
    if (!this.newShopDomain) return [];

    const filterValue = value.toLowerCase();
    let shops =
      this.shopsByDomain
        ?.get(this.newShopDomain)
        ?.filter((option) => option.toLowerCase().includes(filterValue)) || [];

    // filter out shops that are already part of competitors
    const competitorsShops = this.competitors.filter(
      (competitor) => competitor.domain === this.newShopDomain
    )[0];
    if (competitorsShops) {
      shops = shops.filter((shop) => !competitorsShops.shops.includes(shop));
    }
    return shops;
  }

  onDomainChange(): void {
    this.newShopName.setValue('');

    const domainHasShops =
      this.newShopDomain !== undefined && this.shopsByDomain?.get(this.newShopDomain) !== undefined;

    if (!domainHasShops) {
      this.shopPlaceholder = '0 Shops Found';
      this.newShopName.disable();
    }

    if (domainHasShops && this.newShopName.disabled) {
      this.newShopName.enable();
      this.newShopName.setValue('');
      this.shopPlaceholder = this.defaultShopPlaceholder;
    }
    this.updateCompetitorOptions();
    this.checkShopName();
  }

  /**
   * Check if the input text is a valid shop name.
   * Used to enable and disable the plus icon
   */
  checkShopName(): void {
    this.shopValid =
      this.newShopDomain !== undefined &&
      this.isShopValid(this.newShopDomain, this.newShopName.value);
  }

  onAddCompetitor(): void {
    if (!this.newShopDomain || !this.isShopValid(this.newShopDomain, this.newShopName.value)) {
      return;
    }
    this.addCompetitor(this.newShopDomain, this.newShopName.value);
  }

  private isShopValid(domain: string, shop: string): boolean {
    const shops = this.shopsByDomain?.get(domain);
    return shops ? shops.includes(shop) : false;
  }

  private addCompetitor(domain: string, shop: string) {
    const competitor = {
      domain,
      shop,
    };
    this.follow?.setValue([...(this.follow?.value || []), competitor]);
    this.competitors = groupCompetitors(this.follow?.value || []);
    this.newShopName.setValue('');
    this.checkShopName();
    this.competitivePriceForm.markAsDirty();
  }

  deleteCompetitor(domain: string, name: string) {
    const shop = { domain, shop: name };
    this.follow?.setValue((this.follow?.value || []).filter((item: Shop) => !isEqual(item, shop)));
    this.competitors = groupCompetitors(this.follow?.value || []);
    this.competitivePriceForm.markAsDirty();
  }

  toggleTarget() {
    this.isTargetEnabled = !this.isTargetEnabled;
    this.target?.setValue(this.isTargetEnabled ? null : undefined);
    this.competitivePriceForm.markAsDirty();
  }

  togglePrice() {
    this.isPriceEnabled = !this.isPriceEnabled;
    this.price?.setValue(this.isPriceEnabled ? null : undefined);
    this.unit?.setValue(this.isPriceEnabled ? null : undefined);
    this.competitivePriceForm.markAsDirty();
  }

  toggleFollow() {
    this.isFollowEnabled = !this.isFollowEnabled;
    this.follow?.setValue(this.isFollowEnabled ? null : undefined);
    this.competitors = groupCompetitors(this.data?.vendorOfferFilter || []);
    this.competitivePriceForm.markAsDirty();
    this.newShopName?.setValue('');
  }

  toggleRelevantPrice() {
    this.isRelevantPriceEnabled = !this.isRelevantPriceEnabled;
    this.relevantPrice?.setValue(this.isRelevantPriceEnabled ? null : undefined);
    this.competitivePriceForm.markAsDirty();
  }

  ngOnChanges() {
    if (this.data?.targetPosition) {
      this.isTargetEnabled = true;
      this.target?.setValue(
        this.formatFormulaVarsPipe.transform(this.data?.targetPosition, this.varsMap)
      );
    } else {
      this.isTargetEnabled = this.data?.targetPosition === null;
      this.target?.setValue(this.isTargetEnabled ? null : undefined);
    }
    if (this.data?.priceGap) {
      this.isPriceEnabled = true;
      this.price?.setValue(this.formatFormulaVarsPipe.transform(this.data?.priceGap, this.varsMap));
      this.unit?.setValue(this.data.priceGapUnit);
    } else {
      this.isPriceEnabled = this.data?.priceGap === null;
      this.price?.setValue(this.isPriceEnabled ? null : undefined);
      this.unit?.setValue(this.isPriceEnabled ? null : undefined);
    }
    if (this.data?.vendorOfferFilter) {
      this.isFollowEnabled = true;
      this.follow?.setValue(this.data.vendorOfferFilter);
    } else {
      this.isFollowEnabled = this.data?.vendorOfferFilter === null;
      this.follow?.setValue(this.isFollowEnabled ? [] : undefined);
    }

    this.isRelevantPriceEnabled = this.data?.includeDeliveryCosts !== undefined;
    this.relevantPrice?.setValue(this.data?.includeDeliveryCosts);

    this.newShopDomain = this.domains?.[0] || '';
    this.target?.setValidators(formulaValidator(this.vars));
    this.price?.setValidators(formulaValidator(this.vars));
    this.competitors = groupCompetitors(this.data?.vendorOfferFilter || []);

    this.updateCompetitorOptions();
  }

  save() {
    if (this.target?.value)
      this.target.setValue(
        this.formatFormulaVarsPipe.transform(this.target.value, this.varsMap, true)
      );
    if (this.price?.value)
      this.price.setValue(
        this.formatFormulaVarsPipe.transform(this.price.value, this.varsMap, true)
      );
    this.saveCallback.emit(this.competitivePriceForm.value);
  }
}
