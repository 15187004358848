import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { SafetyRuleStrategy } from '../../../ui-strategy-elements/saftey-rule/safety-rule';
import {
  FormulaValidation,
  formulaValidator,
  getInvalidTypeErrorMessage,
} from '../FormulaValidation';

@Component({
  selector: 'app-safety-rule-form',
  templateUrl: './safety-rule-form.component.html',
  styleUrls: ['./safety-rule-form.component.scss'],
})
export class SafetyRuleFormComponent extends CompetitorVariableDirective implements OnChanges {
  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() data: SafetyRuleStrategy | undefined;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  formulaValidation = new FormulaValidation();

  safetyRuleForm = new UntypedFormGroup({
    maxPrice: new UntypedFormControl(null),
    minPrice: new UntypedFormControl(null),
    minVarSearch: new UntypedFormControl(null),
    maxVarSearch: new UntypedFormControl(null),
  });

  isMaxVarsShown = false;

  isMinVarsShown = false;

  maxPrice = this.safetyRuleForm.get('maxPrice');

  minPrice = this.safetyRuleForm.get('minPrice');

  isMaxEnabled = false;

  isMinEnabled = false;

  minVarSearch = '';

  maxVarSearch = '';

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private readonly formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  toggleMax() {
    this.isMaxEnabled = !this.isMaxEnabled;
    this.maxPrice?.setValue(this.isMaxEnabled ? null : undefined);
    this.safetyRuleForm.markAsDirty();
  }

  toggleMin() {
    this.isMinEnabled = !this.isMinEnabled;
    this.minPrice?.setValue(this.isMinEnabled ? null : undefined);
    this.safetyRuleForm.markAsDirty();
  }

  ngOnChanges() {
    if (this.data?.maxPrice) {
      this.isMaxEnabled = true;
      this.maxPrice?.setValue(
        this.formatFormulaVarsPipe.transform(this.data.maxPrice, this.varsMap)
      );
    } else {
      this.isMaxEnabled = this.data?.maxPrice === null;
      this.maxPrice?.setValue(this.isMaxEnabled ? null : undefined);
    }
    if (this.data?.minPrice) {
      this.isMinEnabled = true;
      this.minPrice?.setValue(
        this.formatFormulaVarsPipe.transform(this.data.minPrice, this.varsMap)
      );
    } else {
      this.isMinEnabled = this.data?.minPrice === null;
      this.minPrice?.setValue(this.isMinEnabled ? null : undefined);
    }
    this.maxPrice?.setValidators(formulaValidator(this.vars));
    this.minPrice?.setValidators(formulaValidator(this.vars));
  }

  save() {
    if (this.maxPrice?.value)
      this.maxPrice.setValue(
        this.formatFormulaVarsPipe.transform(this.maxPrice.value, this.varsMap, true)
      );
    if (this.minPrice?.value)
      this.minPrice.setValue(
        this.formatFormulaVarsPipe.transform(this.minPrice.value, this.varsMap, true)
      );
    this.saveCallback.emit(this.safetyRuleForm.value);
  }
}
