<app-start-node [varsMap]="varsMap"></app-start-node>

<div class="node-tree" [ngClass]="{ 'is-dragging': isDragging }">
  <span
    class="drop drop-branch drop-first"
    qDroppable
    (dropped)="copyOrMoveNode(treeStore.tree.children[0], 0)"
  >
    <span class="drop-preview">
      <mat-icon class="connector-main">arrow_drop_down</mat-icon>
    </span>
  </span>
  <ng-container
    *ngTemplateOutlet="
      peanutTreeTmpl;
      context: {
        node: treeStore.startNode$ | async,
        children: treeStore.children$ | async
      }
    "
  ></ng-container>
</div>
<div class="drop drop-last">
  <mat-icon>add_circle_outline</mat-icon>
</div>

<app-execution-flow></app-execution-flow>

<ng-template #peanutTreeTmpl let-children="children" let-node="node">
  <ng-container *ngFor="let child of children; index as i">
    <div class="branch" *ngIf="child">
      <span
        class="drop drop-branch"
        [ngClass]="{
          disabled:
            node.path !== TREE_FIRST_LEVEL_PATH &&
            (node.children.length <= i + 1 ||
              (child.data?.strategyType | isConditionNode) === false)
        }"
        qDroppable
        [index]="i"
        [parent]="node"
        [child]="child"
        (dropped)="copyOrMoveNode(node, i + 1)"
      >
        <span class="drop-preview">
          <mat-icon class="connector-main">arrow_drop_down</mat-icon>
        </span>
      </span>

      <div
        *ngIf="(node.path === TREE_FIRST_LEVEL_PATH && node.children.length > 0) || i !== 0"
        class="vertical-gap"
      >
        <mat-icon class="connector-false">arrow_drop_down</mat-icon>
      </div>
      <app-strategy-rule-name [child]="child"></app-strategy-rule-name>
      <app-node-base
        *ngIf="isDraggingEnabled"
        class="node-component"
        qDraggable
        [index]="i"
        [child]="child"
        [parent]="node"
        [varsMap]="varsMap"
        [focusedNodeIds]="focusedNodeIds"
      ></app-node-base>
      <app-node-base
        *ngIf="!isDraggingEnabled"
        class="node-component"
        [index]="i"
        [child]="child"
        [parent]="node"
        [varsMap]="varsMap"
        [focusedNodeIds]="focusedNodeIds"
        [isDeleteingEnabled]="isDeleteingEnabled"
      ></app-node-base>
      <div class="children" *ngIf="compact(child.children).length > 0">
        <mat-icon class="connector-true">arrow_right</mat-icon>
        <div class="nodes">
          <ng-container
            *ngTemplateOutlet="
              peanutTreeTmpl;
              context: {
                node: child,
                children: compact(child.children)
              }
            "
          ></ng-container>
        </div>
      </div>
      <app-execution-node
        *ngIf="compact(child.children).length === 0 && !branchStats"
        [child]="child"
      ></app-execution-node>
      <app-branch-stats
        class="absolute"
        *ngIf="compact(child.children).length === 0 && branchStats"
        [stats]="branchStats | branchStatsMapValue : child.data.id"
      ></app-branch-stats>
    </div>
  </ng-container>
</ng-template>
