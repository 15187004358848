<div class="content-header">
  <h2>Competitive Pricing</h2>
  <p>Configure how Omnia should calculate price recommendations based on the market situation</p>
</div>

<div class="form" [formGroup]="competitivePriceForm">
  <div class="optional-group">
    <input type="checkbox" [checked]="isTargetEnabled" (change)="toggleTarget()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">my_location</mat-icon>
        Target Position
      </h3>
      <p>Omnia will try to place your offer on the target position defined here.</p>
      <div class="inputs" *ngIf="isTargetEnabled">
        <input
          type="text"
          placeholder="Enter value or select variable"
          formControlName="targetPosition"
        />
        <div class="warning" *ngIf="!target?.errors && !isInteger(+target?.value)">
          In the context of target position strategies, decimal numbers are rounded down: E.g. 1.6
          becomes 1. This applies to the value of a variable as well as decimal numbers used in the
          formula.
        </div>
        <div class="error" *ngIf="target?.errors">
          <span *ngIf="target?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(target?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="target?.errors?.['invalidItem']">
            {{target?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isTargetVarsShown = !isTargetVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isTargetVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isTargetVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isTargetVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="searchTargetVar"
              [(ngModel)]="searchTargetVar"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ searchTargetVar ? 'is-clickable' : '' }}"
              (click)="searchTargetVar = ''"
            >
              <mat-icon *ngIf="searchTargetVar === ''">search</mat-icon>
              <mat-icon *ngIf="searchTargetVar">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchTargetVar"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, target)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="target?.setValue((target?.value || '') + variable); target?.markAsDirty()"
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input type="checkbox" [checked]="isPriceEnabled" (change)="togglePrice()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">expand</mat-icon>
        Price Gap
      </h3>
      <p>
        Define the absolute or relative difference between the targeted position and your own offer
      </p>
      <div class="inputs" *ngIf="isPriceEnabled">
        <select formControlName="priceGapUnit">
          <option disabled [ngValue]="null">Select</option>
          <option value="absolute">Absolute</option>
          <option value="relative">Relative</option>
        </select>
        <input
          type="text"
          placeholder="e.g. 0.01 to be 1 cent cheaper than matched competitor"
          formControlName="priceGap"
        />
        <div class="error" *ngIf="price?.errors">
          <span *ngIf="price?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(price?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="price?.errors?.['invalidItem']">
            {{price?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isPriceVarsShown = !isPriceVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isPriceVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isPriceVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isPriceVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="searchPriceVar"
              [(ngModel)]="searchPriceVar"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ searchPriceVar ? 'is-clickable' : '' }}"
              (click)="searchPriceVar = ''"
            >
              <mat-icon *ngIf="searchPriceVar === ''">search</mat-icon>
              <mat-icon *ngIf="searchPriceVar">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchPriceVar"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, price)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="price?.setValue((price?.value || '') + variable); price?.markAsDirty()"
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input type="checkbox" [checked]="isFollowEnabled" (change)="toggleFollow()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">add_business</mat-icon>
        Follow Competitors
      </h3>
      <p>
        Define a filter to only consider offers from certain competitors. All other offers will be
        ignored.
      </p>
      <div class="inputs" *ngIf="isFollowEnabled">
        <div class="competitors" *ngFor="let competitor of competitors">
          <div class="domain">{{ competitor.domain }}</div>
          <div class="shops">
            <div class="shop" *ngFor="let shop of competitor.shops">
              <span [matTooltip]="shop" [matTooltipDisabled]="shop.length <= 15"
                >{{ shop | slice : 0 : 17 }}{{ shop.length > 15 ? '...' : '' }}</span
              >
              <mat-icon class="delete" (click)="deleteCompetitor(competitor.domain, shop)"
                >delete_forever</mat-icon
              >
            </div>
          </div>
        </div>
        <div class="error" *ngIf="competitors.length < 1">
          Define at least one competitor to activate the filter
        </div>
        <div class="competitor-input">
          <select
            class="domains"
            [(ngModel)]="newShopDomain"
            [ngModelOptions]="{ standalone: true }"
            (change)="onDomainChange()"
          >
            <option [ngValue]="domain" *ngFor="let domain of domains">
              {{ domain }}
            </option>
          </select>
          <input
            class="competitor"
            type="text"
            [formControl]="newShopName"
            [placeholder]="shopPlaceholder"
            [matAutocomplete]="auto"
            (input)="checkShopName()"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of competitorOptions | async"
              [value]="option"
              [matTooltip]="option"
              [matTooltipDisabled]="option.length <= 10"
              [matTooltipPosition]="'left'"
              (click)="checkShopName()"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon class="add" [ngClass]="{ disabled: !shopValid }" (click)="onAddCompetitor()"
            >add</mat-icon
          >
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input type="checkbox" [checked]="isRelevantPriceEnabled" (change)="toggleRelevantPrice()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">request_quote</mat-icon>
        Relevant Price
      </h3>
      <p>
        By default, Omnia will consider your competitors' unit prices. Here, you can define total
        price (incl. delivery cost) as the relevant price for competitive pricing
      </p>
      <div class="inputs" *ngIf="isRelevantPriceEnabled">
        <select formControlName="includeDeliveryCosts">
          <option disabled [ngValue]="null">Select</option>
          <option [ngValue]="true">Total price</option>
          <option [ngValue]="false">Unit price</option>
        </select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          (!isPriceEnabled && !isTargetEnabled && !isFollowEnabled && !isRelevantPriceEnabled) ||
          (isPriceEnabled && (price?.errors || unit?.errors)) ||
          (isTargetEnabled && target?.errors) ||
          (isFollowEnabled && follow?.errors) ||
          (isRelevantPriceEnabled && relevantPrice?.errors) ||
          !competitivePriceForm.dirty
        "
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
