<div
  class="box-border flex h-full cursor-pointer flex-row items-center border-0 border-l-1 border-solid border-gray-200 p-8 text-accent hover:bg-gray-50"
  [matMenuTriggerFor]="companiesMenu"
  #companiesMenuTrigger="matMenuTrigger"
>
  {{ currentContract?.name || 'loading...' }}
  <mat-icon class="menu-arrow">arrow_drop_up</mat-icon>
</div>

<mat-menu #companiesMenu="matMenu" xPosition="before">
  <button class="selected" mat-menu-item [matMenuTriggerFor]="currentCompanyMenu">
    {{ currentCompany?.name }}
  </button>

  <div
    mat-menu-item
    *ngIf="(companies?.length || 0) > 1"
    (click)="toggleAllCompanies(); $event.stopPropagation()"
  >
    {{ isShowingAll ? 'Hide' : 'Show' }} all organizations
  </div>
  <ng-container *ngIf="isShowingAll && (companies?.length || 0) > 1">
    <div class="flex flex-row items-baseline">
      <mat-form-field class="pl-4 pr-1">
        <input
          matInput
          autocomplete="off"
          placeholder="Filter"
          (input)="applyFilter()"
          (click)="$event.stopPropagation()"
          [(ngModel)]="filterTerm"
        />
      </mat-form-field>
      <mat-form-field class="filter-type pr-4 pl-1">
        <mat-select
          [(ngModel)]="filterType"
          (selectionChange)="applyFilter()"
          (click)="$event.stopPropagation()"
        >
          <mat-option [value]="FilterTypes.ANY">Any</mat-option>
          <mat-option [value]="FilterTypes.COMPANIES">Companies</mat-option>
          <mat-option [value]="FilterTypes.CONTRACTS">Contracts</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="companies-wrapper">
      <ng-container *ngFor="let company of companies; let i = index">
        <ng-container
          *ngIf="company.id !== current?.companyId && !filteredCompanies?.includes(company.id)"
        >
          <button mat-menu-item [matMenuTriggerFor]="i">
            <span qHighlightText [highlightText]="filterTerm">
              {{ company.name }}
            </span>
          </button>
          <mat-menu #i="matMenu">
            <ng-container *ngFor="let contract of company.contracts">
              <button
                *ngIf="!filteredContracts?.includes(contract.sid)"
                mat-menu-item
                (click)="onOptionClicked(company.id, contract.sid)"
              >
                <span qHighlightText [highlightText]="filterTerm">
                  {{ contract.name }}
                </span>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
    </div>
    <div class="no-results-message px-4 py-2 text-sm font-bold">No results.</div>
  </ng-container>
</mat-menu>

<mat-menu #currentCompanyMenu="matMenu" class="sub-menu">
  <ng-container *ngFor="let contract of currentCompany?.contracts">
    <button
      [ngClass]="{ selected: contract.sid === current?.contractSid }"
      mat-menu-item
      (click)="onOptionClicked(currentCompany?.id ?? 0, contract.sid)"
    >
      {{ contract.name }}
    </button>
  </ng-container>
</mat-menu>
