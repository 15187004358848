import {
  ApiPriceCalculationType,
  ApiSelectors,
  ApiStrategy,
  ApiStrategyContextModifiers,
  ApiStrategyName,
  ApiStrategyTreeResponse,
  STRATEGY_SCHEMA_VERSION,
} from '@models/interfaces/api-strategies';

export class ApiStrategyTypeValidator {
  static strategyMetaAttributes = [
    'version',
    'documentVersion',
    'updateDate',
    'updatedBy',
    'documentVersionMessage',
  ];

  /**
   * Check and verify if:
   * - Tree has valid version , Only supported version is v.2
   * - Root strategy is start context modifier with global configurations.
   * @param apiStrategyTree : The complete tree object, in another words Root strategy
   */
  static isApiStrategyTreeValid(apiStrategyTree: ApiStrategyTreeResponse): boolean {
    const hasValidVersion =
      apiStrategyTree.version && apiStrategyTree.version === STRATEGY_SCHEMA_VERSION;
    if (!hasValidVersion) {
      throw new Error('Unsupported strategy version');
    }

    const strategyType = this.extractStrategyTreeType(apiStrategyTree);

    const hasValidRootStrategy = ApiStrategyTypeValidator.isStartApiStrategyContextModifier(
      strategyType,
      apiStrategyTree
    );
    if (!hasValidRootStrategy) {
      throw new Error('Unsupported strategy type - invalid start node');
    }

    return hasValidVersion || hasValidRootStrategy;
  }

  static isStartApiStrategyContextModifier(
    strategyType: string,
    apiStrategy: ApiStrategy
  ): boolean {
    return (
      strategyType === ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS &&
      (apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers.selectors
        .adjustToNextPricier !== undefined
    );
  }

  static isSafetyRuleApiStrategyContextModifier(
    strategyType: string,
    apiStrategy: ApiStrategy
  ): boolean {
    return (
      strategyType === ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS &&
      ((apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers.selectors.minPrice !==
        undefined ||
        (apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers.selectors.maxPrice !==
          undefined)
    );
  }

  static isPriceFormulaApiStrategyContextModifier(
    strategyType: string,
    apiStrategy: ApiStrategy
  ): boolean {
    return (
      strategyType === ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS &&
      (apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers.selectors
        .priceCalculation?.value === ApiPriceCalculationType.FORMULA
    );
  }

  static isCompetitivePriceingApiStrategyContextModifier(
    strategyType: string,
    apiStrategy: ApiStrategy
  ): boolean {
    return (
      strategyType === ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS &&
      (apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers.selectors
        .priceCalculation?.value === ApiPriceCalculationType.TARGET_POSITION &&
      !ApiStrategyTypeValidator.isStartApiStrategyContextModifier(strategyType, apiStrategy)
    );
  }

  static isStockClearanceStrategyApiStrategyContextModifier(
    strategyType: string,
    apiStrategy: ApiStrategy
  ): boolean {
    const isStrategyContextModifier = strategyType === ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS;
    if (isStrategyContextModifier) {
      const selectors = (apiStrategy as ApiStrategyContextModifiers).StrategyContextModifiers
        .selectors;
      const isCalculationTargetLevelStockclearance =
        selectors.priceCalculation?.value === ApiPriceCalculationType.TARGET_LEVEL_STOCK_CLEARANCE;
      const areValidStockClearanceSelectors =
        ApiStrategyTypeValidator.areValidStockClearanceSelectors(selectors);
      return isCalculationTargetLevelStockclearance && areValidStockClearanceSelectors;
    } else {
      return false;
    }
  }

  /*
   * startDate and endDate are ApiTimestampSelector and can have either value or tag set on both
   * calculatePriceAfterEndDate has to be defined for a valid StockClearanceStrategy
   */
  static areValidStockClearanceSelectors(selectors: ApiSelectors): boolean {
    const areValid =
      ((selectors.startDate?.value !== undefined && selectors.endDate?.value !== undefined) ||
        (selectors.startDate?.tagKey !== undefined && selectors.endDate?.tagKey !== undefined)) &&
      selectors.calculatePriceAfterEndDate !== undefined;

    return areValid;
  }

  /**
   * Since strategy doesn't have fixed attribute name in the strategy builder JSON
   * and it contains additional meta data attributes,
   * we need to take extra care when retrieving the strategy type (make sure the index 0 contains actually the type)
   * @param apiStrategyTree
   * @returns strategy type
   */
  static extractStrategyTreeType(apiStrategyTree: ApiStrategy): string {
    const strategyBuilder = Object.keys(apiStrategyTree).filter(
      (k) => !this.strategyMetaAttributes.includes(k)
    );
    if (strategyBuilder.length !== 1) {
      throw new Error('Missing strategy type or multiple possible types');
    }
    return strategyBuilder[0];
  }
}
