import {
  IfTagMatcher,
  NumericComparator,
  PriceGapUnit,
  UIStrategyType,
} from './ui-strategy-elements/strategy-types';
import { PeanutTree } from './PeanutTree';

// Mocks the input of formula variables pairs ([api,ui] formats) that gets passed from within calller app e.g Genesis
export const formulaVarsMap = new Map([
  ['<competitor>', 'COMPETITOR'],
  ['<ownPrice>', 'CURRENT_PRICE'],
  ['<ownPosition>', 'CURRENT_POSITION'],
  ['<deliveryCosts>', 'DELIVERY_COSTS'],
  ['<tag.fixedPrice>', 'TAG.fixedPrice'],
  ['<tag.Price>', 'TAG.Price'],
  ['<tag.loweredPrice>', 'TAG.loweredPrice'],
  ['<averageUnitPrice>', 'AVERAGE_UNIT_PRICE'],
  ['<averageTotalPrice>', 'AVERAGE_TOTAL_PRICE'],
  ['<lowestUnitPrice>', 'LOWEST_UNIT_PRICE'],
  ['<lowestTotalPrice>', 'LOWEST_TOTAL_PRICE'],
  ['<highestUnitPrice>', 'HIGHEST_UNIT_PRICE'],
  ['<highestTotalPrice>', 'HIGHEST_TOTAL_PRICE'],
  ['<mostOccurringUnitPrice>', 'MOST_OCCURRING_UNIT_PRICE'],
  ['<mostOccurringTotalPrice>', 'MOST_OCCURRING_TOTAL_PRICE'],
  ['<medianUnitPrice>', 'MEDIAN_UNIT_PRICE'],
  ['<medianTotalPrice>', 'MEDIAN_TOTAL_PRICE'],
  ['<tag.Max_Price>', 'TAG.Max_Price'],
  ['<tag.Max_Price_UB>', 'TAG.Max_Price_UB'],
  ['<tag.articleId>', 'TAG.articleId'],
  // ToDo: revert once concept properly reflected in genesis PH-394
  // ['<maxPrice>', 'MAX_PRICE'],
  // ['<minPrice>', 'MIN_PRICE'],
  // ['<referencePrice>', 'REFERENCE_PRICE'],
]);

export const stubTree: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.Price * 1.45',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: 'TAG.fixedPrice + TAG.Price * 1.45 - TAG.loweredPrice',
        priceRequirement: true,
        adjustToNextPricier: false,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName1', tagValue: 'tagValue1', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName2',
                  tagValue: 'tagValue2',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
              {
                data: {
                  tagName: 'tagName3',
                  tagValue: 'tagValue3',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
              {
                data: {
                  tagName: 'tagName4',
                  tagValue: 'tagValue4',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'and',
              },
              {
                data: {
                  tagName: 'tagName5',
                  tagValue: 'tagValue5',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
              {
                data: {
                  tagName: 'tagName6',
                  tagValue: 'tagValue6',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'and',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '2',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.asin',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfRecommendedPriceStrategy',
            formula: 'TAG.fixedPrice + TAG.Price * 1.45 - TAG.loweredPrice',
            operator: NumericComparator.EQUAL,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.fixedPrice',
            rightFormula: '0',
            operator: 'lessThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.fixedPrice + TAG.Price * 1.45 - TAG.loweredPrice',
                rightFormula: 'TAG.fixedPrice / 2 * 10 + TAG.Price',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.fixedPrice + TAG.fixedPrice',
                    rightFormula: 'TAG.fixedPrice / 2 * 10',
                    operator: 'equalTo',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfCompetitionStrategy',
            competitorsMatcher: {
              operator: 'equalTo',
              matchValue: 1,
            },
            positionMatcher: {
              operator: 'equalTo',
              matchValue: 1,
            },
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfCompetitionStrategy',
                competitorsMatcher: {
                  operator: 'lessThan',
                  matchValue: 3,
                },
                positionMatcher: {
                  operator: 'greaterThan',
                  matchValue: 0,
                },
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfCompetitionStrategy',
                    competitorsMatcher: {
                      operator: 'lessThan',
                      matchValue: 3,
                    },
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'SafetyRuleStrategy',
            maxPrice: 'TAG.recommendSellingPrice',
            minPrice: 'TAG.VVP*1.33',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'SafetyRuleStrategy',
                maxPrice: 'TAG.recommendSellingPrice',
                minPrice: 'VIP',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'SafetyRuleStrategy',
                    maxPrice: 'TAG.recommendSellingPrice',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'SafetyRuleStrategy',
                        minPrice: 'TAG.VVP*1.33',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'PriceFormulaStrategy',
            priceFormula: 'TAG.recommendSellingPrice * 1.55',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceRequirement: false,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.asin',
                    priceRequirement: true,
                  },
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export const emptyTree: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: '0',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
      },
      children: [],
    },
  ],
};
//this edge case is demo the tree of Maxima Holland internally – the customer we taylor the MVP to

export const maximaHollandTree: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.recommendSellingPrice',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: 'SafetyRuleStrategy',
            maxPrice: 'TAG.recommendSellingPrice',
            minPrice: 'TAG.VVP*1.33',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.fixedPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.fixedPrice',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                children: [
                  {
                    data: {
                      isElementValid: true,
                      strategyType: 'PriceFormulaStrategy',
                      priceFormula: 'TAG.fixedPrice',
                    },
                    children: [],
                  },
                ],
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfRecommendedPriceStrategy',
                    formula: 'TAG.PromotionalPrice',
                    operator: 'lessThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro / ggmgvvvvvvvastro.com ',
                              },
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'expondo',
                              },
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'profishop',
                              },
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'xxlgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.PromotionalPrice',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfCompetitionStrategy',
                competitorsMatcher: {
                  operator: 'equalTo',
                  matchValue: 0,
                },
                positionMatcher: {
                  operator: 'greaterThan',
                  matchValue: 0,
                },
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.VVP*1.5',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'expondo',
                  },
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'profishop',
                  },
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'xxlgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.sellingPrice',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: '1',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.sellingPrice',
              },
              children: [],
            },
          ],
        },
      ],
    },
  ],
};
//edge case added to test performance of gian tree, based on existing customer Outzeit in pricemonitor
export const performanceTestTree: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: '1.1',
        priceGapUnit: PriceGapUnit.RELATIVE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: false,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.fixedPrice',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.fixedPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.fixedPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.fixedPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.fixedPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.fixedPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.fixedPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfFormulaAppliesStrategy',
                            leftFormula: 'TAG.PromotionalPrice',
                            rightFormula: '0',
                            operator: 'greaterThan',
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfTagStrategy',
                            query: [
                              {
                                data: {
                                  tagName: 'tagName',
                                  tagValue: 'tagValue',
                                  matcher: IfTagMatcher.IS,
                                },
                              },
                              {
                                data: {
                                  tagName: 'tagName1',
                                  tagValue: 'tagValue1',
                                  matcher: IfTagMatcher.CONTAINS,
                                },
                                operator: 'or',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfTagStrategy',
                            query: [
                              {
                                data: {
                                  tagName: 'tagName',
                                  tagValue: 'tagValue',
                                  matcher: IfTagMatcher.IS,
                                },
                              },
                              {
                                data: {
                                  tagName: 'tagName1',
                                  tagValue: 'tagValue1',
                                  matcher: IfTagMatcher.CONTAINS,
                                },
                                operator: 'or',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfTagStrategy',
                            query: [
                              {
                                data: {
                                  tagName: 'tagName',
                                  tagValue: 'tagValue',
                                  matcher: IfTagMatcher.IS,
                                },
                              },
                              {
                                data: {
                                  tagName: 'tagName1',
                                  tagValue: 'tagValue1',
                                  matcher: IfTagMatcher.CONTAINS,
                                },
                                operator: 'or',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfTagStrategy',
                            query: [
                              {
                                data: {
                                  tagName: 'tagName',
                                  tagValue: 'tagValue',
                                  matcher: IfTagMatcher.IS,
                                },
                              },
                              {
                                data: {
                                  tagName: 'tagName1',
                                  tagValue: 'tagValue1',
                                  matcher: IfTagMatcher.CONTAINS,
                                },
                                operator: 'or',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.fixedPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'PriceFormulaStrategy',
                    priceFormula: 'TAG.fixedPrice',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.fixedPrice',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.fixedPrice',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.fixedPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.fixedPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'IfCompetitionStrategy',
                            competitorsMatcher: {
                              operator: 'equalTo',
                              matchValue: 0,
                            },
                            positionMatcher: {
                              operator: 'greaterThan',
                              matchValue: 0,
                            },
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                                vendorOfferFilter: [
                                  {
                                    domain:
                                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                    shop: 'ggmgastro',
                                  },
                                ],
                              },
                              children: [
                                {
                                  data: {
                                    isElementValid: true,
                                    strategyType: 'CompetitivePricingStrategy',
                                    targetPosition: '1',
                                  },
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfCompetitionStrategy',
                        competitorsMatcher: {
                          operator: 'equalTo',
                          matchValue: 0,
                        },
                        positionMatcher: {
                          operator: 'greaterThan',
                          matchValue: 0,
                        },
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.fixedPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfFormulaAppliesStrategy',
                        leftFormula: 'TAG.PromotionalPrice',
                        rightFormula: '0',
                        operator: 'greaterThan',
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                            vendorOfferFilter: [
                              {
                                domain:
                                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                                shop: 'ggmgastro',
                              },
                            ],
                          },
                          children: [
                            {
                              data: {
                                isElementValid: true,
                                strategyType: 'CompetitivePricingStrategy',
                                targetPosition: '1',
                              },
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfCompetitionStrategy',
                    competitorsMatcher: {
                      operator: 'equalTo',
                      matchValue: 0,
                    },
                    positionMatcher: {
                      operator: 'greaterThan',
                      matchValue: 0,
                    },
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfFormulaAppliesStrategy',
                    leftFormula: 'TAG.PromotionalPrice',
                    rightFormula: '0',
                    operator: 'greaterThan',
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                        vendorOfferFilter: [
                          {
                            domain:
                              'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                            shop: 'ggmgastro',
                          },
                        ],
                      },
                      children: [
                        {
                          data: {
                            isElementValid: true,
                            strategyType: 'CompetitivePricingStrategy',
                            targetPosition: '1',
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfFormulaAppliesStrategy',
                leftFormula: 'TAG.PromotionalPrice',
                rightFormula: '0',
                operator: 'greaterThan',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'IfTagStrategy',
                query: [
                  { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
                  {
                    data: {
                      tagName: 'tagName1',
                      tagValue: 'tagValue1',
                      matcher: IfTagMatcher.CONTAINS,
                    },
                    operator: 'or',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                    vendorOfferFilter: [
                      {
                        domain:
                          'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                        shop: 'ggmgastro',
                      },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'CompetitivePricingStrategy',
                        targetPosition: '1',
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
                vendorOfferFilter: [
                  {
                    domain:
                      'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                    shop: 'ggmgastro',
                  },
                ],
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: '1',
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: '1',
            vendorOfferFilter: [
              {
                domain:
                  'omnia.custom.spidering.spider%231%20-%20maxima%20holland%20de%20-%20mvp%20-%20de',
                shop: 'ggmgastro',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '1',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfFormulaAppliesStrategy',
            leftFormula: 'TAG.PromotionalPrice',
            rightFormula: '0',
            operator: 'greaterThan',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'PriceFormulaStrategy',
                priceFormula: 'TAG.fixedPrice',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'PriceFormulaStrategy',
            priceFormula: 'TAG.fixedPrice',
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: UIStrategyType.STOCK_CLEARANCE,
                startDate: '2023-05-25T18:15:30.00Z',
                endDate: '2023-08-25T18:15:30.00Z',
                targetStockLevel: 0,
                continuePricing: true,
                id: 3,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: UIStrategyType.STOCK_CLEARANCE,
                    startDate: '2024-05-25T18:00:00.00Z',
                    endDate: '2024-08-25T18:15:30.00Z',
                    targetStockLevel: 0,
                    continuePricing: true,
                    id: 3,
                  },
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const treeWithChildren: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '2',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: 'TAG.fixedPrice2',
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: 'TAG.fixedPrice_latestPrice',
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                  },
                  children: [],
                },
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: 'TAG.fixedPrice_latestPrice',
                    priceGap: '0.1',
                    priceGapUnit: PriceGapUnit.ABSOLUTE,
                    vendorOfferFilter: [
                      { domain: '', shop: 'Vendor1' },
                      { domain: '', shop: 'Vendor2' },
                      { domain: '', shop: 'Vendor3' },
                    ],
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: 'IfTagStrategy',
                        query: [
                          {
                            data: {
                              tagName: 'tagName',
                              tagValue: 'tagValue',
                              matcher: IfTagMatcher.IS,
                            },
                          },
                          {
                            data: {
                              tagName: 'tagName1',
                              tagValue: 'tagValue1',
                              matcher: IfTagMatcher.CONTAINS,
                            },
                            operator: 'or',
                          },
                        ],
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'IfRecommendedPriceStrategy',
            formula: 'TAG.fixedPrice + TAG.Price * 1.45 - TAG.loweredPrice',
            operator: NumericComparator.EQUAL,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '2',
              },
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

export const treeWithPriceRecommendationNode: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
        id: 1,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: 'IfTagStrategy',
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
            id: 2,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: '2',
                id: 3,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'CompetitivePricingStrategy',
                    targetPosition: 'TAG.fixedPrice2',
                    id: 4,
                  },
                  children: [],
                },
              ],
            },
            {
              data: {
                isElementValid: true,
                strategyType: 'CompetitivePricingStrategy',
                targetPosition: 'TAG.fixedPrice_latestPrice',
                id: 5,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: 'IfTagStrategy',
                    query: [
                      {
                        data: {
                          tagName: 'tagName',
                          tagValue: 'tagValue',
                          matcher: IfTagMatcher.IS,
                        },
                      },
                      {
                        data: {
                          tagName: 'tagName1',
                          tagValue: 'tagValue1',
                          matcher: IfTagMatcher.CONTAINS,
                        },
                        operator: 'or',
                      },
                    ],
                    id: 6,
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice3',
            id: 7,
          },
          children: [],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice4',
            id: 8,
          },
          children: [],
        },
      ],
    },
  ],
};

export const treeWithTimestampRangeNode: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
        id: 1,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: UIStrategyType.IF_TAG,
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
            id: 2,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                query: [
                  {
                    data: {
                      start: {
                        value: '2023-05-25T10:15:30.00Z',
                      },
                    },
                  },
                ],
                id: 3,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                    query: [
                      {
                        data: {
                          end: {
                            value: '2023-05-25T10:15:30.00Z',
                          },
                        },
                      },
                    ],
                    id: 3,
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                        query: [
                          {
                            data: {
                              start: {
                                value: '2023-05-25T18:15:30.00Z',
                              },
                              end: {
                                value: '2023-10-27T20:22:30.00Z',
                              },
                            },
                          },
                        ],
                        id: 3,
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice4',
            id: 8,
          },
          children: [],
        },
      ],
    },
  ],
};

export const treeWithTimestampRangeFromProductPropertyNode: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
        id: 1,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: UIStrategyType.IF_TAG,
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
            id: 2,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                query: [
                  {
                    data: {
                      start: {
                        productPropertyKey: 'fixed_price_start_date',
                      },
                    },
                  },
                ],
                id: 3,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                    query: [
                      {
                        data: {
                          end: {
                            productPropertyKey: 'fixed_price_end_date',
                          },
                        },
                      },
                    ],
                    id: 3,
                  },
                  children: [
                    {
                      data: {
                        isElementValid: true,
                        strategyType: UIStrategyType.IF_TIMESTAMP_RANGE,
                        query: [
                          {
                            data: {
                              start: {
                                productPropertyKey: 'fixed_price_start_date',
                              },
                              end: {
                                productPropertyKey: 'fixed_price_end_date',
                              },
                            },
                          },
                        ],
                        id: 3,
                      },
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice4',
            id: 8,
          },
          children: [],
        },
      ],
    },
  ],
};

export const treeWithStockClearanceNode: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
        id: 1,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: UIStrategyType.IF_TAG,
            query: [
              { data: { tagName: 'tagName', tagValue: 'tagValue', matcher: IfTagMatcher.IS } },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
            id: 2,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: UIStrategyType.STOCK_CLEARANCE,
                startDate: '2023-05-25T18:15:30.00Z',
                endDate: '2023-08-25T18:15:30.00Z',
                targetStockLevel: 0,
                continuePricing: true,
                id: 3,
              },
              children: [
                {
                  data: {
                    isElementValid: true,
                    strategyType: UIStrategyType.STOCK_CLEARANCE,
                    startDate: '2024-05-25T18:00:00.00Z',
                    endDate: '2024-08-25T18:15:30.00Z',
                    targetStockLevel: 0,
                    continuePricing: false,
                    id: 3,
                  },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice4',
            id: 8,
          },
          children: [],
        },
      ],
    },
  ],
};

export const treeIfTagWithProductPropertyNode: PeanutTree = {
  children: [
    {
      data: {
        isElementValid: true,
        strategyType: 'StartStrategy',
        priceGap: 'TAG.price',
        priceGapUnit: PriceGapUnit.ABSOLUTE,
        targetPosition: '1',
        priceRequirement: true,
        adjustToNextPricier: true,
        id: 1,
      },
      children: [
        {
          data: {
            isElementValid: true,
            strategyType: UIStrategyType.IF_TAG,
            query: [
              {
                data: {
                  productPropertyName: 'fixed_price',
                  productPropertyValue: '100',
                  matcher: IfTagMatcher.IS,
                },
              },
              {
                data: {
                  tagName: 'tagName1',
                  tagValue: 'tagValue1',
                  matcher: IfTagMatcher.CONTAINS,
                },
                operator: 'or',
              },
            ],
            id: 2,
          },
          children: [
            {
              data: {
                isElementValid: true,
                strategyType: UIStrategyType.IF_TAG,
                query: [
                  {
                    data: {
                      productPropertyName: 'variable_price',
                      matcher: IfTagMatcher.IS,
                    },
                  },
                ],
                id: 3,
              },
              children: [],
            },
          ],
        },
        {
          data: {
            isElementValid: true,
            strategyType: 'CompetitivePricingStrategy',
            targetPosition: 'TAG.fixedPrice4',
            id: 8,
          },
          children: [],
        },
      ],
    },
  ],
};
