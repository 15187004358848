<app-condition-form-header></app-condition-form-header>
<div class="content-subheader">
  <mat-icon class="strategy-type-icon">functions</mat-icon>
  <div class="legend">
    <h3>If formula applies</h3>
    <p>
      Specify two formulas to be compared to control whether the following strategy elements are
      applied.
    </p>
  </div>
</div>
<div class="form" [formGroup]="ifFormulaForm">
  <h3>Formula A</h3>
  <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="leftFormula" />
  <div class="error" *ngIf="leftFormula?.errors">
    <span *ngIf="leftFormula?.errors?.['invalidType']">
      {{getInvalidTypeErrorMessage(leftFormula?.errors?.['invalidType'])}}
    </span>
    <span *ngIf="leftFormula?.errors?.['invalidItem']">
      {{leftFormula?.errors?.['invalidItem']}}
    </span>
  </div>
  <div class="suggested-vars-link" (click)="isAVarsShown = !isAVarsShown">
    <span>Available variables</span>
    <mat-icon *ngIf="!isAVarsShown">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="isAVarsShown">arrow_drop_up</mat-icon>
  </div>
  <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isAVarsShown }">
    <div class="suggested-var-filter">
      <input
        type="text"
        formControlName="searchAVar"
        [(ngModel)]="searchAVar"
        placeholder="Search available variables"
      />
      <button
        class="search-button {{ searchAVar ? 'is-clickable' : '' }}"
        (click)="searchAVar = ''"
      >
        <mat-icon *ngIf="searchAVar === ''">search</mat-icon>
        <mat-icon *ngIf="searchAVar">clear</mat-icon>
      </button>
    </div>
    <ul class="suggested-vars">
      <li
        *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchAVar"
        [ngSwitch]="variable"
      >
        <div *ngSwitchCase="'COMPETITOR'">
          <app-competitor-variable
            class="suggested-var"
            (getCompetitorVariable)="setCompetitorVariable($event, leftFormula)"
          ></app-competitor-variable>
        </div>

        <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

        <div
          *ngSwitchDefault
          class="suggested-var"
          (click)="
            leftFormula?.setValue((leftFormula?.value || '') + variable); leftFormula?.markAsDirty()
          "
        >
          {{ variable }}
        </div>
      </li>
    </ul>
  </div>

  <h3>Comparison</h3>
  <div class="radio-group">
    <div class="radio">
      <input id="less" type="radio" [value]="NumericComparator.LESS" formControlName="operator" />
      <label for="less">Less than (<)</label>
    </div>
    <div class="radio">
      <input id="equal" type="radio" [value]="NumericComparator.EQUAL" formControlName="operator" />
      <label for="equal">Equals to (=)</label>
    </div>
    <div class="radio">
      <input
        id="greater"
        type="radio"
        [value]="NumericComparator.GREATER"
        formControlName="operator"
      />
      <label for="greater">Greater than (>)</label>
    </div>
  </div>

  <h3>Formula B</h3>
  <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="rightFormula" />
  <div class="error" *ngIf="rightFormula?.errors">
    <span *ngIf="rightFormula?.errors?.['invalidType']">
      {{getInvalidTypeErrorMessage(rightFormula?.errors?.['invalidType'])}}
    </span>
    <span *ngIf="rightFormula?.errors?.['invalidItem']">
      {{rightFormula?.errors?.['invalidItem']}}
    </span>
  </div>
  <div class="suggested-vars-link" (click)="isBVarsShown = !isBVarsShown">
    <span>Available variables</span>
    <mat-icon *ngIf="!isBVarsShown">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="isBVarsShown">arrow_drop_up</mat-icon>
  </div>
  <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isBVarsShown }">
    <div class="suggested-var-filter">
      <input
        type="text"
        formControlName="searchBVar"
        [(ngModel)]="searchBVar"
        placeholder="Search available variables"
      />
      <button
        class="search-button {{ searchBVar ? 'is-clickable' : '' }}"
        (click)="searchBVar = ''"
      >
        <mat-icon *ngIf="searchBVar === ''">search</mat-icon>
        <mat-icon *ngIf="searchBVar">clear</mat-icon>
      </button>
    </div>
    <ul class="suggested-vars">
      <li
        *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchBVar"
        [ngSwitch]="variable"
      >
        <div *ngSwitchCase="'COMPETITOR'">
          <app-competitor-variable
            class="suggested-var"
            (getCompetitorVariable)="setCompetitorVariable($event, rightFormula)"
          ></app-competitor-variable>
        </div>

        <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

        <div
          *ngSwitchDefault
          class="suggested-var"
          (click)="
            rightFormula?.setValue((rightFormula?.value || '') + variable);
            rightFormula?.markAsDirty()
          "
        >
          {{ variable }}
        </div>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          leftFormula?.errors || operator?.errors || rightFormula?.errors || !ifFormulaForm.dirty
        "
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
