import { Pipe, PipeTransform } from '@angular/core';

import { AttributeOptions } from '../../constants';

@Pipe({
  name: 'formatFormulaVars',
})
export class FormatFormulaVarsPipe implements PipeTransform {
  transform(value: string | undefined, varsMap: Map<string, string>, isReverse: boolean = false) {
    let varsMapkeys = [...varsMap.keys()];
    if (isReverse) {
      value = value?.replace(new RegExp('COMPETITOR\\[', 'g'), '<competitorOnPosition(');
      varsMap = new Map([...varsMap.entries()].map(([k, v]) => [v, k]));
      //  Match a whole word only, why? to address the edge case when converting back to API formula formate and some tags contains other tag names
      varsMapkeys = [...varsMap.keys()].map((key) => {
        return '\\b' + key + '\\b';
      });
    } else {
      value = value?.replace(new RegExp('<competitorOnPosition\\(', 'g'), 'COMPETITOR[');
    }
    Object.entries(AttributeOptions).forEach(([serverValue, uiValue]) => {
      value = value?.replaceAll(
        isReverse ? '].' + uiValue : ').' + serverValue + '>',
        isReverse ? ').' + serverValue + '>' : '].' + uiValue
      );
    });

    return value?.replace(
      new RegExp(varsMapkeys.join('|'), 'gi'),
      (matched) => varsMap.get(matched) || matched
    );
  }
}
