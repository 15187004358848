import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';

import { convertCETCronToUTCCron } from '../helpers/cron-helpers/cron-helpers';

@Pipe({
  name: 'cronToTime',
  pure: true,
})
export class CronToTimePipe implements PipeTransform {
  private appendSundayAtEnd(readableTime: string): string {
    return `${readableTime} and Sunday`;
  }

  transform(value: string): string {
    const utcCron = convertCETCronToUTCCron(value);
    const localOffset = -new Date().getTimezoneOffset();
    let readableTime = cronstrue.toString(utcCron, {
      use24HourTimeFormat: true,
      verbose: true,
      tzOffset: localOffset / 60,
    });
    readableTime = readableTime.replace('only on', 'every');
    if (readableTime.includes('Sunday,')) {
      readableTime = readableTime.replace('Sunday,', '');
      readableTime = readableTime.replace('and', '');
      readableTime = this.appendSundayAtEnd(readableTime);
    }
    if (readableTime.includes('Sunday and')) {
      readableTime = readableTime.replace('Sunday and', '');
      readableTime = this.appendSundayAtEnd(readableTime);
    }
    return readableTime;
  }
}
