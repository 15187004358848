<mat-checkbox
  [color]="color"
  [class]="class"
  [checked]="checked"
  [disabled]="disabled"
  [labelPosition]="labelPosition"
  (change)="setValue($event)"
>
  {{ label }}
</mat-checkbox>
