import { Pipe, PipeTransform } from '@angular/core';

enum SortOrderEnum {
  noCase = 'noCase',
  caseSensitive = 'caseSensitive',
}

type SortOrderType = `${SortOrderEnum}`;
@Pipe({
  name: 'sort',
  pure: false,
})
export class ArraySortPipe implements PipeTransform {
  /**
   * Sorts the list of strings based on the sort order provided
   *
   * @param input array that needs sorting
   * @param sortOrder type of sort order; default is case sensitive
   * @returns list of elements sorted by sortOrder or input array
   *
   */
  transform(input: string[], sortOrder?: SortOrderType): string[] {
    if (!Array.isArray(input)) {
      return input;
    }

    switch (sortOrder) {
      case SortOrderEnum.noCase:
        return input.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      case SortOrderEnum.caseSensitive:
      default:
        return input.sort();
    }
  }
}
