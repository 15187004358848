import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SkipSelf,
} from '@angular/core';

import { PeanutTreeUI } from '../PeanutTree';
import { IsConditionNodePipe } from '../pipes/is-condition-node.pipe';
import { PeanutTreeService } from '../services/peanut-tree.service';

@Directive({
  selector: '[qDroppable]',
})
export class DroppableDirective implements OnInit {
  @Input() index: number | undefined;

  @Input() child = new PeanutTreeUI();

  @Input() parent = new PeanutTreeUI();

  @Output() dropped = new EventEmitter();

  constructor(
    private isConditionNode: IsConditionNodePipe,
    private _elementRef: ElementRef<HTMLDivElement>,
    @SkipSelf() public treeStore: PeanutTreeService
  ) {}

  isNotAllowedToDrop = (element: HTMLDivElement) => {
    const isFirstLevel = (this.child.path?.split('.').length || 0) - 1 === 1;

    // allow dropping a first level element on its own 'branch' drop zone
    if (
      isFirstLevel === true &&
      this.child.path === this.treeStore.draggedChild?.path &&
      element.classList.contains('drop-branch') === true
    )
      return false;

    // forbid dropping a non-condition node between siblings
    if (
      isFirstLevel === false &&
      !this.isConditionNode.transform(this.treeStore.draggedChild?.data.strategyType) &&
      this.index !== undefined &&
      this.parent?.children.length > this.index + 1 &&
      (element.classList.contains('drop-branch') === true ||
        element.classList.contains('drop-false') === true)
    )
      return true;

    // forbid dropping an element on its own descendants
    return (
      this.treeStore.draggedChild?.path !== undefined &&
      this.child.path?.startsWith(this.treeStore.draggedChild.path) === true
    );
  };

  ngOnInit() {
    const element = this._elementRef.nativeElement;
    const dropPreview: HTMLDivElement | null =
      this._elementRef.nativeElement.querySelector('.drop-preview');

    element.addEventListener('dragenter', () => {
      if (this.isNotAllowedToDrop(element)) return false;
      element.classList.add('hover');
      return false;
    });

    element.addEventListener('dragleave', () => {
      element.classList.remove('hover');
      return false;
    });

    element.addEventListener('dragover', (event: DragEvent) => {
      event.preventDefault();
      if (!element.classList.contains('hover')) {
        if (event.dataTransfer) event.dataTransfer.dropEffect = 'none';
        return false;
      }
      if (event.dataTransfer) event.dataTransfer.dropEffect = 'move';
      if (element.classList.contains('drop-branch') && dropPreview) {
        dropPreview.style.top = event.clientY - element.getBoundingClientRect().top - 15 + 'px';
      }
      return false;
    });

    element.addEventListener('drop', (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (element.classList.contains('hover')) {
        element.classList.remove('hover');
        this.dropped.emit();
      }
      return false;
    });
  }
}
