import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent {
  @Input() text?: string;

  @Input() externalId?: string;

  @Input() disabled?: boolean;

  @Input() hasButton = true;

  @Input() hasButtonTooltip? = false;

  @Input() buttonTooltipMessage? = '';

  @Input() hasSearch = true;

  @Output() queryEvent = new EventEmitter<string>();

  @Output() addEvent = new EventEmitter<void>();

  public add(): void {
    if (!this.disabled) {
      this.addEvent.emit();
    }
  }

  public setQuery(event: KeyboardEvent): void {
    this.queryEvent.emit((event.target as HTMLInputElement).value);
  }
}
