<app-my-shops
  [shops]="shops"
  [domains]="domains"
  [isShopSaving]="isShopSaving"
  [toggleShopCallback]="toggleShopCallback"
></app-my-shops>
<div class="strategy-component">
  <app-strategy-library></app-strategy-library>
  <div class="top-bar absolute right-0 top-0 flex">
    <app-raised-button
      mat-button
      text="Copy"
      icon="content_copy"
      iconSize="text-xl"
      (clickEvent)="copyToLocalStorage()"
      [disabled]="treeStore.isDirty || isSaving || isEmpty || isEditPanelOpen"
    >
    </app-raised-button>
    <app-raised-button
      mat-button
      text="Paste"
      icon="content_paste"
      iconSize="text-xl"
      (clickEvent)="pasteFromLocalStorage()"
      [disabled]="isSaving || isEditPanelOpen || !hasStrategyInLocalStorage"
    >
    </app-raised-button>
    <app-raised-button
      mat-button
      text="Refresh"
      icon="refresh"
      iconSize="text-xl"
      (clickEvent)="refresh()"
      [disabled]="!treeStore.isDirty || isSaving || isEditPanelOpen"
    >
    </app-raised-button>
    <app-outlined-button
      mat-button
      color="primary"
      text="Save"
      iconSize="text-xl"
      #calculatePricesTooltip="matTooltip"
      [icon]="saveIcon"
      [disabled]="!treeStore.isDirty || isSaving || isEmpty || isEditPanelOpen"
      [matTooltip]="
        'Cannot save empty pricing strategy. Please add at least one node to your pricing strategy.'
      "
      [matTooltipDisabled]="!isEmpty"
      (clickEvent)="save()"
    >
    </app-outlined-button>
    <app-raised-button
      mat-button
      class="font-bold"
      color="primary"
      text="Calculate Prices"
      iconSize="text-xl"
      [iconAnimationClass]="isCalculatingPrices ? 'spinning-icon' : ''"
      [icon]="calculatePricesIcon"
      [disabled]="isCalculatingPrices || isEmpty || treeStore.isDirty"
      [matTooltip]="
        'Trigger the recalculation of price recommendations with the latest available market data.'
      "
      (clickEvent)="calculatePrices()"
    >
    </app-raised-button>
  </div>

  <div class="tree-wrapper">
    <app-peanut-tree [varsMap]="varsMap" [branchStats]="branchStats"></app-peanut-tree>
  </div>
  <app-edit-panel
    *ngIf="treeStore.selectedChild"
    [domains]="domains"
    [tags]="tags"
    [productProperties]="productProperties"
    [varsMap]="varsMap"
    [shopsByDomain]="shopsByDomain"
    (toggleCallback)="toggleEditPanelCallback($event)"
  ></app-edit-panel>
</div>

<div
  *ngIf="showFeatureMissingWarning"
  class="banner-margin-left sticky top-full z-10 bg-warn pl-4 text-white"
>
  <div class="pl-4 pb-3 leading-6">
    <mat-icon class="relative top-2">warning</mat-icon>
    Total market pricing is not enabled. Please enable it via Pricemonitor Controlpanel
  </div>
</div>
