<section class="sticky top-0 z-10 h-16 bg-white px-10 shadow-md">
  <div
    class="mx-auto flex h-full w-full items-center"
    [ngClass]="hasButton ? 'justify-between' : 'justify-end'"
  >
    <app-raised-button
      *ngIf="hasButton"
      class="pr-6"
      color="primary"
      icon="add"
      [matTooltip]="buttonTooltipMessage ? buttonTooltipMessage : ''"
      [matTooltipDisabled]="hasButtonTooltip"
      [matTooltipPosition]="'below'"
      [attr.data-external-id]="externalId"
      [text]="text"
      [disabled]="disabled"
      (clickEvent)="add()"
    ></app-raised-button>
    <mat-form-field *ngIf="hasSearch" appearance="standard">
      <mat-label>search</mat-label>
      <input matInput (keyup)="setQuery($event)" placeholder="search" />
      <mat-icon class="relative top-1" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</section>
