import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() disabled = false;

  @Input() placeholder: string = '';

  @Input() label?: string;

  @Input() hint?: string;

  @Input() value?: string;

  @Input() maxLength?: number;

  public onChange: (value: string) => void = () => {
    return;
  };

  public onTouched: () => void = () => {
    return;
  };

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setValue(event: KeyboardEvent): void {
    this.onChange((event.target as HTMLInputElement).value);
  }
}
