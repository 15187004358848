<div class="flex" *ngIf="priceCalculationDetails?.size">
  <div class="table" *ngIf="recommendationTags">
    <div class="table-header">Product Information</div>
    <table
      mat-table
      [dataSource]="recommendationTags"
      class="product-information-table table-with-scrollbar"
    >
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef class="w-2/4">Tag key</th>
        <td
          mat-cell
          *matCellDef="let tag"
          class="w-2/4"
          matTooltip="{{ tag.key }}"
          matTooltipPosition="left"
          matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
          #toolTip
        >
          {{ tag.key }}
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="w-2/4">Tag value</th>
        <td
          mat-cell
          *matCellDef="let tag"
          class="w-2/4"
          matTooltip="{{ tag.value }}"
          matTooltipPosition="left"
          matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
          #toolTip
        >
          {{ tag.value }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="tagsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tagsDisplayedColumns"></tr>
    </table>
  </div>

  <div class="table">
    <div class="table-header">Offers</div>
    <ng-container *ngIf="!recommendationOffers || recommendationOffers.length === 0">
      <span class="flex justify-center pt-1">No offers found</span>
    </ng-container>
    <ng-container *ngIf="recommendationOffers">
      <table
        mat-table
        [dataSource]="recommendationOffers"
        class="offers-table table-with-scrollbar"
      >
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef class="w-1/4">Shop name</th>
          <td
            mat-cell
            *matCellDef="let offer"
            class="w-1/4"
            matTooltip="{{ offer.vendorName }}"
            matTooltipPosition="left"
            matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
            #toolTip
          >
            {{ offer.vendorName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef class="w-1/4">Unit price</th>
          <td
            mat-cell
            *matCellDef="let offer"
            class="w-1/4"
            matTooltip="{{ offer.unitPrice }}"
            matTooltipPosition="left"
            matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
            #toolTip
          >
            {{ offer.unitPrice }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deliveryCosts">
          <th mat-header-cell *matHeaderCellDef class="w-1/4">Delivery cost</th>
          <td
            mat-cell
            *matCellDef="let offer"
            class="w-1/4"
            matTooltip="{{ offer.deliveryCosts }}"
            matTooltipPosition="left"
            matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
            #toolTip
          >
            {{ offer.deliveryCosts }}
          </td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef class="w-1/4">Domain</th>
          <td
            mat-cell
            *matCellDef="let offer"
            class="w-1/4"
            matTooltip="{{ offer.domain }}"
            matTooltipPosition="left"
            matTooltipDisabled="{{ !ifOverflow(toolTip) }}"
            #toolTip
          >
            {{ offer.domain }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="offersDisplayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: offersDisplayedColumns"
          [ngClass]="{ 'font-bold': shops?.get(row.domain)?.includes(row.vendorName) }"
        ></tr>
      </table>
    </ng-container>
  </div>
</div>
