import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Used to show error messages immediately since default behavior is to wait for blur.
 * See: https://material.angular.io/components/input/overview#changing-when-error-messages-are-shown
 */
export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}
