export const EXTERNAL_DROPPABLE_ELEMENT_PATH = 'EXTERNAL';

export const BRANCH_NAME_MAX_LENGTH = 70;
export const BRANCH_NAME_ROWS = 2;

export const DIALOG_SIZE = {
  width: '325px',
  height: '225px',
};

export const DIALOG_SIZE_XL = {
  width: '350px',
  height: '325px',
};

export const COMPARATOR_SYMBOL = {
  lessThan: '<',
  greaterThan: '>',
  equalTo: '=',
};

export const TREE_FIRST_LEVEL_PATH = 'children[0]';

export enum AttributeOptions {
  unitPrice = 'UNIT_PRICE',
  deliveryCosts = 'DELIVERY_COSTS',
}

// Default values for branchs that has no stats
export const branchStatsPlaceholder = {
  priceRecommendationsCount: 0,
  avgSellingPriceDifference: 0,
  avgSellingPriceDelta: 0,
  numberOfPriceIncreases: 0,
  numberOfPriceDecreases: 0,
};

export const DATE_FORMAT = 'dd/MM/yyyy, HH:mm';
