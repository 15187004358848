import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

const selectPricingStrategyFeature = ({ pricingStrategy }: AppState) => pricingStrategy;

export const selectStrategyApiData = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.apiData
);

export const selectRevertedApiStrategy = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.revertedApiStrategy
);

export const selectStrategyDomains = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.domains
);

export const selectStrategyTags = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.tags
);

export const selectStrategyVendorSettings = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.vendorSettings
);

export const selectShopsByDomain = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.shopsByDomain
);

export const selectStrategyMetadataHistory = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.strategyMetadataHistory
);

export const selectTaskData = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.taskData
);

export const selectProductData = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.productData
);

export const selectProductPriceRecommendation = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.productPriceRecommendation
);

export const selectProductOffers = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.productOffers
);

export const selectProductProperties = createSelector(
  selectPricingStrategyFeature,
  (strategy) => strategy.productProperties
);

export const selectVarsMap = createSelector(
  selectStrategyTags,
  selectProductProperties,
  (strategyTags, productProperties) => {
    const tagEntries =
      strategyTags?.map((tag) => [`<tag.${tag}>`, `TAG.${tag}`] as [string, string]) || [];
    const productPropertiesEntries =
      productProperties?.map(
        (property) => [`<property.${property}>`, `PROPERTY.${property}`] as [string, string]
      ) || [];
    return [...tagEntries, ...productPropertiesEntries];
  }
);
