import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBar as MatSnackBar,
} from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: unknown,
    public readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  public processLinks(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    const link = element.getAttribute('href');

    if (element.nodeName === 'A' && !link?.includes('//')) {
      e.preventDefault();
      void this.router.navigate([link]);
    }
  }
}
