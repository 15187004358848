<div class="node condition" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Condition</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <mat-icon class="strategy-type-icon float-left mr-1 text-xl">leaderboard</mat-icon>
    <div class="mt-0 truncate text-base font-bold">If competition</div>
    <div *ngIf="data">
      <div class="mt-1 pl-7 text-sm" *ngIf="data.competitorsMatcher">
        {{ comparatorSymbol[data.competitorsMatcher.operator] }}
        {{ data.competitorsMatcher.matchValue }} competitor{{
          data.competitorsMatcher.matchValue > 1 ? 's' : ''
        }}
      </div>
      <div
        class="mt-1 pl-7 text-sm"
        *ngIf="
          data.positionMatcher && data.positionMatcher.operator && data.positionMatcher.matchValue
        "
      >
        On position {{ comparatorSymbol[data.positionMatcher.operator] }}
        {{ data.positionMatcher.matchValue }}
      </div>
    </div>
  </div>
</div>
