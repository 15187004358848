import { Component, OnDestroy, OnInit, SkipSelf } from '@angular/core';

import { delay, Subscription } from 'rxjs';

import { compact, get } from 'lodash';

import { TREE_FIRST_LEVEL_PATH } from '../../constants';
import { PeanutTreeUI } from '../../PeanutTree';
import { PeanutTreeService } from '../../services/peanut-tree.service';

@Component({
  selector: 'app-execution-flow',
  templateUrl: './execution-flow.component.html',
  styleUrls: ['./execution-flow.component.scss'],
})
export class ExecutionFlowComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  isEnabled = false;

  position = {
    left: '0px',
    top: '0px',
    height: '0px',
    width: '0px',
  };

  constructor(@SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    this.subscription.add(
      this.treeStore.selectedChildPath$.pipe(delay(1)).subscribe((selectedChildPath) => {
        if (!selectedChildPath) {
          this.isEnabled = false;
          return;
        }
        const findParentWithNextSibling = (currentPath: string) => {
          const parentPath = currentPath.slice(0, currentPath.lastIndexOf('.'));
          const currentNode: PeanutTreeUI = get(this.treeStore.tree, currentPath);
          const parentNode: PeanutTreeUI | null = get(this.treeStore.tree, parentPath);
          const currentIndex =
            parentNode?.children && currentNode ? parentNode.children.indexOf(currentNode) : 0;
          if (
            currentPath === TREE_FIRST_LEVEL_PATH ||
            (parentNode && currentIndex + 1 < compact(parentNode.children).length)
          ) {
            const flowStartDOMRect = document
              .getElementById(selectedChildPath)
              ?.getBoundingClientRect();
            const flowEndDOMRect = document
              .getElementById(`${parentPath}.children[${currentIndex + 1}]`)
              ?.getBoundingClientRect();
            if (!flowStartDOMRect || !flowEndDOMRect) {
              this.isEnabled = false;
              return;
            }
            this.isEnabled = true;
            const treeElement = document.getElementsByTagName('app-peanut-tree')[0];
            const treeElementDOMRect = treeElement.getBoundingClientRect();
            this.position = {
              top: flowStartDOMRect.bottom + treeElement.scrollTop - treeElementDOMRect.y + 'px',
              left: flowEndDOMRect.left + treeElement.scrollLeft - treeElementDOMRect.x + 'px',
              width: flowStartDOMRect.right - flowEndDOMRect.left + 'px',
              height: flowEndDOMRect.top - flowStartDOMRect.bottom + 'px',
            };
          } else {
            findParentWithNextSibling(parentPath);
          }
        };
        findParentWithNextSibling(selectedChildPath);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
