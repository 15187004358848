import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { IfRecommendedPriceStrategy } from '../../../ui-strategy-elements/conditions/conditions';
import { NumericComparator } from '../../../ui-strategy-elements/strategy-types';
import {
  FormulaValidation,
  formulaValidator,
  getInvalidTypeErrorMessage,
} from '../FormulaValidation';

@Component({
  selector: 'app-if-recommended-form',
  templateUrl: './if-recommended-form.component.html',
  styleUrls: ['./if-recommended-form.component.scss'],
})
export class IfRecommendedFormComponent
  extends CompetitorVariableDirective
  implements OnChanges, OnInit
{
  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  readonly formulaValidator = formulaValidator;

  readonly NumericComparator = NumericComparator;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() data: IfRecommendedPriceStrategy | undefined;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  formulaValidation = new FormulaValidation();

  ifRecommendedForm = new UntypedFormGroup({
    operator: new UntypedFormControl(NumericComparator.LESS),
    formula: new UntypedFormControl(null),
    searchVar: new UntypedFormControl(null),
  });

  isVarsShown = false;

  formula = this.ifRecommendedForm.get('formula');

  operator = this.ifRecommendedForm.get('operator');

  searchVar = '';

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  ngOnInit() {
    this.formula?.addValidators(formulaValidator(this.vars));
  }

  ngOnChanges() {
    this.formula?.setValue(this.formatFormulaVarsPipe.transform(this.data?.formula, this.varsMap));
    if (this.data?.operator) {
      this.operator?.setValue(this.data.operator);
    }
  }

  save() {
    if (this.formula?.value)
      this.formula.setValue(
        this.formatFormulaVarsPipe.transform(this.formula.value, this.varsMap, true)
      );
    this.saveCallback.emit(this.ifRecommendedForm.value);
  }
}
