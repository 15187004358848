<app-card class="relative my-5 py-0">
  <section class="flex h-24 justify-between">
    <div class="flex items-center">
      <img
        *ngIf="shopSummaryOptions.logoSrc; else placeholderImg"
        [src]="shopSummaryOptions.logoSrc"
        (click)="selectStep()"
        class="mr-5 h-12 w-12 cursor-pointer"
      />
      <ng-template #placeholderImg>
        <div
          id="placeholderLogo"
          class="mr-5 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-400 text-2xl text-white"
          (click)="selectStep()"
        >
          {{ shopSummaryOptions.displayName[0] | titlecase }}
        </div>
      </ng-template>
      <div class="mr-4 grid gap-1">
        <h3 class="row-start-1 m-0 text-xl">
          {{ shopSummaryOptions.displayName }}
        </h3>
        <h4 *ngIf="shopSummaryOptions.shopName" class="row-start-2 m-0 text-gray-400">
          {{ shopSummaryOptions.shopName }}
        </h4>
      </div>
    </div>
    <div
      *ngIf="isInProgress || !shopStatus"
      class="mx-6 self-center text-base font-light text-accent"
    >
      {{ shopStatus || 'Loading' | titlecase }}&hellip;
    </div>
    <div *ngIf="shopStatus && !isInProgress" class="flex items-center gap-4">
      <app-delimited-items-list [items]="delimitedItems"></app-delimited-items-list>
      <span
        class="rounded-2xl px-4 py-1 text-sm font-bold"
        [ngClass]="{
          'bg-success': shopStatus === 'success',
          'bg-warn': shopStatus === 'warning',
          'bg-error': shopStatus === 'error',
          'bg-processing': shopStatus === 'processing',
          'text-white':
            shopStatus === 'warning' ||
            shopStatus === 'success' ||
            shopStatus === 'error' ||
            shopStatus === 'processing'
        }"
      >
        {{ shopStatus | titlecase }}
      </span>
      <app-basic-button
        id="showDetailsButton"
        class="pr-3"
        *ngIf="shopSummaryOptions.showInfoButton"
        text="More info"
        [hasIconAfterText]="true"
        [icon]="areDetailsShown ? 'expand_less' : 'expand_more'"
        [color]="'primary'"
        (clickEvent)="showStatusDetails()"
      ></app-basic-button>
    </div>
  </section>

  <div *ngIf="shopStatus === 'error' && shopSummaryOptions.errorMessage" class="py-2">
    <span class="font-bold">Message: </span>
    {{ shopSummaryOptions.errorMessage }}
  </div>

  <ng-container *ngIf="areDetailsShown">
    <div
      [attr.details-index]="i"
      *ngFor="let details of shopSummaryOptions.details; let i = index"
      class="py-3"
    >
      <span class="mx-5 font-bold">{{ details?.timecreated | date : 'dd/MM/yyyy HH:mm' }}</span>
      <span id="externalDescription">{{ details?.externaldescription }}</span>
    </div>
  </ng-container>

  <app-floating-select
    *ngIf="actionItems.length"
    class="absolute top-0 right-0"
    [isIconButton]="true"
    (selected)="selectedAction($event)"
    [items]="actionItems"
  ></app-floating-select>

  <div
    class="shop-summary-actions flex items-center justify-between border-0 border-t border-solid pt-2 pb-2"
  >
    <ng-content select="[stepsList]"></ng-content>
    <span class="ml-auto flex items-center">
      <div class="mx-3 mt-1 text-gray-400" *ngIf="shopSummaryOptions.hasTopX">Top X</div>
      <ng-content *ngIf="!isInProgress" select="[toggle]"></ng-content>
      <ng-content select="[buttons]"></ng-content>
    </span>
  </div>

  <mat-card-footer *ngIf="isInProgress" class="pt-3">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</app-card>
