import { Component, Input } from '@angular/core';

import {
  ProductTag,
  RecommendationOffers,
} from '@app/models/interfaces/price-recommendations.interface';

@Component({
  selector: 'app-explain-why-tables',
  templateUrl: './explain-why-tables.component.html',
  styleUrls: ['./explain-why-tables.component.scss'],
})
export class ExplainWhyTablesComponent {
  @Input() priceCalculationDetails?: Map<string, string>;

  @Input() recommendationTags?: ProductTag[];

  @Input() recommendationOffers?: RecommendationOffers[];

  tagsDisplayedColumns: string[] = ['key', 'value'];

  offersDisplayedColumns: string[] = ['vendorName', 'unitPrice', 'deliveryCosts', 'domain'];

  @Input() shops?: Map<string, string[]>;

  ifOverflow(e: HTMLTableCellElement) {
    return e.scrollWidth > e.clientWidth;
  }
}
