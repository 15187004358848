<button
  mat-icon-button
  class="flex items-center justify-center"
  type="button"
  [disabled]="disabled"
  [color]="color"
  (click)="click($event)"
>
  <mat-icon class="{{ iconSize }} flex justify-center items-center">{{ icon }}</mat-icon>
</button>
