<div class="line"></div>
<div class="ball"></div>

<mat-card *ngIf="stats" class="ml-12">
  <h5 class="mb-2.5 text-base">Execution</h5>
  <div class="flex leading-4">
    <span class="stat-title w-4/5 font-bold">Price recommendations</span>
    <span class="stat-value w-1/5 text-right">{{ stats.priceRecommendationsCount }}</span>
  </div>
  <div class="stat-details-title my-1 mr-10">Comparison to selling price</div>
  <div class="flex leading-4">
    <span class="stat-title w-4/5 font-bold">Avg. difference</span>
    <span class="stat-value w-1/5 text-right">{{
      stats.priceRecommendationsCount ? stats.avgSellingPriceDifference : 'N/A'
    }}</span>
  </div>
  <div class="flex leading-4">
    <span class="stat-title w-3/5 font-bold">Avg. delta</span>
    <span class="stat-value w-2/5 text-right">{{
      stats.priceRecommendationsCount ? (stats.avgSellingPriceDelta | percent : '1.2-2') : 'N/A'
    }}</span>
  </div>
  <div class="flex leading-4">
    <span class="stat-title w-4/5 font-bold">Price increases</span>
    <span class="stat-value w-1/5 text-right">{{
      stats.priceRecommendationsCount ? stats.numberOfPriceIncreases : 'N/A'
    }}</span>
  </div>
  <div class="flex leading-4">
    <span class="stat-title w-4/5 font-bold">Price decreases</span>
    <span class="stat-value w-1/5 text-right">{{
      stats.priceRecommendationsCount ? stats.numberOfPriceDecreases : 'N/A'
    }}</span>
  </div>
</mat-card>
