import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subscription } from 'rxjs';

import { compact } from 'lodash';

import {
  DIALOG_SIZE,
  EXTERNAL_DROPPABLE_ELEMENT_PATH,
  TREE_FIRST_LEVEL_PATH,
} from '../../constants';
import { BranchStats } from '../../models';
import { PeanutTreeUI } from '../../PeanutTree';
import { PeanutTreeService } from '../../services/peanut-tree.service';
import { UIStrategyType } from '../../ui-strategy-elements/strategy-types';
import { CopyOrMoveDialogComponent } from '../copy-or-move-dialog/copy-or-move-dialog.component';

@Component({
  selector: 'app-peanut-tree',
  templateUrl: './peanut-tree.component.html',
  styleUrls: ['./peanut-tree.component.scss'],
})
export class PeanutTreeComponent implements OnInit, OnDestroy {
  @Input() varsMap: Map<string, string> = new Map();

  @Input() focusedNodeIds?: number[];

  /**
   * Holds tree Branch Statistics, maps leaf node id to BranchStats
   */
  @Input() branchStats?: Map<number, BranchStats>;

  @Input() isDraggingEnabled?: boolean = true;

  @Input() isDeleteingEnabled = true;

  isStartNodeClosed = true;

  isDragging = false;

  compact = compact;

  uiStrategyType = UIStrategyType;

  subscription = new Subscription();

  TREE_FIRST_LEVEL_PATH = TREE_FIRST_LEVEL_PATH;

  constructor(public dialog: MatDialog, @SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    this.subscription.add(
      this.treeStore.isDragging$.subscribe((isDragging) => (this.isDragging = isDragging))
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  copyOrMoveNode = (target: PeanutTreeUI, index?: number) => {
    if (this.treeStore.draggedChild?.path === EXTERNAL_DROPPABLE_ELEMENT_PATH) {
      this.treeStore.copyOrMoveNode(target, false, index);
      return;
    }
    const dialogRef = this.dialog.open(CopyOrMoveDialogComponent, DIALOG_SIZE);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      this.treeStore.copyOrMoveNode(target, !result, index);
    });
  };
}
