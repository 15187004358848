import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { isBoolean } from 'lodash';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { PriceFormulaStrategy } from '../../../ui-strategy-elements/price-formula/price-formula';
import {
  FormulaValidation,
  formulaValidator,
  getInvalidTypeErrorMessage,
} from '../FormulaValidation';

@Component({
  selector: 'app-price-formula-form',
  templateUrl: './price-formula-form.component.html',
  styleUrls: ['./price-formula-form.component.scss'],
})
export class PriceFormulaFormComponent extends CompetitorVariableDirective implements OnChanges {
  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() data: PriceFormulaStrategy | undefined;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  formulaValidation = new FormulaValidation();

  priceFormulaForm = new UntypedFormGroup({
    priceFormula: new UntypedFormControl(null),
    priceRequirement: new UntypedFormControl(null, Validators.required),
    searchVar: new UntypedFormControl(null),
  });

  isVarsShown = false;

  formula = this.priceFormulaForm.get('priceFormula');

  requirement = this.priceFormulaForm.get('priceRequirement');

  isFormulaEnabled = false;

  isRequirementEnabled = false;

  searchVar = '';

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  toggleFormula() {
    this.isFormulaEnabled = !this.isFormulaEnabled;
    this.formula?.setValue(this.isFormulaEnabled ? null : undefined);
    this.priceFormulaForm.markAsDirty();
  }

  toggleRequirement() {
    this.isRequirementEnabled = !this.isRequirementEnabled;
    this.requirement?.setValue(this.isRequirementEnabled ? null : undefined);
    this.priceFormulaForm.markAsDirty();
  }

  ngOnChanges() {
    this.formula?.setValidators(formulaValidator(this.vars));
    if (this.data?.priceFormula) {
      this.isFormulaEnabled = true;
      this.formula?.setValue(
        this.formatFormulaVarsPipe.transform(this.data.priceFormula, this.varsMap)
      );
    } else {
      this.isFormulaEnabled = this.data?.priceFormula === null;
      this.formula?.setValue(this.isFormulaEnabled ? null : undefined);
    }
    if (isBoolean(this.data?.priceRequirement)) {
      this.isRequirementEnabled = true;
      this.requirement?.setValue(this.data?.priceRequirement);
    } else {
      this.isRequirementEnabled = this.data?.priceRequirement === null;
      this.requirement?.setValue(this.isRequirementEnabled ? null : undefined);
    }
  }

  save() {
    if (this.formula?.value)
      this.formula.setValue(
        this.formatFormulaVarsPipe.transform(this.formula.value, this.varsMap, true)
      );
    this.saveCallback.emit(this.priceFormulaForm.value);
  }
}
