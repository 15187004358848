import { Directive, ElementRef, Input, OnInit, SkipSelf } from '@angular/core';

import { PeanutTreeUI } from '../PeanutTree';
import { PeanutTreeService } from '../services/peanut-tree.service';

@Directive({
  selector: '[qDraggable]',
})
export class DraggableDirective implements OnInit {
  @Input() index!: number;

  @Input() parent = new PeanutTreeUI();

  @Input() child = new PeanutTreeUI();

  constructor(private _elementRef: ElementRef, @SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    const element: HTMLDivElement | null =
      this._elementRef.nativeElement.querySelector('.node-draggable');
    if (!element) return;
    element.draggable = true;

    element.addEventListener('dragstart', (event: DragEvent) => {
      element.classList.add('drag-src');
      document.documentElement.classList.add('is-dragging');
      this.treeStore.selectedChild = undefined;
      this.treeStore.draggedParent = this.parent;
      this.treeStore.draggedChild = this.child;
      this.treeStore.draggedChildIndex = this.index;
      this.treeStore.isDragging = true;
      if (!event.dataTransfer) return;
      event.dataTransfer.effectAllowed = 'move';
      if (element.classList.contains('strategy-type')) return;
      const draggedElement = element.cloneNode(true) as HTMLDivElement;
      draggedElement.classList.add('dragged');
      if (this.child.children.length) {
        draggedElement.classList.add('with-children');
        let totalChildren = 0;
        const countChildren = (node: PeanutTreeUI) => {
          node.children?.forEach((child) => {
            totalChildren += 1;
            if (child.children.length) countChildren(child);
          });
        };
        countChildren(this.child);
        draggedElement.dataset['children'] = totalChildren.toString();
      }
      document.body.appendChild(draggedElement);
      event.dataTransfer.setDragImage(draggedElement, 110, 55);
    });

    element.addEventListener('dragend', (event: DragEvent) => {
      event.preventDefault();
      document.documentElement.classList.remove('is-dragging');
      document.querySelector('.dragged')?.remove();
      element.classList.remove('drag-src');
      this.treeStore.isDragging = false;
    });
  }
}
