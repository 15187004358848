import {
  Pagination,
  PriceRecommendationApiQueryV2,
  ProductOffersApiQuery,
  TimeRange,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { QueryFilterType } from '@app/models/enum/api-query-filter.enum';
import { ApiQueryFilter } from '@app/utils/query-filter/api-query-filter';

/**
 * Create a query to request a specific price recommendation
 * @param productId unique id of a product
 * @param timestamp
 * @param pagination query pagination including start and limit
 * @returns Api query
 */
export function createPriceRecommendationQuery(
  productId: string,
  timestamp: string,
  pagination: Pagination
): PriceRecommendationApiQueryV2 {
  const service: ApiQueryFilter = new ApiQueryFilter();
  return {
    pagination: pagination,
    range: {
      start: timestamp,
      end: timestamp,
    },
    filter: service.createQueryFilter('productId', QueryFilterType.STRING, productId),
  };
}

/**
 * Create a query to request a specific product query
 * @param contractId
 * @param productId unique id of a product
 * @param minTimestamp
 * @param maxTimestamp
 * @param pagination query pagination including start and limit
 * @returns
 */
export function createProductOffersQuery(
  contractId: number,
  productId: string,
  range: TimeRange,
  pagination: Pagination
): ProductOffersApiQuery {
  const service: ApiQueryFilter = new ApiQueryFilter();
  const productFilter = service.createQueryFilter('productId', QueryFilterType.STRING, productId);
  const ignoreBlackListOffersFilter = service.notFilter(
    service.createQueryFilter('ignoredBy', QueryFilterType.NUMBER, contractId)
  );

  return {
    pagination,
    range,
    filter: service.andFilter([productFilter, ignoreBlackListOffersFilter]),
  };
}

/**
 * Create time range for query where end is one second after the provided maxTimestamp
 * @param minTimestamp
 * @param maxTimestamp
 * @returns time range with start and end timestamps in UTC timezone, or
 *  returns 'undefined' when min or maxTimestamp are empty strings
 */
export function createQueryTimeRange(
  minTimestamp: string,
  maxTimestamp: string
): TimeRange | undefined {
  if (!minTimestamp || !maxTimestamp) {
    return undefined;
  }

  // Why add 1 second?
  // We didnt find a way to pass milliseconds correctly from looker in the URL.
  // Therefore, we add 1 second to ensure getting needed offers and because it is highly unlikely that there are undesired offers in the same millisecond
  const nextSecondTimestamp = new Date(maxTimestamp);
  nextSecondTimestamp.setMilliseconds(0);
  nextSecondTimestamp.setSeconds(nextSecondTimestamp.getSeconds() + 1);
  return {
    start: minTimestamp,
    end: nextSecondTimestamp.toISOString(),
  };
}
