import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { LoaderService } from '@services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.25s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('.25s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LegacyLoaderComponent {
  public readonly loader$ = this.loaderService.showLoader$;

  constructor(private readonly loaderService: LoaderService) {}
}
