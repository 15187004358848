import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { isNil, isNumber } from 'lodash';

import { IfCompetitionStrategy } from '../../../ui-strategy-elements/conditions/conditions';
import { NumericComparator } from '../../../ui-strategy-elements/strategy-types';

const competitorsValueValidator = (control: AbstractControl) =>
  isNumber(control.value) && control.value >= 0 ? null : { error: 'error' };

const positionValueValidator = (form: AbstractControl) => {
  const operator = form.get('positionMatcher.operator');
  const value = form.get('positionMatcher.matchValue');
  if (!value || !operator) return null;
  if (
    operator.value === undefined &&
    (isNil(value.value) || (isNumber(value.value) && value.value >= 0))
  ) {
    value.setErrors(null);
  } else if (!isNumber(value.value) || value.value < 0) {
    value.setErrors({ error: 'error' });
  }
  return null;
};

@Component({
  selector: 'app-if-competition-form',
  templateUrl: './if-competition-form.component.html',
  styleUrls: ['./if-competition-form.component.scss'],
})
export class IfCompetitionFormComponent implements OnChanges, OnInit, OnDestroy {
  readonly NumericComparator = NumericComparator;

  @Input() data: IfCompetitionStrategy | undefined;

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  ifCompetitionForm = new UntypedFormGroup(
    {
      competitorsMatcher: new UntypedFormGroup({
        operator: new UntypedFormControl(NumericComparator.EQUAL, Validators.required),
        matchValue: new UntypedFormControl(undefined, competitorsValueValidator),
      }),
      positionMatcher: new UntypedFormGroup({
        operator: new UntypedFormControl(NumericComparator.EQUAL),
        matchValue: new UntypedFormControl(undefined),
      }),
    },
    positionValueValidator
  );

  competitorsOperator = this.ifCompetitionForm.get('competitorsMatcher.operator');

  competitorsValue = this.ifCompetitionForm.get('competitorsMatcher.matchValue');

  positionOperator = this.ifCompetitionForm.get('positionMatcher.operator');

  positionValue = this.ifCompetitionForm.get('positionMatcher.matchValue');

  isPositionValueDisabled = false;

  subscription = new Subscription();

  togglePositionValue(operator: NumericComparator | undefined) {
    this.isPositionValueDisabled = operator === undefined;
    if (this.isPositionValueDisabled) this.positionValue?.disable();
    else this.positionValue?.enable();
  }

  ngOnInit() {
    this.subscription.add(
      this.positionOperator?.valueChanges.subscribe((operator) => {
        this.togglePositionValue(operator);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    this.togglePositionValue(this.data?.positionMatcher?.operator);

    this.competitorsValue?.setValue(this.data?.competitorsMatcher?.matchValue);
    this.positionValue?.setValue(this.data?.positionMatcher?.matchValue);
    this.competitorsOperator?.setValue(
      this.data?.competitorsMatcher?.operator || NumericComparator.EQUAL
    );
    this.positionOperator?.setValue(
      this.isPositionValueDisabled ? undefined : this.data?.positionMatcher?.operator || undefined
    );
  }
}
