<div
  class="box-border flex h-24 w-full flex-row items-stretch border-0 border-b-1 border-solid border-gray-200 bg-white"
>
  <div class="mr-auto flex flex-row items-center p-8">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <span class="pl-2 capitalize" [innerHTML]="headerSteps"> </span>
  </div>
  <app-contract-switcher
    *ngIf="contractSwitcherData"
    [action]="contractSwitcherAction"
    [companies]="contractSwitcherData.contractsData"
    [current]="contractSwitcherData.currentItem"
  ></app-contract-switcher>
  <div
    *ngIf="email"
    class="box-border flex h-full flex-row items-center border-0 border-l-1 border-solid border-gray-200 p-8"
  >
    {{ email }}
  </div>
</div>
