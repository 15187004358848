import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleComponent,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() color: 'undefined' | 'primary' | 'accent' | 'warn' = 'undefined';

  @Input() label?: string;

  @Input() labelPosition: 'before' | 'after' = 'before';

  @Input() class?: string;

  @Output() valueChange = new EventEmitter<boolean>();

  @Input() toggled?: boolean;

  private cdRef = inject(ChangeDetectorRef);

  public disabled?: boolean;

  public onChange: (value?: boolean) => void = () => {
    return;
  };

  public onTouched: () => void = () => {
    return;
  };

  public setValue(event: MatSlideToggleChange): void {
    if (!this.disabled) {
      this.onChange(event.checked);
      this.onTouched();
      this.valueChange.emit(event.checked);
    }
  }

  public writeValue(state: boolean): void {
    this.toggled = state;
    this.cdRef.detectChanges();
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
