<app-condition-form-header></app-condition-form-header>
<div class="content-subheader">
  <mat-icon class="strategy-type-icon if-competition">leaderboard</mat-icon>
  <div class="legend">
    <h3>If Competition</h3>
    <p>
      Only if there is a certain number of competitors, the following strategy elements (further
      right) should be applied.
    </p>
  </div>
</div>
<div class="form" [formGroup]="ifCompetitionForm">
  <h3>Number of Competitors</h3>
  <div formGroupName="competitorsMatcher">
    <div class="radio-group">
      <div class="radio">
        <input
          id="competitorsGreater"
          type="radio"
          [value]="NumericComparator.GREATER"
          formControlName="operator"
        />
        <label for="competitorsGreater">Greater than (>)</label>
      </div>
      <div class="radio">
        <input
          id="competitorsEqual"
          type="radio"
          [value]="NumericComparator.EQUAL"
          formControlName="operator"
        />
        <label for="competitorsEqual">Equal to (=)</label>
      </div>
      <div class="radio">
        <input
          id="competitorsLess"
          type="radio"
          [value]="NumericComparator.LESS"
          formControlName="operator"
        />
        <label for="competitorsLess">Less than (<)</label>
      </div>
    </div>
    <input type="number" min="0" step="1" placeholder="Enter value" formControlName="matchValue" />
    <div class="error" *ngIf="competitorsValue?.errors">
      Value must be specified correctly. (Only numbers and must not be empty)
    </div>
  </div>

  <h3>Restricted to position</h3>
  <div formGroupName="positionMatcher">
    <div class="radio-group">
      <div class="radio">
        <input
          id="positionAny"
          type="radio"
          [value]="undefined"
          (click)="positionValue?.setValue(undefined)"
          formControlName="operator"
        />
        <label for="positionAny">Any</label>
      </div>
      <div class="radio">
        <input
          id="positionGreater"
          type="radio"
          [value]="NumericComparator.GREATER"
          formControlName="operator"
        />
        <label class="whitespace-nowrap" for="positionGreater">Greater than (>)</label>
      </div>
      <div class="radio">
        <input
          id="positionEqual"
          type="radio"
          [value]="NumericComparator.EQUAL"
          formControlName="operator"
        />
        <label class="whitespace-nowrap" for="positionEqual">Equal to (=)</label>
      </div>
      <div class="radio">
        <input
          id="positionLess"
          type="radio"
          [value]="NumericComparator.LESS"
          formControlName="operator"
        />
        <label class="whitespace-nowrap" for="positionLess">Less than (<)</label>
      </div>
    </div>
    <input
      type="number"
      min="0"
      step="1"
      [placeholder]="isPositionValueDisabled ? 'n/a' : 'Enter value'"
      formControlName="matchValue"
    />
    <div class="error" *ngIf="positionValue?.errors">
      Value must be specified correctly. (Only numbers and must not be empty)
    </div>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          competitorsOperator?.errors ||
          competitorsValue?.errors ||
          positionOperator?.errors ||
          positionValue?.errors ||
          !ifCompetitionForm.dirty
        "
        (click)="saveCallback.emit(ifCompetitionForm.value)"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
