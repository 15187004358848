<ng-container *ngIf="child">
  <textarea
    placeholder="Add a rule name here"
    maxlength="{{ branchNameMaxLength }}"
    [ngClass]="{
      'has-two-lines': child.data?.ruleName?.length >= branchNameMaxLength / branchNameCols
    }"
    [(ngModel)]="child.data.ruleName"
    (keyup)="handleKeyUp()"
  ></textarea>
</ng-container>
