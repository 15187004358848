import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyFilter',
})
export class LegacyFilterPipe implements PipeTransform {
  transform(items: { [key: string]: string }[], searchText: string): { [key: string]: string }[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    const result: any[] = [];
    items.forEach((element) => {
      for (const key in element) {
        if (!result.find((x) => x === element)) {
          if (element[key]) {
            if (element[key].toString().toLowerCase().includes(searchText.toLowerCase())) {
              if (key !== 'valuetype') {
                result.push(element);
                continue;
              }
            }
          }
        }
      }
    });
    return result;
  }
}
