<div class="content-header">
  <h2>Price Formula</h2>
  <p>Configure how Omnia should calculate formula-based price recommendations</p>
</div>

<div class="form" [formGroup]="priceFormulaForm">
  <div class="optional-group">
    <input type="checkbox" [checked]="isFormulaEnabled" (change)="toggleFormula()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">calculate</mat-icon>
        Price Formula
      </h3>
      <p>
        Here, you can configure formulas that define how Omnia's price recommendations will be
        calculated
      </p>
      <div class="inputs" *ngIf="isFormulaEnabled">
        <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="priceFormula" />
        <div class="error" *ngIf="formula?.errors">
          <span *ngIf="formula?.errors?.['invalidType']">
            {{getInvalidTypeErrorMessage(formula?.errors?.['invalidType'])}}
          </span>
          <span *ngIf="formula?.errors?.['invalidItem']">
            {{formula?.errors?.['invalidItem']}}
          </span>
        </div>
        <div class="suggested-vars-link" (click)="isVarsShown = !isVarsShown">
          <span>Available variables</span>
          <mat-icon *ngIf="!isVarsShown">arrow_drop_down</mat-icon>
          <mat-icon *ngIf="isVarsShown">arrow_drop_up</mat-icon>
        </div>
        <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isVarsShown }">
          <div class="suggested-var-filter">
            <input
              type="text"
              formControlName="searchVar"
              [(ngModel)]="searchVar"
              placeholder="Search available variables"
            />
            <button
              class="search-button {{ searchVar ? 'is-clickable' : '' }}"
              (click)="searchVar = ''"
            >
              <mat-icon *ngIf="searchVar === ''">search</mat-icon>
              <mat-icon *ngIf="searchVar">clear</mat-icon>
            </button>
          </div>
          <ul class="suggested-vars">
            <li
              *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchVar"
              [ngSwitch]="variable"
            >
              <div *ngSwitchCase="'COMPETITOR'">
                <app-competitor-variable
                  class="suggested-var"
                  (getCompetitorVariable)="setCompetitorVariable($event, formula)"
                ></app-competitor-variable>
              </div>

              <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

              <div
                *ngSwitchDefault
                class="suggested-var"
                (click)="
                  formula?.setValue((formula?.value || '') + variable); formula?.markAsDirty()
                "
              >
                {{ variable }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="optional-group">
    <input type="checkbox" [checked]="isRequirementEnabled" (change)="toggleRequirement()" />
    <div>
      <h3>
        <mat-icon class="strategy-type-icon">fact_check</mat-icon>
        Price Formula Requirements
      </h3>
      <p>
        Define if the formula will be applied to your products independently of the market situation
        or not.
      </p>
      <div class="inputs" *ngIf="isRequirementEnabled">
        <select formControlName="priceRequirement">
          <option [ngValue]="true">Always recommend price</option>
          <option [ngValue]="false">Only if at least one offer is found</option>
        </select>
        <div class="error" *ngIf="requirement?.errors">
          Price Formula Requirement must be specified
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        color="primary"
        [disabled]="
          (!isFormulaEnabled && !isRequirementEnabled) ||
          (isFormulaEnabled && formula?.errors) ||
          (isRequirementEnabled && requirement?.errors) ||
          !priceFormulaForm.dirty
        "
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
