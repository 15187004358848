<div class="content-header">
  <h2>Save New Strategy Version</h2>
  <label class="block pb-4 text-center text-xl"> Would you like to add a comment? </label>
</div>

<div class="p-2">
  <mat-form-field class="w-full">
    <input matInput [(ngModel)]="message" [maxlength]="messaseMaxLength" placeholder="Comment" />
    <mat-hint>{{ message.length }}/{{ messaseMaxLength }}</mat-hint>
  </mat-form-field>
  <div mat-dialog-actions class="justify-center">
    <app-basic-button
      text="Cancel"
      [hasIconAfterText]="false"
      [color]="'primary'"
      (clickEvent)="dialogRef.close()"
    >
    </app-basic-button>
    <app-basic-button
      text="Save"
      [hasIconAfterText]="false"
      [color]="'primary'"
      [isRaisedButton]="true"
      [mat-dialog-close]="message"
    >
    </app-basic-button>
  </div>
</div>
