<div
  class="banner fixed z-20"
  [ngClass]="'banner-' + type + ' ' + size"
  [@fadeInOut]
  [attr.role]="'banner'"
>
  <div class="banner-content">
    <div class="banner-messages">
      <p *ngFor="let message of messages; index as i" class="banner-message">
        <span [attr.data-testid]="'message-' + (i + 1)" [innerHTML]="message"> </span>
      </p>
    </div>

    <ng-content [select]="'buttons'"></ng-content>
  </div>
</div>
