import { Component, Input } from '@angular/core';

import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-outlined-button',
  templateUrl: '../base-button/base-button.component.html',
})
export class OutlinedButtonComponent extends BaseButtonComponent {
  @Input() override isOutlinedButton = true;
}
