<div
  [ngClass]="{
    selected: isSelected,
    condition: child.data?.strategyType | isConditionNode
  }"
>
  <div class="line"></div>
  <div class="ball"></div>
  <h5>Execution</h5>
</div>
