<div class="drop-wrapper">
  <div class="inline-block">
    <div class="node-draggable" #node [id]="child.path" (click)="treeStore.selectedChild = child">
      <div
        class="drop drop-branch-first"
        *ngIf="parent.path === TREE_FIRST_LEVEL_PATH"
        qDroppable
        [index]="index"
        [parent]="parent"
        [child]="child"
        (dropped)="copyOrMoveToFirst()"
      >
        <mat-icon class="connector-true">arrow_right</mat-icon>
      </div>
      <ng-container [ngSwitch]="child.data?.strategyType">
        <app-if-tag-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.IF_TAG"
        ></app-if-tag-node>
        <app-if-timestamp-range-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.IF_TIMESTAMP_RANGE"
        ></app-if-timestamp-range-node>
        <app-if-recommended-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [varsMap]="varsMap"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.IF_RECOMMENDED_PRICE"
        ></app-if-recommended-node>
        <app-if-formula-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [varsMap]="varsMap"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.IF_FORMULA"
        ></app-if-formula-node>
        <app-if-competition-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.IF_COMPETITOR"
        ></app-if-competition-node>
        <app-competitive-pricing-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [varsMap]="varsMap"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.COMPETITIVE_PRICING"
        ></app-competitive-pricing-node>
        <app-price-formula-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [varsMap]="varsMap"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.PRICE_FORMULA"
        ></app-price-formula-node>
        <app-safety-rule-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [varsMap]="varsMap"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.SAFETY_RULE"
        ></app-safety-rule-node>
        <app-stock-clearance-node
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [varsMap]="varsMap"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeletingEnabled]="isDeleteingEnabled"
          *ngSwitchCase="uiStrategyType.STOCK_CLEARANCE"
        ></app-stock-clearance-node>
        <app-unknown-node
          *ngSwitchDefault
          (deleteCallback)="deleteNode($event)"
          [data]="child.data"
          [isSelected]="isSelected"
          [isFocused]="isFocused"
          [isDeleteingEnabled]="isDeleteingEnabled"
        ></app-unknown-node>
      </ng-container>
    </div>
    <div
      class="drop drop-false"
      qDroppable
      *ngIf="parent.path !== TREE_FIRST_LEVEL_PATH && (child.data?.strategyType | isConditionNode)"
      [index]="index"
      [parent]="parent"
      [child]="child"
      (dropped)="copyOrMoveNode(false)"
    ></div>
  </div>
  <div
    class="select-legend"
    [ngClass]="{
      hide: !isSelected
    }"
  >
    <span *ngIf="child.data?.strategyType | isConditionNode">true</span>
    <span *ngIf="!(child.data?.strategyType | isConditionNode)">continue</span>
    <mat-icon class="connector-true">arrow_right</mat-icon>
  </div>
  <div
    class="drop drop-true"
    qDroppable
    [index]="index"
    [parent]="parent"
    [child]="child"
    (dropped)="copyOrMoveNode(true)"
  ></div>
</div>
