import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyFilterbykey',
  pure: false,
})
export class LegacyFilterByKeyPipe implements PipeTransform {
  transform(items: { [key: string]: string }[], searchText: string, searchKey?: string): any {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return searchKey
      ? items.filter((item) => item[searchKey]?.toLowerCase().includes(searchText))
      : items.filter((item) => JSON.stringify(item)?.toLowerCase().includes(searchText));
  }
}
