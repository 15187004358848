/**********************
 * Api Strategy types
 *********************/

export enum ApiPriceGapUnit {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export enum ApiPriceCalculationType {
  TARGET_POSITION = 'TargetPosition',
  FORMULA = 'Formula',
  TARGET_LEVEL_STOCK_CLEARANCE = 'TargetLevelPricingStockClearance',
}

export enum ApiComparator {
  LESS = 'lessThan',
  GREATER = 'greaterThan',
  EQUAL = 'equalTo',
}

export enum ApiStrategyName {
  TAG_BASED = 'TagBasedFilter',
  COMPETITOR = 'CompetitorsOnPosition',
  FORMULA_PRICE_FILTER = 'FormulaPriceFilter',
  FORMULA_COMPARISON = 'FormulaComparison',
  PRICE_FORMULA = 'Formula',
  SERIAL = 'Serial',
  STRATEGY_CONTEXT_MODIFIERS = 'StrategyContextModifiers',
  PRICE_CALCULATION = 'PriceCalculation',
  FILTERS = 'StrategyFilters',
}

type ObjectValues<T> = T[keyof T];

// In the future it will contain more conditions to expand the generic strategy filters
export const API_CONDITIONS = {
  STRING_COMPARISION: 'stringComparison',
  TIMESTAMP_RANGE: 'timestampRange',
} as const;
export type ApiConditions = ObjectValues<typeof API_CONDITIONS>;

export const API_QUERY_TYPES = {
  AND: 'and',
  OR: 'or',
  CONDITIONS: 'conditions',
} as const;
export type ApiQueryTypes = ObjectValues<typeof API_QUERY_TYPES>;

const API_FILTER_MATCHER_TYPE = {
  EXACT: 'exact',
  IS_NOT: 'isNot',
  CONTAINS: 'contains',
  CONTAINS_NOT: 'containsNot',
} as const;

export type ApiFilterMatcherType = ObjectValues<typeof API_FILTER_MATCHER_TYPE>;

export interface ApiPriceGapUnitStrategyValue {
  value: ApiPriceGapUnit;
}

export interface ApiVendors {
  domain: string;
  vendorNames: string[];
}

export interface ApiBooleanValueSelector {
  value?: boolean;
  tagKey?: string;
}

export interface ApiFormula {
  formula: string;
}

export interface ApiPriceFormula {
  value: string;
}

export interface ApiTimestampSelector {
  value?: string;
  tagKey?: string;
}

/**
 * Strategy Context Modifiers strategy Selectors
 *
 * 1) Price Formula strategy related:
 * - priceFormula
 * - priceCalculation
 * - calculatePriceWhenNoOffersExist
 *
 * 2) Competitive Pricing strategy related:
 * - vendorOfferFilter
 * - targetPosition
 * - priceGap
 * - priceGapUnit
 * - includeDelievryCosts
 *
 * 3) Safety Rules strategy related:
 * - minPrice
 * - maxPrice
 *
 * 4) Start strategy related:
 * - adjustToNextPricier
 * - calculatePriceWhenNoOffersExist
 * - targetPosition
 * - priceGap
 * - priceGapUnit
 *
 * 5) Stock Clearance related:
 * - startDate
 * - endDate
 * - targetStockLevel
 * - priceCalculation
 * - calculatePriceAfterEndDate
 */
export interface ApiSelectors {
  targetPosition?: ApiFormula;
  priceGap?: ApiFormula;
  priceGapUnit?: ApiPriceGapUnitStrategyValue;
  priceFormula?: ApiPriceFormula;
  priceCalculation?: {
    value: ApiPriceCalculationType;
  };
  vendorOfferFilter?: { vendors: ApiVendors[] };
  minPrice?: ApiFormula;
  maxPrice?: ApiFormula;
  adjustToNextPricier?: ApiBooleanValueSelector;
  calculatePriceWhenNoOffersExist?: ApiBooleanValueSelector;
  includeDeliveryCosts?: ApiBooleanValueSelector;
  startDate?: ApiTimestampSelector;
  endDate?: ApiTimestampSelector;
  targetStockLevel?: ApiFormula;
  calculatePriceAfterEndDate?: ApiBooleanValueSelector;
}

/**
 * (1) Holds Api strategy context modifiers strategy
 */
export interface ApiStrategyContextModifiers {
  [ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS]: {
    selectors: ApiSelectors;
    builder: ApiStrategy;
    /**
     * Id can be retrieved from API strategy, however when adding new nodes in UI there wont be an id,
     * therefore it is an optional field and is not required when converting frontend to API tree
     */
    id?: number;
  };
}

/**
 * [Deprecated] - Use @interface ApiStrategyFilters instead
 * (2) Holds Api tag based condition strategy
 */
export interface ApiTagBasedFilter {
  [ApiStrategyName.TAG_BASED]: {
    key: string;
    value: string;
    builder: ApiStrategy;
    id?: number;
  };
}

/**
 * (3) Holds Api formula comparision condition strategy
 */
export interface ApiFormulaComparison {
  [ApiStrategyName.FORMULA_COMPARISON]: {
    leftFormula: string;
    rightFormula: string;
    matcher: {
      type: string;
    };
    builder: ApiStrategy;
    id?: number;
  };
}

/**
 * (4) Holds Api price filter condition strategy
 */
export interface ApiFormulaPriceFilter {
  [ApiStrategyName.FORMULA_PRICE_FILTER]: {
    matcher: {
      type: string;
    };
    formula: string;
    builder: ApiStrategy;
    id?: number;
  };
}

// key is one of ApiComparator
export type ApiMatcher = {
  [key: string]: {
    matchValue: number;
  };
};

/**
 * (5) Holds Api competitors condition strategy
 */
export interface ApiCompetitorsOnPosition {
  [ApiStrategyName.COMPETITOR]: {
    matcher: ApiMatcher;
    position?: ApiMatcher;
    builder: ApiStrategy;
    id?: number;
  };
}

/**
 * (6) Represents Leaf strategy
 */
export interface ApiPriceCalculation {
  [ApiStrategyName.PRICE_CALCULATION]?: { id?: number; name?: string };
}

/**
 * (7) Holds different strategy filters - It is generic and will replace @interface ApiTagBasedFilter and to be replacing more condition nodes.
 */
export interface IApiStrategyFilters {
  [ApiStrategyName.FILTERS]: {
    query: Query;
    builder: ApiStrategy;
    id?: number;
  };
}

export type Query = {
  [key in ApiQueryTypes]?: {};
} & (ApiConditionsQuery | IApiStrategyCondition);

export type ApiConditionsQuery = {
  [key in ApiQueryTypes]?: IApiStrategyCondition[] | ApiConditionsQuery;
};
/**
 * (7.1) Holds string comparison strategy filters
 */
export interface IApiStrategyCondition {
  [API_QUERY_TYPES.CONDITIONS]?: {
    [API_CONDITIONS.STRING_COMPARISION]?: {
      leftString: { tagKey?: String; productPropertyKey?: String };
      matcher: IApiFilterMatcher;
      rightString: { value: String };
    };
    [API_CONDITIONS.TIMESTAMP_RANGE]?: {
      start?: { value?: string; tagKey?: string; productPropertyKey?: string };
      end?: { value?: string; tagKey?: string; productPropertyKey?: string };
    };
  };
}

export interface IApiFilterMatcher {
  type: ApiFilterMatcherType;
}

export type ApiStrategy = {
  [key in ApiStrategyName]?: {
    builder?: ApiStrategy;
  };
} & (
  | ApiStrategyContextModifiers
  | ApiTagBasedFilter
  | ApiFormulaComparison
  | ApiFormulaPriceFilter
  | ApiCompetitorsOnPosition
  | ApiPriceCalculation
  | IApiStrategyFilters
  | ApiSerial
);

/**
 * Serial strategy - siblings strategies
 */
export interface ApiSerial {
  [ApiStrategyName.SERIAL]: {
    builders: ApiStrategy[];
  };
}

export type StrategyMetadata = {
  /**
   * Strategy schema version
   */
  version: string;
  /**
   * The strategy version per contract
   */
  documentVersion: number;
  /**
   * Timestamp of the last tree update
   */
  updateDate?: string;
  /**
   * The user-email of the user who made the last tree update
   */
  updatedBy?: string;
  /**
   * The strategy version message provided by the user
   */
  documentVersionMessage?: string;
};

/**
 * Strategy tree object should contain strategy attributes and metadata attributes
 * [*] This represents the expected response data from API
 * - Why type intersection not interface? Ans: as ApiStrategy is union of types and interface can not extend type.
 */
export type ApiStrategyTreeResponse = ApiStrategy & StrategyMetadata;

/**
 * [*] This represents the expected request data to be sent to the API
 */
export type ApiStrategyTreeRequest = ApiStrategy &
  Omit<StrategyMetadata, 'documentVersion' | 'updateDate' | 'updatedBy'>;

/**
 * The current strategy schema version
 */
export const STRATEGY_SCHEMA_VERSION: string = '2';
