import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, tap } from 'rxjs';

import { SnackbarService } from '@components/index';
import { SnackbarConfig } from '@components/snackbar/snackbar/snackbar.interface';

import { SnackbarActions } from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  constructor(
    private readonly actions: Actions,
    private readonly snackbarService: SnackbarService
  ) {}

  public readonly success$ = createEffect(() => this._success(), { dispatch: false });

  public readonly error$ = createEffect(() => this._error(), { dispatch: false });

  public readonly default$ = createEffect(() => this._default(), { dispatch: false });

  public readonly info$ = createEffect(() => this._info(), { dispatch: false });

  public readonly warning$ = createEffect(() => this._warning(), { dispatch: false });

  private _success(): Observable<SnackbarConfig> {
    return this.actions.pipe(
      ofType(SnackbarActions.SUCCESS),
      tap(({ message }) => this.snackbarService.success({ message }))
    );
  }

  private _error(): Observable<SnackbarConfig> {
    return this.actions.pipe(
      ofType(SnackbarActions.ERROR),
      tap(({ message }) => this.snackbarService.error({ message }))
    );
  }

  private _default(): Observable<SnackbarConfig> {
    return this.actions.pipe(
      ofType(SnackbarActions.DEFAULT),
      tap(({ message }) => this.snackbarService.default({ message }))
    );
  }

  private _info(): Observable<SnackbarConfig> {
    return this.actions.pipe(
      ofType(SnackbarActions.INFO),
      tap(({ message }) => this.snackbarService.info({ message }))
    );
  }

  private _warning(): Observable<SnackbarConfig> {
    return this.actions.pipe(
      ofType(SnackbarActions.WARNING),
      tap(({ message }) => this.snackbarService.warning({ message }))
    );
  }
}
