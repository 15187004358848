import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyFilterByDate',
  pure: false,
})
export class LegacyFilterByDatePipe implements PipeTransform {
  transform(array: { [key: string]: string }[], args: string): { [key: string]: string }[] {
    if (typeof args[0] === 'undefined') {
      return array;
    }
    array.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
      const left = Number(new Date(a[args]));
      const right = Number(new Date(b[args]));
      return right - left;
    });
    return array;
  }
}
