import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';

import { Subscription } from 'rxjs';

import { PeanutTreeUI } from '../../PeanutTree';
import { PeanutTreeService } from '../../services/peanut-tree.service';
import { StartStrategy } from '../../ui-strategy-elements/start/start';

@Component({
  selector: 'app-start-node',
  templateUrl: './start-node.component.html',
  styleUrls: ['./start-node.component.scss'],
})
export class StartNodeComponent implements OnInit, OnDestroy {
  @Input() varsMap: Map<string, string> = new Map();

  node: PeanutTreeUI | undefined;

  data: StartStrategy | undefined;

  isStartNodeClosed = true;

  subscription = new Subscription();

  constructor(@SkipSelf() public treeStore: PeanutTreeService) {}

  ngOnInit() {
    this.subscription.add(
      this.treeStore.startNode$.subscribe((node) => {
        this.node = node;
        this.data = node.data;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
