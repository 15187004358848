import { AfterContentChecked, Directive, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[qHighlightText]',
})
export class HighlightTextDirective implements AfterContentChecked {
  @Input() highlightText?: string;

  constructor(private element: ElementRef<HTMLElement>, private sanitizer: DomSanitizer) {}

  ngAfterContentChecked() {
    const currentTextContent = this.element.nativeElement.textContent;
    if (!currentTextContent) return;
    this.element.nativeElement.innerHTML =
      this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.highlightText
          ? currentTextContent.replace(
              new RegExp(this.highlightText, 'gi'),
              (match) => `<mark class='bg-yellow-200 leading-tight'>${match}</mark>`
            )
          : currentTextContent
      ) || this.element.nativeElement.innerHTML;
  }
}
