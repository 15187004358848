<div class="loading_overlay z-40" *ngIf="loader$ | async" tabindex="-1" [@inOutAnimation]>
  <div class="loading_holderblock">
    <p class="absolute top-6 text-lg font-bold text-primary">Loading...</p>
    <div class="loading_spinner">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
    <div class="loading progress">
      <div class="indeterminate"></div>
    </div>
  </div>
</div>
