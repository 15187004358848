import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

import { IContract } from '@models/interfaces/company.interface';

import { selectUserId } from '../details/details.selectors';

const selectPreferencesFeatureKey = ({ user }: AppState) => user.preferences;

export const selectDefaultCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ defaultCompany }) => defaultCompany
);

export const selectDefaultContract = createSelector(
  selectPreferencesFeatureKey,
  ({ defaultCompany }) => defaultCompany?.contracts[0] as IContract
);

export const selectActiveCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany
);

export const selectActiveCompanyContracts = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.contracts
);

export const selectActiveContract = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany?.contracts[0] as IContract
);

export const selectActiveContractSid = createSelector(
  selectActiveContract,
  (activeContract) => activeContract?.sid
);

export const selectActivePortalName = createSelector(
  selectActiveContract,
  (activeContract) => activeContract?.portalName
);
export const selectActivePortalCustomerName = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany?.contracts[0].customerAdpsName
);
export const selectIsSwitchingContract = createSelector(
  selectPreferencesFeatureKey,
  ({ isSwitchingContracts }) => isSwitchingContracts
);

export const selectFrontendAPILoggingData = createSelector(
  selectActivePortalName,
  selectUserId,
  selectActivePortalCustomerName,
  (activePortalName, userId, activePortalCustomerName) => {
    return [activePortalName, userId, activePortalCustomerName];
  }
);
