import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ApiModule, Configuration } from '@Patagona/pricemonitor-internal-typescript-angular-13';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { extModules } from '@app/build-specifics';
import { AuthInterceptor } from '@app/services/interceptors/auth.interceptor';

import { PMConfigFactory } from '@config/pricemonitor-auth.config';
import { providersList } from '@config/providers.config';

import { Auth0Module } from '@vendor-features/authentication/authentication.module';

import { UserTokenService } from '@services/authentication/user-token.service';
import { InvalidTokenInterceptor } from '@services/interceptors/invalid-token-interceptor.service';

import { headerReducer, ScrollElementIntoViewEffects } from '@store/legacy';
import { LoadingSpinnerEffects } from '@store/loader-spinner/loader-spinner.effects';
import { RouterEffects } from '@store/router';
import { SnackbarEffects, snackbarReducer } from '@store/snackbar';
import {
  AuthenticationEffects,
  CompaniesEffects,
  ContractsEffects,
  UserDetailsEffects,
  UserPortalsEffects,
  UserPreferencesEffects,
  userReducer,
} from '@store/user';

import { ComponentsModule } from './components/components.module';
import { AddPortalNameHeaderInterceptor } from './services/interceptors/add-portal-header.interceptor';
import { BackendTracing } from './services/interceptors/backend-tracing.interceptor';
import { BlockFeApiMismatchInterceptor } from './services/interceptors/block-fe-api-request.interceptor';
import { BlockGetWithSidMismatchInterceptor } from './services/interceptors/block-get-sid-mismatch.interceptor';
import { BlockPostPutWithSidMismatchInterceptor } from './services/interceptors/block-post-put-sid-mismatch.interceptor';
import { ErrorInterceptor } from './services/interceptors/error-interceptor.service';
import { LogFeApiRequestsInterceptor } from './services/interceptors/log-fe-api-requests.interceptor';
import { LookerEffects } from './store/user/looker/looker.effects';
import { TracingModule } from './tracing/tracing.module';
import { resetStateMetaReducer } from './app-meta-reducers';

const sentryConfig = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    Auth0Module,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    ApiModule,
    TracingModule,
    EffectsModule.forRoot([
      RouterEffects,
      LoadingSpinnerEffects,
      AuthenticationEffects,
      UserDetailsEffects,
      UserPortalsEffects,
      LookerEffects,
      CompaniesEffects,
      ContractsEffects,
      UserPreferencesEffects,
      SnackbarEffects,
      ScrollElementIntoViewEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(
      {
        user: userReducer,
        snackbar: snackbarReducer,
        router: routerReducer,
        header: headerReducer,
      },
      { metaReducers: [resetStateMetaReducer] }
    ),
    extModules,
  ],
  providers: [
    [...sentryConfig],
    [...providersList],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvalidTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddPortalNameHeaderInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendTracing,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockGetWithSidMismatchInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockPostPutWithSidMismatchInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockFeApiMismatchInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogFeApiRequestsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: Configuration,
      useFactory: PMConfigFactory,
      deps: [UserTokenService],
      multi: false,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
