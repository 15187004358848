<!-- if two radio buttons are used - always wrap them with <mat-radio-group> -->
<mat-radio-button
  color="accent"
  [disabled]="disabled"
  [checked]="checked"
  [labelPosition]="labelPosition"
  [value]="value"
  (change)="setValue($event)"
  >{{ label }}
</mat-radio-button>
