<div class="grid grid-flow-col gap-9">
  <app-raised-button (click)="notifyDemo()" text="Notify"></app-raised-button>
  <app-raised-button
    color="warn"
    (click)="notifyWithError()"
    text="Throw Error"
  ></app-raised-button>
  <app-raised-button
    color="accent"
    (click)="notifyWithSuccess()"
    text="Throw Success"
  ></app-raised-button>
  <app-raised-button
    (click)="notifyChanges()"
    text="Show unsaved changes notification"
  ></app-raised-button>
</div>
