import { Injectable } from '@angular/core';

import { IJobStatusFailures } from '@app/models/common';

@Injectable({
  providedIn: 'root',
})
export class JsonToCsvConverterService {
  public download(data: IJobStatusFailures[], contractSid: string, timestamp: string) {
    const csvData = this.generateCsv(data);

    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });

    const downloadLink = document.createElement('a');

    downloadLink.style.visibility = 'hidden';
    downloadLink.setAttribute(
      'target',
      navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
        ? '_blank'
        : ''
    );
    downloadLink.setAttribute('href', URL.createObjectURL(blob));
    downloadLink.setAttribute('download', `PriceCalculationRun_${contractSid}_${timestamp}.csv`);

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private generateCsv(items: IJobStatusFailures[]): string {
    const header = Object.keys(items[0].attributes).sort();

    const formattedHeaderColumns = header
      .map((key) =>
        key
          .split(/(?=[A-Z])/)
          .join(' ')
          .toUpperCase()
      )
      .join(',');
    const replacer = (key: any, value: any) => value ?? '';
    const rowItems = items.map((row: { attributes: { [x: string]: any } }) =>
      header.map((fieldName) => JSON.stringify(row.attributes[fieldName], replacer)).join(',')
    );

    // join header and body, and break into separate lines
    return [formattedHeaderColumns, ...rowItems].join('\r\n');
  }
}
