import { Component, EventEmitter, Input, Output } from '@angular/core';

import { COMPARATOR_SYMBOL } from '../../../constants';
import { IfCompetitionStrategy } from '../../../ui-strategy-elements/conditions/conditions';

@Component({
  selector: 'app-if-competition-node',
  templateUrl: './if-competition-node.component.html',
  styleUrls: ['./if-competition-node.component.scss'],
})
export class IfCompetitionNodeComponent {
  @Input() data: IfCompetitionStrategy | undefined;

  @Input() isSelected: boolean = false;

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();

  comparatorSymbol = COMPARATOR_SYMBOL;
}
