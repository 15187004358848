import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DATE_FORMAT } from '../../../constants';
import {
  Condition,
  IfStrategyFilters,
  TimestampRangeCondition,
} from '../../../ui-strategy-elements/conditions/conditions';

@Component({
  selector: 'app-if-timestamp-range-node',
  templateUrl: './if-timestamp-range-node.component.html',
  styleUrls: ['./if-timestamp-range-node.component.scss'],
})
export class IfTimestampRangeNodeComponent implements OnInit {
  public readonly TimestampRangeCondition!: TimestampRangeCondition;

  @Input() data: IfStrategyFilters | undefined;

  @Input() isSelected: boolean = false;

  @Input() isFocused = false;

  @Input() isDeleteingEnabled? = true;

  @Output() deleteCallback = new EventEmitter();

  public timestampCondition: Condition | undefined;

  public dateFormat = DATE_FORMAT;

  ngOnInit() {
    /**
     * 'query' property can include a list of conditions in case operators are used,
     * however UI currently only supports one condition for If Timestamp element
     * therefore, we are directly extracting that element (query[0])
     */
    this.timestampCondition = this.data?.query[0];
  }
}
