import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';

@Component({
  selector: 'app-base-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent extends MatButton {
  @Input() override disabled = false;

  @Input() text?: string;

  @Input() icon?: string;

  @Input() hasIconAfterText = false;

  @Input() textSize: 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' = 'text-base';

  @Input() iconSize: 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' | '' = 'text-base';

  @Input() iconAnimationClass: string = '';

  @Input() isRaisedButton = false;

  @Input() isOutlinedButton = false;

  @Output() clickEvent = new EventEmitter<MouseEvent>();

  public click(event: MouseEvent): void {
    if (!this.disabled) {
      this.clickEvent.emit(event);
    }
  }
}
