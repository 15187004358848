<div class="node condition" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Condition</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <mat-icon class="strategy-type-icon float-left mr-1 text-xl">date_range</mat-icon>
    <div class="mt-0 text-base font-bold">If Date</div>
    <div *ngIf="timestampCondition?.data | as : TimestampRangeCondition as data" class="mt-2">
      <div *ngIf="data.start || data.end" class="pl-7 text-sm">
        <div class="truncate">
          <span>Start: </span>
          <ng-container
            *ngIf="data.start?.value || data.start?.productPropertyKey; else noStartDate"
          >
            <span *ngIf="data.start?.value">{{ data.start!.value | date : dateFormat }}</span>
            <span *ngIf="data.start?.productPropertyKey">{{
              'PROPERTY.' + data.start!.productPropertyKey
            }}</span>
          </ng-container>
          <ng-template #noStartDate>
            <span>no start date</span>
          </ng-template>
        </div>

        <div class="truncate">
          <span class="font">End: </span>
          <ng-container *ngIf="data.end?.value || data.end?.productPropertyKey; else noEndDate">
            <span *ngIf="data.end?.value">{{ data.end!.value | date : dateFormat }}</span>
            <span *ngIf="data.end?.productPropertyKey">{{
              'PROPERTY.' + data.end!.productPropertyKey
            }}</span>
          </ng-container>
          <ng-template #noEndDate>
            <span>no end date</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
