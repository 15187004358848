import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FixedPriceSelectionComponent } from '../fixed-price-selection/fixed-price-selection.component';

@Component({
  selector: 'app-fixed-price-selection-dialog',
  templateUrl: './fixed-price-selection-dialog.component.html',
  styleUrls: ['./fixed-price-selection-dialog.component.scss'],
})
export class FixedPriceSelectionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FixedPriceSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  save(data: any) {
    this.dialogRef.close(data);
  }

  cancel() {
    this.dialogRef.close();
  }
}
