import { Component, Input } from '@angular/core';

import { IDelimitedItems } from './delimited-items-list.interface';

@Component({
  selector: 'app-delimited-items-list',
  templateUrl: './delimited-items-list.component.html',
})
export class DelimitedItemsListComponent {
  @Input() items?: IDelimitedItems[];

  @Input() delimiter = '|';
}
