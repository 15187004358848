interface Details {
  timecreated: Date;
  externaldescription: string;
}

export interface IShopSummaryOptions {
  id: string;
  displayName: string;
  shopName?: string;
  logoSrc?: string;
  showInfoButton?: boolean;
  details?: Details[];
  enabled?: boolean;
  errorMessage?: string;
  hasTopX?: boolean;
}

export const ShopSummaryModel: IShopSummaryOptions = {
  id: '',
  displayName: '',
  shopName: '',
  logoSrc: '',
  showInfoButton: false,
  details: [
    {
      timecreated: new Date(),
      externaldescription: 'Job: Import legacy state was changed to Cancelled',
    },
  ],
};
