<div class="node price-formula" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Price Formula</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <div *ngIf="data?.priceFormula !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">calculate</mat-icon>
      <div *ngIf="data?.priceFormula !== null" class="mt-1 truncate text-sm">
        {{ data?.priceFormula | formatFormulaVars : varsMap }}
      </div>
      <div *ngIf="data?.priceFormula === null" class="mt-1 truncate text-sm">Price formula</div>
    </div>
    <div *ngIf="data?.priceRequirement !== undefined">
      <mat-icon class="strategy-type-icon float-left mr-1 text-base">fact_check</mat-icon>
      <div *ngIf="data?.priceRequirement !== null" class="mt-1 truncate text-sm">
        {{
          data?.priceRequirement ? 'Always recommend price' : 'Only if at least one offer is found'
        }}
      </div>
      <div *ngIf="data?.priceRequirement === null" class="mt-1 truncate text-sm">
        Price formula Req.
      </div>
    </div>
  </div>
</div>
