import { Component, HostListener } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { AttributeOptions } from '../../constants';

@Component({
  selector: 'app-competitor-variable-dialog',
  templateUrl: './competitor-variable-dialog.component.html',
  styleUrls: ['./competitor-variable-dialog.component.scss'],
})
export class CompetitorVariableDialogComponent {
  readonly AttributeOptions = AttributeOptions;

  position: number = 1;

  attribute: AttributeOptions = this.AttributeOptions.unitPrice;

  constructor(public dialogRef: MatDialogRef<CompetitorVariableDialogComponent>) {}

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  formatCompetitorVariable() {
    return `COMPETITOR[${this.position}].${this.attribute}`;
  }
}
