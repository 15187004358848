import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IDelimitedItems } from '../delimited-items-list/delimited-items-list.interface';
import { IItems } from '../selects/floating-select/floating-select.model';

import { IShopSummaryOptions, ShopSummaryModel } from './shop-summary.model';

@Component({
  selector: 'app-shop-summary',
  templateUrl: './shop-summary.component.html',
})
export class ShopSummaryComponent implements OnChanges {
  @Input() shopSummaryOptions = ShopSummaryModel;

  @Input() delimitedItems: IDelimitedItems[] = [];

  @Input() actionItems: IItems[] = [];

  @Input() shopStatus: string = '';

  @Output() showDetailsClickEvent = new EventEmitter();

  @Output() selectStepEvent = new EventEmitter();

  // Custom events, keep in sync with actionItems array
  @Output() clone = new EventEmitter<IShopSummaryOptions>();

  @Output() edit = new EventEmitter<IShopSummaryOptions>();

  @Output() delete = new EventEmitter<IShopSummaryOptions>();

  public isInProgress = false;

  public areDetailsShown = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shopStatus']?.currentValue) {
      this.isInProgress =
        this.shopStatus === 'enqueued' ||
        this.shopStatus === 'processing' ||
        this.shopStatus === 'cancelling';
    }
  }

  public selectedAction({ eventName }: IItems): void {
    const selectedEvent: EventEmitter<IShopSummaryOptions> = this[eventName];

    if (selectedEvent) {
      selectedEvent.emit(this.shopSummaryOptions);
    } else {
      console.error(`This ${eventName} is not implemented`);
    }
  }

  public showStatusDetails(): void {
    this.areDetailsShown = !this.areDetailsShown;
    this.showDetailsClickEvent.emit();
  }

  public selectStep(): void {
    this.selectStepEvent.emit();
  }
}
