import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IItems } from './floating-select.model';

@Component({
  selector: 'app-floating-select',
  templateUrl: './floating-select.component.html',
})
export class FloatingSelectComponent {
  @Input() isIconButton = false;

  @Input() disabled = false;

  @Input() items?: IItems[];

  @Input() placeholder?: string;

  @Output() selected = new EventEmitter<IItems>();
}
