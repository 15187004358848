<div class="node condition" [ngClass]="{ selected: isSelected, focus: isFocused }">
  <div class="header">
    <span>Condition</span>
    <ng-container *ngIf="isDeleteingEnabled">
      <mat-icon class="delete-button" (click)="deleteCallback.emit($event)">
        delete_forever
      </mat-icon>
    </ng-container>
  </div>
  <div class="content">
    <mat-icon class="strategy-type-icon float-left mr-1 text-xl">dashboard_customize</mat-icon>
    <div class="mt-0 text-base font-bold">If tag</div>
    <div *ngIf="data" class="mt-2">
      <ng-container *ngFor="let queryData of data.query; let i = index">
        <div class="separator" *ngIf="queryData?.operator && i < dataRowLimit">
          <span>{{ queryData?.operator }}</span>
        </div>
        <ng-template #showTagsOrProductProperties let-name="name" let-value="value">
          <div class="truncate pl-7 text-sm" *ngIf="i < dataRowLimit && name">
            {{ name }} ({{
              (queryData.data | as : StringComparisonCondition)?.matcher === 'exact'
                ? 'is'
                : (queryData.data | as : StringComparisonCondition)?.matcher
            }})
            <span class="{{ !value ? 'italic' : '' }}"> {{ value || 'empty' }}</span>
          </div>
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            showTagsOrProductProperties;
            context: {
              name: (queryData.data | as : StringComparisonCondition)?.tagName,
              value: (queryData.data | as : StringComparisonCondition)?.tagValue
            }
          "
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="
            showTagsOrProductProperties;
            context: {
              name: (queryData.data | as : StringComparisonCondition)?.productPropertyName,
              value: (queryData.data | as : StringComparisonCondition)?.productPropertyValue
            }
          "
        ></ng-container>
      </ng-container>
      <div class="mt-2 text-center" *ngIf="data.query.length > dataRowLimit">
        + {{ data.query.length - dataRowLimit }} more
      </div>
    </div>
  </div>
</div>
