import { NgModule } from '@angular/core';

import { LegacyFilterByDatePipe } from '@pipes/legacy-filter-by-date/legacy-filter-by-date.pipe';
import { LegacyFilterConditionPipe } from '@pipes/legacy-filter-condition/legacy-filter-condition.pipe';
import { LegacyFilterByKeyPipe } from '@pipes/legacy-filterbykey/legacy-filterwithkey.pipe';
import { LegacyReplacePipe } from '@pipes/legacy-replace/legacy-replace.pipe';

import { LegacyFilterPipe } from './legacy-filter/legacy-filter.pipe';

const pipes = [
  LegacyFilterPipe,
  LegacyFilterByKeyPipe,
  LegacyFilterConditionPipe,
  LegacyReplacePipe,
  LegacyFilterByDatePipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
})
export class PipeModule {}
