import { Component } from '@angular/core';

import { SnackbarService } from './snackbar/snackbar.service';

@Component({
  selector: 'app-snackbar-demo-button',
  templateUrl: './snackbar-demo.component.html',
})
export class SnackBarDemoComponent {
  constructor(private readonly snackbar: SnackbarService) {}

  public notifyDemo(): void {
    this.snackbar.default({ message: 'This is an awesome Toaster' });
  }

  public notifyWithError(): void {
    this.snackbar.error({ message: 'Oh noes!' });
  }

  public notifyWithSuccess(): void {
    this.snackbar.success({ message: 'Great stuff!' });
  }

  public notifyChanges(): void {
    this.snackbar.notifyChanges();
  }
}
