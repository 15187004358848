<div class="p-5">
  <app-fixed-price-selection
    [fixedPriceEnabled]="data !== undefined"
    [price]="data?.price"
    [startDate]="data?.startDate"
    [endDate]="data?.endDate"
    [purchasePrice]="data?.purchasePrice"
    [vat]="data?.vat"
    (cancel)="cancel()"
    (save)="save($event)"
  >
  </app-fixed-price-selection>
</div>
