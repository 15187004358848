import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { IfFormulaAppliesStrategy } from '../../../ui-strategy-elements/conditions/conditions';
import { NumericComparator } from '../../../ui-strategy-elements/strategy-types';
import {
  FormulaValidation,
  formulaValidator,
  getInvalidTypeErrorMessage,
} from '../FormulaValidation';

@Component({
  selector: 'app-if-formula-form',
  templateUrl: './if-formula-form.component.html',
  styleUrls: ['./if-formula-form.component.scss'],
})
export class IfFormulaFormComponent
  extends CompetitorVariableDirective
  implements OnChanges, OnInit
{
  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  readonly formulaValidator = formulaValidator;

  readonly NumericComparator = NumericComparator;

  @Input() data: IfFormulaAppliesStrategy | undefined;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  formulaValidation = new FormulaValidation();

  ifFormulaForm = new UntypedFormGroup({
    leftFormula: new UntypedFormControl(null),
    operator: new UntypedFormControl(NumericComparator.LESS),
    rightFormula: new UntypedFormControl(null),
    searchAVar: new UntypedFormControl(null),
    searchBVar: new UntypedFormControl(null),
  });

  isAVarsShown = false;

  isBVarsShown = false;

  leftFormula = this.ifFormulaForm.get('leftFormula');

  rightFormula = this.ifFormulaForm.get('rightFormula');

  operator = this.ifFormulaForm.get('operator');

  searchAVar = '';

  searchBVar = '';

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  ngOnInit() {
    this.leftFormula?.addValidators(formulaValidator(this.vars));
    this.rightFormula?.addValidators(formulaValidator(this.vars));
  }

  ngOnChanges() {
    this.leftFormula?.setValue(
      this.formatFormulaVarsPipe.transform(this.data?.leftFormula, this.varsMap)
    );
    this.rightFormula?.setValue(
      this.formatFormulaVarsPipe.transform(this.data?.rightFormula, this.varsMap)
    );
    this.operator?.setValue(this.data?.operator || NumericComparator.LESS);
  }

  save() {
    if (this.leftFormula?.value)
      this.leftFormula.setValue(
        this.formatFormulaVarsPipe.transform(this.leftFormula.value, this.varsMap, true)
      );
    if (this.rightFormula?.value)
      this.rightFormula.setValue(
        this.formatFormulaVarsPipe.transform(this.rightFormula.value, this.varsMap, true)
      );
    this.saveCallback.emit(this.ifFormulaForm.value);
  }
}
