import { Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[qCompetitorVariable]',
})
export class CompetitorVariableDirective {
  setCompetitorVariable(competitorVar: string | undefined, formulaControl: AbstractControl | null) {
    if (competitorVar && formulaControl) {
      formulaControl.setValue((formulaControl.value || '') + competitorVar);
      formulaControl.markAsDirty();
    }
  }
}
