import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { isInteger } from 'lodash';

import { StockClearanceStrategy } from '@app/components/strategy-component/ui-strategy-elements/goal-based-pricing/stock-clearance';
import * as Timestamp from '@app/components/strategy-component/utils/timestamp';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { formulaValidator, getInvalidTypeErrorMessage } from '../FormulaValidation';

@Component({
  selector: 'app-stock-clearance-form',
  templateUrl: './stock-clearance-form.component.html',
  styleUrl: './stock-clearance-form.component.scss',
})
export class StockClearanceFormComponent extends CompetitorVariableDirective implements OnChanges {
  @Input() data?: StockClearanceStrategy;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isSavingEnabled = false;

  @Input() tags: string[] = [];

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  isTargetVarsShown: boolean = false;

  searchTargetVar: string = '';

  selectDatesFromTags: boolean = false;

  stockClearanceForm: FormGroup = new FormGroup(
    {
      startDate: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      endDate: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      startTime: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      endTime: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      searchTargetVar: new FormControl('', null),
      targetStockLevel: new FormControl('', null),
      continuePricing: new FormControl(false, {}),
      startDateFromTags: new FormControl(
        { value: null, disabled: true },
        { validators: Validators.required }
      ),
      endDateFromTags: new FormControl(
        { value: null, disabled: true },
        { validators: Validators.required }
      ),
    },
    {
      validators: this.timestampsValidator.bind(this), // Here only the timestamp validation is needed.
    }
  );

  target = this.stockClearanceForm.get('targetStockLevel');

  readonly isInteger = isInteger;

  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  controlErrorMessage: Map<string, string> = new Map([
    ['incorrectDateOrder', 'Start date/time should not be larger than end date/time'],
    ['startValueMissing', 'Both start date and time should be defined'],
    ['endValueMissing', 'Both end date and time should be defined'],
    ['endDatePassed', 'End date and time should be in the future'],
    ['startAndEndRequired', 'Both start and end date should be defined'],
    [
      'incorrectTargetLevel',
      'Value must be specified correctly. (Only positive numbers and must not be empty)',
    ],
  ]);

  minDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private formatFormulaVarsPipe: FormatFormulaVarsPipe
  ) {
    super();
  }

  get vars() {
    return [...this.varsMap.values()];
  }

  // runs when stock clearance is first opened in the edit panel and for every node switch thereafter to update the form based on inputs
  ngOnChanges(): void {
    this.initStrategy();
  }

  private initStrategy(): void {
    const stockClearanceStrategy = this.data;
    if (!stockClearanceStrategy) {
      return;
    }
    const start = stockClearanceStrategy.startDate;
    const end = stockClearanceStrategy.endDate;
    if (start && end) {
      // dates from tags
      if (start.tagKey && end.tagKey) {
        this.stockClearanceForm.get('startDateFromTags')?.setValue(start.tagKey);
        this.stockClearanceForm.get('endDateFromTags')?.setValue(end.tagKey);
        this.setDateSelection('tags');
      }
      // dates from date pickers
      else if (start.value && end.value) {
        this.stockClearanceForm.get('startDate')?.setValue(Timestamp.extractDate(start.value));
        this.stockClearanceForm.get('endDate')?.setValue(Timestamp.extractDate(end.value));
        this.stockClearanceForm.get('startTime')?.setValue(Timestamp.extractTime(start.value));
        this.stockClearanceForm.get('endTime')?.setValue(Timestamp.extractTime(end.value));
        this.setDateSelection('datePicker');
      }
      // invalid case i.e either start or end from tags and other from date picker
      else {
        this.setDateTimeDefaults();
        this.setDateSelection('datePicker');
      }
    } else {
      this.setDateTimeDefaults();
      this.setDateSelection('datePicker');
    }

    this.stockClearanceForm.patchValue({
      searchTargetVar: '',
      targetStockLevel:
        this.formatFormulaVarsPipe.transform(
          stockClearanceStrategy.targetStockLevel,
          this.varsMap
        ) ?? null,
      continuePricing: stockClearanceStrategy.continuePricing ?? null,
    });
    this.target?.setValidators(formulaValidator(this.vars));
  }

  /**
   * Perform following validations:
   * 1. Both start and end date should be defined
   * 2. Start date should be before end date
   * 3. End date should be in the future
   * @param control
   * @returns
   */
  private timestampsValidator(stockClearanceForm: AbstractControl) {
    // When the validation is executed during the form creation the form is null
    if (!stockClearanceForm) {
      return null;
    }

    if (this.selectDatesFromTags) {
      const { startDateFromTags, endDateFromTags } = stockClearanceForm.value;
      if (!startDateFromTags && !endDateFromTags) {
        return { startAndEndRequired: true };
      }
      return null;
    }
    // Simplify the code using the same strategy I suggested on the save method
    const { startDate, startTime, endDate, endTime } = stockClearanceForm.value;

    if (!startDate && !endDate) {
      return { startAndEndRequired: true };
    }
    if (!startDate || !startTime) {
      return { startValueMissing: true };
    }
    if (!endDate || !endTime) {
      return { endValueMissing: true };
    }

    const start = Timestamp.createTimestamp(startDate, startTime);
    const end = Timestamp.createTimestamp(endDate, endTime);
    const currentDate = new Date();
    if (end <= start) {
      return { incorrectDateOrder: true };
    }
    if (end <= currentDate) {
      return { endDatePassed: true };
    }

    return null;
  }

  private setDateTimeDefaults(): void {
    this.stockClearanceForm.get('startDate')?.setValue('');
    this.stockClearanceForm.get('endDate')?.setValue('');
    this.stockClearanceForm.get('startTime')?.setValue('00:00');
    this.stockClearanceForm.get('endTime')?.setValue('00:00');
  }

  toggleDateSelection() {
    if (!this.selectDatesFromTags) {
      this.setDateSelection('tags');
    } else {
      this.setDateSelection('datePicker');
    }
  }

  private setDateSelection(option: string) {
    if (option === 'datePicker') {
      this.selectDatesFromTags = false;
      this.disableTagPickers();
      this.enableDatePickers();
    } else if (option === 'tags') {
      this.selectDatesFromTags = true;
      this.disableDatePickers();
      this.enableTagPickers();
    }
  }

  private disableDatePickers() {
    this.stockClearanceForm.get('startDate')?.disable();
    this.stockClearanceForm.get('endDate')?.disable();
    this.stockClearanceForm.get('startTime')?.disable();
    this.stockClearanceForm.get('endTime')?.disable();
  }

  private enableDatePickers() {
    this.stockClearanceForm.get('startDate')?.enable();
    this.stockClearanceForm.get('endDate')?.enable();
    this.stockClearanceForm.get('startTime')?.enable();
    this.stockClearanceForm.get('endTime')?.enable();
  }

  private disableTagPickers() {
    this.stockClearanceForm.get('startDateFromTags')?.disable();
    this.stockClearanceForm.get('endDateFromTags')?.disable();
  }

  private enableTagPickers() {
    this.stockClearanceForm.get('startDateFromTags')?.enable();
    this.stockClearanceForm.get('endDateFromTags')?.enable();
  }

  save(): void {
    this.target?.setValue(
      this.formatFormulaVarsPipe.transform(this.target.value, this.varsMap, true)
    );
    let start, end;
    if (this.selectDatesFromTags) {
      const { startDateFromTags, endDateFromTags } = this.stockClearanceForm.value;
      if (!startDateFromTags || !endDateFromTags) {
        console.error('start and end date tags must be defined');
        //Force validation just in case
        this.stockClearanceForm.markAllAsTouched();
        return;
      }
      start = { tagKey: startDateFromTags };
      end = { tagKey: endDateFromTags };
    } else {
      const { startDate, startTime, endDate, endTime } = this.stockClearanceForm.value;
      if (!startDate || !startTime || !endDate || !endTime) {
        console.error('start and end dates must be defined');
        //Force validation just in case
        this.stockClearanceForm.markAllAsTouched();
        return;
      }
      start = { value: Timestamp.createTimestampInUTC(startDate, startTime) };
      end = { value: Timestamp.createTimestampInUTC(endDate, endTime) };
    }

    //Simplify using form values
    const { targetStockLevel, continuePricing } = this.stockClearanceForm.value;

    if (
      targetStockLevel === null ||
      targetStockLevel === undefined ||
      continuePricing === null ||
      continuePricing === undefined
    ) {
      console.error('Target stock level or continue pricing values are missing');
      //Force validation just in case
      this.stockClearanceForm.markAllAsTouched();
      return;
    }

    const strategy = {
      startDate: start,
      endDate: end,
      targetStockLevel: targetStockLevel,
      continuePricing: continuePricing,
    };

    const filter = { ...this.data, ...strategy };
    this.saveCallback.emit(filter);
  }

  /**
   * Reseting form control field to null , only in case of reseting time fields we reset to default 00:00
   */
  clearFormControl(control: string) {
    if (control === 'startTime' || control === 'endTime') {
      this.stockClearanceForm.get(control)!.setValue('00:00');
    } else {
      this.stockClearanceForm.get(control)!.setValue(null);
    }
  }
}
