import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  OffersService,
  Pagination,
  PreprocessingService,
  PricemonitorApiQuery,
  PricerecommendationsService,
  ProductsService,
  SettingsService,
  StrategyService,
  TasksService,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import {
  catchError,
  combineLatest,
  EMPTY,
  exhaustMap,
  map,
  mergeMap,
  Observable,
  repeat,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs';

import { cloneDeep, isEqual, some } from 'lodash';

import { TIMERANGE_IN_MINUTES } from '@app/constants/timerange';
import {
  createPriceRecommendationQuery,
  createProductOffersQuery,
  createQueryTimeRange,
} from '@app/store/pricing-strategy/pricing-strategy.helper';

import { AppState } from '@store/app.state';
import {
  fetchDomains,
  fetchProductData,
  fetchProductOffers,
  fetchProductPriceRecommendation,
  fetchProductProperties,
  fetchShopsByDomain,
  fetchStrategy,
  fetchStrategyByDocumentVersion,
  fetchStrategyMetadataHistory,
  fetchTags,
  fetchTaskData,
  fetchTaskStatus,
  fetchVendorSettings,
  pricingStrategyDestroy,
  pricingStrategyInit,
  publishPreprocessingTask,
  saveStrategy,
  selectStrategyVendorSettings,
  toggleShop,
} from '@store/pricing-strategy';
import { contractSwitchAction, selectQueryParams, selectRouteParams } from '@store/router';

import { HttpErrorType } from '@models/interfaces/http-message.interface';

import { SnackbarService } from '@components/index';

import { httpErrorMessageMapper } from '@utils/http-message-mapper';

const PREPROCESSING_TASK_TYPE = 'backend.tasks.pricemonitor.offers.preprocessing';
const PREPROCESSING_TASK_SUCCESS_MSG = 'Operation completed successfully';
const TASK_POLLING_INTERVAL = 3000; // 3 seconds
const enum PreprocessingTaskState {
  Succeeded = 'succeeded',
  Failed = 'failed',
  Pending = 'pending',
}

@Injectable()
export class PricingStrategyEffects implements OnRunEffects {
  private productQuery: PricemonitorApiQuery = {
    pagination: {
      start: 0,
      limit: 0,
    },
    filter: {
      oneOf: {
        field: 'productId',
        values: [],
      },
    },
  };

  public readonly getStrategy$ = createEffect(() => this.getStrategy());

  public readonly getStrategyByDocumentVersion$ = createEffect(() =>
    this.getStrategyByDocumentVersion()
  );

  public readonly getStrategyHistory$ = createEffect(() => this.getStrategyMetadataHistory());

  public readonly getTaskData$ = createEffect(() => this.getTaskData());

  public readonly getProductData$ = createEffect(() => this.getProductData());

  public readonly getTaskStatus$ = createEffect(() => this.getTaskStatus());

  public readonly getDomains$ = createEffect(() => this.getDomains());

  public readonly getShopsByDomain$ = createEffect(() => this.getShopsByDomain());

  public readonly getTags$ = createEffect(() => this.getTags());

  public readonly getVendorSettings$ = createEffect(() => this.getVendorSettings());

  public readonly toggleShop$ = createEffect(() => this.toggleShop());

  public readonly saveStrategy$ = createEffect(() => this.saveStrategy());

  public readonly publishPreprocessingTask$ = createEffect(() => this.publishPreprocessingTask());

  public readonly getProductPriceRecommendation$ = createEffect(() =>
    this.getProductPriceRecommendation()
  );

  public readonly getProductOffers$ = createEffect(() => this.getProductOffers());

  public readonly getProductProperties$ = createEffect(() => this.getProductProperties());

  private readonly contractSwitchInit$ = this.actions$.pipe(ofType(contractSwitchAction.init));

  constructor(
    private readonly pricerecommendationsService: PricerecommendationsService,
    private readonly settingsService: SettingsService,
    private readonly productsService: ProductsService,
    private readonly preprocessingService: PreprocessingService,
    private readonly tasksService: TasksService,
    private readonly offersService: OffersService,
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly snackbarService: SnackbarService,
    private readonly strategyService: StrategyService
  ) {}

  ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(pricingStrategyInit),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(pricingStrategyDestroy))))
      )
    );
  }

  private getStrategy(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchStrategy.init),
      switchMap(({ sid }) =>
        this.pricerecommendationsService.getRepricingStrategyVendorV2(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchStrategy.failed());
            return EMPTY;
          })
        )
      ),
      map((apiData) => fetchStrategy.success({ apiData }))
    );
  }

  private getStrategyByDocumentVersion(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchStrategyByDocumentVersion.init),
      withLatestFrom(this.store.select(selectRouteParams)),
      switchMap(([{ documentVersion }, { id }]) => {
        return this.pricerecommendationsService
          .getRepricingStrategyVendorV2(id, documentVersion)
          .pipe(
            catchError(() => {
              this.store.dispatch(fetchStrategyByDocumentVersion.failed());
              return EMPTY;
            })
          );
      }),
      map((apiData) => fetchStrategyByDocumentVersion.success({ apiData }))
    );
  }

  private getStrategyMetadataHistory(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchStrategyMetadataHistory.init),
      switchMap(({ sid }) =>
        this.strategyService.getStrategyHistory(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchStrategyMetadataHistory.failed());
            return EMPTY;
          })
        )
      ),
      map(({ data }) =>
        fetchStrategyMetadataHistory.success({ strategyMetadataHistory: data.history })
      )
    );
  }

  // Get task data that's needed for CSV file
  private getTaskData(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchTaskData.init),
      switchMap(({ sid }) =>
        this.tasksService.getTasksVendorV2(sid, undefined, PREPROCESSING_TASK_TYPE, 1).pipe(
          map(([taskData]) => fetchTaskData.success({ taskData })),
          catchError(() => {
            this.store.dispatch(fetchTaskData.failed());
            return EMPTY;
          })
        )
      )
    );
  }

  private getProductData(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchTaskData.success),
      withLatestFrom(this.store.select(selectRouteParams)),
      switchMap(([{ taskData }, { id }]) => {
        if (taskData?.failures?.length) {
          this.productQuery.pagination.limit = taskData.failures?.length;
          this.productQuery.filter!.oneOf!.values = taskData.failures?.map(
            (failedTaskData: any) => failedTaskData.attributes.productId
          );
          return this.productsService.queryProductsVendorV3(id, this.productQuery).pipe(
            map((productData) => fetchProductData.success({ productData })),
            catchError(() => {
              this.store.dispatch(fetchProductData.failed());
              return EMPTY;
            })
          );
        } else {
          return EMPTY;
        }
      })
    );
  }

  // When the user triggers a price calculation, it should be reflected in the Calculate prices
  // button state and be persistent so that when he leaves the page and comes back, if that
  // calculation is not finished, he won't be able to trigger another one
  private getTaskStatus(): Observable<Action> {
    return combineLatest([
      this.actions$.pipe(ofType(fetchTaskStatus.init, contractSwitchAction.success)),
      this.store.select(selectRouteParams),
    ]).pipe(
      switchMap(([, { id }]) => {
        let isCreatingTask = true;
        return this.tasksService.getTasksVendorV2(id, undefined, PREPROCESSING_TASK_TYPE, 1).pipe(
          map(([data]) => {
            const { state } = data;
            isCreatingTask = state === PreprocessingTaskState.Pending;

            switch (state) {
              case PreprocessingTaskState.Pending:
                return publishPreprocessingTask.inProgress();
              case PreprocessingTaskState.Failed:
                return publishPreprocessingTask.failed();
              case PreprocessingTaskState.Succeeded:
              default:
                return publishPreprocessingTask.success();
            }
          }),
          repeat({ delay: TASK_POLLING_INTERVAL }),
          takeWhile(() => isCreatingTask, true),
          catchError(() => {
            this.store.dispatch(publishPreprocessingTask.failed());
            return EMPTY;
          }),
          takeUntil(
            this.contractSwitchInit$.pipe(
              tap(() => this.store.dispatch(publishPreprocessingTask.aborted()))
            )
          )
        );
      })
    );
  }

  private getDomains(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchDomains.init),
      switchMap(({ sid }) =>
        this.settingsService.getDomainsVendorV2(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchDomains.failed());
            return EMPTY;
          })
        )
      ),
      map(({ data }) => fetchDomains.success({ domains: data }))
    );
  }

  private getShopsByDomain(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchShopsByDomain.init),
      switchMap(({ sid }) =>
        this.offersService.getOffersShopsVendorV3(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchShopsByDomain.failed());
            return EMPTY;
          })
        )
      ),
      map((apiData) => {
        const shopsByDomain: Map<string, string[]> = new Map(
          apiData.data.map((i) => [i.domain, Array.from(i.shops)])
        );
        return fetchShopsByDomain.success({ shopsByDomain });
      })
    );
  }

  private getTags(): Observable<Action> {
    return this.actions$.pipe(ofType(fetchTags.init)).pipe(
      switchMap(({ sid }) =>
        this.productsService.getTagsVendorV2(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchTags.failed());
            return EMPTY;
          })
        )
      ),
      map((tags) => fetchTags.success({ tags }))
    );
  }

  private getVendorSettings(): Observable<Action> {
    return this.actions$.pipe(ofType(fetchVendorSettings.init)).pipe(
      switchMap(({ sid }) =>
        this.settingsService.getVendorSettingsV2VendorV2(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchVendorSettings.failed());
            return EMPTY;
          })
        )
      ),
      map((vendorSettings) => fetchVendorSettings.success({ vendorSettings }))
    );
  }

  private toggleShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(toggleShop.init),
      withLatestFrom(
        this.store.select(selectRouteParams),
        this.store.select(selectStrategyVendorSettings)
      ),
      mergeMap(([{ domain, shop }, { id }, vendorSettings]) => {
        if (!vendorSettings?.shops) return toggleShop.failed();
        const shopPayload = { domain, shop };
        const settings = cloneDeep(vendorSettings);
        if (settings && some(settings.shops, shopPayload))
          settings.shops = settings.shops.filter((item) => !isEqual(item, shopPayload));
        else settings?.shops.push(shopPayload);
        return this.settingsService.putVendorSettingsVendorV2(id, settings).pipe(
          catchError((error: HttpErrorType) => {
            this.snackbarService.error({
              message: httpErrorMessageMapper(error.status),
              hasHtml: true,
            });
            this.store.dispatch(toggleShop.failed());
            return EMPTY;
          })
        );
      }),
      map((vendorSettings) => toggleShop.success({ vendorSettings }))
    );
  }

  private saveStrategy(): Observable<Action> {
    return this.actions$.pipe(
      ofType(saveStrategy.init),
      withLatestFrom(this.store.select(selectRouteParams)),
      switchMap(([apiTree, { id }]) => {
        return this.pricerecommendationsService.putRepricingStrategyVendorV2(id, apiTree).pipe(
          map(() => {
            this.store.dispatch(fetchStrategyMetadataHistory.init({ sid: id }));
            this.store.dispatch(fetchStrategy.init({ sid: id }));
            return saveStrategy.success();
          }),
          catchError((error: HttpErrorType) => {
            this.snackbarService.error({
              message: httpErrorMessageMapper(error.status),
              hasHtml: true,
            });
            this.store.dispatch(saveStrategy.failed());
            return EMPTY;
          }),
          takeUntil(
            this.contractSwitchInit$.pipe(tap(() => this.store.dispatch(saveStrategy.aborted())))
          )
        );
      })
    );
  }

  private publishPreprocessingTask(): Observable<Action> {
    return this.actions$.pipe(
      ofType(publishPreprocessingTask.init),
      withLatestFrom(this.store.select(selectRouteParams)),
      switchMap(([, { id }]) =>
        // We don't want to trigger callback tasks once preprocessing is done therefore we hard code it to false
        this.preprocessingService
          .publishPreprocessingTaskVendorV3(TIMERANGE_IN_MINUTES, id, false)
          .pipe(
            map(() => id),
            catchError((error: HttpErrorType) => {
              this.snackbarService.error({
                message: httpErrorMessageMapper(error.status),
                hasHtml: true,
              });
              this.store.dispatch(publishPreprocessingTask.failed());
              return EMPTY;
            })
          )
      ),
      switchMap((id) => {
        let isCreatingTask = true;
        return this.tasksService.getTasksVendorV2(id, undefined, PREPROCESSING_TASK_TYPE, 1).pipe(
          map(([data]) => {
            const { state } = data;
            isCreatingTask = state === PreprocessingTaskState.Pending;
            if (state === PreprocessingTaskState.Failed) {
              this.snackbarService.error({
                message: httpErrorMessageMapper(0),
                hasHtml: true,
              });
              this.store.dispatch(fetchTaskData.success({ taskData: data }));
              return publishPreprocessingTask.failed();
            }
            if (state === PreprocessingTaskState.Succeeded) {
              this.snackbarService.success({
                message: PREPROCESSING_TASK_SUCCESS_MSG,
              });
              this.store.dispatch(fetchTaskData.success({ taskData: data }));
              return publishPreprocessingTask.success();
            }
            return publishPreprocessingTask.inProgress();
          }),
          repeat({ delay: TASK_POLLING_INTERVAL }),
          takeWhile(() => isCreatingTask, true),
          catchError((error: HttpErrorType) => {
            this.snackbarService.error({
              message: httpErrorMessageMapper(error.status),
              hasHtml: true,
            });
            this.store.dispatch(fetchTaskData.failed());
            this.store.dispatch(publishPreprocessingTask.failed());
            return EMPTY;
          }),
          takeUntil(
            this.contractSwitchInit$.pipe(
              tap(() => this.store.dispatch(publishPreprocessingTask.aborted()))
            )
          )
        );
      })
    );
  }

  /**
   * Retrieve the price recommendation for a specific product by a query
   */
  private getProductPriceRecommendation(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchProductPriceRecommendation.init),
      switchMap(({ sid, productId, timestamp }) => {
        const pagination: Pagination = { start: 0, limit: 1 };
        const query = createPriceRecommendationQuery(productId, timestamp, pagination);
        return this.pricerecommendationsService.queryPriceRecommendationsVendorV2(sid, query).pipe(
          catchError(() => {
            this.store.dispatch(fetchProductPriceRecommendation.failed());
            return EMPTY;
          })
        );
      }),
      map((apiData) => {
        const productPriceRecommendation = apiData.data[0];
        return fetchProductPriceRecommendation.success({ productPriceRecommendation });
      })
    );
  }

  /**
   * Retrieve the offers for a specific product by a query
   */
  private getProductOffers(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchProductOffers.init),
      withLatestFrom(this.store.select(selectQueryParams)),
      switchMap(
        ([
          { sid, contractId, productId },
          { maxOfferCreationTimestamp, minOfferCreationTimestamp },
        ]) => {
          const pagination: Pagination = { start: 0, limit: 10000 };
          const timeRange = createQueryTimeRange(
            minOfferCreationTimestamp,
            maxOfferCreationTimestamp
          );
          if (timeRange === undefined) {
            return EMPTY;
          }
          const query = createProductOffersQuery(contractId, productId, timeRange, pagination);
          return this.offersService.queryOffersVendorV3(sid, query).pipe(
            catchError(() => {
              this.store.dispatch(fetchProductOffers.failed());
              return EMPTY;
            })
          );
        }
      ),
      map((apiData) => {
        const productOffers = apiData.data;
        return fetchProductOffers.success({ productOffers });
      })
    );
  }

  /**
   * Retrieve product property keys for a given contract
   */
  private getProductProperties(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchProductProperties.init),
      switchMap(({ sid }) => {
        return this.productsService.getProductPropertyKeysV3(sid).pipe(
          catchError(() => {
            this.store.dispatch(fetchProductProperties.failed());
            return EMPTY;
          })
        );
      }),
      map(({ data }) => {
        return fetchProductProperties.success({ productProperties: data });
      })
    );
  }
}
