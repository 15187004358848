<!-- TODO: why is this is a button. This should be a link  -->
<span class="link-item flex">
  <button
    class="relative flex cursor-pointer items-center border-0 bg-transparent py-5 px-0"
    [ngClass]="{ active: selected, 'text-gray-400': disabled, 'text-accent': !disabled }"
    [disabled]="disabled"
    [routerLink]="[route]"
    (click)="onClick()"
  >
    {{ placeholder }}</button
  ><i
    *ngIf="hasIcon"
    class="material-icons self-center py-4 opacity-30"
    [ngClass]="disabled ? 'text-gray-400' : 'text-accent'"
    >keyboard_arrow_right</i
  >
</span>
