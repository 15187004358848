import { BehaviorSubject, map } from 'rxjs';

import { cloneDeep, compact, get, isArray, isEqual, set, unset } from 'lodash';

import { EXTERNAL_DROPPABLE_ELEMENT_PATH } from '../constants';
import { PeanutTree, PeanutTreeUI } from '../PeanutTree';
import { CompetitivePricingStrategy } from '../ui-strategy-elements/competitive-pricing/competitive-pricing';
import {
  IfCompetitionStrategy,
  IfFormulaAppliesStrategy,
  IfRecommendedPriceStrategy,
  IfStrategyFilters,
} from '../ui-strategy-elements/conditions/conditions';
import { PriceFormulaStrategy } from '../ui-strategy-elements/price-formula/price-formula';
import { SafetyRuleStrategy } from '../ui-strategy-elements/saftey-rule/safety-rule';
import {
  getBranchRuleName,
  resetLeavesRuleNameToNull,
  setFirstLeafRuleNameToTargetRuleName,
  shouldPropagateChildRuleNameToSourceParent,
} from '../utils/branch-rule-name';

export class PeanutTreeService {
  public draggedChildIndex: number | undefined;

  public draggedChild: PeanutTreeUI | undefined;

  public draggedParent: PeanutTreeUI | undefined;

  private readonly _isDragging = new BehaviorSubject<boolean>(false);

  public readonly isDragging$ = this._isDragging.asObservable();

  public set isDragging(val: boolean) {
    this._isDragging.next(val);
  }

  public get isDragging(): boolean {
    return this._isDragging.getValue();
  }

  private readonly _isDirty = new BehaviorSubject<boolean>(false);

  public readonly isDirty$ = this._isDirty.asObservable();

  public set isDirty(val: boolean) {
    this._isDirty.next(val);
  }

  public get isDirty(): boolean {
    return this._isDirty.getValue();
  }

  private readonly _lastCreatedPath = new BehaviorSubject<string | undefined>(undefined);

  public readonly lastCreatedPath$ = this._lastCreatedPath.asObservable();

  public set lastCreatedPath(val: string | undefined) {
    this._lastCreatedPath.next(val);
  }

  public get lastCreatedPath(): string | undefined {
    return this._lastCreatedPath.getValue();
  }

  private readonly _selectedChild = new BehaviorSubject<PeanutTreeUI | undefined>(undefined);

  public readonly selectedChild$ = this._selectedChild.asObservable();

  public readonly selectedChildPath$ = this.selectedChild$.pipe(map((child) => child?.path));

  public readonly selectedChildData$ = this.selectedChild$.pipe(map((child) => child?.data));

  public set selectedChild(val: PeanutTreeUI | undefined) {
    if (
      (val === undefined || (this.selectedChild?.path && this.selectedChild.path !== val.path)) &&
      this.draggedChild?.path === EXTERNAL_DROPPABLE_ELEMENT_PATH
    ) {
      const parentPath = this.selectedChild?.path?.slice(
        0,
        this.selectedChild?.path.lastIndexOf('.')
      );
      if (!parentPath || !this.selectedChild) return;
      const selectedParent: PeanutTreeUI = get(this.tree, parentPath);
      let selectedChildIndex = selectedParent?.children.findIndex((child) =>
        isEqual(child, this.selectedChild)
      );
      // This check safe guard in case of new unsaved node 'findIndex' above returns -1 i.e  selectedChildIndex is -1 which throws errors that blocks the code flow.
      if (selectedChildIndex >= 0)
        this.deleteNode(true, this.selectedChild, selectedParent, selectedChildIndex);
      this.draggedParent = undefined;
      this.draggedChild = undefined;
      this.draggedChildIndex = undefined;
      val = undefined;
    }
    this._selectedChild.next(val);
  }

  public get selectedChild(): PeanutTreeUI | undefined {
    return this._selectedChild.getValue();
  }

  private readonly _tree = new BehaviorSubject<PeanutTreeUI>(new PeanutTreeUI());

  public readonly tree$ = this._tree.asObservable();

  public readonly startNode$ = this.tree$.pipe(map((tree) => tree.children[0]));

  public readonly children$ = this.tree$.pipe(map((tree) => tree.children[0].children));

  public set tree(val: PeanutTreeUI) {
    this._tree.next(val);
  }

  public get tree(): PeanutTreeUI {
    return this._tree.getValue();
  }

  /**
   * Adds paths to `children` array items in the provided data
   * and sets the initial value of the store.
   *
   * @param PeanutTree  initialTree  The initial data as it comes from the backend.
   *
   * @return void
   */
  public init = (initialTree: PeanutTree) => {
    const workingTree = cloneDeep(initialTree);
    this.addPaths(workingTree);
    this.tree = workingTree;
    this.isDirty = false;
  };

  public paste = (pastedStrategy: PeanutTree) => {
    this.init(pastedStrategy);
    this.isDirty = true;
  };

  /**
   * Recursively mutates a given tree setting the path value for all `children` array items.
   *
   * @param any       node           The element to add the path if it is part
   *                                 of a `children` array.
   * @param string[]  stack          The initial pile of keys leading to the
   *                                 current value.
   *
   * @return void
   */
  private addPaths = (node: any, stack: string[] = []) => {
    if (typeof node === 'object') {
      for (const key in node) {
        if (
          !isArray(node) &&
          (stack[stack.length - 2] === 'children' || stack.length === 0 || node.path)
        )
          node.path = stack.join('.').replace(/(?:\.)(\d+)(?![a-z_])/gi, '[$1]');
        this.addPaths(node[key], [...stack, key]);
      }
    }
  };

  /**
   * Copies or moves nodes to different positions in the tree.
   *
   * @param PeanutTreeUI  target            The node where the copied/moved element
   *                                        will be placed.
   * @param boolean       isMoving          Wheter or not to remove the source element
   *                                        after it's copied to the new position.
   * @param number        index             Optional. The exact position in the children
   *                                        array where the copied element will be placed.
   * @param boolean       isTrueDirection   Optional. To determine if the element has to be
   *                                        copied to the children of the target node or as
   *                                        a new child of the target's parent.
   * @param boolean       isFirstOfBranch   Optional. Should the target's children be placed
   *                                        as children of the dragged node.
   *
   * @return void
   */
  public copyOrMoveNode = (
    target: PeanutTreeUI,
    isMoving: boolean,
    index?: number,
    isTrueDirection?: boolean,
    isFirstOfBranch?: boolean
  ) => {
    if (this.draggedChild === undefined || target.path === undefined) return;
    const workingTree = cloneDeep(this.tree);

    const draggedChild = cloneDeep(this.draggedChild);

    // [Don't override leaf target ruleName while move/copy] hence we reset all leaves' ruleName of the copied/moved nodes(s) to null.
    resetLeavesRuleNameToNull(draggedChild);
    // If target name is a leaf with ruleName then assign ruleName to the 1st leaf.
    if (target.children.length === 0 && target.data.ruleName) {
      setFirstLeafRuleNameToTargetRuleName(draggedChild, target);
    }

    // [Retain Source ruleName on Move] hence assign ruleName to parent after moving child (only if single child)
    if (this.draggedParent && isMoving) {
      let parentNode = get(workingTree, this.draggedParent.path!);
      parentNode.data.ruleName = shouldPropagateChildRuleNameToSourceParent(this.draggedParent)
        ? getBranchRuleName(this.draggedParent)
        : null;
    }
    // [Cleanup] leaf target node ruleName
    if (target.children.length === 0 && target.data.ruleName) {
      let targetNode = get(workingTree, target.path);
      targetNode.data.ruleName = null;
    }

    const draggedChildrenPath = this.draggedParent?.path
      ? this.draggedParent.path + '.children'
      : 'children';
    let draggedChildren = get(workingTree, draggedChildrenPath);

    if (isMoving && isArray(draggedChildren)) {
      draggedChildren.splice(this.draggedChildIndex || 0, 1, undefined);
      draggedChildren = draggedChildren.filter((child) => {
        return child !== undefined;
      });
      set(workingTree, draggedChildrenPath, draggedChildren);
    }

    const targetChildrenPath = target.path ? target.path + '.children' : 'children';
    let targetChildren: PeanutTreeUI[] = get(workingTree, targetChildrenPath);

    const moveToLastChild = (children: PeanutTreeUI[]) => {
      let nextChildren = children[0]?.children;
      if (isArray(nextChildren) && nextChildren.length > 0) {
        moveToLastChild(nextChildren);
      } else {
        if (children[0]) {
          children[0].children = targetChildren;
        } else {
          children.push(...targetChildren);
        }
      }
    };

    if (isFirstOfBranch) {
      const draggedParentChildren = cloneDeep(this.draggedParent)?.children || [];
      if (draggedParentChildren[0] && !draggedParentChildren[0].children.length) {
        draggedParentChildren[0].children[0] = cloneDeep(targetChildren)[index || 0];
        targetChildren.splice(index || 0, 1, draggedParentChildren[0]);
      } else {
        const targetChildrenFull = cloneDeep(targetChildren);
        targetChildren = [targetChildren[index || 0]];
        moveToLastChild(draggedChild.children);
        targetChildrenFull.splice(index || 0, 1, cloneDeep(draggedChild));
        targetChildren = targetChildrenFull;
      }
    } else if (isArray(targetChildren) && targetChildren.length > 0) {
      if (isTrueDirection) {
        moveToLastChild(draggedChild.children);
        targetChildren = [draggedChild];
      } else if (index !== undefined) {
        targetChildren.splice(index, 0, draggedChild);
      } else {
        targetChildren.push(draggedChild);
      }
    } else {
      targetChildren = [draggedChild];
    }

    this.addPaths(targetChildren, [targetChildrenPath]);
    set(workingTree, targetChildrenPath, targetChildren);
    this.tree = workingTree;
    this.lastCreatedPath = `${targetChildrenPath}[${index || 0}]`;
    if (this.draggedChild.path === EXTERNAL_DROPPABLE_ELEMENT_PATH) {
      this.selectedChild = get(this.tree, this.lastCreatedPath);
      if (this.selectedChild && target.data.ruleName) {
        this.selectedChild.data.ruleName = target.data.ruleName;
        target.data.ruleName = null;
      }
    } else {
      this.isDirty = true;
    }
  };

  /**
   * Removes nodes from the tree.
   *
   * @param boolean       isNotDeletingChildren   Wheter or not to remove the children
   *                                              of the deleted element.
   * @param PeanutTreeUI  node                    The element to be removed.
   * @param PeanutTreeUI  parent                  The parent of the element to be removed.
   * @param number        index                   The exact position of the element in its
   *                                              parent's children array.
   *
   * @return void
   */
  public deleteNode = (
    isNotDeletingChildren: boolean,
    node: PeanutTreeUI,
    parent: PeanutTreeUI,
    index: number
  ) => {
    const workingTree = cloneDeep(this.tree);
    const children: PeanutTreeUI[] = get(workingTree, node.path + '.children');
    const parentPath = parent.path;
    const parentChildrenPath = parentPath + '.children';
    let parentChildren: PeanutTreeUI[] = get(workingTree, parentChildrenPath);

    // Parent only gets assigned the rule name if they are becoming leafs after deletion.
    if (parent.children.length === 1) {
      // Move ruleName to the parent element when deleting, to keep the rulename displayed
      let parentElement: PeanutTreeUI = get(workingTree, parentPath || '');
      parentElement.data.ruleName = isNotDeletingChildren
        ? node.data.ruleName
        : getBranchRuleName(parentElement);
    }
    unset(workingTree, node.path || '');

    if (isNotDeletingChildren) parentChildren.splice(index, 1, ...children);

    parentChildren = compact(parentChildren);
    this.addPaths(parentChildren, [parentChildrenPath]);
    set(workingTree, parentChildrenPath, parentChildren);
    this.tree = workingTree;
    if (this.draggedChild?.path !== EXTERNAL_DROPPABLE_ELEMENT_PATH) this.isDirty = true;
  };

  /**
   * Edits the data of the selected node.
   *
   * @param data  | IfTagStrategy               The data object to update the node with.
   *              | IfCompetitionStrategy
   *              | IfFormulaAppliesStrategy
   *              | IfRecommendedPriceStrategy
   *              | PriceFormulaStrategy
   *              | SafetyRuleStrategy
   *              | CompetitivePricingStrategy
   *
   * @return void
   */
  public editSelectedNode = (
    data:
      | IfStrategyFilters
      | IfCompetitionStrategy
      | IfFormulaAppliesStrategy
      | IfRecommendedPriceStrategy
      | PriceFormulaStrategy
      | SafetyRuleStrategy
      | CompetitivePricingStrategy
  ) => {
    const workingTree = cloneDeep(this.tree);
    const selectedChild = cloneDeep(this.selectedChild);
    if (!selectedChild?.path) return;
    selectedChild.data = {
      ...selectedChild.data,
      ...data,
    };
    set(workingTree, selectedChild.path, selectedChild);
    this.selectedChild = undefined;
    this.tree = workingTree;
    this.isDirty = true;
  };
}
