import { set } from 'lodash';

import { StockClearanceStrategy } from '@app/components/strategy-component/ui-strategy-elements/goal-based-pricing/stock-clearance';
import { DIRECT_SCRAPING, DIRECT_SCRAPING_CONNECTOR } from '@app/models/const/pricing-strategy';

import {
  ApiCompetitorsOnPosition,
  ApiFormulaComparison,
  ApiFormulaPriceFilter,
  ApiPriceCalculationType,
  ApiPriceGapUnit,
  ApiSelectors,
  ApiStrategy,
  ApiStrategyContextModifiers,
  ApiStrategyName,
  ApiVendors,
  IApiStrategyCondition,
  IApiStrategyFilters,
} from '@models/interfaces/api-strategies';

import { Comparator, UIStrategyType } from '@components/index';
import { CompetitivePricingStrategy } from '@components/strategy-component/ui-strategy-elements/competitive-pricing/competitive-pricing';
import {
  IfCompetitionStrategy,
  IfFormulaAppliesStrategy,
  IfRecommendedPriceStrategy,
  IfStrategyFilters,
  StringComparisonCondition,
  TimestampRangeCondition,
} from '@components/strategy-component/ui-strategy-elements/conditions/conditions';
import { PriceFormulaStrategy } from '@components/strategy-component/ui-strategy-elements/price-formula/price-formula';
import { SafetyRuleStrategy } from '@components/strategy-component/ui-strategy-elements/saftey-rule/safety-rule';
import { StartStrategy } from '@components/strategy-component/ui-strategy-elements/start/start';

import { Condition } from './peanut-tree-factory';

export type UiStrategy =
  | StartStrategy
  | SafetyRuleStrategy
  | CompetitivePricingStrategy
  | PriceFormulaStrategy
  | StockClearanceStrategy
  | IfStrategyFilters
  | IfCompetitionStrategy
  | IfFormulaAppliesStrategy
  | IfRecommendedPriceStrategy;

export class ApiStrategyFactory {
  // Maps each uiStrategy type to its corresponding create ApiStrategy
  static readonly uiApiStrategyMap: Map<string, (uiStrategy: UiStrategy) => ApiStrategy> =
    this.getUiToApiStrategyMap();

  static readonly uiApiTypesMap: Map<UIStrategyType, ApiStrategyName> = this.getUiToApiTypesMap();

  private static getUiToApiStrategyMap(): Map<string, (uiStrategy: UiStrategy) => ApiStrategy> {
    const mapping: Map<string, (uiStrategy: UiStrategy) => ApiStrategy> = new Map();
    mapping.set(UIStrategyType.START, this.createApiStrategyContextModifiers);
    mapping.set(UIStrategyType.COMPETITIVE_PRICING, this.createApiStrategyContextModifiers);
    mapping.set(UIStrategyType.SAFETY_RULE, this.createApiStrategyContextModifiers);
    mapping.set(UIStrategyType.PRICE_FORMULA, this.createApiStrategyContextModifiers);
    mapping.set(UIStrategyType.STOCK_CLEARANCE, this.createApiStrategyContextModifiers);
    mapping.set(UIStrategyType.IF_TAG, this.createApiStrategyFilters);
    mapping.set(UIStrategyType.IF_TIMESTAMP_RANGE, this.createApiStrategyFilters);
    mapping.set(UIStrategyType.IF_COMPETITOR, this.createApiCompetitorsOnPosition);
    mapping.set(UIStrategyType.IF_FORMULA, this.createApiFormulaComparison);
    mapping.set(UIStrategyType.IF_RECOMMENDED_PRICE, this.createApiFormulaPriceFilter);
    return mapping;
  }

  private static getUiToApiTypesMap(): Map<UIStrategyType, ApiStrategyName> {
    const mapping: Map<UIStrategyType, ApiStrategyName> = new Map();
    mapping.set(UIStrategyType.START, ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS);
    mapping.set(UIStrategyType.COMPETITIVE_PRICING, ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS);
    mapping.set(UIStrategyType.PRICE_FORMULA, ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS);
    mapping.set(UIStrategyType.SAFETY_RULE, ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS);
    mapping.set(UIStrategyType.STOCK_CLEARANCE, ApiStrategyName.STRATEGY_CONTEXT_MODIFIERS);
    mapping.set(UIStrategyType.IF_COMPETITOR, ApiStrategyName.COMPETITOR);
    mapping.set(UIStrategyType.IF_FORMULA, ApiStrategyName.FORMULA_COMPARISON);
    mapping.set(UIStrategyType.IF_RECOMMENDED_PRICE, ApiStrategyName.FORMULA_PRICE_FILTER);
    mapping.set(UIStrategyType.IF_TAG, ApiStrategyName.FILTERS);
    mapping.set(UIStrategyType.IF_TIMESTAMP_RANGE, ApiStrategyName.FILTERS);
    return mapping;
  }

  // Used to lowercase the formula functions signatures before saving to API
  static functionSignatureToLowercase(formula: string): string {
    const formulaFunctionsSignatures = {
      MAX2: 'max2',
      MAX3: 'max3',
      MAX4: 'max4',
      MAX5: 'max5',
      MIN2: 'min2',
      MIN3: 'min3',
      MIN4: 'min4',
      MIN5: 'min5',
    };
    const functionsSignaturesRegex = /(MAX[2-5]|MIN[2-5])/g;
    return formula.replace(
      functionsSignaturesRegex,
      (term) => formulaFunctionsSignatures[term as keyof typeof formulaFunctionsSignatures]
    );
  }

  /**
   * Convert to strategy context modifiers
   * @param uiStrategy can be StartStrategy or CompetitivePricingStrategy or SafetyRuleStrategy or PriceFormula but with different selectors
   * @returns
   */
  static createApiStrategyContextModifiers(
    uiStrategy:
      | StartStrategy
      | CompetitivePricingStrategy
      | PriceFormulaStrategy
      | SafetyRuleStrategy
      | StockClearanceStrategy
  ): ApiStrategyContextModifiers {
    let selectors = {};
    if (uiStrategy.strategyType === UIStrategyType.START) {
      selectors = ApiStrategyFactory.mapStartAttrToSelectors(uiStrategy);
    } else if (uiStrategy.strategyType === UIStrategyType.COMPETITIVE_PRICING) {
      selectors = ApiStrategyFactory.mapCompetitivePricingAttrToSelectors(uiStrategy);
    } else if (uiStrategy.strategyType === UIStrategyType.SAFETY_RULE) {
      selectors = ApiStrategyFactory.mapSafetyRulesAttrToSelectors(uiStrategy);
    } else if (uiStrategy.strategyType === UIStrategyType.PRICE_FORMULA) {
      selectors = ApiStrategyFactory.mapPriceFormulaAttrToSelectors(uiStrategy);
    } else if (uiStrategy.strategyType === UIStrategyType.STOCK_CLEARANCE) {
      selectors = ApiStrategyFactory.mapStockClearanceAttrToSelectors(uiStrategy);
    } else {
      throw new Error('Unsupported UI strategy type');
    }
    return {
      StrategyContextModifiers: {
        selectors,
        builder: {},
      },
    };
  }

  // priceCalculation value is set to tell backend if this context modifier is for configuration or price calculation / formula or target position
  static createPriceCalculationType(apiPriceCalculationType: ApiPriceCalculationType): {
    priceCalculation: { value: ApiPriceCalculationType };
  } {
    return {
      priceCalculation: {
        value: apiPriceCalculationType,
      },
    };
  }

  static mapStartAttrToSelectors(uiStrategy: StartStrategy): ApiSelectors {
    let selectors: ApiSelectors = {
      ...ApiStrategyFactory.createPriceCalculationType(ApiPriceCalculationType.TARGET_POSITION),
    };
    if (uiStrategy.priceGap !== undefined) {
      selectors.priceGap = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.priceGap),
      };
    }
    if (uiStrategy.priceGapUnit) {
      selectors.priceGapUnit = {
        value: uiStrategy.priceGapUnit as unknown as ApiPriceGapUnit,
      };
    }
    if (uiStrategy.targetPosition) {
      selectors.targetPosition = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.targetPosition),
      };
    }
    if (uiStrategy.priceRequirement !== undefined) {
      selectors.calculatePriceWhenNoOffersExist = {
        value: uiStrategy.priceRequirement,
      };
    }
    if (uiStrategy.adjustToNextPricier !== undefined) {
      selectors.adjustToNextPricier = {
        value: uiStrategy.adjustToNextPricier,
      };
    }

    return selectors;
  }

  // ToDo: Make Shop type exported from quantum
  private static groupVendorsByDomain(vendors: any[]): ApiVendors[] {
    const vendorGroups: Map<string, string[]> = vendors.reduce(
      (vendorsByDomain, vendorOfferFilter) => {
        // Hint: for direct scraping shops name presented in UI ĺike this: shopName/domain
        // to distinguish similar direct scraping shops having the same name, so we need to decompose them before save to API
        if (vendorOfferFilter.domain === DIRECT_SCRAPING) {
          const [shop, domain] = vendorOfferFilter.shop.split(DIRECT_SCRAPING_CONNECTOR);
          vendorOfferFilter.domain = domain;
          vendorOfferFilter.shop = shop;
        }
        if (!vendorsByDomain[vendorOfferFilter.domain]) {
          vendorsByDomain[vendorOfferFilter.domain] = [];
        }
        vendorsByDomain[vendorOfferFilter.domain].push(vendorOfferFilter.shop);
        return vendorsByDomain;
      },
      {}
    );
    return Object.keys(vendorGroups).map((domain) => ({
      domain,
      // @ts-ignore till we figure out the todo above
      vendorNames: vendorGroups[domain],
    }));
  }

  static mapCompetitivePricingAttrToSelectors(
    uiStrategy: CompetitivePricingStrategy
  ): ApiSelectors {
    let selectors: ApiSelectors = {
      ...ApiStrategyFactory.createPriceCalculationType(ApiPriceCalculationType.TARGET_POSITION),
    };
    if (uiStrategy.priceGap !== undefined) {
      selectors.priceGap = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.priceGap),
      };
    }
    if (uiStrategy.priceGapUnit) {
      selectors.priceGapUnit = {
        value: uiStrategy.priceGapUnit as unknown as ApiPriceGapUnit,
      };
    }
    if (uiStrategy.targetPosition) {
      selectors.targetPosition = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.targetPosition),
      };
    }
    if (uiStrategy.vendorOfferFilter !== undefined) {
      selectors.vendorOfferFilter = {
        vendors: ApiStrategyFactory.groupVendorsByDomain(uiStrategy.vendorOfferFilter),
      };
    }
    if (uiStrategy.includeDeliveryCosts !== undefined) {
      selectors.includeDeliveryCosts = {
        value: uiStrategy.includeDeliveryCosts,
      };
    }
    return selectors;
  }

  static mapSafetyRulesAttrToSelectors(uiStrategy: SafetyRuleStrategy): ApiSelectors {
    let selectors: ApiSelectors = {};
    if (uiStrategy.minPrice) {
      selectors.minPrice = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.minPrice),
      };
    }
    if (uiStrategy.maxPrice) {
      selectors.maxPrice = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.maxPrice),
      };
    }
    return selectors;
  }

  static mapPriceFormulaAttrToSelectors(uiStrategy: PriceFormulaStrategy): ApiSelectors {
    let selectors: ApiSelectors = {
      ...ApiStrategyFactory.createPriceCalculationType(ApiPriceCalculationType.FORMULA),
    };
    if (uiStrategy.priceFormula) {
      selectors.priceFormula = {
        value: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.priceFormula),
      };
    }
    if (uiStrategy.priceRequirement !== undefined) {
      selectors.calculatePriceWhenNoOffersExist = {
        value: uiStrategy.priceRequirement,
      };
    }
    return selectors;
  }

  static mapStockClearanceAttrToSelectors(uiStrategy: StockClearanceStrategy): ApiSelectors {
    let selectors: ApiSelectors = ApiStrategyFactory.createPriceCalculationType(
      ApiPriceCalculationType.TARGET_LEVEL_STOCK_CLEARANCE
    );
    if (uiStrategy.startDate !== undefined) {
      selectors.startDate = uiStrategy.startDate.value
        ? { value: uiStrategy.startDate?.value }
        : { tagKey: uiStrategy.startDate.tagKey as string };
    }
    if (uiStrategy.endDate !== undefined) {
      selectors.endDate = uiStrategy.endDate.value
        ? { value: uiStrategy.endDate?.value }
        : { tagKey: uiStrategy.endDate.tagKey as string };
    }

    if (uiStrategy.targetStockLevel !== undefined && uiStrategy.targetStockLevel !== null) {
      selectors.targetStockLevel = {
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.targetStockLevel),
      };
    }
    if (uiStrategy.continuePricing !== undefined) {
      selectors.calculatePriceAfterEndDate = { value: uiStrategy.continuePricing };
    }
    return selectors;
  }

  /**
   * This method maps UI condition strategy query to API ApiStrategyFilters whether consists of single condition or nested nested operators/conditions
   * Context:
   * - Given our UI: no conditions grouping, so the query will always contain n logical operator(s) & n+1 conditon(s)
   * - Nested operator combines 1 operater and 1 condition
   * - Leaf operator combines 2 conditons
   * - Path variable always points to the last object path to insert into
   *
   * @param strategy
   * @returns
   */
  static createApiStrategyFilters(strategy: UiStrategy): IApiStrategyFilters {
    const uiStrategy = strategy as IfStrategyFilters;
    const apiStrategy: IApiStrategyFilters = {
      [ApiStrategyName.FILTERS]: {
        query: {},
        builder: {},
      },
    };

    //(1) Handle nested and/or
    if (uiStrategy.query.length > 1) {
      let path = ApiStrategyName.FILTERS + '.query';

      const operators = uiStrategy.query.filter((q) => q.operator).map((q) => q.operator);

      // Append n operators along with n conditions
      operators.forEach((op, i) => {
        // Append 1st operator (root operator)
        if (i === 0) path = path + '.' + op;
        // check if there is next nested operator i.e nested operator
        if (operators[i + 1]) {
          //set(apiStrategy, path + '[1].' + operators[i + 1], []);
          set(
            apiStrategy,
            path + '[0]',
            ApiStrategyFactory.createApiCondition(uiStrategy.query[i])
          );
          path = path + '[1].' + operators[i + 1];
        } else {
          // Leaf operator
          // - Append nth condition
          set(
            apiStrategy,
            path + '[0]',
            ApiStrategyFactory.createApiCondition(uiStrategy.query[i])
          );
          // - Append last (n+1) condition to query leaf operator
          set(
            apiStrategy,
            path + '[1]',
            ApiStrategyFactory.createApiCondition(uiStrategy.query[operators.length])
          );
        }
      });

      return apiStrategy;
    }

    // (2) Just single condition no and/or
    apiStrategy[ApiStrategyName.FILTERS].query = ApiStrategyFactory.createApiCondition(
      uiStrategy.query[0]
    );
    return apiStrategy;
  }

  static createApiCondition(strategyCondition: Condition): IApiStrategyCondition {
    const data = strategyCondition.data;
    if (strategyCondition.data.hasOwnProperty('tagName')) {
      const stringComparison = data as StringComparisonCondition;
      return this.createStringComparisonCondition(stringComparison);
    } else {
      const timestampRange = data as TimestampRangeCondition;
      return this.createTimestampRangeCondition(timestampRange);
    }
  }

  static createTimestampRangeCondition(
    strategyCondition: TimestampRangeCondition
  ): IApiStrategyCondition {
    const condition = {
      conditions: {
        timestampRange: {
          start: {
            tagKey: strategyCondition.start?.tagKey,
            value: strategyCondition.start?.value,
            productPropertyKey: strategyCondition.start?.productPropertyKey,
          },
          end: {
            tagKey: strategyCondition.end?.tagKey,
            value: strategyCondition.end?.value,
            productPropertyKey: strategyCondition.end?.productPropertyKey,
          },
        },
      },
    };
    return this.removeUndefinedProperties(condition);
  }

  static createStringComparisonCondition(
    strategyCondition: StringComparisonCondition
  ): IApiStrategyCondition {
    return {
      conditions: {
        stringComparison: {
          leftString: strategyCondition.productPropertyName
            ? { productPropertyKey: strategyCondition.productPropertyName }
            : { tagKey: strategyCondition.tagName },
          matcher: {
            type: strategyCondition.matcher,
          },
          rightString: {
            value: strategyCondition.productPropertyValue
              ? strategyCondition.productPropertyValue
              : strategyCondition.tagValue,
          },
        },
      },
    };
  }

  static createApiCompetitorsOnPosition(strategy: UiStrategy): ApiCompetitorsOnPosition {
    const uiStrategy = strategy as IfCompetitionStrategy;
    let apiCompetitorsOnPosition: ApiCompetitorsOnPosition = {
      CompetitorsOnPosition: {
        matcher: {
          [uiStrategy.competitorsMatcher.operator]: {
            matchValue: uiStrategy.competitorsMatcher.matchValue,
          },
        },
        builder: {},
      },
    };
    if (uiStrategy.positionMatcher) {
      if (uiStrategy.positionMatcher.operator) {
        apiCompetitorsOnPosition.CompetitorsOnPosition = {
          ...apiCompetitorsOnPosition.CompetitorsOnPosition,
          ...{
            position: {
              [uiStrategy.positionMatcher.operator]: {
                matchValue: uiStrategy.positionMatcher.matchValue,
              },
            },
          },
        };
      } else {
        // Default in any position case
        apiCompetitorsOnPosition.CompetitorsOnPosition = {
          ...apiCompetitorsOnPosition.CompetitorsOnPosition,
          ...{
            position: {
              [Comparator.GREATER]: {
                matchValue: 0,
              },
            },
          },
        };
      }
    }
    return apiCompetitorsOnPosition;
  }

  static createApiFormulaPriceFilter(strategy: UiStrategy): ApiFormulaPriceFilter {
    const uiStrategy = strategy as IfRecommendedPriceStrategy;
    return {
      FormulaPriceFilter: {
        matcher: {
          type: uiStrategy.operator,
        },
        formula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.formula),
        builder: {},
      },
    };
  }

  static createApiFormulaComparison(strategy: UiStrategy): ApiFormulaComparison {
    const uiStrategy = strategy as IfFormulaAppliesStrategy;
    return {
      FormulaComparison: {
        leftFormula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.leftFormula),
        rightFormula: ApiStrategyFactory.functionSignatureToLowercase(uiStrategy.rightFormula),
        matcher: {
          type: uiStrategy.operator,
        },
        builder: {},
      },
    };
  }

  /**
   * Remove undefined poperties. Also, remove nested objcets that only contain undefined properties
   * For example:
   *  parent = {
   *    childA: {grandChildA: 'someValue', grandChildB: undefined},
   *    childB: {grandChild: undefined}
   *  }
   * will be returned as:
   *  parent = {
   *    childA: {grandChildA: 'someValue'}
   *  }
   * @param obj
   * @returns object clean from undefined properties
   */
  static removeUndefinedProperties(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    Object.keys(obj).forEach((key) => {
      const propertyValue = obj[key];

      if (
        propertyValue === undefined ||
        (typeof propertyValue === 'object' &&
          Object.values(propertyValue).every((value) => value === undefined))
      ) {
        delete obj[key];
      } else {
        obj[key] = this.removeUndefinedProperties(propertyValue);
      }
    });

    return obj;
  }
}
