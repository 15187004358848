<form [formGroup]="filterSelect" class="inline pr-5">
  <mat-form-field floatLabel="never">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      (openedChange)="afterClose($event)"
      (valueChange)="change($event)"
      formControlName="selectedOption"
    >
      <div class="p-2">
        <input
          #filter
          matInput
          name="filter"
          formControlName="filter"
          type="text"
          placeholder="filter"
          (keydown)="$event.stopPropagation()"
        />
      </div>
      <div class="max-h-40 overflow-auto">
        <mat-option
          *ngFor="let filter of filteredValues$ | async"
          [value]="filter.options.reference || filter.options.value"
        >
          {{ filter.options.label }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</form>
