import { Component, Input } from '@angular/core';

import { BranchStats } from '../../models';

@Component({
  selector: 'app-branch-stats',
  templateUrl: './branch-stats.component.html',
  styleUrls: ['./branch-stats.component.scss'],
})
export class BranchStatsComponent {
  @Input() stats?: BranchStats;
}
