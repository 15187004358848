import { Component, Host, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';

import { PeanutTreeUI } from '@app/components/strategy-component/PeanutTree';
import { PeanutTreeService } from '@app/components/strategy-component/services/peanut-tree.service';
import { emptyTree } from '@app/components/strategy-component/stub';
import {
  ProductTag,
  RecommendationOffers,
} from '@app/models/interfaces/price-recommendations.interface';
import { SetHeaderSuccessAction } from '@app/store/legacy';

/**
 * This component presents a read-only version of the strategy component.
 * Its goal is to allow users see all data related to a price calculation without making any adjustments to the strategy
 */
@Component({
  selector: 'app-explain-why-strategy',
  templateUrl: './explain-why-strategy.component.html',
  styleUrls: ['./explain-why-strategy.component.scss'],
  providers: [PeanutTreeService],
})
export class ExplainWhyStrategyComponent implements OnChanges {
  @Input() tree?: PeanutTreeUI;

  @Input() shops?: Map<string, string[]>;

  @Input() domains?: string[];

  @Input() tags?: string[] = [];

  @Input() shopsByDomain?: Map<string, string[]>;

  @Input() varsMap = new Map();

  /**
   * CON-373: This input is used for the price explain banner.
   * Placing the banner outside app-strategy was hiding some aspect of the strategy view.
   * For example, putting it at the bottom of the screen was hiding the horizontal scrollbar.
   */
  @Input() priceCalculationDetails?: Map<string, string>;

  /**
   *CON-373: To keep code related to same concern close by, the warning message about the price banner has also been moved to app-strategy.
   */
  @Input() showFeatureMissingWarning = false;

  /**
   * Node ids of the evaluated path
   * Evaluated path is the path followed for a price calculation for a certain product
   */
  @Input() evaluatedPathNodeIds?: number[];

  @Input() recommendationOffers?: RecommendationOffers[];

  @Input() recommendationTags?: ProductTag[];

  bannerOpenState = false;

  isEditPanelOpen: boolean = false;

  offersDisplayedColumns: string[] = ['vendorName', 'unitPrice', 'deliveryCosts', 'domain'];

  tagsDisplayedColumns: string[] = ['key', 'value'];

  @ViewChild('recommendationPanel') recommendationPanel!: MatExpansionPanel;

  constructor(@Host() public treeStore: PeanutTreeService, private store: Store) {
    this.store.dispatch(
      SetHeaderSuccessAction({
        payload: {
          title: `Pricing Strategy Explain Why`,
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tree']) this.refresh();
    if (changes['recommendationOffers']) {
      this.bannerOpenState = false;
      if (this.recommendationPanel) {
        this.recommendationPanel.close();
      }
    }

    // Upon changes to Tree i.e re-render, the local state of treeStore.selectedChild should be clean up, which is used to show/hide the editpanel.
    this.treeStore.selectedChild = undefined;
  }

  private refresh() {
    this.treeStore.init(this.tree || emptyTree);
  }

  toggleEditPanelCallback(isEditPanelOpen: boolean) {
    setTimeout(() => {
      this.isEditPanelOpen = isEditPanelOpen;
    }, 0);
  }

  /**
   * To ensure that keyValue pipe preserves the order of Map entries
   * @returns 1 to keep item in its original slot
   * For more info, check this solution: https://github.com/angular/angular/issues/31420#issuecomment-509759583
   */
  asIsOrder(_a: any, _b: any) {
    return 1;
  }

  ifOverflow(e: HTMLTableCellElement) {
    return e.scrollWidth > e.clientWidth;
  }
}
