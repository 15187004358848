import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { isInteger } from 'lodash';

import { CompetitorVariableDirective } from '../../../directives/competitor-variable.directive';
import { FormatFormulaVarsPipe } from '../../../pipes/format-formula-vars/format-formula-vars.pipe';
import { StartStrategy } from '../../../ui-strategy-elements/start/start';
import { formulaValidator, getInvalidTypeErrorMessage } from '../FormulaValidation';

@Component({
  selector: 'app-default-settings-form',
  templateUrl: './default-settings-form.component.html',
  styleUrls: ['./default-settings-form.component.scss'],
})
export class DefaultSettingsFormComponent extends CompetitorVariableDirective implements OnChanges {
  readonly isInteger = isInteger;

  readonly getInvalidTypeErrorMessage = getInvalidTypeErrorMessage;

  @Input() data: StartStrategy | undefined;

  @Input() varsMap: Map<string, string> = new Map();

  @Input() isSavingEnabled? = true;

  @Output() saveCallback = new EventEmitter();

  @Output() cancelCallback = new EventEmitter();

  defaultSettingsForm = new UntypedFormGroup({
    targetPosition: new UntypedFormControl('1'),
    priceGap: new UntypedFormControl('0'),
    priceGapUnit: new UntypedFormControl('absolute', Validators.required),
    priceRequirement: new UntypedFormControl(false, Validators.required),
    adjustToNextPricier: new UntypedFormControl(false, Validators.required),
    searchTargetVar: new UntypedFormControl(null),
    searchGapVar: new UntypedFormControl(null),
  });

  isTargetVarsShown = false;

  isGapVarsShown = false;

  target = this.defaultSettingsForm.get('targetPosition');

  gap = this.defaultSettingsForm.get('priceGap');

  unit = this.defaultSettingsForm.get('priceGapUnit');

  requirement = this.defaultSettingsForm.get('priceRequirement');

  boundary = this.defaultSettingsForm.get('adjustToNextPricier');

  searchTargetVar = '';

  searchGapVar = '';

  get vars() {
    return [...this.varsMap.values()];
  }

  constructor(private formatFormulaVarsPipe: FormatFormulaVarsPipe) {
    super();
  }

  ngOnChanges() {
    this.target?.setValidators(formulaValidator(this.vars));
    this.gap?.setValidators(formulaValidator(this.vars));
    this.target?.setValue(
      this.formatFormulaVarsPipe.transform(this.data?.targetPosition || '1', this.varsMap, true)
    );
    this.requirement?.setValue(this.data?.priceRequirement || false);
    this.gap?.setValue(
      this.formatFormulaVarsPipe.transform(this.data?.priceGap || '0', this.varsMap, true)
    );
    this.unit?.setValue(this.data?.priceGapUnit || 'absolute');
    this.boundary?.setValue(this.data?.adjustToNextPricier || false);
  }

  save() {
    if (this.target?.value)
      this.target.setValue(
        this.formatFormulaVarsPipe.transform(this.target.value, this.varsMap, true)
      );
    if (this.gap?.value)
      this.gap.setValue(this.formatFormulaVarsPipe.transform(this.gap.value, this.varsMap, true));
    this.saveCallback.emit(this.defaultSettingsForm.value);
  }
}
