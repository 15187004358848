<app-condition-form-header></app-condition-form-header>
<div class="content-subheader">
  <mat-icon class="strategy-type-icon">price_check</mat-icon>
  <div class="legend">
    <h3>Recommended price</h3>
    <p>Define under which condition to accept the price advice calculated further to the right.</p>
  </div>
</div>
<div class="form" [formGroup]="ifRecommendedForm">
  <h3>Comparison</h3>
  <div class="radio-group">
    <div class="radio">
      <input id="less" type="radio" [value]="NumericComparator.LESS" formControlName="operator" />
      <label for="less">Less than (<)</label>
    </div>
    <div class="radio">
      <input id="equal" type="radio" [value]="NumericComparator.EQUAL" formControlName="operator" />
      <label for="equal">Equals to (=)</label>
    </div>
    <div class="radio">
      <input
        id="greater"
        type="radio"
        [value]="NumericComparator.GREATER"
        formControlName="operator"
      />
      <label for="greater">Greater than (>)</label>
    </div>
  </div>

  <h3>Price</h3>
  <input type="text" placeholder="e.g. CURRENT_PRICE + 0.01" formControlName="formula" />
  <div class="error" *ngIf="formula?.errors">
    <span *ngIf="formula?.errors?.['invalidType']">
      {{getInvalidTypeErrorMessage(formula?.errors?.['invalidType'])}}
    </span>
    <span *ngIf="formula?.errors?.['invalidItem']">
      {{formula?.errors?.['invalidItem']}}
    </span>
  </div>
  <div class="suggested-vars-link" (click)="isVarsShown = !isVarsShown">
    <span>Available variables</span>
    <mat-icon *ngIf="!isVarsShown">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="isVarsShown">arrow_drop_up</mat-icon>
  </div>
  <div class="suggested-vars-container" [ngClass]="{ 'is-visible': isVarsShown }">
    <div class="suggested-var-filter">
      <input
        type="text"
        formControlName="searchVar"
        [(ngModel)]="searchVar"
        placeholder="Search available variables"
      />
      <button class="search-button {{ searchVar ? 'is-clickable' : '' }}" (click)="searchVar = ''">
        <mat-icon *ngIf="searchVar === ''">search</mat-icon>
        <mat-icon *ngIf="searchVar">clear</mat-icon>
      </button>
    </div>
    <ul class="suggested-vars">
      <li
        *ngFor="let variable of vars | sort : 'noCase' | filterBy : searchVar"
        [ngSwitch]="variable"
      >
        <div *ngSwitchCase="'COMPETITOR'">
          <app-competitor-variable
            class="suggested-var"
            (getCompetitorVariable)="setCompetitorVariable($event, formula)"
          ></app-competitor-variable>
        </div>

        <div *ngSwitchCase="null" class="mt-4 text-center">No results found</div>

        <div
          *ngSwitchDefault
          class="suggested-var"
          (click)="formula?.setValue((formula?.value || '') + variable); formula?.markAsDirty()"
        >
          {{ variable }}
        </div>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="isSavingEnabled">
    <div class="row mt-4 flex justify-end">
      <app-raised-button
        class="mr-4"
        text="Apply"
        [disabled]="operator?.errors || formula?.errors || !ifRecommendedForm.dirty"
        (click)="save()"
      >
      </app-raised-button>
      <app-raised-button text="Cancel" (click)="cancelCallback.emit()"></app-raised-button>
    </div>
  </ng-container>
</div>
