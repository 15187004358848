/**
 * This helper includes methods that are Timestamp related
 */
export function createTimestamp(date: string, time: string): Date {
  const [hours, minutes] = time.split(':');
  const localTimestamp = new Date(date);
  localTimestamp.setHours(parseInt(hours));
  localTimestamp.setMinutes(parseInt(minutes));

  return localTimestamp;
}

export function createTimestampInUTC(date: string, time: string): string {
  const localTimestamp = createTimestamp(date, time);
  return localTimestamp.toISOString();
}

export function extractDate(
  date: string,
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): string {
  const timestamp = new Date(date);
  // en-CA locale represents the date format YYYY-MM-DD
  // date is stored in UTC but has to be displayed in local timezone
  // unless otherwise specified via timezone parameter like 'Europe/Berlin'
  return timestamp.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
}

export function extractTime(
  date: string,
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): string {
  const timestamp = new Date(date);
  return (
    timestamp
      .toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timezone,
        hour12: false,
      })
      // we use 00:00 instead of 24:00. 23:59 turns to 00:00
      .replace('24:00', '00:00')
  );
}
