import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ContractSwitcherCurrentItem, ContractSwitcherData } from './PageHeader.models';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() headerSteps: string | null = null;

  @Input() icon?: string;

  @Input() email?: string;

  @Input() contractSwitcherData?: ContractSwitcherData;

  @Output() contractSwitcherAction = new EventEmitter<ContractSwitcherCurrentItem>();
}
