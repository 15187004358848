import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() placeholder: string = '';

  @Input() selected = false;

  @Input() disabled = false;

  @Input() hasIcon = false;

  @Input() route: string = '';

  @Output() clickEvent = new EventEmitter();

  public onClick(): void {
    this.clickEvent.emit();
  }
}
